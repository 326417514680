import {
    ApiCallState,
    EntityWrapper,
    selectApiBase,
    selectAuthState,
    selectCombinedApiState,
    withAuthedAxios,
} from "@thekeytechnology/framework-react";
import {withTranslation, WithTranslation} from "react-i18next";
import {FieldInputProps, Form, Formik, FormikProps} from "formik";
import React, {useEffect} from "react";
import {
    ContentContainer,
    EditHeaderWithBackground,
    LoadingRow,
    SaveAndBackButtons,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps,
    WysiwygField
} from "@thekeytechnology/framework-react-ux";
import {connect, useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {selectNextSettlementNumber} from "../selectors";
import {faBox} from "@fortawesome/free-solid-svg-icons";
import {
    Address,
    AddressField,
    CurrencyDisplay,
    CustomerJustIdAndName,
    EditFormHeading,
} from "@thekeytechnology/auktionshaus-frontend-library";
import {ENTITY_TYPE_SETTLEMENT_FOR_SAVING, SettlementForSaving} from "../model/settlement-for-saving";
import {API_GET_CURRENT_SETTLEMENT_NUMBER, getCurrentSettlementNumber} from "../actions/get-current-settlement-number";
import {
    ENTITY_NEW_SETTLEMENT_FACTORY,
    ENTITY_TYPE_SETTLEMENT,
    Settlement,
    SETTLEMENT_STATUS_OPEN
} from "../model/settlement";
import {OrderHistoryList} from "../../orders/components/order-history/OrderHistoryList";
import {
    ACTION_FACTORY_FETCH_CREDIT_NOTE,
    ACTION_FACTORY_REFRESH_CREDIT_NOTE,
    ACTION_FACTORY_SEND_CREDIT_NOTE_EMAIL,
    ReceiptList
} from "../../orders/components/receipt-list/ReceiptList";
import {PositionsField} from "../../orders/components/positions-field/PositionsField";
import {GenerateCreditNoteButton} from "./GenerateCreditNoteButton";
import {API_REFRESH_CREDIT_NOTE} from "../actions/refresh-credit-note";
import {API_SEND_CREDIT_NOTE} from "../actions/send-credit-note";
import {CancelSettlement} from "./CancelSettlement";
import {CustomerWarnings} from "../../warnings/components/CustomerWarnings";
import {CustomerSelectField} from "../../items/components/edit/CustomerSelectField";
import {saveSettlementAction} from "../actions/save-settlement";
import {CustomerAddress} from "../../customers/model/customer-address";
import {PageTitle} from "../../core/components/PageTitle";
import {DownloadSettlementPreviewButton} from "./DownloadSettlementPreviewButton";
import {GenerateFollowUpCreditNoteButton} from "./GenerateFollowUpCreditNoteButton";
import {NavLink} from "react-bootstrap";
import {DownloadBackToSellerListButton} from "./DownloadBackToSellerListButton";
import {LogDisplay} from "../../log/components/LogDisplay";

interface StateProps {
    settlementNumberApiCallState: ApiCallState;
    nextSettlementNumber?: number;
}

interface DispatchProps {
    getCurrentSettlementNumber: typeof getCurrentSettlementNumber
}

type Props =
    StateProps
    & DispatchProps
    & WithSingleEntityFromPathProps<Settlement, SettlementForSaving>
    & WithTranslation;

const SettlementEditFormComponent = ({
                                         entity,
                                         t,
                                         nextSettlementNumber,
                                         settlementNumberApiCallState,
                                         getCurrentSettlementNumber
                                     }: Props) => {

    useEffect(() => {
        getCurrentSettlementNumber();
    }, [getCurrentSettlementNumber]);


    const dispatch = useDispatch();

    const authState = useSelector(selectAuthState)
    const apiBase = useSelector(selectApiBase)
    const authAxios = withAuthedAxios(apiBase, authState)

    return entity && nextSettlementNumber !== undefined && settlementNumberApiCallState.succeeded ? (
        <>
            <PageTitle title={"Abr. " + entity.entity.settlementNumber}/>
            <EditFormHeading entity={entity} label={t("entity.singular")}/>

            <Formik
                initialValues={{
                    settlementNumber: entity.entity.settlementNumber ? entity.entity.settlementNumber : nextSettlementNumber,
                    customer: entity.entity.customer ? entity.entity.customer : undefined,
                    positions: entity.entity.positions,
                    billingAddress: entity.entity.billingAddress,
                    checkNumber: entity.entity.checkNumber,
                    status: entity.entity.status,
                    comment: entity.entity.comment,
                }}
                validateOnMount={true}
                // enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    settlementNumber: Yup.number().required(t("core:forms.required-field", {fieldName: t("entity.labels.order-number")})),
                    customer: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.customer")})),
                    billingAddress: Yup
                        .object()
                        .test("test", "test", function (value) {
                            const address = value as Address
                            if (!address.street || !address.houseNumber || !address.country || !address.name || !address.postalCode) {
                                return this.createError({
                                    path: "billingAddress",
                                    message: "Alle Felder einer Adresse müssen ausgefüllt sein."
                                });
                            }
                            return true;
                        }),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper<SettlementForSaving>(entity.id, {
                        settlementNumber: values.settlementNumber,
                        customerRef: values.customer!.id!,
                        positions: values.positions,
                        billingAddress: values.billingAddress!,
                        status: values.status,
                        comment: values.comment,
                        attachedReceipts: entity.entity.attachedReceipts,
                        settlementHistory: entity.entity.settlementHistory,
                        checkNumber: values.checkNumber,
                        attributes: entity.entity.attributes,
                        createdAt: entity.entity.createdAt,
                        auctionRef: entity?.entity.auctionRef
                    });
                    // upsertEntity(saving);
                    dispatch(saveSettlementAction(saving))
                    setSubmitting(false);
                }}
            >
                {formikState => {
                    const editingDisabled = entity?.entity.status !== SETTLEMENT_STATUS_OPEN;

                    return (
                        <Form>
                            <EditHeaderWithBackground
                                heading={entity.id ?
                                    <div className={"mb-5"}>
                                        {entity.entity.settlementNumber} | {entity?.entity.customer?.entity.name} (<NavLink
                                        href={"/kunden/" + entity?.entity.customer?.id + "/edit"} target={"_blank"}
                                        className={"p-0 d-inline"}>{entity?.entity.customer?.entity.customerNumber}</NavLink>)
                                        | <CurrencyDisplay
                                        amount={entity?.entity.sum}/> | {t("status." + entity?.entity.status)}
                                    </div> :
                                    t("core:edit-header.heading-empty")}
                            >
                                {entity.id ? <>
                                    <CancelSettlement settlement={entity}/>
                                    <GenerateCreditNoteButton settlement={entity} disabled={formikState.dirty}/>
                                    <GenerateFollowUpCreditNoteButton settlement={entity} disabled={formikState.dirty}/>
                                </> : null}

                                {entity?.entity.positions.length ? <>
                                        <DownloadSettlementPreviewButton settlement={entity}/>
                                        <DownloadBackToSellerListButton settlement={entity}/>
                                    </>
                                    : null}

                                <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                    backPath="/abrechnungen/"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <CustomerWarnings customerId={formikState.values.customer?.id}/>

                                <OrderHistoryList orderHistory={entity?.entity.settlementHistory}/>
                                <ReceiptList containerId={entity?.id!}
                                             containerType={ENTITY_TYPE_SETTLEMENT}
                                             containerStatus={entity?.entity.status}
                                             attachedReceipts={entity?.entity.attachedReceipts}
                                             refreshReceiptActionFactory={ACTION_FACTORY_REFRESH_CREDIT_NOTE}
                                             fetchActionFactory={ACTION_FACTORY_FETCH_CREDIT_NOTE}
                                             sendEmailActionFactory={ACTION_FACTORY_SEND_CREDIT_NOTE_EMAIL}
                                             refreshApiStateId={API_REFRESH_CREDIT_NOTE}
                                             sendReceiptApiStateId={API_SEND_CREDIT_NOTE}
                                />

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Status</label>
                                    <div className="col-10 d-flex align-items-center">
                                        {t("status." + entity?.entity.status)}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="number"
                                                    disabled={editingDisabled}
                                                    placeholder={t("entity.labels.settlement-number")}
                                                    name="settlementNumber"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.settlement-number")}
                                                    required/>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={CustomerSelectField}
                                                    name="customer"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.customer")}
                                                    required
                                                    disabled={editingDisabled}
                                                    isDisabled={editingDisabled}
                                                    onSelect={(selected: EntityWrapper<CustomerJustIdAndName> | EntityWrapper<CustomerJustIdAndName>[] | undefined,
                                                               form: FormikProps<any>,
                                                               field: FieldInputProps<any>) => {
                                                        const singleSelected = Array.isArray(selected) ? selected[0] : selected
                                                        if (singleSelected?.id) {
                                                            authAxios.get<CustomerAddress>("/customers/" + singleSelected?.id + "/get-address")
                                                                .then(result => {
                                                                    formikState.setFieldValue("billingAddress", result.data.billingAddress)
                                                                })
                                                        }
                                                    }}
                                    />
                                </div>
                                <hr/>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    disabled={editingDisabled}
                                                    component={PositionsField}
                                                    name="positions"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.positions")}
                                                    containerType={ENTITY_TYPE_SETTLEMENT}
                                    />
                                </div>
                                <hr/>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    disabled={editingDisabled}
                                                    component={AddressField}
                                                    placeholder={t("entity.labels.billing-address")}
                                                    name="billingAddress"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.billing-address")}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="text"
                                                    icon={faBox}
                                                    placeholder={t("entity.labels.check-number")}
                                                    name="checkNumber"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.check-number")}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={WysiwygField}
                                                    placeholder={t("entity.labels.comment")}
                                                    name="comment"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.comment")}
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    );
                }}
            </Formik>
            {entity.id ? <LogDisplay entityId={entity.id}/> : null}
        </>
    ) : <LoadingRow/>;
};

export const SettlementEditForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            settlementNumberApiCallState: selectCombinedApiState(API_GET_CURRENT_SETTLEMENT_NUMBER)(state),
            nextSettlementNumber: selectNextSettlementNumber(state)
        };
    },
    {
        getCurrentSettlementNumber: getCurrentSettlementNumber,
    }
)(WithSingleEntityFromPath<StateProps & DispatchProps, Settlement, SettlementForSaving>(
    withTranslation(["settlement", "core"])(SettlementEditFormComponent),
    ENTITY_TYPE_SETTLEMENT,
    "settlementId",
    ENTITY_NEW_SETTLEMENT_FACTORY,
    undefined,
    ENTITY_TYPE_SETTLEMENT_FOR_SAVING
) as any);
