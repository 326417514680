import {Action} from "redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctioneerLiveAuction} from "../model/auctioneer-live-auction";

export const AUCTIONEER_UPDATED_ACTION = "auctioneer-updated";

export interface AuctioneerUpdatedAction extends Action {
    liveAuction: EntityWrapper<AuctioneerLiveAuction>;
}

export const auctioneerUpdatedAction = (liveAuction: EntityWrapper<AuctioneerLiveAuction>) => {
    return {
        type: AUCTIONEER_UPDATED_ACTION,
        liveAuction
    } as AuctioneerUpdatedAction;
};
