import React, {useState} from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {AuctioneerBid} from "../../../../bids/model/auctioneerBid";
import {AssignWinningBidForm} from "../../../../bids/components/AssignWinningBidForm";
import {faGavel} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {closeLotForBiddingAction} from "../../../actions/close-lot-for-bidding-action";
import {selectAuctioneerLotId} from "../../../selectors";

interface OwnProps {
    bid: EntityWrapper<AuctioneerBid>;
    useIcon?: boolean;
}

type Props = OwnProps;

export const AssignWinningBidButton = ({
                                           bid,
                                           useIcon = false
                                       }: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const {t} = useTranslation(["auctioneer"]);

    const currentLotId = useSelector(selectAuctioneerLotId)

    if (!currentLotId) {
        return null;
    }

    return <>
        <button
            className={"btn-link assign-winning-bid"}
            onClick={() => {
                dispatch(closeLotForBiddingAction(currentLotId));
                handleShow();
            }}
        >
            {useIcon ? <FontAwesomeIcon icon={faGavel}/> : t("actions.assign-winning-bid")}
        </button>

        <Modal size="xl" show={show} onHide={handleClose} autoFocus={false} onShow={() => {}}>
            {show ? <AssignWinningBidForm handleClose={handleClose} bid={bid}/> : null}
        </Modal>
    </>;
};
