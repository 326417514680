import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {startLiveAuctionAction} from "../actions/start-live-auction-action";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGavel} from "@fortawesome/free-solid-svg-icons";
import {OverlayTooltip} from "@thekeytechnology/framework-react-ux";

interface DispatchProps {
    startAuction: typeof startLiveAuctionAction
}

interface OwnProps {
    auctionId: string
}

type Props = OwnProps & DispatchProps;

const StartAuctionButtonComponent = ({
                                         startAuction,
                                         auctionId
                                     }: Props) => {

    const {t} = useTranslation(["auction"]);

    const onClick = () => {
        startAuction(auctionId);
    }

    return <OverlayTooltip tooltip={t("entity.labels.start-auction")}>
        <button className={"btn-link start-button"}
                onClick={onClick}>
            <FontAwesomeIcon icon={faGavel}/>
        </button>
    </OverlayTooltip>
};

export const StartAuctionButton = connect<{}, DispatchProps, {}>(
    null,
    {
        startAuction: startLiveAuctionAction
    }
)
(StartAuctionButtonComponent);
