import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";

export class AuctioneerLotShort {
    public static TYPE = "lot";
    public static CONTEXT = "auctioneer-short";

    constructor(
        public status: string,
        public lotNumber: number | undefined,
        public description: { [lang: string]: string },
        public bidCount: number,
        public thumbnail?: EntityWrapper<TkFile>,
    ) {
    }
}
