import {OrderPosition, RECEIPT_TYPE_INVOICE} from "@thekeytechnology/auktionshaus-frontend-library";
import * as Yup from "yup";
import React from "react";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {PositionsField} from "../positions-field/PositionsField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useDispatch, useSelector} from "react-redux";
import {ReceiptTypeSelectField} from "./ReceiptTypeSelectField";
import {API_CREATE_CUSTOM_RECEIPT, createCustomReceiptAction} from "../../actions/custom-receipt";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";

interface OwnProps {
    initialPositions?: OrderPosition[];
    handleClose: () => void;
    containerId: string,
    containerType: string
}

type Props = OwnProps

export const CustomReceiptForm = ({
                                      initialPositions,
                                      handleClose,
                                      containerId,
                                      containerType
                                  }: Props) => {

    const {t} = useTranslation(["order"]);

    const dispatch = useDispatch();

    const apiCallState = useSelector(selectCombinedApiState(API_CREATE_CUSTOM_RECEIPT))


    const disabled = apiCallState.inProgress;


    return <Formik
        initialValues={{
            positions: initialPositions ? initialPositions : [],
            receiptType: RECEIPT_TYPE_INVOICE
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
            positions: Yup.array().min(1, t("entity.labels.positions")),
            receiptType: Yup.string().required()
        })}
        onSubmit={(values, {setSubmitting}) => {
            dispatch(createCustomReceiptAction(
                containerId,
                values.receiptType,
                values.positions,
                containerType
            ))
        }}
    >
        {
            formikState => {
                return (
                    <Form className="custom-receipt-form">
                        <Modal.Header closeButton>
                            <Modal.Title>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={ReceiptTypeSelectField}
                                                name="receiptType"
                                                className="default-input"
                                                label={"Typ"}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={PositionsField}
                                                name="positions"
                                                className="form-control default-input"
                                                containerType={containerType}
                                />
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button type={"submit"} className={"btn btn-primary"} disabled={disabled}>
                                Erstellen
                            </Button>
                            <Button className="btn btn-secondary"
                                    onClick={handleClose}>
                                {t("core:save-and-back-buttons.cancel")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )
            }
        }
    </Formik>

}