import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities,
    setPaginationQueryAction,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React, {useEffect} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import Select from "react-select";
import {connect} from "react-redux";
import {ItemCategory} from "../../model/itemCategory";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";

interface StateProps {
    categories: Array<EntityWrapper<ItemCategory>>;
}

interface DispatchProps {
    fetchCategories: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
}

type Props = StateProps & DispatchProps & WithTranslation & FieldProps & WithSwitchableLanguageProps;

const dot = (color = "#ccc") => ({
    "alignItems": "center",
    "display": "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: "\" \"",
        display: "block",
        marginRight: 10,
        height: 12,
        width: 12,
    },
});

// noinspection JSUnusedGlobalSymbols
const colourStyles = {
    singleValue: (styles: any, {data}: any) => ({...styles, ...dot(data.entity.color)}),
};

const CategorySelectField = ({categories, field, t, currentLanguage, form, setPaginationQuery, fetchCategories}: Props) => {

    useEffect(() => {
        setPaginationQuery({docsPerPage: -1, page: 0});
        fetchCategories();
    }, [setPaginationQuery, fetchCategories]);

    const onChange = (category: EntityWrapper<ItemCategory>) => {
        form.setFieldValue(field.name, category);
    };

    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            placeholder={t("core:controls.select.select-with-type", {
                type: t("entity.singular")
            })}
            components={{ClearIndicator}}
            isClearable={true}
            options={categories}
            name={field.name}
            value={categories ?
                categories.find(option => field.value && (field.value.id === option.id)) : undefined}
            onChange={onChange as any}
            getOptionValue={opt => opt.id!}
            getOptionLabel={(item: EntityWrapper<ItemCategory>) => item.entity.orderIndex.toString() + " " + item.entity.name["de"]}
            onBlur={field.onBlur}
            styles={colourStyles}
        />
    );
};

export default connect<StateProps, DispatchProps, FieldProps>(
    (state: any) => {
        return {
            categories: selectEntities<ItemCategory>(ItemCategory.TYPE)(state)
        };
    },
    {
        fetchCategories: reloadEntityListAction(ItemCategory.TYPE),
        setPaginationQuery: setPaginationQueryAction(ItemCategory.TYPE)
    }
)(
    WithSwitchableLanguage<any>(withTranslation(["itemCategory", "core"])(CategorySelectField))
);
