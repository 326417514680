import {useTranslation} from "react-i18next";
import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {
    SHIPPING_METHOD_ALLKURIER,
    SHIPPING_METHOD_DHL,
    SHIPPING_METHOD_DPD,
    SHIPPING_METHOD_OTHER,
    SHIPPING_METHOD_PICKUP,
    SHIPPING_METHOD_REGISTERED_MAIL
} from "../../../orders/model/Order";

type Props = FieldProps
export const ShippingMethodSelectField = ({field, form}: Props) => {

    const {t} = useTranslation(["customer","core"])

    const options = [
        SHIPPING_METHOD_DHL,
        SHIPPING_METHOD_DPD,
        SHIPPING_METHOD_ALLKURIER,
        SHIPPING_METHOD_PICKUP,
        SHIPPING_METHOD_REGISTERED_MAIL,
        SHIPPING_METHOD_OTHER
    ].map(o => {
        return {
            value: o,
            label: t("shipping-method-options." + o)
        }
    })

    return (<Select
        className="react-select category-select"
        classNamePrefix="react-select"
        placeholder={t("shipping-method-select-field.placeholder")}
        components={{ClearIndicator}}
        options={options}
        name={field.name}
        value={options.find(o => o.value === field.value)}
        onChange={(item: any) => form.setFieldValue(field.name, item.value)}
        getOptionValue={opt => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};