import {combineReducers} from "redux";
import {currentHighestCustomerNumberReducer} from "./currentCustomerReducer";
import {customerAddressReducer} from "./currentCustomerAddressReducer";

export const customerModuleReducer = combineReducers({
    currentHighestCustomerNumber: currentHighestCustomerNumberReducer,
    customerAddress: customerAddressReducer
});

export type CustomerModuleState = ReturnType<typeof customerModuleReducer>;
