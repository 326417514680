import {createSelector} from "reselect";
import {AppState} from "../../reducers";
import {WarningModuleState} from "../reducers";

export const selectWarningModuleState = (state: AppState) => state.warning;

export const selectWarnings = createSelector(
    selectWarningModuleState,
    (moduleState: WarningModuleState) =>
        moduleState.warning
);
