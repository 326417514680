import React from "react";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";
import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux/";

interface OwnProps {
    property: string
    placeholder: string
    endpointFilters: PaginatedEndpointFiltersReturnProps
    options: { value: string, label: string}[]
    customValueFunction?: (property: string, endpointFilters: PaginatedEndpointFiltersReturnProps) => string | undefined
    customOnSelectFunction?: (
        property: string,
        endpointFilters: PaginatedEndpointFiltersReturnProps,
        selected: { value: string, label: string} | { value: string, label: string}[] | undefined
    ) => void
}

type Props = OwnProps

export const PaginatedEndpointSimpleSelectFilter = ({
                                                        property,
                                                        placeholder,
                                                        endpointFilters,
                                                        customValueFunction,
                                                        options,
                                                        customOnSelectFunction
                                                    }: Props) => {

    const setSelected = () => {
        let value: string | undefined = undefined;
        if (customValueFunction) {
            value = customValueFunction(property, endpointFilters)
        } else {
            const currentFilters = endpointFilters.getFilters().filter(f => f.key === property).flatMap(f => f.filters)
            value = (currentFilters && currentFilters.length && currentFilters[0]) ?
                (currentFilters[0] as PropertyFilter).filterTerm.value : undefined
        }

        const currentOption = options.find(option => option.value === value)
        return currentOption || null;
    }

    const onSelect = (selected: { value: string, label: string} | { value: string, label: string}[] | undefined) => {
        if (customOnSelectFunction) {
            customOnSelectFunction(property, endpointFilters,selected)
        } else {
            if (selected) {
                if (Array.isArray(selected)) {
                    //Multiple
                    endpointFilters.addFilters(property, [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_IN, selected.map(s => s.value)))])
                } else {
                    //Single
                    endpointFilters.addFilters(property, [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, selected.value))])
                }
            } else {
                endpointFilters.removeFilters([property])
            }
        }
    }

    return <Select
        components={{ClearIndicator}}
        placeholder={placeholder}
        className="filter react-select"
        classNamePrefix="react-select"
        isClearable={true}
        onChange={(option: any) => onSelect(option as { value: string, label: string})}
        options={options as any}
        value={setSelected()}
    />

}
