import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities,
    setPaginationQueryAction,
} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React, {useEffect} from "react";
import {useTranslation, WithTranslation} from "react-i18next";
import Select from "react-select";
import {connect} from "react-redux";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {Auction, ENTITY_TYPE_AUCTION} from "../model/auction";

interface StateProps {
    auctions: Array<EntityWrapper<Auction>>;
}

interface DispatchProps {
    fetchAuctions: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
}

type Props = StateProps & DispatchProps & WithTranslation & FieldProps;

const AuctionSelectFieldComponent = (props: Props) => {
    const {
        auctions,
        field,
        fetchAuctions,
        setPaginationQuery
    } = props;
    const {t} = useTranslation(["auction", "core"]);

    useEffect(() => {
        setPaginationQuery({docsPerPage: -1, page: 0});
        fetchAuctions();
    }, [setPaginationQuery, fetchAuctions]);

    const onChange = (selectedAuction: EntityWrapper<Auction>) => {
        props.form.setFieldValue(props.field.name, selectedAuction);
    };

    const getValue = () => {
        return auctions ?
            auctions.find(option => field.value && (field.value.id === option.id)) : undefined;
    };

    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            placeholder={t("core:controls.select.select-with-type", {
                type: t("entity.singular")
            })}
            components={{ClearIndicator}}
            isClearable={true}
            options={auctions}
            name={field.name}
            value={getValue()}
            onChange={onChange as any}
            getOptionValue={auction => auction.id!}
            getOptionLabel={(auction: EntityWrapper<Auction>) => `${auction.entity.auctionNumber}`}
            onBlur={field.onBlur}
            isMulti={false}
        />
    );
};

export const AuctionSelectField = connect<StateProps, DispatchProps, FieldProps>(
    (state: any) => {
        return {
            auctions: selectEntities<Auction>(ENTITY_TYPE_AUCTION)(state)
        };
    },
    {
        fetchAuctions: reloadEntityListAction(ENTITY_TYPE_AUCTION),
        setPaginationQuery: setPaginationQueryAction(ENTITY_TYPE_AUCTION)
    }
)(
    AuctionSelectFieldComponent
);
