import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {EmailCustomerModal} from "./EmailCustomerModal";

interface OwnProps {
    customerId: string
}

export const EmailCustomerButton = ({customerId}: OwnProps) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        <button type="button"
                className="btn btn-secondary mr-3"
                onClick={() => handleShow()}
        >
            <FontAwesomeIcon className="mr-2"
                             icon={faEnvelope}/> Sende E-Mail
        </button>

        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <EmailCustomerModal customerId={customerId} handleClose={handleClose}/> : null}
        </Modal>
    </>
};

