import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_RECEIPT = "download-receipt";

export interface DownloadReceiptPayload {
    receiptId: string,
    receiptType: string
}

export const downloadReceiptAction = (receiptId: string, receiptType: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_RECEIPT,
            callType: API_DOWNLOAD_RECEIPT
        },
        payload: {
            receiptId,
            receiptType
        }
    } as ApiCallAction<DownloadReceiptPayload>;
};
