import {
    addFiltersAction,
    ComplexFilter,
    Filter,
    reloadEntityListAction,
    removeFiltersAction,
    selectFilter
} from "@thekeytechnology/framework-react";
import React from "react";
import {connect} from "react-redux";
import {withTranslation, WithTranslation} from "react-i18next";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>,
    removeFilters: ReturnType<typeof removeFiltersAction>
    reloadEntities: ReturnType<typeof reloadEntityListAction>
}

type Props = StateProps & DispatchProps & WithTranslation;

export const ONLY_INVALID_FILTER_KEY = "only-invalid";
export const ONLY_INVALID_FILTER = new ComplexFilter(ONLY_INVALID_FILTER_KEY, {});

const OnlyInvalidLotsFilterComponent = ({addFilters, removeFilters, reloadEntities, currentFilters, t}: Props) => {
    const filter = (isChecked: boolean) => {
        if (isChecked) {
            addFilters(ONLY_INVALID_FILTER_KEY, [ONLY_INVALID_FILTER]);
        } else {
            removeFilters([ONLY_INVALID_FILTER_KEY]);
        }
        reloadEntities()
    };

    const isFiltered = currentFilters && currentFilters.filter(f => f.filterType === "complex-filter").length > 0;

    return (
        <div className="form-check d-flex align-items-center pl-3 pr-3">
            <input
                className="mr-2"
                type="checkbox"
                checked={isFiltered}
                onChange={change => filter(change.target.checked)}
            />
            <label className="form-check-label" htmlFor="exampleCheck1">{t("only-invalid-lots-filters.text")}</label>
        </div>
    );
};

export const OnlyInvalidLotsFilter = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            currentFilters: selectFilter(AuctionManagerLot.TYPE, ONLY_INVALID_FILTER_KEY)(state)
        };
    },
    {
        addFilters: addFiltersAction(AuctionManagerLot.TYPE),
        removeFilters: removeFiltersAction(AuctionManagerLot.TYPE),
        reloadEntities: reloadEntityListAction(AuctionManagerLot.TYPE)
    }
)(withTranslation("auctionPlanning")(OnlyInvalidLotsFilterComponent));
