import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_DESCRIPTION = "update-description";

export interface UpdateDescriptionPayload {
    lotId: string;
    writeToItem: boolean;
    description: { [lang: string]: string }
}

export const updateDescriptionAction = (lotId: string, writeToItem: boolean, description: { [lang: string]: string }) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_DESCRIPTION,
            callType: API_UPDATE_DESCRIPTION
        },
        payload: {
            lotId,
            writeToItem,
            description
        }
    } as ApiCallAction<UpdateDescriptionPayload>;
};
