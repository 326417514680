import React from "react";
import {useTranslation} from "react-i18next";
import {FieldProps} from "formik";
import Select from "react-select";
import {Bid} from "../model/bid";

interface LabelValuePair {
    value: string;
    label: string;
}

interface OwnProps {
    customSources? : string[]
}

type Props = FieldProps & OwnProps;

export const BidSourceSelectField = ({
                                         field,
                                         form,
                                         customSources
                                     }: Props) => {

    const onChange = (value: LabelValuePair) => {
        const newValue = value.value;
        form.setFieldValue(field.name, newValue);
    };

    const {t} = useTranslation(["bid", "auctioneer"]);

    const options = (customSources ? customSources : Bid.SOURCES_FOR_SELECT_FIELD).map(source => {
        return {
            value: source,
            label: t("source." + source)
        }
    });

    return <Select
        className="react-select"
        classNamePrefix="react-select"
        placeholder={t("auctioneer:labels.source")}
        options={options}
        name={field.name}
        value={field.value ? {
            value: field.value,
            label: t("source." + field.value)
        } : null}
        onChange={onChange as any}
        getOptionValue={opt => opt.value}
        getOptionLabel={opt => opt.label}
        onBlur={field.onBlur}
    />
};
