import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {CurrentHighestCustomerNumber} from "../model/current-highest-customer-number";
import {API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER} from "../actions/current-customer";
import {Action} from "redux";

export interface HighestCustomerNumberState {
    currentHighestCustomerNumber?: number;

}

export const initialState: HighestCustomerNumberState = {
    currentHighestCustomerNumber: undefined,
};

export function currentHighestCustomerNumberReducer(state = initialState, action: Action): HighestCustomerNumberState {
    if (isApiSuccess(API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER)(action)) {
        const customerNumberResponse = action as ApiCallSucceededAction<CurrentHighestCustomerNumber>;
        return {
            ...state,
            currentHighestCustomerNumber: customerNumberResponse.payload.currentHighestCustomerNumber
        };
    }
    return state;
}
