import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {
    AUCTION_STATUS_ARCHIVED,
    AUCTION_STATUS_CONCLUDED,
    AUCTION_STATUS_FINALIZED,
    AUCTION_STATUS_IN_PLANNING,
    AUCTION_STATUS_LIVE
} from "../model/auction";
import {useTranslation} from "react-i18next";

export const AuctionStatusSelectField = (props: FieldProps) => {
    const {field, form} = props;

    const {t} = useTranslation(["auction"]);
    const accountingTypeOptions =
        [AUCTION_STATUS_IN_PLANNING, AUCTION_STATUS_FINALIZED, AUCTION_STATUS_LIVE, AUCTION_STATUS_CONCLUDED, AUCTION_STATUS_ARCHIVED]
            .map(status => ({value: status, label: t(`status.${status}`)}));

    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            components={{ClearIndicator}}
            options={accountingTypeOptions}
            name={field.name}
            value={accountingTypeOptions.find(o => o.value === field.value)}
            onChange={(item: any) => form.setFieldValue(field.name, item.value)}
            getOptionValue={opt => opt.value}
            getOptionLabel={item => item.label}
            onBlur={field.onBlur}
        />);
};
