import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GENERATE_FINANCING_CREDIT_NOTE = "generate-financing-credit-note";

export const generateFinancingCreditNoteAction = (orderId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GENERATE_FINANCING_CREDIT_NOTE,
            callType: API_GENERATE_FINANCING_CREDIT_NOTE
        },
        payload: orderId
    } as ApiCallAction<string>;
};
