import React from "react";
import {useTranslation} from "react-i18next";
import {EntityWrapper, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {PaginatedEndpointCollapsibleFilters} from "../../../paginated-endpoints/components/PaginatedEndpointCollapsibleFilters";
import {PaginatedEndPointTextFilter} from "../../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import {CustomerSelectFilter} from "../../../auction-planning/components/filters/CustomerSelectFilter";
import {PaginatedEndpointSimpleSelectFilter} from "../../../paginated-endpoints/components/PaginatedEndpointSimpleSelectFilter";
import {DUNNING_STATUS_DUNNING_SENT, DUNNING_STATUS_OPEN, DUNNING_STATUS_REMINDER_MAIL_SENT} from "../../model/Order";
import {PaginatedEndPointAsyncEntitySelectFilter} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelectFilter";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelect";
import {createRangeFilterForNumberInput} from "../../../core/utils/create-range-filter-for-number-input";
import {parseInt} from "lodash";
import {DaysSinceFilter} from "./DaysSinceFilter";


interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = OwnProps

export const DunningsFilters = ({
                                    endpointFilters
                                }: Props) => {

    const {t} = useTranslation(["order", "core", "item"]);

    return (
        <div className={"tk-itemCategory-filter"}>
            <PaginatedEndpointCollapsibleFilters
                endpointFilters={endpointFilters}
                filterKeys={[
                    "entity.orderNumber",
                    "entity.customerRef",
                    "entity.attachedReceipts.status",
                    "entity.status",
                    "entity.attachedReceipts.receiptName",
                    "entity.mainInvoice.dateTime",
                    "entity.mainInvoice.dunningStatus"
                ]}
            >
                <PaginatedEndPointTextFilter
                    property={"entity.orderNumber"}
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.order-number")
                    })}
                    customFilterFunction={(
                        property,
                        endpointFilters,
                        text
                    ) => {
                        if (text?.length) {
                            const intVal = parseInt(text);
                            if (intVal) {
                                endpointFilters.addFilters(property, [
                                    new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, parseInt(text)))
                                ])
                            }
                        } else {
                            endpointFilters.removeFilters([property]);
                        }
                    }}
                />

                <PaginatedEndPointTextFilter
                    property={"entity.attachedReceipts.receiptName"}
                    placeholder={t("entity.labels.invoice-number")}
                    endpointFilters={endpointFilters}
                />

                <CustomerSelectFilter
                    endpointFilters={endpointFilters}
                    property={"entity.customerRef"}
                    placeholder={t("entity.labels.customer")}/>

                <PaginatedEndpointSimpleSelectFilter
                    property={"entity.mainInvoice.dunningStatus"}
                    placeholder={t("entity.labels.dunning-status")}
                    endpointFilters={endpointFilters}
                    options={[DUNNING_STATUS_OPEN, DUNNING_STATUS_REMINDER_MAIL_SENT, DUNNING_STATUS_DUNNING_SENT]
                        .map(status => ({
                            label: t("attached-receipt.dunning-status." + status),
                            value: status
                        }))}
                />

                <DaysSinceFilter
                    endpointFilters={endpointFilters}
                    placeholder={t("attached-receipt.days-since-creation")}
                    property={"entity.mainInvoice.dateTime"}
                />

                <DaysSinceFilter
                    endpointFilters={endpointFilters}
                    placeholder={t("attached-receipt.days-since-last-action")}
                    property={"entity.mainInvoice.lastDunningChange"}
                />

                <PaginatedEndPointAsyncEntitySelectFilter<Auction>
                    property={"entity.auctionRef"}
                    entityType={ENTITY_TYPE_AUCTION}
                    shownEntityProperties={["entity.auctionNumber"]}
                    listRenderer={(item: EntityWrapper<Auction>) => item.entity.auctionNumber.toString()}
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.auction")
                    })}
                    endpointFilters={endpointFilters}
                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/auctions/get-auctions")}
                    customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                        if (inputValue) {
                            return createRangeFilterForNumberInput(property, inputValue, 3)
                        } else {
                            return []
                        }
                    }}
                />
            </PaginatedEndpointCollapsibleFilters>
        </div>
    );
};

