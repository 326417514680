import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Filters, TextFilter} from "@thekeytechnology/framework-react-ux";
import {ItemCategory} from "../model/itemCategory";

const ItemCategoryFilterComponent = (props: WithTranslation) => {
    const {
        t
    } = props;
    return (
        <div className={"tk-itemCategory-filter"}>
            <Filters
                entityType={ItemCategory.TYPE}
                filterKeys={["entity.name", "entity.color"]}>
                <TextFilter
                    placeholder={t("core:filters.search")}
                    entityType={ItemCategory.TYPE}
                    property="entity.name"/>
            </Filters>
        </div>
    );
};

export const ItemCategoryFilter = withTranslation("itemCategory")(ItemCategoryFilterComponent);
