import {DateTimeDisplay} from "@thekeytechnology/framework-react-ux";
import React from "react";
import "./order-history-list.scss";
import {OrderHistory} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    orderHistory: OrderHistory[]
}

export const OrderHistoryList = ({orderHistory}: OwnProps) => {
    return orderHistory.length > 0 ? <>
        <h3>Aktivitäten</h3>
        <div className="order-history-list mb-5 alert alert-secondary">
            <div className="history-row header">
                <div className="upload-time">Erstellt</div>
                <div className="invoice-name">Name</div>
            </div>
            {orderHistory.map((receipt, index) => {
                return <div key={index} className="history-row">
                    <div className="upload-time"><DateTimeDisplay
                        dateTime={receipt.dateTime}/></div>
                    <div className="invoice-name">
                        {receipt.message}
                    </div>
                </div>
            })}
        </div>
    </> : null
}
