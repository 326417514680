import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {SettlementForSaving} from "../model/settlement-for-saving";

export const API_SAVE_SETTLEMENT = "save-settlement";



export const saveSettlementAction = (item: EntityWrapper<SettlementForSaving>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_SETTLEMENT,
            callType: API_SAVE_SETTLEMENT
        },
        payload: item
    } as ApiCallAction<EntityWrapper<SettlementForSaving>>;
};
