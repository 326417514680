export const ENTITY_TYPE_BID_IN_ORDER = "bid"
export const ENTITY_CONTEXT_BID_IN_ORDER = "order"

export interface BidInOrder {
    lotRef: string;
    lotNumber?: number;
    customerName: string,
    lotDescription: { [lang: string]: string },
    timestamp: string,
    value: number
}
