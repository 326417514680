import {EntityWrapper, selectCombinedApiState,} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import React, {useEffect} from "react";
import {
    ContentContainer,
    DateTimeField,
    EditHeaderWithBackground,
    LoadingRow,
    SaveAndBackButtons,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "@thekeytechnology/framework-react-ux";
import {Auction, AUCTION_FACTORY, ENTITY_TYPE_AUCTION} from "../model/auction";
import {API_GET_CURRENT_AUCTION_NUMBER, getCurrentAuctionNumberAction} from "../actions/current-auction";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentAuctionNumber} from "../selectors";
import * as Yup from "yup";
import {EditFormHeading} from "@thekeytechnology/auktionshaus-frontend-library";
import {AuctionStatusSelectField} from "./AuctionStatusSelectField";
import {ArchiveAuctionButton} from "./ArchiveAuctionButton";

type Props = WithSingleEntityFromPathProps<Auction, Auction>;

function AuctionEditFormComponent({entity, upsertEntity}: Props) {
    const dispatch = useDispatch();
    const {t} = useTranslation(["auction", "core"])

    const auctionNumberApiCallState = useSelector(selectCombinedApiState(API_GET_CURRENT_AUCTION_NUMBER))
    const currentAuctionNumber = useSelector(selectCurrentAuctionNumber);

    useEffect(() => {
        dispatch(getCurrentAuctionNumberAction());
    }, [dispatch])


    return entity && auctionNumberApiCallState.succeeded ? (
        <>
            <EditFormHeading entity={entity} label={t("entity.singular")}/>

            <Formik
                initialValues={{
                    auctionNumber: entity.entity.auctionNumber ? entity.entity.auctionNumber : (currentAuctionNumber! + 1),
                    startDateTime: entity.entity.startDateTime,
                    previewDateTime: entity.entity.previewDateTime,
                    nextAuctionDateTime: entity.entity.nextAuctionDateTime,
                    status: entity.entity.status
                }}
                validationSchema={Yup.object().shape({
                    auctionNumber: Yup.number().required(t("core:forms.required-field", {fieldName: t("entity.labels.auction-number")})),
                    startDateTime: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.start-date-time")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper<Auction>(entity.id,
                        {
                            auctionNumber: values.auctionNumber,
                            startDateTime: values.startDateTime,
                            previewDateTime: values.previewDateTime ? values.previewDateTime : undefined,
                            nextAuctionDateTime: values.nextAuctionDateTime ? values.nextAuctionDateTime : undefined,
                            status: values.status
                        }
                    );
                    upsertEntity(saving);
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                entity.entity.auctionNumber :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/auktionen/"/>
                            <ArchiveAuctionButton
                               auctionId={entity?.id!}
                            />
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="number" placeholder={t("entity.labels.auction-number")}
                                                name="auctionNumber"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-number")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={DateTimeField}
                                                name="startDateTime"
                                                className="form-control default-input"
                                                label={t("entity.labels.start-date-time")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={DateTimeField}
                                                name="previewDateTime"
                                                className="form-control default-input"
                                                label={t("entity.labels.preview-date-time")}/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={DateTimeField}
                                                name="nextAuctionDateTime"
                                                className="form-control default-input"
                                                label={t("entity.labels.next-auction-date-time")}/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AuctionStatusSelectField}
                                                name="status"
                                                className="form-control default-input"
                                                label={t("entity.labels.status")}/>
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
}

export const AuctionEditForm = WithSingleEntityFromPath<{}, Auction, Auction>(
    AuctionEditFormComponent,
    ENTITY_TYPE_AUCTION,
    "auctionId", AUCTION_FACTORY);
