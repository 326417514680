import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DELETE_DUPLICATE_CUSTOMERS = "delete-duplicate-customers";

export interface DeleteDuplicateCustomersPayload {
    customerId: string
}

export const deleteDuplicateCustomersAction = (customerId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DELETE_DUPLICATE_CUSTOMERS,
            callType: API_DELETE_DUPLICATE_CUSTOMERS
        },
        payload: {
            customerId
        }
    } as ApiCallAction<DeleteDuplicateCustomersPayload>;
};
