import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import {API_DOWNLOAD_WINNING_BIDS, downloadWinningBidsAction} from "../../actions/winning-bids/download-winning-bids";
import {useTranslation} from "react-i18next";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGavel} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";

export const DownloadWinningBidsButton = () => {

    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_WINNING_BIDS))

    const {t} = useTranslation("auctionPlanning");

    return auctionId ? <Dropdown.Item
        disabled={apiCallState.inProgress}
        onClick={() => {
            dispatch(downloadWinningBidsAction(auctionId));
        }}
    >
        <FontAwesomeIcon className="mr-2"
                         icon={faGavel}/>{t("auction-planning.actions.download-winning-bids-csv")}
    </Dropdown.Item> : null;
}
