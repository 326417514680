import React from "react";
import "./dunnings-table.scss";
import {ApiCallState, EntityWrapper, Pagination, SortQuery} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {
    AboveTableContainer,
    ActionTableColumn,
    AsyncEntityTableBody,
    Table,
    TableColumn,
    TableHeader,
    TableRow,
    UnconnectedPaginationInfo
} from "@thekeytechnology/framework-react-ux";
import {CurrencyDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {TableOrder} from "../../model/TableOrder";
import {PaginatedEndpointSortableTableHeaderColumn} from "../../../paginated-endpoints/components/PaginatedEndpointSortableTableHeaderColumn";
import {CustomActionLink} from "../../../core/components/CustomActionLink/CustomActionLink";
import {SendReminderMailButton} from "./SendReminderMailButton";
import {DownloadDunningButton} from "./DownloadDunningButton";

interface OwnProps {
    orders: Array<EntityWrapper<TableOrder>>;
    apiCallState: ApiCallState;
    pagination: Pagination;
    sortQuery: SortQuery;
    setSortQuery: (sortQuery: SortQuery) => void;
}

type Props = OwnProps;

export const DunningsTable = ({
                                  apiCallState,
                                  orders,
                                  pagination,
                                  sortQuery,
                                  setSortQuery
                              }: Props) => {

    const entities = orders
    const entityApiState = apiCallState

    const {t} = useTranslation(["order", "core", "customer"])

    return (
        <>
            <AboveTableContainer>
                <UnconnectedPaginationInfo pagination={pagination} className="mr-auto"/>
                <NavLink className="mr-3"
                         to={"/bestellungen/"}>
                    <button type="button" className="btn btn-primary">
                        Zurück zu den Bestellungen
                    </button>
                </NavLink>
            </AboveTableContainer>
            <Table className="order-table" itemCount={entities.length}>
                <TableHeader>
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.mainInvoice.dateTime"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.due-for")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.orderNumber"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.order-number")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    <TableColumn>{t("entity.labels.customer")}</TableColumn>
                    <TableColumn>{t("customer:entity.labels.customer-number")}</TableColumn>
                    <TableColumn>{t("entity.labels.sum")}</TableColumn>
                    <TableColumn>{t("entity.labels.dunning-status")}</TableColumn>
                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </TableHeader>

                <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={order => {

                    return (
                        <TableRow key={order.id}>
                            <TableColumn>
                                {order.entity.dueForTime ? order.entity.dueForTime.concat(" Tage") : null}
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {order.entity.orderNumber}
                            </TableColumn>
                            <TableColumn>
                                {order.entity.customer.name}
                            </TableColumn>
                            <TableColumn>
                                {order.entity.customer.customerNumber}
                            </TableColumn>
                            <TableColumn>
                                <CurrencyDisplay amount={order.entity.sum}/>
                            </TableColumn>
                            <TableColumn>
                                {t("attached-receipt.dunning-status." + order.entity.dunningStatus)}
                            </TableColumn>
                            <ActionTableColumn isDisabled={false}>
                                <>
                                    <CustomActionLink to={"/bestellungen/" + order.id + "/edit"}
                                                      tooltip={t("core:actions.edit")}
                                                      icon={faPencilAlt}/>
                                    {order.id && order.entity.mainInvoiceId ?
                                        <>
                                            <SendReminderMailButton orderId={order.id}
                                                                    receiptId={order.entity.mainInvoiceId!}/>
                                            <DownloadDunningButton orderId={order.id}
                                                                    receiptId={order.entity.mainInvoiceId!}/>
                                        </>
                                        : null
                                    }
                                </>
                            </ActionTableColumn>
                        </TableRow>
                    );
                }}/>
            </Table>
        </>
    );
};
