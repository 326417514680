import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withTranslation, WithTranslation} from "react-i18next";
import {faBox} from "@fortawesome/free-solid-svg-icons/faBox";
import {connect} from "react-redux";
import {assignPreassignedItemsAction} from "../../actions/assign/assign-preassigned-items";
import {EntityWrapper, selectSingleEntity} from "@thekeytechnology/framework-react";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";

interface StateProps {
    selectedAuction?: EntityWrapper<Auction>;
}

interface DispatchProps {
    assignPreassigned: typeof assignPreassignedItemsAction;
}

type Props = WithTranslation & StateProps & DispatchProps;

const AssignPreassignedButtonComponent = (props: Props) => {
    const {t, assignPreassigned, selectedAuction} = props;

    return selectedAuction ? <button type="button" onClick={() => {
        assignPreassigned(selectedAuction.id as string);

    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon className="mr-2"
                         icon={faBox}/>{t("auction-planning.actions.assign-preassigned")}
    </button> : null;
};

export const AssignPreassignedButton = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        selectedAuction: selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION)(state),
    }),
    {
        assignPreassigned: assignPreassignedItemsAction,
    }
)(withTranslation("auctionPlanning")(AssignPreassignedButtonComponent));
