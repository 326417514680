import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_BUYER = "update-buyer";

export interface UpdateBuyerPayload {
    lotId: string
    bidId: string
    buyer?: string
}

export const updateBuyerAction = (lotId: string, bidId: string, buyer: string | undefined) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_BUYER,
            callType: API_UPDATE_BUYER
        },
        payload: {
            lotId,
            bidId,
            buyer
        }
    } as ApiCallAction<UpdateBuyerPayload>;
};
