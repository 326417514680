import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {useTranslation} from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer,
    SimpleHeader,
    UnconnectedPagination
} from "@thekeytechnology/framework-react-ux";
import {OrderFilters} from "./OrderFilters";
import {OrderTable} from "./OrderTable";
import {usePaginatedEndpointFilters} from "../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {usePaginatedEndpoint} from "../../paginated-endpoints/hooks/use-paginated-endpoint";
import {TableOrder} from "../model/TableOrder";
import {PageTitle} from "../../core/components/PageTitle";

export const OrderOverview = () => {

    const {t} = useTranslation(["order"])

    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "order-table"
    )

    const {
        resolved,
        setFilters,
        apiCallState,
        setPaginationQuery,
        setSortQuery,
        sortQuery
    } = usePaginatedEndpoint<TableOrder>(
        "/orders/get-table-orders",
        undefined,
        [],
        endpointFilters.getFilters().flatMap(f => f.filters)
    )


    return (
        <>
            <PageTitle title={"Alle Bestellungen"} />
            <BreadcrumbsItem to={"/bestellungen"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <OrderFilters endpointFilters={endpointFilters}/>
            </BackgroundContainer>
            <ContentContainer>
                <OrderTable orders={resolved.entities}
                            apiCallState={apiCallState}
                            pagination={resolved.pagination}
                            setSortQuery={setSortQuery}
                            sortQuery={sortQuery}
                />
            </ContentContainer>
            <PaginationContainer>
                <UnconnectedPagination pagination={resolved.pagination} setPage={page => {
                    setPaginationQuery({
                        page,
                        docsPerPage: resolved.pagination.docsPerPage
                    })
                }}/>
            </PaginationContainer>
        </>
    );
};
