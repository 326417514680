import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import "./log-message-display.scss";
import {useTranslation} from "react-i18next";

interface OwnProps {
    message?: string;
}

type Props = OwnProps

export const LogMessageDisplay = ({message}: Props) => {

    const {t} = useTranslation("log")

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <div className={"log-message-display"}>
        <div dangerouslySetInnerHTML={{__html: message!}} onClick={handleShow} className={"log-message-preview btn-link"}/>
        <Modal size="lg" show={show} onHide={handleClose}>
            <div className={"p-3"}>
                <h3>{t("entity.labels.message")}</h3>
                <div dangerouslySetInnerHTML={{__html: message!}}/>
            </div>
        </Modal>
    </div>

}