import React, {useState} from "react"
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {Button, Modal} from "react-bootstrap";
import update from "immutability-helper";
import "./winning-bid-selection.scss"
import {POSITION_TYPE_WINNING_BID, WinningBidPosition} from "../../../model/position/WinningBidPosition";
import {Order} from "../../../model/Order";
import {WinningBidsForSelectionTable} from "./WinningBidForSelectionTable";
import {useDispatch} from "react-redux";
import {partiallyCancelOrderAction} from "../../../actions/partially-cancel-order";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";

interface State {
    selectedForAdding: Array<WinningBidPosition>
    newStatus: string
}

interface OwnProps {
    order: EntityWrapper<Order>
    handleClose: () => void;
}

export const PartialCancellationEditor = ({order, handleClose}: OwnProps) => {
    const [state, setState] = useState<State>({selectedForAdding: [], newStatus: "not-sold"});
    const dispatch = useDispatch();

    const handleAdd = (item: WinningBidPosition) => setState(update(state, {selectedForAdding: {$push: [item]}}));
    const handleRemove = (item: WinningBidPosition) => setState(update(state, {selectedForAdding: {$set: state.selectedForAdding.filter(i => i.bidRef !== item.bidRef)}}));

    const selectBids = new Set(state.selectedForAdding.map(i => i.bidRef as string));
    const availableItems = order.entity.positions.filter(p => p.positionType === POSITION_TYPE_WINNING_BID) as WinningBidPosition[];

    const isAllSelected = state.selectedForAdding.length === availableItems.length;
    const options = [{
        value: "not-sold",
        label: "Nicht verkauft"
    }];

    return <>
        <Modal.Header closeButton>
            <Modal.Title>Positionen auswählen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Zu eventuell bereits abgerechneten Losen wird eine Rückrechnung erzeugt.</p>

            <h3>Ausgewählt</h3>
            {state.selectedForAdding.length === 0 ? <p>Nichts ausgewählt</p> :
                <WinningBidsForSelectionTable
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                    items={state.selectedForAdding}
                    selectedBids={selectBids}
                />}

            <hr className="mt-4 mb-2"/>
            <h3>Alle</h3>
            <WinningBidsForSelectionTable
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                items={availableItems}
                selectedBids={selectBids}
            />
            <h3>Neuer Status</h3>
            <Select
                className="react-select category-select"
                classNamePrefix="react-select"
                components={{ClearIndicator}}
                placeholder={"Neuer Status"}
                options={options}
                value={options.find(o => o.value === state.newStatus)}
                onChange={(item: any) => {
                    setState({
                        ...state,
                        newStatus: item.value
                    })
                }}
                getOptionValue={(opt: any) => opt.value}
                getOptionLabel={item => item.label}
                onMenuClose={() => {
                }}
                onMenuOpen={() => {
                }}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button className="btn btn-secondary"
                    onClick={handleClose}>
                Abbrechen
            </Button>

            {isAllSelected ? <div className="alert alert-danger w-100">
                Es können nicht alle Positionen für Teilstornierung ausgewählt werden. Bitte stornieren Sie die gesamte
                Rechnung.
            </div> : null}

            <Button disabled={isAllSelected || state.selectedForAdding.length === 0}
                    className="btn btn-primary"
                    onClick={() => {
                        dispatch(partiallyCancelOrderAction(order.id!, state.selectedForAdding.map(s => s.bidRef!), state.newStatus))
                        handleClose();
                    }}
            >
                Hinzufügen
            </Button>
        </Modal.Footer>
    </>;
};
