import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_CURRENT_ORDER_NUMBER = "get-current-order-number";

export const getCurrentOrderNumber = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_CURRENT_ORDER_NUMBER,
            callType: API_GET_CURRENT_ORDER_NUMBER
        },
        payload: null
    } as ApiCallAction<null>;
};
