import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {faDoorClosed} from "@fortawesome/free-solid-svg-icons";
import {LogoutLink} from "../../auth/components/LogoutLink";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface OwnProps {
    label: string;
    isCollapsed: boolean;
}

export const CollapsibleLogoutLink = ({
                                          isCollapsed,
                                          label
                                      }: OwnProps) => {

    const Link = <li className={isCollapsed ? "d-flex justify-content-center" : undefined}>
            <LogoutLink>
                        <span><FontAwesomeIcon className="separator"
                                               icon={faDoorClosed}/>{isCollapsed ? null : label}</span>
            </LogoutLink>
        </li>
    ;

    return isCollapsed ? <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip id={label.replace(" ", "-").toLowerCase()}>
                {label}
            </Tooltip>
        }
    >
        {Link}
    </OverlayTrigger> : Link
};
