import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import React from "react";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {submitBulkLimitBidsAction} from "../../actions/submit-bulk-limit-bids-action";
import {connect} from "react-redux";
import {CustomerSelectField} from "../../../customers/components/edit/CustomerSelectField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {LotMultiSelectField} from "./LotMultiselectField";
import {BidField} from "@thekeytechnology/auktionshaus-frontend-library";

interface DispatchProps {
    submitBulkLimitBids: typeof submitBulkLimitBidsAction;
}

interface OwnProps {
    handleClose: () => void;
}

type Props = DispatchProps & OwnProps;

const SubmitBulkLimitBidsFormComponent = (props: Props) => {
    const {
        submitBulkLimitBids,
        handleClose
    } = props;

    const {t} = useTranslation(["core", "bid", "lot", "customer"]);

    return (
        <>

            <Formik
                initialValues={{
                    customer: "",
                    bid: 0,
                    lots: [],
                }}
                onSubmit={(values, {setSubmitting}) => {
                    if (values.customer && values.bid && values.lots.length) {
                        submitBulkLimitBids(values.customer, values.bid, values.lots);
                    }
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className={"lot-edit-form col-12 col-md-10 m-auto"}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("bid:entity.labels.bulk-limit-bids")}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={CustomerSelectField}
                                                placeholder={t("customer:entity.singular")}
                                                name="customer"
                                                className="form-control default-input"
                                                label={t("customer:entity.singular")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={BidField}
                                                name="bid"
                                                className="form-control default-input"
                                                label={t("bid:entity.labels.value")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={LotMultiSelectField}
                                                placeholder={t("customer:entity.singular")}
                                                name="lots"
                                                className="form-control default-input"
                                                label={t("lot:entity.plural")}
                                                required/>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-secondary"
                                    onClick={handleClose}>
                                {t("save-and-back-buttons.cancel")}
                            </Button>
                            <Button type={"submit"} className={"btn btn-primary"} disabled={formikState.isSubmitting}>
                                {t("edit.submit")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export const SubmitBulkLimitBidsForm = connect<{}, DispatchProps, {}>(
    null,
    {
        submitBulkLimitBids: submitBulkLimitBidsAction
    }
)(SubmitBulkLimitBidsFormComponent);
