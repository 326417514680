export class ItemForSaving {
    public static TYPE = "item";

    constructor(
        public inventoryNumber: number,
        public description: { [lang: string]: string },
        public condition: string,
        public category: string | undefined,
        public limitPrice: number,
        public seller: string,
        public tags: string,
        public publicAttachments: string[],
        public privateAttachments: string[],
        public suitability: boolean,
        public status: string,
        public checkinDate: string,
        public plannedAuction: string | undefined,
        public transferCount: number,
        public p86: boolean
    ) {
    }
}
