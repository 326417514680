import {FieldProps} from "formik";
import {useTranslation} from "react-i18next";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {fa3, faCalendarXmark, faCreditCard, faHeading} from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import {CreditCard} from "../../../model/credit-card";

interface OwnProps {
    optional?: boolean;
    optionalText?: string;
    disabled?: boolean;
}

type Props = FieldProps & OwnProps;

export const CreditCardField = ({
                                 field,
                                 form,
                                 disabled
                             }: Props) => {
    const {t} = useTranslation("core");

    const currentCreditCard = field.value ? field.value as CreditCard : {
        name: "",
        number: "",
        expiration: "",
        threeDigitCode: ""
    };

    return <div className="address-field">
        <div>
            <div className="row mb-2">
                <div className="col-12 input-field-wrapper">
                    <FontAwesomeIcon className="input-icon ml-3" icon={faHeading}/>
                    <input
                        disabled={disabled}
                        className="form-control default-input"
                        placeholder={t("credit-card-field.name")}
                        value={currentCreditCard.name}
                        onChange={event => form.setFieldValue(field.name, update(currentCreditCard, {name: {$set: event.target.value}}))}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-8 input-field-wrapper">
                    <FontAwesomeIcon className="input-icon ml-3" icon={faCreditCard}/>
                    <input
                        disabled={disabled}
                        className="form-control default-input"
                        placeholder={t("credit-card-field.number")}
                        value={currentCreditCard.number}
                        onChange={event => form.setFieldValue(field.name, update(currentCreditCard, {number: {$set: event.target.value}}))}/>
                </div>
                <div className="col-4 input-field-wrapper">
                    <FontAwesomeIcon className="input-icon ml-3" icon={fa3}/>
                    <input
                        disabled={disabled}
                        className="form-control default-input"
                        placeholder={t("credit-card-field.three-digit-code")}
                        value={currentCreditCard.threeDigitCode}
                        onChange={event => form.setFieldValue(field.name, update(currentCreditCard, {threeDigitCode: {$set: event.target.value}}))}/>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-12 input-field-wrapper">
                    <FontAwesomeIcon className="input-icon ml-3" icon={faCalendarXmark}/>
                    <input
                        disabled={disabled}
                        className="form-control default-input"
                        placeholder={t("credit-card-field.expiration")}
                        value={currentCreditCard.expiration}
                        onChange={event => form.setFieldValue(field.name, update(currentCreditCard, {expiration: {$set: event.target.value}}))}/>
                </div>
            </div>
        </div>
    </div>
};

