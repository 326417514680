import {AppState} from "../../reducers";
import {createSelector} from "reselect";
import {AuctionPlanningModuleState} from "../reducers";
import {ValidationState} from "../reducers/validation-reducer";

export const selectAuctionPlanningModuleState = (state: AppState) => state.auctionPlanning;

export const selectValidationState = createSelector(
    selectAuctionPlanningModuleState,
    (moduleState: AuctionPlanningModuleState) =>
        moduleState.validation
);

export const selectValidationErrors = createSelector(
    selectValidationState,
    (validationState: ValidationState) =>
        validationState.errors
);

export const selectReloadView = createSelector(
    selectAuctionPlanningModuleState,
    (moduleState:AuctionPlanningModuleState ) =>
        moduleState.reloadView.lastUpdate
)
