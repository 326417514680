import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {API_DOWNLOAD_CUSTOMERS_CSV, downloadCustomersCsvAction} from "../../actions/download-customers-csv";

export const DownloadCustomersCsvButton = () => {

    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_CUSTOMERS_CSV))

    const {t} = useTranslation("customer");

    return <button type="button" disabled={apiCallState.inProgress} onClick={() => {
        dispatch(downloadCustomersCsvAction());
    }} className="btn btn-outline-success mr-3">

        <FontAwesomeIcon className="mr-2"
                         icon={faDownload}/>{t("actions.download-csv")}
    </button>;
}
