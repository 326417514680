import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_REFRESH_CREDIT_NOTE = "refresh-credit-note";

export interface SendReceiptPayload {
    orderId: string,
    receiptId: string
}

export const refreshCreditNoteAction = (orderId: string, receiptId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_REFRESH_CREDIT_NOTE,
            callType: API_REFRESH_CREDIT_NOTE
        },
        payload: {
            orderId,
            receiptId
        }
    } as ApiCallAction<SendReceiptPayload>;
};
