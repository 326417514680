import React, {useEffect, useState} from "react"
import {Pagination} from "@thekeytechnology/framework-react-ux";
import {
    addFiltersAction,
    ApiCallState,
    ComplexFilter,
    EntityWrapper,
    reloadEntityListAction,
    removeFiltersAction,
    selectCombinedApiState,
    selectEntities,
    selectSingleEntity
} from "@thekeytechnology/framework-react";
import {Item} from "../../../items/model/item";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {ItemFilters} from "../../../items/components/filter/ItemFilters";
import {Button, Modal} from "react-bootstrap";
import update from "immutability-helper";
import "./manual-assignment.scss"
import {ItemForSelectionTable} from "./ItemForSelectionTable";
import {manuallyAssignItemsAction} from "../../actions/assign/manually-assign-items";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";

interface StateProps {
    selectedAuction?: EntityWrapper<Auction>;
    items: Array<EntityWrapper<Item>>;
    itemsApiState: ApiCallState;
}

interface DispatchProps {
    fetchItems: ReturnType<typeof reloadEntityListAction>;
    manuallyAssignItems: typeof manuallyAssignItemsAction;
    addFilters: ReturnType<typeof addFiltersAction>;
    removeFilters: ReturnType<typeof removeFiltersAction>;
}

interface State {
    selectedForAdding: Array<EntityWrapper<Item>>
}

interface OwnProps {
    handleClose: () => void;
}

type Props = StateProps & DispatchProps & OwnProps & WithTranslation;

const ManualAssignmentEditorComponent = (props: Props) => {
    const {items, t, itemsApiState, handleClose, manuallyAssignItems, selectedAuction, fetchItems, addFilters, removeFilters} = props;


    useEffect(() => {
        if (selectedAuction) {
            addFilters("manual-assignment", [new ComplexFilter("only-not-assigned-to-auction", {auctionId: selectedAuction.id as string})]);
            fetchItems();
        }
        return () => {
            removeFilters(["manual-assignment"])
        }
    }, [addFilters, fetchItems, removeFilters, selectedAuction]);

    const [state, setState] = useState<State>({selectedForAdding: []});

    const handleAdd = (item: EntityWrapper<Item>) => setState(update(state, {selectedForAdding: {$push: [item]}}));
    const handleRemove = (item: EntityWrapper<Item>) => setState(update(state, {selectedForAdding: {$set: state.selectedForAdding.filter(i => i.id !== item.id)}}));

    const selectedItemIds = new Set(state.selectedForAdding.map(i => i.id as string));

    if (!selectedAuction) {
        return null;
    }
    return <>
        <Modal.Header closeButton>
            <Modal.Title>{t("manual-assignment-editor.heading")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <h3>{t("manual-assignment-editor.added")}</h3>
            {state.selectedForAdding.length === 0 ? <p>{t("manual-assignment-editor.nothing-added")}</p> :
                <ItemForSelectionTable
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                    items={state.selectedForAdding}
                    selectedItemIds={selectedItemIds}
                    apiState={{succeeded: true, inProgress: false, failed: false}}/>}

            <hr className="mt-4 mb-2"/>
            <h3>{t("manual-assignment-editor.available")}</h3>
            <ItemFilters/>
            <ItemForSelectionTable
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                items={items}
                selectedItemIds={selectedItemIds}
                apiState={itemsApiState}/>
            <Pagination entityType={Item.TYPE}/>
        </Modal.Body>
        <Modal.Footer>
            <Button className="btn btn-secondary"
                    onClick={handleClose}>
                {t("manual-assignment-editor.cancel")}
            </Button>
            <Button disabled={state.selectedForAdding.length === 0}
                    className="btn btn-primary"
                    onClick={() => {
                        manuallyAssignItems(selectedAuction.id as string, state.selectedForAdding.map(i => i.id as string));
                        handleClose();
                    }}
            >
                {t("manual-assignment-editor.assign")}
            </Button>
        </Modal.Footer>
    </>;
};

export const ManualAssignmentEditor = connect<StateProps, DispatchProps, OwnProps>(
    (state: any) => ({
        selectedAuction: selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION)(state),
        items: selectEntities<Item>(Item.TYPE)(state),
        itemsApiState: selectCombinedApiState(Item.TYPE)(state)
    }),
    {
        fetchItems: reloadEntityListAction(Item.TYPE),
        addFilters: addFiltersAction(Item.TYPE),
        removeFilters: removeFiltersAction(Item.TYPE),
        manuallyAssignItems: manuallyAssignItemsAction
    }
)(withTranslation(["auctionPlanning", "item"])(ManualAssignmentEditorComponent));
