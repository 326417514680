import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_LIMIT_PRICE = "update-limit-price";

export interface UpdateLimitPricePayload {
    lotId: string;
    writeToItem: boolean;
    limitPrice: number;
}

export const updateLimitPriceAction = (lotId: string, writeToItem: boolean, limitPrice: number) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_LIMIT_PRICE,
            callType: API_UPDATE_LIMIT_PRICE
        },
        payload: {
            lotId,
            writeToItem,
            limitPrice
        }
    } as ApiCallAction<UpdateLimitPricePayload>;
};
