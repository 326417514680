import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities,
    setContextAction,
    setPaginationQueryAction,
} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React, {useEffect} from "react";
import {useTranslation, WithTranslation} from "react-i18next";
import Select from "react-select";
import {connect} from "react-redux";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {extractLotTitle, ShortLot} from "@thekeytechnology/auktionshaus-frontend-library";

interface StateProps {
    lots: EntityWrapper<ShortLot>[];
}

interface DispatchProps {
    fetchLots: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
    setContext: ReturnType<typeof setContextAction>
}

type Props = StateProps & DispatchProps & WithTranslation & FieldProps;

const LotMultiSelectFieldComponent = (props: Props) => {
    const {
        lots,
        field,
        fetchLots,
        setPaginationQuery,
        setContext
    } = props;
    const {t} = useTranslation(["lot", "core"]);

    useEffect(() => {
        setPaginationQuery({docsPerPage: -1, page: 0});
        setContext(ShortLot.CONTEXT);
        fetchLots();
    }, [setPaginationQuery, setContext, fetchLots]);

    const onChange = (selectedLots: EntityWrapper<ShortLot>[]) => {
        props.form.setFieldValue(props.field.name, selectedLots?.map(l => l.id));
    };


    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            placeholder={t("core:controls.select.select-with-type", {
                type: t("entity.plural")
            })}
            components={{ClearIndicator}}
            isClearable={true}
            options={lots}
            name={field.name}
            value={lots ? lots.filter(option => field.value && Array.isArray(field.value) && (field.value.indexOf(option.id) > -1)) : undefined}
            onChange={onChange as any}
            getOptionValue={lot => lot.id!}
            getOptionLabel={(lot: EntityWrapper<ShortLot>) => `${lot.entity.lotNumber} - ${extractLotTitle(lot.entity.description.de)}`}
            onBlur={field.onBlur}
            isMulti={true}
        />
    );
};

export const LotMultiSelectField = connect<StateProps, DispatchProps, FieldProps>(
    (state: any) => {
        return {
            lots: selectEntities<ShortLot>(ShortLot.TYPE)(state)
        };
    },
    {
        fetchLots: reloadEntityListAction(ShortLot.TYPE),
        setPaginationQuery: setPaginationQueryAction(ShortLot.TYPE),
        setContext: setContextAction(ShortLot.TYPE)
    }
)(
    LotMultiSelectFieldComponent
);
