import React from "react";
import {useTranslation} from "react-i18next";
import {Filters, NumberFilter, TextFilter} from "@thekeytechnology/framework-react-ux";

import {FilterTerm} from "@thekeytechnology/framework-react";
import {CustomersListSelectFilter} from "./CustomersListSelectFilter";
import {ENTITY_TYPE_CUSTOMER} from "../model/customer";
import {
    CUSTOMER_ALLOWED_LIST_ALLOW_ONCE,
    CUSTOMER_ALLOWED_LIST_BLACK,
    CUSTOMER_ALLOWED_LIST_NO_LIST,
    CUSTOMER_ALLOWED_LIST_REQUESTED,
    CUSTOMER_ALLOWED_LIST_WHITE
} from "@thekeytechnology/auktionshaus-frontend-library";

export const CustomersFilters = () => {
    const {t} = useTranslation(["customer", "core"]);

    const AllowedListSelectFilter = CustomersListSelectFilter();

    return (
        <div className={"tk-customers-filter"}>
            <Filters
                entityType={ENTITY_TYPE_CUSTOMER}
                filterKeys={["entity.personalData.firstName", "entity.personalData.lastName", "entity.customerNumber", "entity.allowedList"]}>
                <NumberFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.customer-number")
                    })}
                    entityType={ENTITY_TYPE_CUSTOMER}
                    property={"entity.customerNumber"}
                    step={1}
                />
                <TextFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.personal-data.last-name")
                    })}
                    entityType={ENTITY_TYPE_CUSTOMER}
                    property="entity.personalData.lastName"
                />
                <TextFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.personal-data.first-name")
                    })}
                    entityType={ENTITY_TYPE_CUSTOMER}
                    property="entity.personalData.firstName"
                />
                <TextFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("core:address-field:postal-code")
                    })}
                    entityType={ENTITY_TYPE_CUSTOMER}
                    property="entity.billingAddress.postalCode"
                />
                <AllowedListSelectFilter entityType={ENTITY_TYPE_CUSTOMER}
                              placeholder={t("core:filters.filter-by", {
                                  label: t("entity.labels.allowed-list")
                              })}
                              filterKey="entity.allowedList"
                              property="entity.allowedList"
                              options={[
                                  CUSTOMER_ALLOWED_LIST_BLACK,
                                  CUSTOMER_ALLOWED_LIST_NO_LIST,
                                  CUSTOMER_ALLOWED_LIST_ALLOW_ONCE,
                                  CUSTOMER_ALLOWED_LIST_REQUESTED,
                                  CUSTOMER_ALLOWED_LIST_WHITE]
                                  .map(list => ({
                                      label: t("entity.labels.allowed-lists." + list),
                                      value: list === CUSTOMER_ALLOWED_LIST_NO_LIST ? "" : list
                                  }))}
                              filterType={FilterTerm.TYPE_STRING}
                />
            </Filters>
        </div>
    );
};
