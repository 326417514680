import {ENTITY_TYPE_LOG_ENTRY, LogEntry} from "../model/log-entry";
import {WithEntityListProps} from "@thekeytechnology/framework-react/dist/entity/hoc/WithEntityList";
import {
    AsyncEntityTableBody,
    DateTimeDisplay,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableRow
} from "@thekeytechnology/framework-react-ux";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {addFiltersAction, FilterTerm, PropertyFilter, WithEntityList} from "@thekeytechnology/framework-react";
import {useDispatch} from "react-redux";
import "./log-display.scss"
import {InlineCommentEditor} from "./InlineCommentEditor";
import {LogMessageDisplay} from "./LogMessageDisplay";

interface OwnProps {
    entityId?: string;
}

type Props = OwnProps & WithEntityListProps<LogEntry>

const LogDisplayComponent = ({entityId, entities, entityApiState}: Props) => {
    const {t} = useTranslation("log");
    const dispatch = useDispatch();
    useEffect(() => {
        if (entityId) {
            dispatch(addFiltersAction(ENTITY_TYPE_LOG_ENTRY)("log-filter", [
                new PropertyFilter("entity.entityRef", new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, entityId))
            ]))
        }
    }, [dispatch, entityId])

    return <>
        {entityId ? <h2>Log-Einträge</h2> : null}
        <Table className="log-entry-table" itemCount={entities.length}>
            <TableHeader entities={entities}>
                <TableColumn>{t("entity.labels.created-at")}</TableColumn>
                <TableColumn>{t("entity.labels.user")}</TableColumn>
                <TableColumn>{t("entity.labels.operation")}</TableColumn>
                <TableColumn> {t("entity.labels.entity-type")}</TableColumn>
                <TableColumn> {t("entity.labels.message")}</TableColumn>
                <TableColumn> {t("entity.labels.comment")}</TableColumn>
            </TableHeader>
            <TableBody>
                <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={logEntry => {
                    return (
                        <TableRow key={logEntry.id}>
                            <TableColumn className="bold name-column title-column">
                                <DateTimeDisplay dateTime={logEntry.entity.createdAt}/>
                            </TableColumn>
                            <TableColumn>
                                {logEntry.entity.user?.entity.name}
                            </TableColumn>
                            <TableColumn>
                                {t(`log-operations.${logEntry.entity.operation}`)}
                            </TableColumn>
                            <TableColumn>
                                <NavLink
                                    to={t(`log-links.${logEntry.entity.entityType}`, {entityId: logEntry.entity.entityRef}) as any}>
                                    {t(`entity-types.${logEntry.entity.entityType}`)}
                                </NavLink>
                            </TableColumn>
                            <TableColumn className={"message"} >
                                <LogMessageDisplay message={logEntry.entity.message} />
                            </TableColumn>
                            <TableColumn>
                                <InlineCommentEditor currentComment={logEntry.entity.comment}
                                                     logEntryId={logEntry.id!}/>
                            </TableColumn>
                        </TableRow>
                    );
                }}/>
            </TableBody>
        </Table>
    </>

}

export const LogDisplay = WithEntityList(LogDisplayComponent, ENTITY_TYPE_LOG_ENTRY) as any
