import {useRouteMatch} from "react-router";
import {EntityWrapper, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {usePaginatedEndpoint} from "../../paginated-endpoints/hooks/use-paginated-endpoint";


export function useSingleEntityFromPath<EntityType>(endpoint: string, pathParam: string, context?: string):
    [EntityWrapper<EntityType> | undefined, () => void] {
    const {params} = useRouteMatch<any>();

    if (params[pathParam]) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const {resolved, reload} = usePaginatedEndpoint<EntityType>(endpoint, context, [
            new PropertyFilter("id", new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, params[pathParam]))
        ])
        return [resolved.entities[0], reload]
    } else {
        return [undefined, () => {
        }];
    }
}
