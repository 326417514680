import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHammer} from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import {useTranslation} from "react-i18next";
import {WinningBidPosition} from "../../model/position/WinningBidPosition";
import {LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import Select from "react-select";
import {Bid} from "../../../bids/model/bid";
import {LOT_BILLING_STATUS_OPEN, LOT_BILLING_STATUS_PAID} from "../../model/Order";

interface OwnProps {
    disabled?: boolean
    position: WinningBidPosition;
    onChange: (newPosition: WinningBidPosition) => void;
}

type Props = OwnProps;

export const WinningBidPositionEditor = ({
                                             disabled,
                                             position,
                                             onChange
                                         }: Props) => {

    const {t} = useTranslation(['order', "bid"]);

    const statusToOption = (status: string) => ({
        label: t(`winning-bid-position-editor.status.${status}`),
        value: status
    })

    const STATUS_OPTIONS = [LOT_BILLING_STATUS_OPEN, LOT_BILLING_STATUS_PAID].map(s => statusToOption(s));

    const [statusState, setStatusState] = useState({
        selectedType: position.status
    });

    const SOURCE_OPTIONS = [Bid.SOURCE_ONLINE, Bid.SOURCE_INVALUABLE, Bid.SOURCE_LOTTISSIMO, Bid.SOURCE_BIDSPIRIT, Bid.SOURCE_AUCTIONEER].map(source => ({
        label: t(`bid:source.${source}`),
        value: source
    }));

    const [sourceState, setSourceState] = useState({
        selectedType: position.source
    });

    const getStatus = () => {
        const status = STATUS_OPTIONS.find((selected: any) => selected.value === statusState.selectedType)
        if (!status) {
            return statusToOption(LOT_BILLING_STATUS_OPEN)
        }
        return status
    }


    return <div className={"d-flex flex-column w-100"}>
        <div className="d-flex w-100 flex-wrap mb-2">
            Los {position.lot?.entity.lotNumber} - <LotTitleDisplay description={position.lot?.entity.description.de}/> ({position.lot?.entity.items[0].inventoryNumber})
        </div>
        <div className="d-flex w-100">
            <div className="input-field-wrapper" style={{minWidth: '161px'}}>
                <FontAwesomeIcon className="input-icon" icon={faHammer}/>
                <input className="form-control default-input mr-2"
                       placeholder={t("winning-bid-position-editor.placeholder")}
                       type="number"
                       min={0}
                       disabled={disabled}
                       step={0.01}
                       value={position.amount}
                       onChange={event => {
                           const newAmount = Number.parseFloat(event.target.value);
                           onChange(update(position, {amount: {$set: newAmount}}))
                       }}/>
            </div>
            <div className="input-field-wrapper" style={{minWidth: '161px'}}>
                <Select
                    isDisabled={disabled}
                    className="react-select mr-2"
                    classNamePrefix="react-select"
                    isClearable={false}
                    options={STATUS_OPTIONS}
                    onChange={(newValue: any) => {
                        setStatusState({
                            ...statusState,
                            selectedType: newValue.value
                        });
                        onChange(update(position, {status: {$set: newValue.value}}));
                    }}
                    value={getStatus()}
                />
            </div>
            <div className="input-field-wrapper" style={{minWidth: '161px'}}>
                <Select
                    isDisabled={disabled}
                    className="react-select mr-2"
                    classNamePrefix="react-select"
                    isClearable={false}
                    options={SOURCE_OPTIONS}
                    onChange={(newValue: any) => {
                        setSourceState({
                            ...sourceState,
                            selectedType: newValue.value
                        });
                        onChange(update(position, {source: {$set: newValue.value}}));
                    }}
                    value={SOURCE_OPTIONS.find((selected: any) => selected.value === sourceState.selectedType)}
                />
            </div>
        </div>
    </div>
};

