import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SHIP_ORDER = "ship-order";

export interface ShipOrderPayload {
    orderId: string,
    trackingNumber: string
}

export const shipOrderAction = (orderId: string, trackingNumber: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SHIP_ORDER,
            callType: API_SHIP_ORDER
        },
        payload: {
            orderId,
            trackingNumber
        }
    } as ApiCallAction<ShipOrderPayload>;
};
