import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_TRANSFER_LOTS = "transfer-lots";

export interface TransferLotsPayload {
    auctionId: string;
}

export const transferLotsAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_TRANSFER_LOTS,
            callType: API_TRANSFER_LOTS
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<TransferLotsPayload>;
};
