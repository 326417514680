import {Address, AttachedReceipt, OrderHistory, OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";

export const ENTITY_TYPE_SETTLEMENT_FOR_SAVING = "settlement"

export interface SettlementForSaving {
    settlementNumber?: number,
    customerRef: string,
    positions: OrderPosition[],
    billingAddress: Address,
    checkNumber?: string,
    status: string,
    comment?: string,
    attachedReceipts: AttachedReceipt[],
    settlementHistory: OrderHistory[],
    attributes: string[],
    createdAt: string,
    auctionRef?: string
}
