import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {OrderOverview} from "../components/OrderOverview";
import {OrderEditForm} from "../components/OrderEditForm";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {DunningsView} from "../components/dunnings/DunningsView";

export const OrderModuleRoutes = () => {
    return (
        <>

            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/bestellungen" component={OrderOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/bestellungen/mahnungen" component={DunningsView}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/bestellungen/:orderId/edit" component={OrderEditForm}/>
        </>
    );
};
