import {EntityWrapper} from "@thekeytechnology/framework-react";
import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {
    ContentContainer,
    EditHeaderWithBackground,
    SaveAndBackButtons,
    ValidatedField
} from "@thekeytechnology/framework-react-ux";
import {Form, Formik} from "formik";
import {useSingleEntityFromPath} from "../../../core/utils/use-single-entity-from-path";
import * as Yup from "yup";
import {saveUserAction} from "../../actions/save-user";
import {UserForEditing} from "../../model/UserForEditing";

export const EditUser = () => {

    const [entity] = useSingleEntityFromPath<UserForEditing>(
        "/users/get-single",
        "userId"
    )

    const {t} = useTranslation(["users", "core"])

    const dispatch = useDispatch()


    return (
        entity ? <>
            <BreadcrumbsItem to={"/users"}>Users</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/users/" + entity.id + "/roles"}>
                {t("user-settings.heading")} {entity.entity.name}
            </BreadcrumbsItem> : null}

            <ContentContainer>

                <Formik
                    initialValues={{
                        name: entity.entity.name,
                        email: entity.entity.email,
                        isAdmin: entity.entity.isAdmin,
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string()
                            .required(t("core:forms.required-field", {fieldName: t("entity.user.labels.name")})),
                        email: Yup.string()
                            .required(t("core:forms.required-field", {fieldName: t("entity.user.labels.email")}))
                            .email("Keine gültige E-Mail"),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const saving = new EntityWrapper<UserForEditing>(
                            entity.id,
                            {
                                name: values.name,
                                email: values.email,
                                isAdmin: values.isAdmin,
                                activated: entity?.entity.activated
                            }
                        );
                        dispatch(saveUserAction(saving));
                        setSubmitting(false);
                    }}
                >
                    {formikState => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={entity.id ?
                                    t("entity.user.singular") + ": " + entity.entity.name :
                                    t("core:edit-header.heading-empty")}
                            >
                                <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                    backPath="/benutzer/"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>

                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="text"
                                                    placeholder={t("entity.user.labels.name")}
                                                    name="name"
                                                    className="form-control default-input"
                                                    label={t("entity.user.labels.name")}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="email"
                                                    placeholder={t("entity.user.labels.email")}
                                                    name="email"
                                                    className="form-control default-input"
                                                    label={t("entity.user.labels.email")}
                                                    required
                                    />
                                </div>

                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="checkbox"
                                                    placeholder={t("entity.user.labels.is-admin")}
                                                    name="isAdmin"
                                                    className="form-control default-input"
                                                    label={t("entity.user.labels.is-admin")}
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik>
            </ContentContainer>
        </> : null
    );
};
