import React from "react";
import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";

interface OwnProps {
    property: string
    placeholder: string
    endpointFilters: PaginatedEndpointFiltersReturnProps
    customFilterFunction?: (
        property: string,
        endpointFilters: PaginatedEndpointFiltersReturnProps,
        text: string,
        filterKey: string
    ) => void
    customValueFunction?: (
        endpointFilters: PaginatedEndpointFiltersReturnProps,
        filterKey: string
    ) => string

    filterOperation?: string;
    filterKey?: string;

    min?: number;
    max?: number;
    step?: number;
}


export const PaginatedEndPointNumberFilter = ({
                                                  property,
                                                  placeholder,
                                                  endpointFilters,
                                                  customFilterFunction,
                                                  customValueFunction,
                                                  min,
                                                  max,
                                                  step,
                                                  filterKey,
                                                  filterOperation
                                              }: OwnProps) => {

    const filter = (event: any) => {
        const text = event?.target?.value;
        const filterKeyToUse = filterKey ? filterKey : property;

        if (customFilterFunction) {
            customFilterFunction(property, endpointFilters, text, filterKeyToUse)
        } else {
            if (text?.length) {
                const filterOperationToUse = filterOperation ? filterOperation : FilterTerm.OPERATION_EQ;
                endpointFilters.addFilters(filterKeyToUse, [new PropertyFilter(property,
                    new FilterTerm(FilterTerm.TYPE_BIG_DECIMAL, filterOperationToUse, text as number)
                )]);
            } else {
                endpointFilters.removeFilters([filterKeyToUse]);
            }
        }
    };

    const setValue = () => {
        const filterKeyToUse = filterKey ? filterKey : property;
        if (customValueFunction) {
            return customValueFunction(endpointFilters, filterKeyToUse)
        } else {
            const currentFilters = endpointFilters.getFilters().filter(f => f.key === filterKeyToUse).flatMap(f => f.filters)

            return currentFilters && currentFilters.length && currentFilters[0] ?
                (currentFilters[0] as PropertyFilter).filterTerm.value : "";

        }
    }

    return (
        <div className={"tk-catalogue-item-filter"}>
            <div className="filter text-filter">
                <input type="number"
                       min={min}
                       max={max}
                       step={step}
                       value={setValue()}
                       className="text-filter default-input" onChange={filter} placeholder={placeholder}/>
                <FontAwesomeIcon icon={faSearch}/>
            </div>
        </div>

    );
};

