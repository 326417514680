import {EntityWrapper} from "@thekeytechnology/framework-react";

export const AUCTION_STATUS_IN_PLANNING = "in-planning"
export const AUCTION_STATUS_FINALIZED = "finalized"
export const AUCTION_STATUS_LIVE = "live"
export const AUCTION_STATUS_CONCLUDED = "concluded"
export const AUCTION_STATUS_ARCHIVED = "archived"



export const ENTITY_TYPE_AUCTION = "auction";

export const AUCTION_FACTORY = () => new EntityWrapper<Auction>(
    undefined,
    {
        auctionNumber: 0,
        startDateTime: "",
        previewDateTime: undefined,
        nextAuctionDateTime: undefined,
        status: AUCTION_STATUS_IN_PLANNING
    } as Auction
)

export interface Auction {
    auctionNumber: number
    startDateTime: string
    previewDateTime: string | undefined
    nextAuctionDateTime: string | undefined
    status: string | undefined
}
