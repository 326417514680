import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import {AuctionData} from "./auction-data";
import {PaymentData} from "./payment-data";
import {Address, PersonalData} from "@thekeytechnology/auktionshaus-frontend-library";

export const ENTITY_TYPE_CUSTOMER = "customer";
export const CUSTOMER_CONTEXT_JUST_NAME_AND_ID = "just-name-and-id"

export const CUSTOMER_NEW_FACTORY = () => new EntityWrapper<Customer>(
    undefined,
    {
        customerNumber: 0,
        oldCustomerNumber: 0,
        personalData: new PersonalData(),
        attachments: [],
        auctionData: new AuctionData(),
        paymentData: new PaymentData()
    }
);


export interface Customer {
    customerNumber: number
    oldCustomerNumber?: number
    personalData: PersonalData
    adOptOut?: boolean
    comment?: string
    attachments: EntityWrapper<TkFile>[]
    paymentData: PaymentData
    auctionData: AuctionData
    shippingAddress?: Address
    billingAddress?: Address
    allowedList?: string
    user?: EntityWrapper<User>
    warning?: string
    lexOfficeContactId?: string
    invaNumber?: string
    roomNumber?: string
}

export interface User {
    name: string;
    email: string;
    isAdmin: boolean;
}
