import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    API_UPDATE_ENTITIES,
    apiGet,
    apiPost,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {ENTITY_TYPE_AUCTION} from "../model/auction";
import "@thekeytechnology/framework-react/"
import {API_GET_CURRENT_AUCTION_NUMBER} from "../actions/current-auction";
import {redirectAfterEntityApiOperation} from "@thekeytechnology/framework-react-ux";
import {API_ARCHIVE_AUCTION, ArchiveAuctionPayload} from "../actions/archive-auction";

const redirectOnAuctionSave$ = redirectAfterEntityApiOperation(
    [API_CREATE_ENTITIES, API_UPDATE_ENTITIES], ENTITY_TYPE_AUCTION, "/auktionen");

const reloadOnAuctionDelete$ = reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_AUCTION);

const getCurrentAuctionNumber$ = apiGet(
    {apiType: API_GET_CURRENT_AUCTION_NUMBER},
    "/auctions/current-number"
);

const archiveAuction$ = apiPost<ArchiveAuctionPayload, any>(
    {apiType: API_ARCHIVE_AUCTION},
    "/auctions/archive-auction",
);

export const auctionModuleEpics$ = combineEpics(
    redirectOnAuctionSave$,
    reloadOnAuctionDelete$,
    getCurrentAuctionNumber$,
    archiveAuction$
);
