import {faFilter, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useTranslation} from "react-i18next";
import {Accordion, Button, Card} from "react-bootstrap";
import "./collapsible-filters.scss";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps;
    filterKeys: string[];

    children: any;
}

type Props = OwnProps;

export const PaginatedEndpointCollapsibleFilters = ({
                                         endpointFilters,
                                         filterKeys,
                                         children
                                     }: Props) => {

    const {t} = useTranslation(["core"])
    const resetFilters = () => {
        endpointFilters.removeFilters(filterKeys);
    };

    const filterCount = endpointFilters.getFilters().length;

    return (
        <div className="collapsible-filters">
            <Accordion>
                <Card>
                    <Card.Header className="d-flex w-100 align-items-center ">
                        <Accordion.Toggle className="mr-4" as={Button} variant="link" eventKey="0">
                            <FontAwesomeIcon className="filter-icon mr-4" icon={faFilter}/>
                            {t("filters.heading")}
                        </Accordion.Toggle>
                        {filterCount ?
                            <div>
                                ({t("filters.selected-count", {count: filterCount})})
                            </div> : null}
                        <button disabled={filterCount === 0} className="ml-auto clear-filters btn btn-outline-secondary"
                                type="button"
                                onClick={() => {
                                    resetFilters();
                                }}>
                            <FontAwesomeIcon icon={faTimes}/> {t("filters.clear")}
                        </button>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div className="inner-filters">
                                {children}
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};
