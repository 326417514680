import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_COMMISSION_ORDER = "commission-order";

export interface CommissionOrderPayload {
    orderId: string,
    pickingNumber: string
}

export const commissionOrderAction = (orderId: string, pickingNumber: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_COMMISSION_ORDER,
            callType: API_COMMISSION_ORDER
        },
        payload: {
            orderId,
            pickingNumber
        }
    } as ApiCallAction<CommissionOrderPayload>;
};
