import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {concludeAuctionAction} from "../../../actions/conclude-live-auction-action";
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";


export const ConcludeButton = () => {

    const {t} = useTranslation(["auctioneer"])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const handleConfirm = () => {
        dispatch(concludeAuctionAction)
    }

    return <>
        <button className={"btn btn-primary conclude-button"}
                onClick={() => handleShow()}>{t("actions.conclude-auction")}</button>
        <Modal size="sm" show={show} onHide={handleClose}>
            {show ? <div className={"p-2 text-center"}>
                    <h4>Auktion final beenden?</h4>
                    <p>Kann nicht rückgängig gemacht werden</p>
                    <hr/>
                    <Button onClick={() => handleConfirm()}>Aktion beenden</Button>
                    <Button onClick={() => handleClose()}>Abbrechen</Button>
                </div>
                : null}
        </Modal>
    </>
};
