import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_PREV_LOT = "prev-lot";

export const prevLotAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_PREV_LOT,
            callType: API_PREV_LOT
        },
        payload: {}
    } as ApiCallAction<{}>;
};
