import {downloadLimitBidsAction} from "../../actions/limit-bids/download-limit-bids";
import React from "react";
import {Dropdown} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {useRouteMatch} from "react-router";
import {faEuro} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


export const DownloadLimitBidsButton = () => {

    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]

    return <Dropdown.Item
        onClick={() => {
            dispatch(downloadLimitBidsAction(auctionId))
        }}
    >
        <FontAwesomeIcon className="mr-2"
                         icon={faEuro}/>
        Vorgebote
    </Dropdown.Item>
}