import {API_UPDATE_ENTITIES, matchesApiSuccess} from "@thekeytechnology/framework-react";
import {push} from "connected-react-router";
import {Action} from "redux";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {EmailConfiguration} from "../model/email-configuration.model";

const redirectOnEmailSave$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_UPDATE_ENTITIES, EmailConfiguration.TYPE),
    map(() => push("/emails"))
);

export const emailModuleEpic$ = combineEpics(
    redirectOnEmailSave$
);
