import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    Pagination,
    PaginationContainer,
    SimpleHeader
} from "@thekeytechnology/framework-react-ux";
import {Lot} from "../../model/Lot";
import "./lots.scss";
import {LotTable} from "./LotTable";
import {LotFilters} from "./LotFilters";

const LotOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/lose"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <LotFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <LotTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={Lot.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const LotOverview = withTranslation("lot")(LotOverviewComponent);
