import React, {MutableRefObject, useEffect, useRef} from "react";
import {connect} from "react-redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Form, Formik} from "formik";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {BidSourceSelectField} from "./BidSourceSelectField";
import {AuctioneerBid} from "../model/auctioneerBid";
import {assignWinningBidAction} from "../../auctioneer-view/actions/assign-winning-bid-action";
import {Bid} from "../model/bid";

interface OwnProps {
    bid: EntityWrapper<AuctioneerBid>
    handleClose: () => void;
}

interface DispatchProps {
    confirmBid: typeof assignWinningBidAction;
}

type Props = OwnProps & DispatchProps;

const useFocus = (): [any, () => void] => {
    const htmlElRef: MutableRefObject<any> = useRef(null);
    const setFocus = (): void => {
        htmlElRef?.current?.focus?.();
    };

    return [htmlElRef, setFocus];
};

const AssignWinningBidFormComponent = ({
                                            bid,
                                            confirmBid,
                                            handleClose
                                        }: Props) => {

    const {t} = useTranslation(["auctioneer", "core", "bid"]);


    const [inputRef, setInputFocus] = useFocus()

    useEffect(() => {
        setInputFocus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const initialCustomerNumber = [Bid.SOURCE_ONLINE, Bid.SOURCE_IMPORT].includes(bid.entity.source) && bid.entity.customer.customerNumber ? bid.entity.customer.customerNumber.toString(10) : ""

    return <>
        <Formik
            initialValues={{
                customerId: initialCustomerNumber,
                source: bid.entity.source,
                tel: bid.entity.tel ? bid.entity.tel : "",
                comment: bid.entity.comment ? bid.entity.comment : ""
            }}
            onSubmit={(values, {setSubmitting}) => {
                confirmBid(
                    bid.id as string,
                    values.customerId,
                    values.source,
                    values.tel ? values.tel : "",
                    values.comment ? values.comment : ""
                );
                setSubmitting(false);
                handleClose();
            }}
        >
            {formikState => (
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t("actions.assign-winning-bid")}
                            (

                            {t("labels.bid-height")}: {bid.entity.value}

                            {bid.entity.source === Bid.SOURCE_ONLINE ?
                                <>, {t("labels.customer-id")}: {bid.entity.customer.customerNumber}</> :
                                <>, {t("bid:source." + bid.entity.source)}</>
                            }

                            {bid.entity.limitedAt ? <>, <strong>{t("bid:entity.labels.limit-bid")}</strong></> : ""}

                            )
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="form-group">
                            <label htmlFor="customerId" className="col-sm-10 col-form-label">
                                {t("labels.customer-id")}
                            </label>
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            placeholder="Kundennummer"
                                            name="customerId"
                                            className="form-control default-input"
                                            readOnly={initialCustomerNumber !== ""}
                                            autoFocus={true}
                                            innerRef={inputRef}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="source" className="col-sm-10 col-form-label">
                                {t("labels.source")}
                            </label>
                            <ValidatedField formikState={formikState}
                                            component={BidSourceSelectField}
                                            name="source"
                                            className="form-control default-input"/>
                        </div>
                        {/*<div className="form-group">*/}
                        {/*    <label htmlFor="tel" className="col-sm-10 col-form-label">*/}
                        {/*        {t("labels.tel")}*/}
                        {/*    </label>*/}
                        {/*    <ValidatedField formikState={formikState}*/}
                        {/*                    type="text"*/}
                        {/*                    placeholder={t("labels.tel")}*/}
                        {/*                    name="tel"*/}
                        {/*                    className="form-control default-input"/>*/}
                        {/*</div>*/}
                        <div className="form-group">
                            <label htmlFor="comment" className="col-sm-10 col-form-label">
                                {t("labels.comment")}
                            </label>
                            <ValidatedField formikState={formikState}
                                            type="text"
                                            component="textarea"
                                            placeholder={t("labels.comment")}
                                            name="comment"
                                            className="form-control default-input"/>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type={"submit"} className={"btn btn-primary"}>
                            {t("actions.assign-winning-bid")}
                        </Button>
                        <Button className="btn btn-secondary"
                                onClick={handleClose}>
                            {t("core:save-and-back-buttons.cancel")}
                        </Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </>;
};

export const AssignWinningBidForm = connect<null, DispatchProps, {}>(
    null,
    {
        confirmBid: assignWinningBidAction
    }
)
(AssignWinningBidFormComponent);
