import {
    EntityListResolvedPayload,
    EntityWrapper,
    fetchEntityList,
    Filter,
    FilterTerm,
    OrFilter,
    PaginationQuery,
    PaginationResponse,
    PropertyFilter,
    selectApiBase,
    selectAuthState,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import React, {Component, FocusEventHandler, useRef} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import AsyncSelect from "react-select/async";
import {AxiosInstance, AxiosResponse} from "axios";
import {map} from "rxjs/operators";
import {InputActionMeta} from "react-select";
import {from, Observable} from "rxjs";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {useSessionStorage} from "react-use";

export const DEFAULT_FETCH_FUNCTION_FACTORY: FetchFunctionFactory = (authedAxios: AxiosInstance, entityType: string, paginationQuery: PaginationQuery, filters: Filter[], context?: string) => fetchEntityList(authedAxios, entityType, paginationQuery, filters, context)


export const CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY: (endpoint: string) => FetchFunctionFactory = (endpoint: string) => (authedAxios: AxiosInstance, entityType: string, paginationQuery: PaginationQuery, filters: Filter[], context?: string) => from(authedAxios.post(endpoint, {
    entityType,
    paginationRequest: paginationQuery,
    context,
    filters
})).pipe(
    map((response: AxiosResponse) => {
        const paginationResponse = response.data as PaginationResponse<any>;

        return ({
            pagination: {
                currentPage: paginationResponse.page,
                docsPerPage: paginationResponse.docsPerPage,
                totalPages: paginationResponse.totalPages,
                totalDocs: paginationResponse.totalDocs
            },
            entities: paginationResponse.entities,
            entityType: ""
        });
    }),
)

export type FetchFunctionFactory = (authedAxios: AxiosInstance, entityType: string, paginationQuery: PaginationQuery, filters: Filter[], context?: string) => Observable<EntityListResolvedPayload<any>>;

interface SelectedEntity<T> {
    value: string;
    label: string;
    entity?: EntityWrapper<T>
}

interface OwnProps<T> {
    shownEntityType: string;
    shownEntityTypeProperties: string[];
    customShownEntityTypePropertyFilterCallback?: (property: string, inputValue: string) => PropertyFilter[]
    shownEntityTypeContext?: string;

    selected: EntityWrapper<T> | EntityWrapper<T>[] | undefined;
    select: (newValue: EntityWrapper<T> | EntityWrapper<T>[] | undefined) => void;

    additionalFilters?: Filter[];

    listRenderer: (item: EntityWrapper<T>) => string;
    placeholder: string;
    isMulti?: boolean;
    isClearable?: boolean;
    disabled?: boolean

    onMenuClose?: () => void;
    onBlur?: FocusEventHandler
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;

    fetchFunctionFactory?: FetchFunctionFactory
}

interface StateProps {
    authedAxios: AxiosInstance;
}

type Props<T> = OwnProps<T> & StateProps & WithTranslation;

function PaginatedEndpointAsyncEntitySelectComponent<T>({
                                                            t,
                                                            placeholder,
                                                            shownEntityTypeProperties,
                                                            customShownEntityTypePropertyFilterCallback,
                                                            shownEntityType,
                                                            shownEntityTypeContext,
                                                            listRenderer,
                                                            selected,
                                                            select,
                                                            authedAxios,
                                                            isMulti,
                                                            isClearable,
                                                            additionalFilters,
                                                            disabled,
                                                            onMenuClose,
                                                            onBlur,
                                                            onInputChange,
                                                            fetchFunctionFactory = DEFAULT_FETCH_FUNCTION_FACTORY
                                                        }: Props<T>) {

    // const [cachedLoadedOptions, setCachedLoadedOptions] = useState<SelectedEntity<T>[]>([]);
    const sessionStorageKey = window.location.pathname + ".select." + shownEntityType

    const [cachedLoadedOptions, setCachedLoadedOptions] = useSessionStorage<SelectedEntity<T>[]>(sessionStorageKey, []);


    let currentValue: SelectedEntity<T> | SelectedEntity<T>[] | null;
    if (selected) {
        if (isMulti && Array.isArray(selected)) {
            currentValue = cachedLoadedOptions.filter(o => selected.map(s => s.id).includes(o.value))
        } else {
            const singleSelected = Array.isArray(selected) ? selected[0] : selected

            const currentValueOpt = cachedLoadedOptions.find(o => o.value === singleSelected.id)
            const notFoundSelected: SelectedEntity<T> | null =
                singleSelected.id && singleSelected.entity ? {
                    value: singleSelected.id,
                    label: listRenderer(singleSelected),
                    entity: singleSelected
                } as SelectedEntity<T> : null;
            console.log()

            currentValue = currentValueOpt ? currentValueOpt : notFoundSelected;
        }
    } else {
        currentValue = isMulti ? [] : null;
    }

    const searchOptions = (inputValue: string) => {
        const selectedIdsFilters = currentValue ? [new PropertyFilter("id", new FilterTerm(
            FilterTerm.TYPE_STRING_LIST,
            FilterTerm.OPERATION_IN,
            Array.isArray(currentValue) ? currentValue.map(c => c.value) : [currentValue.value]
        ))] : [];
        console.log("TEST_1: " + inputValue);
        const orSubFilters = selectedIdsFilters.concat(
            inputValue ?
                shownEntityTypeProperties.flatMap(p => {
                    return customShownEntityTypePropertyFilterCallback ?
                        customShownEntityTypePropertyFilterCallback(p, inputValue) :
                        [new PropertyFilter(p, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))]
                }) : [])
        console.log(orSubFilters);

        const filters = [
            ...(additionalFilters ? additionalFilters : []),
            ...(orSubFilters.length >= 1 ? [new OrFilter(orSubFilters)] : [])
        ];
        console.log(filters)
        return fetchFunctionFactory(authedAxios, shownEntityType, {
            page: 0,
            docsPerPage: 20
        }, filters, shownEntityTypeContext).pipe(
            map((action: EntityListResolvedPayload<any>) => {
                const loadedOptions = action.entities.map(e => ({
                    value: e.id!,
                    label: listRenderer(e),
                    entity: e
                }));
                setCachedLoadedOptions(loadedOptions)
                return loadedOptions;
            })
        ).toPromise();
    }

    const selectRef = useRef<AsyncSelect<SelectedEntity<T>>>(null);

    const findSelectedValue = (value: any) => {
        if (!value) {
            return undefined;
        }
        return cachedLoadedOptions.find((o: any) => o.value === value)
    };

    return (
        <AsyncSelect<SelectedEntity<T>>
            ref={selectRef}
            components={{
                ClearIndicator,
            }}
            placeholder={placeholder}
            className="react-select"
            classNamePrefix="react-select"
            value={currentValue}
            isDisabled={disabled}
            onMenuClose={onMenuClose}
            getOptionLabel={(option: any) => {

                /*
                This ghetto solution is because react-select does not allow asynchronously setting the value,
                nor reloading the label of an already set value. The problem is that the filter only provides the ID,
                not the label for that id. This means that during initialization, there is no label - which breaks the UI.
                We will now use the default label (if set) or looking for the appropriate (resolved) item.
                */
                if (option.label) {
                    return option.label;
                }
                const value = option.value ? option.value : option;
                const selectedValue = findSelectedValue(value);
                return selectedValue && selectedValue.entity ? listRenderer(selectedValue.entity) : undefined;
            }}
            noOptionsMessage={() => t("async-entity-select.no-options")}
            loadingMessage={() => t("async-entity-select.loading")}
            onSelectResetsInput={false}
            isClearable={isClearable === undefined ? true : isClearable}
            isMulti={isMulti === undefined ? false : isMulti}
            onChange={(option: any) => {
                console.log(option)
                if (isMulti && Array.isArray(option)) {
                    const values = option.map(o => {
                        const value = o.value ? o.value : o;
                        return findSelectedValue(value);
                    });
                    select(values ? values.map(v => v!.entity) as any : []);
                } else {
                    const value = option && option.value ? option.value : option;
                    const selectedEntity = findSelectedValue(value);
                    select(selectedEntity?.entity);
                }
                selectRef.current!.setState({
                    ...selectRef.current!.state,
                    inputValue: ""
                })
            }}
            onInputChange={onInputChange}
            onBlur={onBlur}
            loadOptions={searchOptions}
            defaultOptions
        />
    );
}

// tslint:disable-next-line:max-classes-per-file
export class PaginatedEndPointAsyncEntitySelect<T> extends Component<OwnProps<T>> {
    private InnerComponent = connect<StateProps, {}, OwnProps<T>>(
        (state: any) => {
            return {
                authedAxios: withAuthedAxios(selectApiBase(state), selectAuthState(state))
            };
        },
    )(withTranslation("core")(PaginatedEndpointAsyncEntitySelectComponent));

    public render() {
        const InnerComponent = this.InnerComponent;
        // @ts-ignore
        return <InnerComponent {...this.props}/>;
    }
}
