import React from "react";
import "./pricing-information-display.scss";
import {WithTranslation, withTranslation} from "react-i18next";
import {CurrencyDisplay, PricingInformation} from "@thekeytechnology/auktionshaus-frontend-library";
import {uuid4} from "@sentry/utils";

interface OwnProps {
    pricingInformation: PricingInformation;
}

type Props = OwnProps & WithTranslation;

const PricingInformationDisplayComponent = ({pricingInformation, t}: Props) => {
    const hasDiscount = pricingInformation.discountedLimitPrice && pricingInformation.limitPrice !== pricingInformation.discountedLimitPrice && pricingInformation.discountedLimitPrice !== 0;
    return <div className="pricing-information-display">
        <div className="prices">
            {hasDiscount ? <div className="discounted-price">
                <CurrencyDisplay amount={pricingInformation.discountedLimitPrice}/>
            </div> : null}

            <div className={"real-price" + (hasDiscount ? " discounted" : "")}>
                <CurrencyDisplay amount={pricingInformation.limitPrice}/>
            </div>
        </div>
        {pricingInformation.discounts.length ?
            <div className="discounts">
                {pricingInformation.discounts.map(discount =>
                    <div className="discount" key={`${uuid4()}`}>
                        {t("pricing-information.discount-types." + discount.discountType)}: {(discount.percentage * 100)}%
                    </div>
                )}
            </div> : null
        }
    </div>
};

export const PricingInformationDisplay = withTranslation("lot")(PricingInformationDisplayComponent);
