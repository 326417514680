import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";

import {API_GET_CURRENT_AUCTION_NUMBER} from "../actions/current-auction";
import {CurrentAuctionNumberDto} from "../model/current-auction-number-dto";
import {Action} from "redux";

export interface AuctionNumberState {
    currentAuctionNumber?: number;

}

export const initialState: AuctionNumberState = {
    currentAuctionNumber: undefined,
};

export function currentAuctionNumberReducer(state = initialState, action: Action): AuctionNumberState {
    if (isApiSuccess(API_GET_CURRENT_AUCTION_NUMBER)(action)) {
        const auctionNumberResponse = action as ApiCallSucceededAction<CurrentAuctionNumberDto>;
        return {
            ...state,
            currentAuctionNumber: auctionNumberResponse.payload.currentAuctionNumber
        };
    }
    return state;
}
