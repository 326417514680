import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_ASSIGN_PREASSIGNED = "assign-preassigned-items";

export interface AssignedItemsPayload {
    auctionId: string;
}

export const assignPreassignedItemsAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ASSIGN_PREASSIGNED,
            callType: API_ASSIGN_PREASSIGNED
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<AssignedItemsPayload>;
};
