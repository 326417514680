import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign, faSection} from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import {WithTranslation, withTranslation} from "react-i18next";
import {ShippingPosition} from "../../model/position/ShippingPosition";

interface OwnProps {
    disabled?: boolean
    position: ShippingPosition;
    onChange: (newPosition: ShippingPosition) => void;
}

type Props = OwnProps & WithTranslation;

const ShippingPositionEditorComponent = ({
                                             disabled,
                                             position,
                                             onChange,
                                             t
                                         }: Props) => {
    return <>
        <div className="input-field-wrapper w-100">
            <FontAwesomeIcon className="input-icon" icon={faEuroSign}/>
            <input className="form-control default-input"
                   placeholder={t("shipping-position-editor.placeholder")}
                   type="number"
                   disabled={disabled}
                   min={0}
                   step={0.01}
                   value={position.amount}
                   onChange={event => {
                       const newAmount = Number.parseFloat(event.target.value);
                       onChange(update(position, {amount: {$set: newAmount}}))
                   }}/>
        </div>
        <div className="input-field-wrapper">
            <FontAwesomeIcon className="input-icon" icon={faSection}/>
            <input className="form-control default-input"
                   placeholder={t("position-editor.tax")}
                   type="number"
                   disabled={disabled}
                   min={0}
                   step={1}
                   value={position.tax}
                   onChange={event => {
                       const newAmount = Number.parseFloat(event.target.value);
                       onChange(update(position, {tax: {$set: newAmount}}))
                   }}/>
        </div>
    </>;
};

export const ShippingPositionEditor = withTranslation("order")(ShippingPositionEditorComponent);
