import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_PAUSE_AUCTION = "pause-live-auction";

export interface PauseLiveAuctionPayload extends Action {
    message: string;
}

export const pauseAuctionAction = (message: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_PAUSE_AUCTION,
            callType: API_PAUSE_AUCTION
        },
        payload: {
            message
        }
    } as ApiCallAction<PauseLiveAuctionPayload>;
};
