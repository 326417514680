import {faDownload, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import React from "react";
import {useTranslation} from "react-i18next";
import "./attachment-display.scss"

interface AttachmentDisplayProps {
    item: EntityWrapper<TkFile>
    remove: (item: EntityWrapper<TkFile>) => void;
}

export const AttachmentDisplayWithDownload = (props: AttachmentDisplayProps) => {
    const {item, remove} = props;
    const {t} = useTranslation(["files"]);
    return <li className="d-flex list-group-item attachment-display">
        <div className="mr-2">
            {item.entity.thumbnailUrl ? <img alt="" src={item.entity.thumbnailUrl}/> : <>{t("attachment-display.no-preview")}</>}
        </div>
        <div className="title">{item.entity.name}&nbsp;</div>
        <div className={"download ml-auto"}><a href={item.entity.url} download={true}><FontAwesomeIcon icon={faDownload} /></a></div>
        <div className="cross ml-auto"><button type={"button"} onClick={() => {
            remove(props.item);
        }}><FontAwesomeIcon icon={faTimes}/></button></div>
    </li>;
};