import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_WARNING = "fetch-warnings";

export const fetchWarningsAction = (customerId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_WARNING,
            callType: API_FETCH_WARNING
        },
        payload: customerId
    } as ApiCallAction<string>;
};
