import {faEnvelope, faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {
    acceptInvitationAttemptWithRegistration,
    API_ACCEPT_INVITATION,
    ApiCallState,
    selectCombinedApiState,
    selectInvitation
} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import * as Yup from "yup";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";

interface AcceptInvitationProps extends WithTranslation {
    apiCallState: ApiCallState;
    token: string;
    acceptInvitation: (token: string, name: string, email: string, password: string) => void;
}

class AcceptInvitationWithNewRegistration extends React.Component<AcceptInvitationProps> {
    public render() {
        const {t, token, acceptInvitation, apiCallState} = this.props;

        return (
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    password: "",
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    email: Yup.string().email().required(),
                    password: Yup.string().min(8).required(),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    acceptInvitation(token, values.name, values.email, values.password);
                    setSubmitting(false);
                }}
            >
                {(formikState) => (
                    <Form>
                        <div className="paragraph text-center">
                            Do you have an account already? You can join multiple accounts! <NavLink
                            className="mb-5"
                            to="/login">{t("To login")}</NavLink>
                        </div>
                        <hr/>
                        <div className="form-group">
                            <ValidatedField formikState={formikState} type="name" icon={faUser}
                                            placeholder={t("Your name")} name="name"
                                            className="form-control default-input" required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState} type="email" icon={faEnvelope}
                                            placeholder={t("E-Mail")}
                                            name="email"
                                            className="form-control default-input" required/>
                        </div>
                        <div className="form-group">
                            <ValidatedField formikState={formikState} icon={faLock} type="password"
                                            placeholder={t("Password")} name="password"
                                            className="form-control default-input" required/>
                        </div>
                        <button disabled={formikState.isSubmitting || apiCallState.inProgress}
                                type="submit"
                                className="btn btn-primary w-100">
                            Anmelden
                        </button>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default connect(
    (state: any) => ({
        invitation: selectInvitation(state),
        apiCallState: selectCombinedApiState(API_ACCEPT_INVITATION)(state)
    }),
    {acceptInvitation: acceptInvitationAttemptWithRegistration}
)(withTranslation()(AcceptInvitationWithNewRegistration));
