import React from "react";
import {useDispatch} from "react-redux";
import {resetAuctionSpecificNumbersAction} from "../../actions/reset-auction-specific-numbers";


export const ResetAuctionSpecificNumbersButton = () => {

    const dispatch = useDispatch();

    return <>
        <button type="button"
                className="btn btn-secondary mr-3"
                onClick={() => dispatch(resetAuctionSpecificNumbersAction())}
        >
            Inva/Room Nummern zurück setzen
        </button>

    </>
};

