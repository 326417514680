import React from "react";
import {generateString, WithSwitchableLanguage, WithSwitchableLanguageProps} from "@thekeytechnology/framework-react";
import * as Yup from "yup";
import {ValidatedField, WysiwygField} from "@thekeytechnology/framework-react-ux";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {updateDescriptionAction} from "../../actions/inline-update/update-description";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik} from "formik";
import {WriteToItemCheckbox} from "./WriteToItemCheckbox";
import {LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    lotId: string;
    editingEnabled: boolean;
    writeToItemEnabled: boolean;
    currentDescription: { [lang: string]: string }
}

interface DispatchProps {
    updateDescription: typeof updateDescriptionAction;
}

type Props = OwnProps & WithSwitchableLanguageProps & WithTranslation & DispatchProps;

const InlineDescriptionEditorComponent = (props: Props) => {
    const {currentDescription, writeToItemEnabled, lotId, editingEnabled, updateDescription, t} = props;

    const currentLanguage = "de";

    const popover = <Popover id={generateString(10)}>
        <Popover.Title as="h3" className="d-flex align-items-center">
            {t("item:entity.labels.description")}
            <button
                onClick={() => {
                    document.body.click()
                }}
                className="ml-auto btn btn-link p-0">
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </Popover.Title>
        <Popover.Content>
            <Formik
                initialValues={{
                    description: currentDescription[currentLanguage],
                    writeToItem: writeToItemEnabled,
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    description: Yup.string()
                        .test("description", t("item:entity.labels.description-error"), function (val) {
                            if (!val || (val.length < 100 && val.indexOf("#") < 0)) {
                                return this.createError({message: t("item:entity.labels.description-error")})
                            }
                            return true;
                        }),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    updateDescription(lotId,
                        writeToItemEnabled && values.writeToItem,
                        {
                            ...currentDescription,
                            [currentLanguage]: values.description
                        }
                    );
                    document.body.click()
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="d-flex flex-column">
                        <ValidatedField formikState={formikState}
                                        component={WysiwygField}
                                        name="description"
                                        className="form-control default-input"
                                        required/>
                        {writeToItemEnabled ?
                            <WriteToItemCheckbox/>
                            : null}
                        <button disabled={formikState.isSubmitting}
                                className="btn btn-success w-100 mt-3"
                                type="submit">
                            {t("inline-editor.save")}
                        </button>
                    </Form>
                )}
            </Formik>
        </Popover.Content>
    </Popover>;

    return <div className="d-flex align-items-center">
        <LotTitleDisplay
            description={currentDescription[currentLanguage]}/>

        {editingEnabled ?
            <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
                <button className="btn btn-link ml-1">
                    <FontAwesomeIcon icon={faPencilAlt}/>
                </button>
            </OverlayTrigger> : null}
    </div>
};

export const InlineDescriptionEditor = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        updateDescription: updateDescriptionAction
    }
)(
    WithSwitchableLanguage<any>(
        withTranslation(["auctionPlanning", "item"])(InlineDescriptionEditorComponent))
);
