import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";

export class DiscountPosition implements OrderPosition {
    public static TYPE = "discount";

    public positionType = DiscountPosition.TYPE;

    public constructor(
        public amount: number,
        public tax: number = 0
    ) {
    }
}
