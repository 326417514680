import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import {ItemInLot} from "./ItemInLot";
import {CategoryJustIdNameAndColor} from "../../item-category/model/CategoryJustIdNameAndColor";
import {PricingInformation} from "@thekeytechnology/auktionshaus-frontend-library/dist/lot/lot";
import {CustomerJustIdAndName} from "@thekeytechnology/auktionshaus-frontend-library";

export class LotForEditing {
    public static CONTEXT = "edit";
    public static TYPE="lot"

    public static new() {
        return new EntityWrapper(
            undefined,
            new LotForEditing(
                {},
                new CategoryJustIdNameAndColor("", {}, ""),
                "",
                undefined,
                "",
                {limitPrice: undefined, discountedLimitPrice: undefined, discounts: []},
                [],
                [],
                "",
                [],
                [],
                new CustomerJustIdAndName("", "", 0)
            ));
    }

    constructor(
        public description: { [lang: string]: string },
        public category: CategoryJustIdNameAndColor,
        public status: string,
        public lotNumber: number | undefined,
        public condition: string,
        public pricingInformation: PricingInformation,
        public publicAttachments: Array<EntityWrapper<TkFile>>,
        public privateAttachments: Array<EntityWrapper<TkFile>>,
        public source: string,
        public errors: string[],
        public items: ItemInLot[],
        public seller: CustomerJustIdAndName,
    ) {
    }
}
