import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";
import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import {CustomReceiptForm} from "./CustomReceiptForm";

interface OwnProps {
    containerId: string
    initialPositions?: OrderPosition[],
    containerType: string
}

type Props = OwnProps

export const CustomReceiptButton = ({
                                        containerId,
                                        initialPositions,
                                        containerType
                                    }: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        <button
            className={"btn-link"}
            onClick={() => handleShow()}
            type="button"
        >
            Neuer Beleg
        </button>


        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <CustomReceiptForm
                handleClose={handleClose}
                initialPositions={initialPositions}
                containerId={containerId}
                containerType={containerType}
            /> : null}
        </Modal>
    </>;

}