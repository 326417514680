import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {PauseAuctionForm} from "./PauseAuctionForm";

export const PauseButton = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {t} = useTranslation(["auctioneer", "core"]);

    return <>
        <button className={"btn btn-primary pause-auction-button"}
                onClick={() => handleShow()}
        >
            {t("actions.pause-auction")}
        </button>

        <Modal size="xl" show={show} onHide={handleClose} className={"pause-auction-modal"}>
            {show ? <PauseAuctionForm handleClose={handleClose} /> : null}
        </Modal>
    </>;
};
