import React from "react";
import {useTranslation} from "react-i18next";
import {EntityWrapper, FilterTerm, OrFilter, PropertyFilter} from "@thekeytechnology/framework-react";
import {CategoryJustIdNameAndColor, ENTITY_TYPE_CATEGORY} from "@thekeytechnology/auktionshaus-frontend-library";
import {PaginatedEndpointCollapsibleFilters} from "../../../paginated-endpoints/components/PaginatedEndpointCollapsibleFilters";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {PaginatedEndPointTextFilter} from "../../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {PaginatedEndPointNumberFilter} from "../../../paginated-endpoints/components/PaginatedEndPointNumberFilter";
import {PaginatedEndPointAsyncEntitySelectFilter} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelectFilter";
import {CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelect";
import {CustomerSelectFilter} from "../../../auction-planning/components/filters/CustomerSelectFilter";
import {createRangeFilterForNumberInput} from "../../../core/utils/create-range-filter-for-number-input";
import StatusFilter from "./StatusFilter";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = OwnProps;

export const ItemFiltersNew = ({
                                   endpointFilters
                               }: Props) => {

    const {t} = useTranslation(["item"])
    return (
        <div className={"mt-4 assigned-lots-filters"}>


            <PaginatedEndpointCollapsibleFilters
                endpointFilters={endpointFilters}
                filterKeys={[
                    "entity.inventoryNumber",
                    "entity.sellerRef",
                    "entity.plannedAuctionRef",
                    "entity.status",
                    `entity.description.de`,
                    "entity.categoryRef",
                    "entity.minLimitPrice",
                    "entity.maxLimitPrice",
                ]}
            >

                <PaginatedEndPointNumberFilter
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.inventory-number")
                    })}
                    property={"entity.inventoryNumber"}
                    step={1}
                    customFilterFunction={(property: string,
                                           endpointFilters: PaginatedEndpointFiltersReturnProps,
                                           text: string,
                                           filterKey: string
                                           ) => {
                        if (text) {
                            endpointFilters.addFilters(filterKey, [new OrFilter(createRangeFilterForNumberInput(property, text, 7))]);
                        } else {
                            endpointFilters.removeFilters([filterKey]);
                        }
                    }}
                    customValueFunction={(endpointFilters: PaginatedEndpointFiltersReturnProps, filterKey) => {
                        const currentFilters = endpointFilters.getFilters().filter(f => f.key === filterKey).flatMap(f => f.filters)

                        return currentFilters && currentFilters.length && currentFilters[0] ?
                            ((currentFilters[0] as OrFilter).subfilters[0] as PropertyFilter).filterTerm.value : "";
                    }}
                />

                <CustomerSelectFilter
                    endpointFilters={endpointFilters}
                    property={"entity.sellerRef"}
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.seller")
                    })}
                />

                <PaginatedEndPointTextFilter
                    property={`entity.description.de`}
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("lot:entity.labels.short-description")
                    })}
                />


                <PaginatedEndPointAsyncEntitySelectFilter<Auction>
                    property={"entity.plannedAuctionRef"}
                    entityType={ENTITY_TYPE_AUCTION}
                    shownEntityProperties={["entity.auctionNumber"]}
                    listRenderer={(item: EntityWrapper<Auction>) => item.entity.auctionNumber.toString()}
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.auction")
                    })}
                    endpointFilters={endpointFilters}
                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/auctions/get-auctions")}
                    customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                        if (inputValue) {
                            return createRangeFilterForNumberInput(property, inputValue, 3)
                        } else {
                            return []
                        }
                    }}
                />

                <PaginatedEndPointAsyncEntitySelectFilter<CategoryJustIdNameAndColor>
                    property={"entity.categoryRef"}
                    entityType={ENTITY_TYPE_CATEGORY}
                    shownEntityProperties={["entity.name.de"]}
                    listRenderer={(item: EntityWrapper<CategoryJustIdNameAndColor>) => item.entity.name["de"]}
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.category")
                    })}
                    endpointFilters={endpointFilters}
                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/item-categories/get-categories")}
                />


                <PaginatedEndPointNumberFilter
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.min-price")
                    })}
                    property={"entity.limitPrice"}
                    filterOperation={FilterTerm.OPERATION_GTE}
                    step={1}
                    min={0}
                    filterKey={"entity.minLimitPrice"}
                />


                <PaginatedEndPointNumberFilter
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.max-price")
                    })}
                    property={"entity.limitPrice"}
                    filterOperation={FilterTerm.OPERATION_LTE}
                    step={1}
                    min={0}
                    filterKey={"entity.maxLimitPrice"}
                />

                <StatusFilter endpointFilters={endpointFilters}/>
            </PaginatedEndpointCollapsibleFilters>
        </div>
    );
};
