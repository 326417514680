import {EntityWrapper, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import React from "react";
import {CustomerJustIdAndName} from "@thekeytechnology/auktionshaus-frontend-library";
import {ENTITY_TYPE_CUSTOMER} from "../../../customers/model/customer";
import {PaginatedEndPointAsyncEntitySelectFilter} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelectFilter";
import {CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelect";
import {createRangeFilterForNumberInput} from "../../../core/utils/create-range-filter-for-number-input";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
    property: string,
    placeholder: string
}

type Props = OwnProps

export const CustomerSelectFilter = ({
                                          endpointFilters,
                                          property,
                                          placeholder
                                      }: Props) => {

    return <PaginatedEndPointAsyncEntitySelectFilter<CustomerJustIdAndName>
        property={property}
        entityType={ENTITY_TYPE_CUSTOMER}
        shownEntityProperties={["entity.personalData.firstName", "entity.personalData.lastName", "entity.customerNumber"]}
        listRenderer={(item: EntityWrapper<CustomerJustIdAndName>) => item.entity.customerNumber.toString(10).concat(" - ").concat(item.entity.name)}
        placeholder={placeholder}
        endpointFilters={endpointFilters}
        customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
            if (property === "entity.customerNumber") {
                if (inputValue && parseInt(inputValue)) {
                    return createRangeFilterForNumberInput(property, inputValue, 5)
                } else {
                    return []
                }
            } else {
                return [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))]
            }
        }}
        fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/customers/get-customers-for-select")}
    />
}
