import {webSocket, WebSocketSubject} from "rxjs/webSocket";
import {Observable, of} from "rxjs";
import {Action} from "redux";
import {ofType, StateObservable} from "redux-observable";
import {catchError, debounceTime, map, mergeMap, withLatestFrom} from "rxjs/operators";
import {selectAuthState} from "@thekeytechnology/framework-react";
import {
    CONNECT_TO_AUCTIONEER_SOCKET,
    connectToAuctioneerSocketAction
} from "../actions/connect-to-auctioneer-socket-action";
import {auctioneerUpdatedAction} from "../actions/live-auction-updated-auctioneer";

let socket: WebSocketSubject<unknown> | undefined;

export const connectToAuctioneerSocket$ = (action$: Observable<Action>, state$: StateObservable<any>) => action$.pipe(
    ofType(CONNECT_TO_AUCTIONEER_SOCKET),
    debounceTime(1000),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, any]) => {
            const currentAuthState = selectAuthState(state);
            socket = webSocket(`${process.env.REACT_APP_API_WS_BASE}/live-auction/ws-auctioneer?authToken=${currentAuthState.token}&accountId=${currentAuthState.currentAccount!.accountId}`);

            return socket!
                .multiplex(
                    () => ({type: "subscribe"}),
                    () => ({type: "unsubscribe"}),
                    () => true
                )
                .pipe(
                    map(
                        (response: any) => auctioneerUpdatedAction(response.liveAuction)
                    ),
                    catchError(error =>
                        of(connectToAuctioneerSocketAction())
                    )
                )
        }
    )
);
