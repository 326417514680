import {Form, Formik} from "formik";
import Modal from "react-bootstrap/Modal";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import Button from "react-bootstrap/Button";
import React from "react";
import {useTranslation} from "react-i18next";

interface OwnProps {
    handleClose: () => void;
    disabled: boolean
    submitCallback: (month: number, year: number) => void;
}

type Props = OwnProps;

export const DownloadOrdersForDatevForm = ({
                                               handleClose,
                                               disabled,
                                               submitCallback
                                           }: Props) => {
    const {t} = useTranslation(["lot"])

    return <Formik
        initialValues={{
            month: new Date().getMonth(),
            year: new Date().getFullYear()
        }}
        onSubmit={(values, {setSubmitting}) => {
            if (values.month && values.year) {
                submitCallback(values.month, values.year)
                setSubmitting(false);
            }
            handleClose();
        }}
    >
        {formikState => (
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="month" className="col-sm-10 col-form-label">
                            Monat
                        </label>
                        <ValidatedField formikState={formikState}
                                        type="number"
                                        name="month"
                                        min={1}
                                        max={12}
                                        className="form-control default-input"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="year" className="col-sm-10 col-form-label">
                            Jahr
                        </label>
                        <ValidatedField formikState={formikState}
                                        type="number"
                                        name="year"
                                        min={2021}
                                        max={2030}
                                        className="form-control default-input"/>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button type={"submit"} className={"btn btn-primary"} disabled={disabled}>
                        Download
                    </Button>
                    <Button className="btn btn-secondary"
                            onClick={handleClose}>
                        {t("core:save-and-back-buttons.cancel")}
                    </Button>
                </Modal.Footer>
            </Form>
        )}
    </Formik>;
}