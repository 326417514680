import {createSelector} from "reselect";
import {AppState} from "../../reducers";
import {OrderModuleState} from "../reducers";
import {OrderNumberState} from "../reducers/currentOrderNumber";

export const selectOrderModuleState = (state: AppState) => state.order;

export const selectOrderNumberState = createSelector(
    selectOrderModuleState,
    (moduleState: OrderModuleState) =>
        moduleState.orderNumber
);

export const selectNextOrderNumber = createSelector(
    selectOrderNumberState,
    (numberState: OrderNumberState) =>
        numberState.currentOrderNumber !== undefined ? (numberState.currentOrderNumber + 1) : undefined
);
