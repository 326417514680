import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {
    apiModuleReducer,
    ApiModuleState,
    authModuleReducer,
    AuthModuleState,
    dialogModuleReducer,
    DialogState,
    entityModuleReducer,
    EntityModuleState,
    filesModuleReducer,
    i18nModuleReducer,
    I18nModuleState,
    integrationsModuleReducer,
    IntegrationsModuleState,
    messagesModuleReducer,
    MessagesState,
    settingsModuleReducer,
    SettingsModuleState,
    UploadModuleState,
    usersModuleReducer
} from "@thekeytechnology/framework-react";
import {auctionModuleReducer, AuctionModuleState} from "./auction/reducers";
import {itemModuleReducer, ItemModuleState} from "./items/reducers";
import {customerModuleReducer, CustomerModuleState} from "./customers/reducers";
import {auctionPlanningModuleReducer, AuctionPlanningModuleState} from "./auction-planning/reducers";
import {orderModuleReducer, OrderModuleState} from "./orders/reducers";
import {auctioneerModuleReducer, AuctioneerModuleState} from "./auctioneer-view/reducers";
import {settlementModuleReducer, SettlementModuleState} from "./settlements/reducers";
import {warningModuleReducer, WarningModuleState} from "./warnings/reducers";

/* TODO: Warum gibt es das nicht im Frontend? => Kann ich so nachbauen in der webapp */
export interface AppState {
    router: any;
    auth: AuthModuleState;
    entities: EntityModuleState;
    dialog: DialogState;
    upload: UploadModuleState;
    messages: MessagesState;
    api: ApiModuleState;
    i18n: I18nModuleState;
    settings: SettingsModuleState;
    integrations: IntegrationsModuleState;
    auctioneer: AuctioneerModuleState;
    auction: AuctionModuleState;
    item: ItemModuleState;
    customer: CustomerModuleState;
    auctionPlanning: AuctionPlanningModuleState;
    order: OrderModuleState;
    settlement: SettlementModuleState;
    warning: WarningModuleState;
}

export const appReducers = (history: any) => combineReducers({
    router: connectRouter(history),
    auth: authModuleReducer,
    entities: entityModuleReducer,
    dialog: dialogModuleReducer,
    upload: filesModuleReducer,
    messages: messagesModuleReducer,
    api: apiModuleReducer(process.env.REACT_APP_API_BASE!),
    i18n: i18nModuleReducer,
    settings: settingsModuleReducer,
    integrations: integrationsModuleReducer,
    users: usersModuleReducer,
    auctioneer: auctioneerModuleReducer,
    auction: auctionModuleReducer,
    item: itemModuleReducer,
    customer: customerModuleReducer,
    auctionPlanning: auctionPlanningModuleReducer,
    order: orderModuleReducer,
    settlement: settlementModuleReducer,
    warning: warningModuleReducer
});
