import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SEND_EMAIL_TO_CUSTOMER = "send-email-to-customer";

interface OwnProps {
    customerId: string,
    subject: string
    body: string
}

export const sendEmailToCustomerAction = (customerId: string, subject: string, body: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SEND_EMAIL_TO_CUSTOMER,
            callType: API_SEND_EMAIL_TO_CUSTOMER
        },
        payload: {
            customerId,
            subject,
            body
        }
    } as ApiCallAction<OwnProps>;
};
