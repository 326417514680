import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_REFRESH_ALL_INVOICES = "refresh-all-invoices";

export const refreshAllInvoicesAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_REFRESH_ALL_INVOICES,
        callType: API_REFRESH_ALL_INVOICES
    }
} as ApiCallAction<string>;

