import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FETCH_CREDIT_NOTE = "fetch-credit-note";

export const fetchCreditNoteAction = (creditNoteId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FETCH_CREDIT_NOTE,
            callType: API_FETCH_CREDIT_NOTE
        },
        payload: creditNoteId
    } as ApiCallAction<string>;
};
