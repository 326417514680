import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {
    API_DOWNLOAD_AUCTION_PROTOCOL,
    downloadAuctionProtocolAction
} from "../../actions/download-pdfs/download-auction-protocol";
import {useRouteMatch} from "react-router";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {Dropdown} from "react-bootstrap";

export const DownloadAuctionProtocolButton = () => {
    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"];
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_AUCTION_PROTOCOL));

    return <>
        <Dropdown.Item
            disabled={apiCallState.inProgress}
            onClick={() => dispatch(downloadAuctionProtocolAction(auctionId))}
        >
            <FontAwesomeIcon icon={faList}/> Protokoll
        </Dropdown.Item>
    </>
}
