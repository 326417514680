import {FieldProps} from "formik";
import {WithTranslation, withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import "./positions-field.scss";
import {Table, TableBody, TableColumn, TableHeader, TableRow} from "@thekeytechnology/framework-react-ux";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";
import {POSITION_TYPE_WINNING_BID, WinningBidPosition} from "../../model/position/WinningBidPosition";
import {
    BidSpiritCommissionPosition,
    InvaluableCommissionPosition,
    LottissimoCommissionPosition,
    NegativeCommissionPosition,
    POSITION_TYPE_BIDSPIRIT_COMMISSION,
    POSITION_TYPE_FINANCING_COMMISSION,
    POSITION_TYPE_INVALUABLE_COMMISSION,
    POSITION_TYPE_LOTTISSIMO_COMMISSION,
    POSITION_TYPE_NEGATIVE_COMMISSION,
    POSITION_TYPE_POSITIVE_COMMISSION,
    PositiveCommissionPosition
} from "../../model/position/CommissionPosition";
import {ShippingPosition} from "../../model/position/ShippingPosition";
import {DiscountPosition} from "../../model/position/DiscountPosition";
import {CommissionPositionEditor} from "./CommissionPositionEditor";
import update from "immutability-helper";
import {DiscountPositionEditor} from "./DiscountPositionEditor";
import {ShippingPositionEditor} from "./ShippingPositionEditor";
import {WinningBidPositionEditor} from "./WinningBidPositionEditor";
import {CustomPosition, POSITION_TYPE_CUSTOM} from "../../model/position/CustomPosition";
import {CustomPositionEditor} from "./CustomPositionEditor";
import {POSITION_TYPE_UNSOLD_LOT, UnsoldLotPosition} from "../../model/position/UnsoldLotPosition";
import {UnsoldLotPositionEditor} from "./UnsoldLotPositionEditor";
import {LotPosition, POSITION_TYPE_LOT} from "../../model/position/LotPosition";
import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";
import {Bid} from "../../../bids/model/bid";
import {CustomTaxFreePosition, POSITION_TYPE_CUSTOM_TAX_FREE} from "../../model/position/CustomTaxFreePosition";
import {LOT_BILLING_STATUS_PAID} from "../../model/Order";
import {CustomNegativePosition, POSITION_TYPE_CUSTOM_NEGATIVE} from "../../model/position/CustomNegativePosition";
import {
    CustomNegativeTaxFreePosition,
    POSITION_TYPE_CUSTOM_NEGATIVE_TAX_FREE
} from "../../model/position/CustomNegativeTaxFreePosition";
import {ENTITY_TYPE_SETTLEMENT} from "../../../settlements/model/settlement";
import {useCustomEndpoint} from "../../../core/utils/use-custom-endpoint";

interface OwnProps {
    disabled?: boolean,
    containerType?: string
}

type Props = OwnProps & FieldProps & WithTranslation;

const PositionsFieldComponent = ({
                                     disabled,
                                     field,
                                     form,
                                     t,
                                     containerType
                                 }: Props) => {

    const currentOrderPositions = field.value ? field.value as OrderPosition[] : [];
    const [defaultTax, setDefaultTax] = useState(19);
    const defaultTaxEndpoint = useCustomEndpoint<number>("/auction-app-settings/get-default-tax");
    useEffect(() => {
        defaultTaxEndpoint.call((result) => {
            setDefaultTax(result);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const OPTION_TYPES = [
        POSITION_TYPE_CUSTOM,
        POSITION_TYPE_CUSTOM_TAX_FREE,
        POSITION_TYPE_CUSTOM_NEGATIVE,
        POSITION_TYPE_CUSTOM_NEGATIVE_TAX_FREE,
        ShippingPosition.TYPE,
        POSITION_TYPE_INVALUABLE_COMMISSION,
        POSITION_TYPE_LOTTISSIMO_COMMISSION,
        POSITION_TYPE_BIDSPIRIT_COMMISSION,
        POSITION_TYPE_FINANCING_COMMISSION,
        POSITION_TYPE_POSITIVE_COMMISSION,
        POSITION_TYPE_NEGATIVE_COMMISSION,
        DiscountPosition.TYPE,
    ].map(positionType => ({
        label: t(`positions-field.position-types.${positionType}`),
        value: positionType
    }));

    const [state, setState] = useState({
        selectedType: POSITION_TYPE_CUSTOM
    });

    const calculateComissionValue = (percentage: number, sum: number) => {
        return (sum * ((100 + percentage) / 100) - sum)
    }

    const calcItemsSum = (positions: OrderPosition[], onlyForPaid: boolean = false) => {
        return positions.map(position => {
            switch (position.positionType) {
                case POSITION_TYPE_WINNING_BID:
                    return (!onlyForPaid || (position as WinningBidPosition).status === LOT_BILLING_STATUS_PAID) ? position.amount || 0 : 0
                default:
                    return 0
            }
        }).reduce((sum, current) => sum + current, 0);
    }

    const calcInvaluableSum = (positions: OrderPosition[], onlyForPaid: boolean = false) => {
        return positions.map(position => {
            switch (position.positionType) {
                case POSITION_TYPE_WINNING_BID:
                    return ((position as WinningBidPosition).source === Bid.SOURCE_INVALUABLE && (!onlyForPaid || (position as WinningBidPosition).status === LOT_BILLING_STATUS_PAID)) ? position.amount || 0 : 0
                default:
                    return 0
            }
        }).reduce((sum, current) => sum + current, 0);
    }

    const calcLottissimoSum = (positions: OrderPosition[], onlyForPaid: boolean = false) => {
        return positions.map(position => {
            switch (position.positionType) {
                case POSITION_TYPE_WINNING_BID:
                    return ((position as WinningBidPosition).source === Bid.SOURCE_LOTTISSIMO && (!onlyForPaid || (position as WinningBidPosition).status === LOT_BILLING_STATUS_PAID)) ? position.amount || 0 : 0
                default:
                    return 0
            }
        }).reduce((sum, current) => sum + current, 0);
    }

    const calcBidSpiritSum = (positions: OrderPosition[], onlyForPaid: boolean = false) => {
        return positions.map(position => {
            switch (position.positionType) {
                case POSITION_TYPE_WINNING_BID:
                    return ((position as WinningBidPosition).source === Bid.SOURCE_BIDSPIRIT && (!onlyForPaid || (position as WinningBidPosition).status === LOT_BILLING_STATUS_PAID)) ? position.amount || 0 : 0
                default:
                    return 0
            }
        }).reduce((sum, current) => sum + current, 0);
    }

    const calcTotalSum = (positions: OrderPosition[], withTax: boolean = true, onlyForPaid: boolean = false) => {
        const netSum = calcItemsSum(positions, onlyForPaid)
        const lottissimoSum = calcLottissimoSum(positions, onlyForPaid)
        const invaluableSum = calcInvaluableSum(positions, onlyForPaid)
        const bidSpiritSum = calcBidSpiritSum(positions, onlyForPaid)

        let x = 0;
        const commissions = currentOrderPositions.map(position => {
            switch (position.positionType) {
                case POSITION_TYPE_POSITIVE_COMMISSION:
                    x = calculateComissionValue(position.amount, netSum)
                    x = x * (withTax ? 1.19 : 1)
                    return calculateComissionValue(position.amount, netSum) * (withTax ? 1.19 : 1)
                case POSITION_TYPE_NEGATIVE_COMMISSION:
                    return -calculateComissionValue(position.amount, netSum) * (withTax ? 1.19 : 1)
                case POSITION_TYPE_INVALUABLE_COMMISSION:
                    return calculateComissionValue(position.amount, invaluableSum) * (withTax ? 1.19 : 1)
                case POSITION_TYPE_LOTTISSIMO_COMMISSION:
                    return calculateComissionValue(position.amount, lottissimoSum) * (withTax ? 1.19 : 1)
                case POSITION_TYPE_BIDSPIRIT_COMMISSION:
                    return calculateComissionValue(position.amount, bidSpiritSum) * (withTax ? 1.19 : 1)
                case POSITION_TYPE_FINANCING_COMMISSION:
                    x = calculateComissionValue(position.amount, netSum)
                    x = x * (withTax ? 1.19 : 1)
                    return calculateComissionValue(position.amount, netSum)
                default:
                    return 0
            }
        }).reduce((sum, current) => sum + current, 0);

        const othersSum = currentOrderPositions.map(position => {
            switch (position.positionType) {
                case POSITION_TYPE_CUSTOM:
                case ShippingPosition.TYPE:
                    return (position.amount || 0) * (withTax ? 1.19 : 1)
                case POSITION_TYPE_CUSTOM_TAX_FREE:
                    return position.amount || 0
                case POSITION_TYPE_CUSTOM_NEGATIVE:
                    return -(position.amount || 0) * (withTax ? 1.19 : 1)
                case POSITION_TYPE_CUSTOM_NEGATIVE_TAX_FREE:
                case DiscountPosition.TYPE:
                case POSITION_TYPE_UNSOLD_LOT:
                    return -position.amount || 0
                default:
                    return 0
            }
        }).reduce((sum, current) => sum + current, 0);

        return netSum + commissions + othersSum
    }

    const itemsSum = calcItemsSum(currentOrderPositions)
    const totalSum = calcTotalSum(currentOrderPositions)
    const totalSumWithoutTax = calcTotalSum(currentOrderPositions, false)
    const invaluableSum = calcInvaluableSum(currentOrderPositions)
    const lottissimoSum = calcLottissimoSum(currentOrderPositions)
    const bidSpiritSum = calcBidSpiritSum(currentOrderPositions)

    const totalPaidSum = calcTotalSum(currentOrderPositions, true, true)
    const totalPaidSumWithoutTax = calcTotalSum(currentOrderPositions, false, true)

    return <div className="positions-field">
        <Table className="positions-table mb-2" itemCount={currentOrderPositions.length}>
            <TableHeader>
                <TableColumn>{t("positions-field.columns.position")}</TableColumn>
                <TableColumn>{t("positions-field.columns.type")}</TableColumn>
                <TableColumn>{t("positions-field.columns.details")}</TableColumn>
                <TableColumn>{t("positions-field.columns.actions")}</TableColumn>
            </TableHeader>
            <TableBody>
                {currentOrderPositions.map((orderPosition: OrderPosition, index: number) => {
                    const onChange = (newPosition: OrderPosition) => {
                        form.setFieldValue(field.name,
                            update(currentOrderPositions, {[index]: {$set: newPosition}})
                        )
                    };

                    let Editor;
                    switch (orderPosition.positionType) {
                        case POSITION_TYPE_POSITIVE_COMMISSION:
                            Editor = <CommissionPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as PositiveCommissionPosition}
                                sum={itemsSum}
                            />;
                            break;
                        case POSITION_TYPE_NEGATIVE_COMMISSION:
                            Editor = <CommissionPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as NegativeCommissionPosition}
                                sum={itemsSum}
                            />;
                            break;
                        case POSITION_TYPE_INVALUABLE_COMMISSION:
                            Editor = <CommissionPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as PositiveCommissionPosition}
                                sum={invaluableSum}
                            />;
                            break;
                        case POSITION_TYPE_LOTTISSIMO_COMMISSION:
                            Editor = <CommissionPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as PositiveCommissionPosition}
                                sum={lottissimoSum}
                            />;
                            break;
                        case POSITION_TYPE_BIDSPIRIT_COMMISSION:
                            Editor = <CommissionPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as PositiveCommissionPosition}
                                sum={bidSpiritSum}
                            />;
                            break;
                        case POSITION_TYPE_FINANCING_COMMISSION:
                            Editor = <CommissionPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as PositiveCommissionPosition}
                                sum={itemsSum}
                            />;
                            break;
                        case DiscountPosition.TYPE:
                            Editor = <DiscountPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as DiscountPosition}/>;
                            break;
                        case ShippingPosition.TYPE:
                            Editor = <ShippingPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as ShippingPosition}/>;
                            break;
                        case POSITION_TYPE_WINNING_BID:
                            Editor = <WinningBidPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as WinningBidPosition}/>;
                            break;
                        case POSITION_TYPE_CUSTOM:
                            Editor = <CustomPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as CustomPosition}/>;
                            break;
                        case POSITION_TYPE_CUSTOM_TAX_FREE:
                            Editor = <CustomPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as CustomTaxFreePosition}/>;
                            break;
                        case POSITION_TYPE_CUSTOM_NEGATIVE:
                            Editor = <CustomPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as CustomNegativePosition}/>;
                            break;
                        case POSITION_TYPE_CUSTOM_NEGATIVE_TAX_FREE:
                            Editor = <CustomPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as CustomNegativeTaxFreePosition}/>;
                            break;
                        case POSITION_TYPE_UNSOLD_LOT:
                            Editor = <UnsoldLotPositionEditor
                                disabled={disabled}
                                onChange={onChange}
                                position={orderPosition as UnsoldLotPosition}/>;
                            break;
                    }

                    return <TableRow key={index}>
                        <TableColumn>{index + 1}</TableColumn>
                        <TableColumn>{t(`positions-field.position-types.${orderPosition.positionType}`)}</TableColumn>
                        <TableColumn>{Editor}</TableColumn>
                        <TableColumn>
                            <button
                                disabled={disabled}
                                onClick={() => form.setFieldValue(field.name,
                                    update(currentOrderPositions, {$splice: [[index, 1]]})
                                )}
                                type="button" className="btn btn-danger ml-auto"><FontAwesomeIcon icon={faTrash}/>
                            </button>
                        </TableColumn>
                    </TableRow>
                })}
            </TableBody>
        </Table>
        <div className="d-flex">
            <Select
                isDisabled={disabled}
                className="react-select mr-2"
                classNamePrefix="react-select"
                isClearable={false}
                options={OPTION_TYPES}
                onChange={(newValue: any) => setState({
                    ...state,
                    selectedType: newValue.value
                })}
                value={OPTION_TYPES.find((selected: any) => selected.value === state.selectedType)}
            />
            <button disabled={disabled} type="button" className="btn btn-success ml-auto" onClick={() => {
                let newElement;
                switch (state.selectedType) {
                    case POSITION_TYPE_WINNING_BID:
                        newElement = {
                            positionType: POSITION_TYPE_WINNING_BID,
                            amount: 0,
                            bidRef: ''
                        } as WinningBidPosition;
                        break;
                    case POSITION_TYPE_POSITIVE_COMMISSION:
                        newElement = {
                            amount: 0,
                            comment: "",
                            positionType: POSITION_TYPE_POSITIVE_COMMISSION,
                            tax: defaultTax
                        } as PositiveCommissionPosition;
                        break;
                    case POSITION_TYPE_NEGATIVE_COMMISSION:
                        newElement = {
                            amount: 0,
                            comment: "",
                            positionType: POSITION_TYPE_NEGATIVE_COMMISSION,
                            tax: defaultTax
                        } as NegativeCommissionPosition;
                        break;
                    case POSITION_TYPE_INVALUABLE_COMMISSION:
                        newElement = {
                            amount: 5,
                            comment: "Invaluable Gebühr (5%)",
                            positionType: POSITION_TYPE_INVALUABLE_COMMISSION,
                            tax: defaultTax
                        } as InvaluableCommissionPosition;
                        break;
                    case POSITION_TYPE_LOTTISSIMO_COMMISSION:
                        newElement = {
                            amount: 3,
                            comment: "Lottissimo Gebühr (3%)",
                            positionType: POSITION_TYPE_LOTTISSIMO_COMMISSION,
                            tax: defaultTax
                        } as LottissimoCommissionPosition;
                        break;
                    case POSITION_TYPE_BIDSPIRIT_COMMISSION:
                        newElement = {
                            amount: 1,
                            comment: "BidSpirit Gebühr (1%)",
                            positionType: POSITION_TYPE_BIDSPIRIT_COMMISSION,
                            tax: defaultTax
                        } as BidSpiritCommissionPosition;
                        break;
                    case POSITION_TYPE_FINANCING_COMMISSION:
                        newElement = {
                            amount: 5,
                            comment: "Finanzierung (5%)",
                            positionType: POSITION_TYPE_FINANCING_COMMISSION,
                            tax: 0
                        } as NegativeCommissionPosition;
                        break;
                    case DiscountPosition.TYPE:
                        newElement = new DiscountPosition(0);
                        break;
                    case ShippingPosition.TYPE:
                        newElement = new ShippingPosition(0);
                        break;
                    case POSITION_TYPE_LOT:
                        newElement = {
                            positionType: POSITION_TYPE_LOT,
                            lotRef: undefined,
                            amount: 0,
                            tax: 0
                        } as LotPosition
                        break;
                    case POSITION_TYPE_UNSOLD_LOT:
                        newElement = {
                            positionType: POSITION_TYPE_UNSOLD_LOT,
                            lotRef: undefined,
                            amount: 0,
                            tax: 0
                        } as UnsoldLotPosition
                        break;
                    case POSITION_TYPE_CUSTOM:
                        newElement = {
                            positionType: POSITION_TYPE_CUSTOM,
                            amount: 0,
                            comment: "",
                            tax: defaultTax
                        } as CustomPosition
                        break;
                    case POSITION_TYPE_CUSTOM_TAX_FREE:
                        newElement = {
                            positionType: POSITION_TYPE_CUSTOM_TAX_FREE,
                            amount: 0,
                            comment: "",
                            tax: 0
                        } as CustomTaxFreePosition
                        break;
                    case POSITION_TYPE_CUSTOM_NEGATIVE:
                        newElement = {
                            positionType: POSITION_TYPE_CUSTOM_NEGATIVE,
                            amount: 0,
                            comment: "",
                            tax: defaultTax
                        } as CustomNegativePosition
                        break;
                    case POSITION_TYPE_CUSTOM_NEGATIVE_TAX_FREE:
                        newElement = {
                            positionType: POSITION_TYPE_CUSTOM_NEGATIVE_TAX_FREE,
                            amount: 0,
                            comment: "",
                            tax: 0
                        } as CustomNegativeTaxFreePosition
                        break;
                }

                form.setFieldValue(field.name, [
                    ...currentOrderPositions,
                    newElement
                ])
            }}><FontAwesomeIcon icon={faPlus}/></button>
        </div>
        <div>
            Summe: {totalSum.toFixed(2)} (Brutto: {totalSumWithoutTax.toFixed(2)} -
            Steuern: {(totalSum - totalSumWithoutTax).toFixed(2)} )
            {containerType === ENTITY_TYPE_SETTLEMENT ?
                <>
                    <br/>
                    Summe (nur bezahlt): {totalPaidSum.toFixed(2)} (Brutto: {totalPaidSumWithoutTax.toFixed(2)} -
                    Steuern (nur bezahlt): {(totalPaidSum - totalPaidSumWithoutTax).toFixed(2)} )
                    <br/>Unverkaufte
                    Lose: {currentOrderPositions.filter(p => p.positionType === POSITION_TYPE_UNSOLD_LOT).length}
                    <br/>Verkaufte
                    Lose: {currentOrderPositions.filter(p => p.positionType === POSITION_TYPE_WINNING_BID).length}
                    <br/>Bezahlte
                    Lose: {currentOrderPositions.filter(p => p.positionType === POSITION_TYPE_WINNING_BID && (p as WinningBidPosition).status === LOT_BILLING_STATUS_PAID).length}
                </>
                : null}

        </div>
    </div>
};

export const PositionsField = withTranslation("order")(PositionsFieldComponent);
