import {apiPost, matchesAnyOfTheseApiSuccesses, Message, postMessageAction} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {Action} from "redux";
import {API_SAVE_AUCTION_APP_SETTINGS} from "../actions/save-auction-app-setting";


const successMessageAfterAuctionAppSettingsSave$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_AUCTION_APP_SETTINGS
    }),
    map(action => {
        return postMessageAction(Message.TYPE_SUCCESS, "Einstellungen erfolgreich gespeichert")
    })
);

const saveAuctionAppSettings$ = apiPost(
    {apiType: API_SAVE_AUCTION_APP_SETTINGS},
    "/auction-app-settings/save-settings"
)

export const auctionAppSettingsModuleEpics$ = combineEpics(
    saveAuctionAppSettings$,
    successMessageAfterAuctionAppSettingsSave$
);

