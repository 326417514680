import React, {useEffect, useRef, useState} from "react";
import {FieldProps} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLevelDownAlt, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import "./bid-field.scss";
import {BID_STEP_TABLE, bidStepsFromValue} from "@thekeytechnology/auktionshaus-frontend-library";

interface State {
    currentStep: number;
    min: number;
    typingTimeout?: NodeJS.Timeout;
}

interface OwnProps {
    minimumBid: number;
    currentNeededBid?: number;
    disableSnapping?: boolean;
    autoAdjustOnNewBidOrLot: boolean;
}

type Props = FieldProps & OwnProps;

export const BidFieldCustom = ({
                                   field,
                                   form,
                                   minimumBid = 0,
                                   currentNeededBid = 0,
                                   disableSnapping = false,
                                   autoAdjustOnNewBidOrLot = true
                               }: Props) => {

    const stepDown = () => {
        snapFieldValueToStep();
        const value = field.value
        const step = bidStepsFromValue(value)[0]
        const newValue = parseInt(value, 10) - step;

        form.setFieldValue(field.name, Math.max(newValue, minimumBid));
    }

    const stepUp = () => {
        snapFieldValueToStep();
        const value = field.value
        const step = bidStepsFromValue(value)[1]
        const newValue = parseInt(value, 10) + step;

        form.setFieldValue(field.name, newValue);
    }


    const keyPress = (event: any) => {
        switch (event.keyCode) {
            case 38:
                event.preventDefault();
                event.stopPropagation();
                stepUp();
                break;
            case 40:
                event.preventDefault();
                event.stopPropagation();
                stepDown();
                break;
        }
    }

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    const [state, setState] = useState<State>({
        currentStep: bidStepsFromValue(field.value)[1],
        min: minimumBid
    });

    const snapFieldValueToStep = () => {
        const valueStr = ref.current && ("value" in ref.current) ? ref.current.value : "0";

        const value = Number(valueStr);
        const step = bidStepsFromValue(value)[1];
        const lowerBound = BID_STEP_TABLE.find((element: any) => element.step === step)!.lowerBound;

        setState({
            ...state,
            currentStep: step,
            min: lowerBound - step
        });

        const valueWithoutLowerBound = value - lowerBound;
        const low = valueWithoutLowerBound - valueWithoutLowerBound % step;
        const high = low + step;

        const newValue = (valueWithoutLowerBound - low < high - valueWithoutLowerBound ? low : high) + lowerBound;
        form.setFieldValue(field.name, Math.max(newValue, minimumBid));
        ref.current!.setAttribute("value", newValue.toString())
    };

    const onChange = (value: number) => {
        if (state.typingTimeout) {
            clearTimeout(state.typingTimeout);
        }
        setState({
            ...state,
            typingTimeout: global.setTimeout(() => {
                if (value >= minimumBid) {
                    if (!disableSnapping) {
                        snapFieldValueToStep();
                    }
                } else {
                    form.setFieldValue(field.name, minimumBid);
                }
            }, 1000),
            currentStep: bidStepsFromValue(value)[1]
        });
        form.setFieldValue(field.name, value.toString());
    };

    useEffect(() => {
        if (autoAdjustOnNewBidOrLot) {
            form.setFieldValue(field.name, Math.max(minimumBid, currentNeededBid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minimumBid, currentNeededBid]);

    return <>
        <div className={"bid-field"}>
            <input
                type="number"
                name="bid"
                value={field.value}
                onChange={event => onChange(parseInt(event.target.value, 10))}
                onKeyDown={keyPress}
                className="form-control default-input"
                ref={ref}
                required/>
            <button type={"button"} className={"step-down"} onClick={stepDown}><FontAwesomeIcon icon={faMinus}/>
            </button>
            <button type={"button"} className={"step-up"} onClick={stepUp}><FontAwesomeIcon icon={faPlus}/></button>
            {disableSnapping ?
                <button type={"button"} className={"snap"} onClick={snapFieldValueToStep}><FontAwesomeIcon
                    icon={faLevelDownAlt}/> Bietschritt angleichen</button> : null}
        </div>
        {/*<div className={"current-step"}>*/}
        {/*    (Bietschritt: {state.currentStep})*/}
        {/*</div>*/}
    </>;
}
