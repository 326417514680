import React, {ReactElement} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign, faHeading, faSection} from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import {useTranslation} from "react-i18next";
import {CustomPosition} from "../../model/position/CustomPosition";
import {CustomNegativePosition} from "../../model/position/CustomNegativePosition";
import {CustomTaxFreePosition} from "../../model/position/CustomTaxFreePosition";
import {CustomNegativeTaxFreePosition} from "../../model/position/CustomNegativeTaxFreePosition";

interface OwnProps< T extends (CustomPosition|CustomTaxFreePosition|CustomNegativePosition|CustomNegativeTaxFreePosition)> {
    disabled?: boolean
    position: T;
    onChange: (newPosition: T) => void;
}

export function CustomPositionEditor<T extends (CustomPosition|CustomTaxFreePosition|CustomNegativePosition|CustomNegativeTaxFreePosition)> ({
                                         disabled,
                                         position,
                                         onChange,
                                     }: OwnProps<T>): ReactElement<T> {

    const {t} = useTranslation("order")

    return <div className="d-flex w-100">
        <div className="input-field-wrapper mr-2 w-100">
            <FontAwesomeIcon className="input-icon" icon={faHeading}/>
            <input className="form-control default-input"
                   placeholder={t("custom-position-editor.comment-placeholder")}
                   type="text"
                   disabled={disabled}
                   value={position.comment}
                   onChange={event => {
                       onChange(update(position, {comment: {$set: event.target.value}} as any))
                   }}/>
        </div>
        <div className="input-field-wrapper">
            <FontAwesomeIcon className="input-icon" icon={faEuroSign}/>
            <input className="form-control default-input"
                   placeholder={t("custom-position-editor.placeholder")}
                   type="number"
                   min={0}
                   disabled={disabled}
                   step={0.01}
                   value={position.amount}
                   onChange={event => {
                       const newAmount = Number.parseFloat(event.target.value);
                       onChange(update(position, {amount: {$set: newAmount}} as any))
                   }}/>
        </div>
        <div className="input-field-wrapper">
            <FontAwesomeIcon className="input-icon" icon={faSection}/>
            <input className="form-control default-input"
                   placeholder={t("position-editor.tax")}
                   type="number"
                   disabled={disabled}
                   min={0}
                   step={1}
                   value={position.tax}
                   onChange={event => {
                       const newAmount = Number.parseFloat(event.target.value);
                       onChange(update(position, {tax: {$set: newAmount}} as any))
                   }}/>
        </div>
    </div>
};

