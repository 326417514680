import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {EntityWrapper, selectSingleEntity} from "@thekeytechnology/framework-react";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {transferLotsAction} from "../../actions/assign/transfer";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

interface StateProps {
    selectedAuction?: EntityWrapper<Auction>;
}

interface DispatchProps {
    transfer: typeof transferLotsAction;
}

type Props = WithTranslation & StateProps & DispatchProps;

const TransferButtonComponent = (props: Props) => {
    const {t, transfer, selectedAuction} = props;

    return selectedAuction ? <button type="button" onClick={() => {
        transfer(selectedAuction.id as string);

    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon className="mr-2"
                         icon={faArrowRight}/>{t("auction-planning.actions.transfer")}
    </button> : null;
};

export const TransferButton = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        selectedAuction: selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION)(state),
    }),
    {
        transfer: transferLotsAction,
    }
)(withTranslation("auctionPlanning")(TransferButtonComponent));
