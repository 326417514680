import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    Pagination,
    PaginationContainer,
    SimpleHeader
} from "@thekeytechnology/framework-react-ux";
import {AuctionsTable} from "./AuctionsTable";
import {ENTITY_TYPE_AUCTION} from "../model/auction";
import {AuctionsFilter} from "./AuctionsFilter";

const ItemsDefinitionOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/auctions"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <AuctionsFilter/>
            </BackgroundContainer>
            <ContentContainer>
                <AuctionsTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_AUCTION}/>
            </PaginationContainer>
        </>
    );
};

export const AuctionOverview = withTranslation("auction")(ItemsDefinitionOverviewComponent);
