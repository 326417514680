import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SUBMIT_BULK_LIMIT_BIDS = "submit-bulk-limit-bids";

export interface SubmitBulkLimitBidsActionPayload extends Action {
    customerId: string;
    value: number;
    lotIds: string[];
}

export const submitBulkLimitBidsAction = (customerId: string, value: number, lotIds: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SUBMIT_BULK_LIMIT_BIDS,
            callType: API_SUBMIT_BULK_LIMIT_BIDS
        },
        payload: {
            customerId,
            value,
            lotIds
        }
    } as ApiCallAction<SubmitBulkLimitBidsActionPayload>;
};
