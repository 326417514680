import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_SELLER = "update-seller";

export interface UpdateSellerPayload {
    lotId: string;
    writeToItem: boolean;
    seller: string
}

export const updateSellerAction = (lotId: string, writeToItem: boolean, seller: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_SELLER,
            callType: API_UPDATE_SELLER
        },
        payload: {
            lotId,
            writeToItem,
            seller
        }
    } as ApiCallAction<UpdateSellerPayload>;
};
