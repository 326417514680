import {EntityWrapper, selectCombinedApiState,} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import React, {useEffect} from "react";
import {
    AsyncEntitySelectField,
    ContentContainer,
    CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY,
    DateField,
    EditHeaderWithBackground,
    LoadingRow,
    SaveAndBackButtons,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps,
    WysiwygField,
} from "@thekeytechnology/framework-react-ux";
import {CustomerForSaving} from "../../model/customer-for-saving";
import {Customer, CUSTOMER_NEW_FACTORY, ENTITY_TYPE_CUSTOMER, User} from "../../model/customer";
import {CatalogDeliverySelectField} from "./CatalogDeliverySelectField";
import {ShippingMethodSelectField} from "./ShippingMethodSelectField";
import {useDispatch, useSelector} from "react-redux";
import {API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER, getCurrentCustomerNumberAction} from "../../actions/current-customer";
import {selectCurrentHighestCustomerNumber} from "../../selectors";
import {PaymentMethodSelectField} from "./PaymentMethodSelectField";
import * as Yup from "yup";
import {PaymentData} from "../../model/payment-data";
import {AuctionData} from "../../model/auction-data";
import {faCalendar, faPercent} from "@fortawesome/free-solid-svg-icons";
import {AddressField, EditFormHeading, PersonalData} from "@thekeytechnology/auktionshaus-frontend-library";
import {EmailCustomerButton} from "../email/EmailCustomerButton";
import {LogDisplay} from "../../../log/components/LogDisplay";
import {CustomerListSelectField} from "./CustomerListSelectField";
import {saveCustomerAction} from "../../actions/save-customer";
import {AttachmentFieldWithDownload} from "../../../core/files/attachment/AttachmentFieldWithDownload";
import {DeleteDuplicateCustomersButton} from "../delete-duplicates/DeleteDuplicateCustomersButton";
import {CustomerWarnings} from "../../../warnings/components/CustomerWarnings";
import {CreditCardField} from "./credit-card-field/CreditCardField";
import {useCustomEndpoint} from "../../../core/utils/use-custom-endpoint";
import {IntRequest} from "../../../core/model/custom-endpoints/IntRequest";
import {DownloadCustomerPrintSheetButton} from "../downloads/DownloadCustomerPrintSheetButton";


function CustomerEditFormComponent({entity, upsertEntity}: WithSingleEntityFromPathProps<Customer, CustomerForSaving>) {
    const dispatch = useDispatch();

    const {t} = useTranslation(["customer", "core"]);

    const customerNumberApiCallState = useSelector(selectCombinedApiState(API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER))
    const currentHighestCustomerNumber = useSelector(selectCurrentHighestCustomerNumber);

    const nextFreeCustomerNumberEndpoint = useCustomEndpoint<number>("/customers/get-next-free-customer-number")

    useEffect(() => {
        dispatch(getCurrentCustomerNumberAction());
    }, [dispatch])


    return entity && customerNumberApiCallState.succeeded ? (
        <>
            <EditFormHeading entity={entity} label={t("entity.singular")}/>

            <Formik
                initialValues={{
                    customerNumber: entity.entity.customerNumber ? entity.entity.customerNumber : (currentHighestCustomerNumber! + 1),
                    oldCustomerNumber: entity.entity.oldCustomerNumber,
                    invaNumber: entity.entity.invaNumber,
                    roomNumber: entity.entity.roomNumber,
                    firstName: entity.entity.personalData.firstName,
                    lastName: entity.entity.personalData.lastName,
                    salutation: entity.entity.personalData.salutation,
                    email: entity.entity.personalData.email,
                    birthday: entity.entity.personalData.birthday,
                    tel: entity.entity.personalData.tel,
                    additionalTels: entity.entity.personalData.additionalTels ? entity.entity.personalData.additionalTels : [],
                    fax: entity.entity.personalData.fax,
                    website: entity.entity.personalData.website,
                    adOptOut: entity.entity.adOptOut,
                    comment: entity.entity.comment,
                    attachments: entity.entity.attachments,

                    iban: entity.entity.paymentData.iban,
                    bic: entity.entity.paymentData.bic,
                    paymentMethod: entity.entity.paymentData.paymentMethod,
                    vatId: entity.entity.paymentData.vatId,
                    daysUntilReminderBill: entity.entity.paymentData.daysUntilReminderBill,
                    creditCard: entity.entity.paymentData.creditCard,

                    allowedList: entity.entity.allowedList,
                    catalogDelivery: entity.entity.auctionData.catalogDelivery,
                    deliveryMethod: entity.entity.auctionData.deliveryMethod,
                    repeatOrderer: entity.entity.auctionData.repeatOrderer,
                    invoiceCommission: entity.entity.auctionData.invoiceCommission,
                    porto: entity.entity.auctionData.porto,
                    sellerCommission: entity.entity.auctionData.sellerCommission,
                    priceReduction: entity.entity.auctionData.priceReduction,
                    auctionTransfersLimits: entity.entity.auctionData.auctionTransfersLimits,
                    shippingAddress: entity.entity.shippingAddress,
                    billingAddress: entity.entity.billingAddress ? entity.entity.billingAddress : entity.entity.shippingAddress,
                    user: entity.entity.user ? {id: entity.entity.user.id} : undefined,
                    warning: entity.entity.warning
                }}
                validationSchema={Yup.object().shape({
                    customerNumber: Yup.number().required(t("core:forms.required-field", {fieldName: t("entity.labels.customer-number")})),
                    firstName: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.personal-data.first-name")})),
                    lastName: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.personal-data.last-name")})),
                })}

                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper<CustomerForSaving>(entity.id,
                        {
                            customerNumber: values.customerNumber,
                            oldCustomerNumber: values.oldCustomerNumber ? values.oldCustomerNumber : 0,
                            invaNumber: values.invaNumber,
                            roomNumber: values.roomNumber,
                            personalData: new PersonalData(
                                values.firstName,
                                values.lastName,
                                values.salutation,
                                values.email,
                                values.birthday,
                                values.tel,
                                values.additionalTels,
                                values.fax,
                                values.website
                            ),
                            adOptOut: values.adOptOut,
                            comment: values.comment,
                            attachments: values.attachments.map(a => a.id as string),
                            paymentData: new PaymentData(
                                values.iban,
                                values.bic,
                                values.paymentMethod,
                                values.vatId,
                                values.daysUntilReminderBill,
                                entity.entity.paymentData.creditCardNumber,
                                values.creditCard,
                            ),
                            auctionData: new AuctionData(
                                values.catalogDelivery,
                                values.deliveryMethod,
                                values.repeatOrderer,
                                values.invoiceCommission,
                                values.porto,
                                values.sellerCommission,
                                values.priceReduction,
                                values.auctionTransfersLimits
                            ),
                            shippingAddress: values.shippingAddress,
                            billingAddress: values.billingAddress,
                            userRef: values.user?.id,
                            allowedList: values.allowedList,
                            warning: values.warning,
                            lexOfficeContactId: entity?.entity.lexOfficeContactId
                        }
                    );
                    dispatch(saveCustomerAction(saving));
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                `${entity.entity.personalData.lastName}, ${entity.entity.personalData.firstName}` :
                                t("core:edit-header.heading-empty")}
                        >

                            {entity?.entity.user ? <EmailCustomerButton customerId={entity.id!}/> : null}
                            {entity.id ? <DownloadCustomerPrintSheetButton customerId={entity.id}/> : null}
                            {entity.id && entity?.entity.customerNumber ? <DeleteDuplicateCustomersButton customerId={entity.id!}/> : null}
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/kunden/"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <CustomerWarnings customerId={entity.id}/>

                            <div className="form-group row">


                                <ValidatedField formikState={formikState}
                                                type="number"
                                                placeholder={t("entity.labels.customer-number")}
                                                name="customerNumber"
                                                className="form-control default-input"
                                                label={t("entity.labels.customer-number")}
                                                required
                                                onKeyDown={(event: React.KeyboardEvent) => {
                                                    if(event.keyCode === 9){
                                                        nextFreeCustomerNumberEndpoint.call(result => {
                                                            formikState.setFieldValue("customerNumber", result)
                                                        }, {value: formikState.values.customerNumber} as IntRequest)
                                                    }
                                                }}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="number"
                                                placeholder={t("entity.labels.old-customer-number")}
                                                name="oldCustomerNumber"
                                                className="form-control default-input"
                                                label={t("entity.labels.old-customer-number")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.inva-number")}
                                                name="invaNumber"
                                                className="form-control default-input"
                                                label={t("entity.labels.inva-number")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.room-number")}
                                                name="roomNumber"
                                                className="form-control default-input"
                                                label={t("entity.labels.room-number")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                placeholder={t("entity.labels.user")}
                                                name="user"
                                                shownEntityType={"doesnt-matter"}
                                                component={AsyncEntitySelectField}
                                                shownEntityTypeProperties={["entity.name", "entity.email"]}
                                                listRenderer={(user: EntityWrapper<User>) => {
                                                    return user?.entity ? `${user?.entity.name} - (${user.entity.email})` : ""
                                                }}
                                                isMulti={false}
                                                isClearable={true}

                                                fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/users/get-list")}
                                                className="form-control default-input"
                                                label={t("entity.labels.user")}
                                />
                            </div>
                            <hr className="mt-4 mb-3"/>
                            <h3>{t("customer-edit-form.headings.personal-data")}</h3>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.personal-data.salutation")}
                                                name="salutation"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.salutation")}
                                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.personal-data.first-name")}
                                                name="firstName"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.first-name")}
                                                required/>
                            </div>
                            <div className="form-group row ">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.personal-data.last-name")}
                                                name="lastName"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.last-name")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="email"
                                                placeholder={t("entity.labels.personal-data.email")}
                                                name="email"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.email")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={DateField}
                                                placeholder={t("entity.labels.personal-data.birthday")}
                                                name="birthday"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.birthday")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.personal-data.tel")}
                                                name="tel"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.tel")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.personal-data.fax")}
                                                min={8}
                                                name="fax"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.fax")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.personal-data.website")}
                                                name="website"
                                                className="form-control default-input"
                                                label={t("entity.labels.personal-data.website")}
                                />
                            </div>

                            <hr className="mt-5 mb-3"/>
                            <h3>{t("customer-edit-form.headings.payment-data")}</h3>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.payment-data.iban")}
                                                name="iban"
                                                className="form-control default-input"
                                                label={t("entity.labels.payment-data.iban")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.payment-data.bic")}
                                                name="bic"
                                                className="form-control default-input"
                                                label={t("entity.labels.payment-data.bic")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={PaymentMethodSelectField}
                                                placeholder={t("entity.labels.payment-data.payment-method")}
                                                name="paymentMethod"
                                                className="form-control default-input"
                                                label={t("entity.labels.payment-data.payment-method")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.payment-data.vat-id")}
                                                name="vatId"
                                                className="form-control default-input"
                                                label={t("entity.labels.payment-data.vat-id")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={CreditCardField}
                                                name="creditCard"
                                                className="form-control default-input"
                                                label={t("entity.labels.payment-data.credit-card-number")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                icon={faCalendar}
                                                type="number"
                                                placeholder={t("entity.labels.payment-data.days-until-reminder-bill")}
                                                name="daysUntilReminderBill"
                                                className="form-control default-input"
                                                label={t("entity.labels.payment-data.days-until-reminder-bill")}
                                />
                            </div>

                            <hr className="mt-5 mb-3"/>
                            <h3>{t("customer-edit-form.headings.shipping-address")}</h3>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AddressField}
                                                placeholder={t("registration-form.shipping-address-placeholder")}
                                                name="shippingAddress"
                                                className="form-control default-input"
                                                required
                                />
                            </div>

                            <hr className="mt-5 mb-3"/>
                            <h3>{t("customer-edit-form.headings.billing-address")}</h3>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AddressField}
                                                placeholder={t("registration-form.billing-address-placeholder")}
                                                name="billingAddress"
                                                className="form-control default-input"
                                                required
                                />
                            </div>

                            <hr className="mt-5 mb-3"/>
                            <h3>{t("customer-edit-form.headings.auction-data")}</h3>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={CustomerListSelectField}
                                                name="allowedList"
                                                className="form-control default-input"
                                                label={t("entity.labels.allowed-list")}

                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={CatalogDeliverySelectField}
                                                name="catalogDelivery"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.catalog-delivery")}

                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={ShippingMethodSelectField}
                                                name="deliveryMethod"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.shipping-method")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="checkbox"
                                                placeholder={t("entity.labels.auction-data.repeat-orderer")}
                                                name="repeatOrderer"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.repeat-orderer")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                icon={faPercent}
                                                type="number"
                                                step={0.01}
                                                min={0}
                                                max={100}
                                                placeholder={t("entity.labels.auction-data.invoice-commission")}
                                                name="invoiceCommission"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.invoice-commission")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="number"
                                                placeholder={t("entity.labels.auction-data.porto")}
                                                name="porto"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.porto")}
                                />
                            </div>

                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                icon={faPercent}
                                                type="number"
                                                step={0.01}
                                                min={0}
                                                max={100}
                                                placeholder={t("entity.labels.auction-data.seller-commission")}
                                                name="sellerCommission"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.seller-commission")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                icon={faPercent}
                                                type="number"
                                                step={0.01}
                                                min={0}
                                                max={100}
                                                placeholder={t("entity.labels.auction-data.price-reduction")}
                                                name="priceReduction"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.price-reduction")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="number"
                                                placeholder={t("entity.labels.auction-data.auction-transfers-limits")}
                                                min={0}
                                                name="auctionTransfersLimits"
                                                className="form-control default-input"
                                                label={t("entity.labels.auction-data.auction-transfers-limits")}

                                />
                            </div>

                            <hr className="mt-5 mb-3"/>
                            <h3>{t("customer-edit-form.headings.other-fields")}</h3>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="checkbox" placeholder={t("entity.labels.ad-opt-out")}
                                                name="adOptOut"
                                                className="form-control default-input"
                                                label={t("entity.labels.ad-opt-out")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={WysiwygField}
                                                name="comment"
                                                className="form-control default-input"
                                                label={t("entity.labels.comment")}

                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AttachmentFieldWithDownload}
                                                placeholder={t("entity.labels.attachments")}
                                                name="attachments"
                                                className="form-control default-input"
                                                label={t("entity.labels.attachments")}
                                />
                            </div>

                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                name="warning"
                                                className="form-control default-input"
                                                label={t("entity.labels.warning")}

                                />
                            </div>

                            {entity.id ? <LogDisplay entityId={entity.id}/> : null}
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
}

export const CustomerEditForm = WithSingleEntityFromPath<{}, Customer, CustomerForSaving>(
    CustomerEditFormComponent,
    ENTITY_TYPE_CUSTOMER,
    "customerId",
    CUSTOMER_NEW_FACTORY
);
