import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_SEND_BULK_SELLER_LIST_EMAIL = "send-bulk-seller-list-email";

export interface SendBulkSellerListPayload extends Action {
    customerIds: string[],
    auctionId: string;
}

export const sendBulkSellerListEmailAction = (customerIds: string[], auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SEND_BULK_SELLER_LIST_EMAIL,
            callType: API_SEND_BULK_SELLER_LIST_EMAIL
        },
        payload: {
            customerIds,
            auctionId
        }
    } as ApiCallAction<SendBulkSellerListPayload>;
};
