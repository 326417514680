import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Settlement, SETTLEMENT_STATUS_OPEN} from "../model/settlement";
import {API_FINALIZE_CREDIT_NOTE, finalizeCreditNoteAction} from "../actions/finalize-credit-note";

interface OwnProps {
    settlement: EntityWrapper<Settlement>
    disabled?: boolean;
}

export const GenerateCreditNoteButton = ({settlement, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_FINALIZE_CREDIT_NOTE))

    return settlement.entity.status === SETTLEMENT_STATUS_OPEN ?
        <button disabled={apiCallState.inProgress || disabled}
                onClick={() => dispatch(finalizeCreditNoteAction(settlement.id!))}
                className="btn btn-info mr-5" type="button">
            Abrechnung erstellen {disabled ? "(bitte vorher speichern" : null}
        </button> : null;

}
