import React from "react";

import {useDispatch} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {deleteDuplicateItemsAction} from "../../actions/delete-duplicate-items";


interface OwnProps {
    itemId: string
    handleClose: () => void;
}

export const DeleteDuplicateItemsModal = ({itemId, handleClose}: OwnProps) => {
    const dispatch = useDispatch();

    return (<>
            <Modal.Header closeButton>
                <Modal.Title>Doppelte Objekte löschen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Hierbei werden alle Objekte mit der gleichen Bestandsnummer wie dieses Objekt gelöscht.
                    Alle Lose, die vorher einem Duplikat zugeordnet waren, werden dabei diesem Objekt zugeordnet.
                </p>
                <p>
                    <strong>Achtung: Dieser Vorgang ist nicht umkehbar!</strong>
                </p>

                <Button type={"button"} onClick={() => {
                    dispatch(deleteDuplicateItemsAction(itemId))
                    handleClose()
                }}>
                    Endgültig löschen
                </Button>
            </Modal.Body>
        </>
    );
}
