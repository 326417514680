import {ApiCallAction, apiGet} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {API_FETCH_WARNING} from "../actions/fetch-warning";

const fetchWarning$ = apiGet(
    {apiType: API_FETCH_WARNING},
    (action: ApiCallAction<string>) => `/customers/${action.payload}/warnings`
);
export const warningModuleEpics$ = combineEpics(
    fetchWarning$,
);
