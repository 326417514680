import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    ContentContainer,
    HeadingContainer,
    Pagination,
    PaginationContainer,
    SimpleHeader
} from "@thekeytechnology/framework-react-ux";
import {ENTITY_TYPE_LOG_ENTRY} from "../model/log-entry";
import {LogDisplay} from "./LogDisplay";

const LogOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/logs"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <ContentContainer>
                <LogDisplay/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_LOG_ENTRY}/>
            </PaginationContainer>
        </>
    );
};

export const LogOverview = withTranslation("log")(LogOverviewComponent);
