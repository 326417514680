import {
    ApiCallState,
    EntityWrapper,
    selectApiBase,
    selectAuthState,
    selectCombinedApiState,
    withAuthedAxios,
} from "@thekeytechnology/framework-react";
import {withTranslation, WithTranslation} from "react-i18next";
import {FieldInputProps, Form, Formik, FormikProps} from "formik";
import React, {useEffect} from "react";
import {
    ContentContainer,
    EditHeaderWithBackground,
    LoadingRow,
    SaveAndBackButtons,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps,
    WysiwygField
} from "@thekeytechnology/framework-react-ux";
import * as Yup from "yup";
import {
    ENTITY_NEW_ORDER_FACTORY,
    ENTITY_TYPE_ORDER,
    Order,
    ORDER_CONTEXT_AGGREGATED,
    ORDER_STATUS_OPEN
} from "../model/Order";
import {API_GET_CURRENT_ORDER_NUMBER, getCurrentOrderNumber} from "../actions/get-current-order-number";
import {faBox} from "@fortawesome/free-solid-svg-icons";
import {ENTITY_TYPE_ORDER_FOR_SAVING, OrderForSaving} from "../model/OrderForSaving";
import {PositionsField} from "./positions-field/PositionsField";
import {GenerateInvoiceButton} from "./GenerateInvoiceButton";
import {ReceiptList} from "./receipt-list/ReceiptList";
import {OrderHistoryList} from "./order-history/OrderHistoryList";
import {CancelOrderFees} from "./cancel/CancelOrderFees";
import {CancelOrderNoFees} from "./cancel/CancelOrderNoFees";
import {PartialCancellationButton} from "./cancel/partially/PartialCancellationButton";
import {
    Address,
    AddressField,
    CheckboxField,
    CurrencyDisplay,
    CustomerJustIdAndName,
    EditFormHeading
} from "@thekeytechnology/auktionshaus-frontend-library";
import {selectNextOrderNumber} from "../selectors";
import "./order-edit-form.scss"
import {CustomerWarnings} from "../../warnings/components/CustomerWarnings";
import {connect, useDispatch, useSelector} from "react-redux";
import {ReopenOrderButton} from "./ReopenOrderButton";
import {PageTitle} from "../../core/components/PageTitle";
import {CustomerSelectField} from "../../items/components/edit/CustomerSelectField";
import {saveOrderAction} from "../actions/save-order";
import {CustomerAddress} from "../../customers/model/customer-address";
import {DropdownButton, NavLink} from "react-bootstrap";
import {GenerateFinancingCreditNoteButton} from "./GenerateFinancingCreditNoteButton";
import {LogDisplay} from "../../log/components/LogDisplay";
import {ShipOrderButton} from "./shipping/ShipOrderButton";
import {CommissionOrderButton} from "./shipping/CommissionOrderButton";
import {ShippingStatusSelectField} from "./shipping/ShippingStatusSelectField";
import {ShippingMethodSelectField} from "../../customers/components/edit/ShippingMethodSelectField";
import {DownloadOrderPreviewButton} from "./DownloadOrderPreviewButton";

interface StateProps {
    orderNumberApiCallState: ApiCallState;
    nextOrderNumber?: number;
}

interface DispatchProps {
    getCurrentAuctionNumber: typeof getCurrentOrderNumber
}

type Props = StateProps & DispatchProps & WithSingleEntityFromPathProps<Order, OrderForSaving> & WithTranslation;

const OrderEditFormComponent = ({
                                    entity,
                                    t,
                                    nextOrderNumber,
                                    orderNumberApiCallState,
                                    getCurrentAuctionNumber
                                }: Props) => {
    const editingDisabled = entity?.entity.status !== ORDER_STATUS_OPEN;
    const dispatch = useDispatch();

    useEffect(() => {
        getCurrentAuctionNumber();
    }, [getCurrentAuctionNumber]);

    const authState = useSelector(selectAuthState)
    const apiBase = useSelector(selectApiBase)
    const authAxios = withAuthedAxios(apiBase, authState)

    console.log(entity)


    return entity && nextOrderNumber !== undefined && orderNumberApiCallState.succeeded ? (
        <>
            <PageTitle title={"Bes. " + entity.entity.orderNumber}/>
            <EditFormHeading entity={entity} label={t("entity.singular")}/>

            <Formik
                initialValues={{
                    orderNumber: entity.entity.orderNumber ? entity.entity.orderNumber : nextOrderNumber,
                    customer: entity.entity.customer ? entity.entity.customer : undefined,
                    positions: entity.entity.positions,
                    shippingAddress: entity.entity.shippingAddress,
                    billingAddress: entity.entity.billingAddress,
                    trackingNumber: entity.entity.trackingNumber,
                    status: entity.entity.status,
                    comment: entity.entity.comment,
                    pickingNumber: entity.entity.pickingNumber,
                    overwriteUserData: false,
                    shippingStatus: entity.entity.shippingStatus,
                    shippingMethod: entity.entity.shippingMethod
                }}
                validateOnMount={true}
                validationSchema={Yup.object().shape({
                    orderNumber: Yup.number().required(t("core:forms.required-field", {fieldName: t("entity.labels.order-number")})),
                    customer: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.customer")})),
                    billingAddress: Yup
                        .object()
                        .test("test", "test", function (value) {
                            const address = value as Address
                            if (!address.street || !address.houseNumber || !address.country || !address.name || !address.postalCode) {
                                return this.createError({
                                    path: "billingAddress",
                                    message: "Alle Felder einer Adresse müssen ausgefüllt sein."
                                });
                            }
                            return true;
                        }),
                })}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                    const saving = new EntityWrapper<OrderForSaving>(entity.id, {
                        orderNumber: values.orderNumber,
                        customerRef: values.customer!.id!,
                        positions: values.positions,
                        billingAddress: values.billingAddress,
                        shippingAddress: values.shippingAddress,
                        trackingNumber: values.trackingNumber,
                        status: values.status,
                        comment: values.comment,
                        attachedReceipts: entity.entity.attachedReceipts,
                        orderHistory: entity.entity.orderHistory,
                        pickingNumber: values.pickingNumber,
                        createdAt: entity.entity.createdAt,
                        overwriteUserData: values.overwriteUserData,
                        auctionRef: entity.entity.auctionRef,
                        shippingStatus: values.shippingStatus,
                        shippingMethod: values.shippingMethod
                    });
                    dispatch(saveOrderAction(saving));
                    setSubmitting(false);
                    resetForm();
                }}
            >
                {formikState => {

                    return (
                        <Form className="order-edit-form">
                            <EditHeaderWithBackground
                                heading={entity.id ?
                                    <div className={"mb-5"}>
                                        {entity.entity.orderNumber} | {entity?.entity.customer?.entity.name} (<NavLink
                                        href={"/kunden/" + entity?.entity.customer?.id + "/edit"} target={"_blank"}
                                        className={"p-0 d-inline"}>{entity?.entity.customer?.entity.customerNumber}</NavLink>)
                                        | <CurrencyDisplay
                                        amount={entity?.entity.sum}/> | {t("status." + entity?.entity.status)}
                                    </div> :
                                    t("core:edit-header.heading-empty")}
                            >
                                {editingDisabled ?
                                    <>
                                        <DropdownButton title={"Bearbeiten..."} className={"mr-2"}>
                                            <PartialCancellationButton order={entity}/>
                                            <CancelOrderFees order={entity}/>
                                            <CancelOrderNoFees order={entity}/>
                                            <ReopenOrderButton order={entity}/>
                                        </DropdownButton>
                                        <GenerateFinancingCreditNoteButton order={entity}/>
                                    </>
                                    : <>
                                        <GenerateInvoiceButton disabled={!formikState.isValid || formikState.dirty}
                                                               order={entity} isDirty={formikState.dirty}/>
                                    </>
                                }

                                {entity?.entity.positions.length ? <>
                                        <DownloadOrderPreviewButton order={entity}/>
                                    </>
                                    : null}

                                <CommissionOrderButton order={entity}/>
                                <ShipOrderButton order={entity}/>

                                <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                    backPath="/bestellungen/"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                <CustomerWarnings customerId={formikState.values.customer?.id}/>

                                <OrderHistoryList orderHistory={entity?.entity.orderHistory}/>
                                <ReceiptList
                                    containerId={entity?.id!}
                                    containerType={ENTITY_TYPE_ORDER}
                                    containerStatus={entity?.entity.status}
                                    attachedReceipts={entity?.entity.attachedReceipts}/>

                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={CheckboxField}
                                                    disabled={editingDisabled}
                                                    name="overwriteUserData"
                                                    label={"Benutzerdaten überschreiben"}
                                                    className="form-control default-input"
                                    />
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Status</label>
                                    <div className="col-10 d-flex align-items-center">
                                        {t("status." + entity?.entity.status)}
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={ShippingStatusSelectField}
                                                    disabled={editingDisabled}
                                                    placeholder={t("entity.labels.shipping-status")}
                                                    name="shippingStatus"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.shipping-status")}
                                    />
                                </div>

                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="number"
                                                    disabled={editingDisabled}
                                                    placeholder={t("entity.labels.order-number")}
                                                    name="orderNumber"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.order-number")}
                                                    required/>
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={CustomerSelectField}
                                                    name="customer"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.customer")}
                                                    required
                                                    disabled={editingDisabled}
                                                    isDisabled={editingDisabled}
                                                    onSelect={(selected: EntityWrapper<CustomerJustIdAndName> | EntityWrapper<CustomerJustIdAndName>[] | undefined,
                                                               form: FormikProps<any>,
                                                               field: FieldInputProps<any>) => {
                                                        const singleSelected = Array.isArray(selected) ? selected[0] : selected
                                                        if (singleSelected?.id) {
                                                            authAxios.get<CustomerAddress>("/customers/" + singleSelected?.id + "/get-address")
                                                                .then(result => {
                                                                    formikState.setFieldValue("billingAddress", result.data.billingAddress)
                                                                    formikState.setFieldValue("shippingAddress", result.data.shippingAddress)
                                                                })
                                                        }
                                                    }}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={ShippingMethodSelectField}
                                                    disabled={editingDisabled}
                                                    placeholder={t("entity.labels.delivey-method")}
                                                    name="shippingMethod"
                                                    className="form-control default-input"
                                                    label={t("entity.auction-data.shipping-method")}
                                    />
                                </div>
                                <hr/>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    disabled={editingDisabled}
                                                    component={PositionsField}
                                                    name="positions"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.positions")}
                                    />
                                </div>
                                <hr/>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    disabled={editingDisabled}
                                                    component={AddressField}
                                                    placeholder={t("entity.labels.billing-address")}
                                                    name="billingAddress"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.billing-address")}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={AddressField}
                                                    placeholder={t("entity.labels.shipping-address")}
                                                    name="shippingAddress"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.shipping-address")}
                                                    optional={true}
                                                    optionalText={t("entity.labels.shipping-address-differs")}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="text"
                                                    icon={faBox}
                                                    placeholder={t("entity.labels.tracking-number")}
                                                    name="trackingNumber"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.tracking-number")}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    type="text"
                                                    icon={faBox}
                                                    placeholder={t("entity.labels.picking-number")}
                                                    name="pickingNumber"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.picking-number")}
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField formikState={formikState}
                                                    component={WysiwygField}
                                                    placeholder={t("entity.labels.comment")}
                                                    name="comment"
                                                    className="form-control default-input"
                                                    label={t("entity.labels.comment")}
                                    />
                                </div>
                            </ContentContainer>
                        </Form>
                    );
                }}
            </Formik>
            {entity.id ? <LogDisplay entityId={entity.id}/> : null}
        </>
    ) : <LoadingRow/>;
};

export const OrderEditForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            orderNumberApiCallState: selectCombinedApiState(API_GET_CURRENT_ORDER_NUMBER)(state),
            nextOrderNumber: selectNextOrderNumber(state)
        };
    },
    {
        getCurrentAuctionNumber: getCurrentOrderNumber,
    }
)(WithSingleEntityFromPath<StateProps & DispatchProps, Order, OrderForSaving>(
    withTranslation(["order", "core"])(OrderEditFormComponent),
    ENTITY_TYPE_ORDER,
    "orderId",
    ENTITY_NEW_ORDER_FACTORY,
    ORDER_CONTEXT_AGGREGATED,
    ENTITY_TYPE_ORDER_FOR_SAVING
) as any);
