import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {useTranslation} from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer,
    SimpleHeader,
    UnconnectedPagination
} from "@thekeytechnology/framework-react-ux";
import {usePaginatedEndpointFilters} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {usePaginatedEndpoint} from "../../../paginated-endpoints/hooks/use-paginated-endpoint";
import {TableOrder} from "../../model/TableOrder";
import {PageTitle} from "../../../core/components/PageTitle";
import {DunningsFilters} from "./DunningsFilters";
import {DunningsTable} from "./DunningsTable";
import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {ORDER_STATUS_INVOICED, RECEIPT_STATUS_OPEN} from "../../model/Order";

export const DunningsView = () => {

    const {t} = useTranslation(["order"])

    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "dunnings-table"
    )

    const {
        resolved,
        setFilters,
        apiCallState,
        setPaginationQuery,
        setSortQuery,
        sortQuery
    } = usePaginatedEndpoint<TableOrder>(
        "/orders/get-table-orders",
        undefined,
        [
            new PropertyFilter(
                "entity.status",
                new FilterTerm(
                    FilterTerm.TYPE_STRING,
                    FilterTerm.OPERATION_EQ,
                    ORDER_STATUS_INVOICED
                )
            ),
            new PropertyFilter(
                "entity.mainInvoice.status",
                new FilterTerm(
                    FilterTerm.TYPE_STRING,
                    FilterTerm.OPERATION_EQ,
                    RECEIPT_STATUS_OPEN
                )
            )
        ],
        endpointFilters.getFilters().flatMap(f => f.filters)
    )


    return (
        <>
            <PageTitle title={"Mahnungen"}/>
            <BreadcrumbsItem to={"/bestellungen"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Mahnungen")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <DunningsFilters endpointFilters={endpointFilters}/>
            </BackgroundContainer>
            <ContentContainer>
                <DunningsTable
                    orders={resolved.entities}
                    apiCallState={apiCallState}
                    pagination={resolved.pagination}
                    setSortQuery={setSortQuery}
                    sortQuery={sortQuery}
                />
            </ContentContainer>
            <PaginationContainer>
                <UnconnectedPagination pagination={resolved.pagination} setPage={page => {
                    setPaginationQuery({
                        page,
                        docsPerPage: resolved.pagination.docsPerPage
                    })
                }}/>
            </PaginationContainer>
        </>
    );
};
