import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_START_LIVE_AUCTION = "start-live-auction";

export interface StartLiveAuctionPayload extends Action {
    auctionId: string;
}

export const startLiveAuctionAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_START_LIVE_AUCTION,
            callType: API_START_LIVE_AUCTION
        },
        payload: {
            auctionId: auctionId
        }
    } as ApiCallAction<StartLiveAuctionPayload>;
};
