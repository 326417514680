import React, {createRef, RefObject, useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {
    addFiltersAction,
    FilterTerm,
    PropertyFilter,
    reloadEntityListAction,
    setContextAction,
    setPaginationQueryAction,
    WithEntityList,
    WithEntityListProps,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import {
    selectAuctioneerAuction,
    selectAuctioneerLiveLot,
    selectAuctioneerLiveLotID,
    selectAuctioneerLot,
    selectAuctioneerLotStatus
} from "../../../../selectors";
import {useTranslation} from "react-i18next";
import {AuctioneerLotShort} from "../../../../../lots/model/AuctioneerLotShort";
import {AuctioneerLotFilter} from "./AuctioneerLotFilter";
import {LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";


interface DispatchProps {
    fetchEntities: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
    setContext: ReturnType<typeof setContextAction>
    addFilters: ReturnType<typeof addFiltersAction>
}

type Props = DispatchProps & WithEntityListProps<AuctioneerLotShort> & WithSelectionsProps<string>;

const AuctioneerLotTableComponent = ({
                                         entities,
                                         setPaginationQuery,
                                         setContext,
                                         fetchEntities,
                                         addFilters
                                     }: Props) => {

    const liveLot = useSelector(selectAuctioneerLiveLot)
    const currentLot = useSelector(selectAuctioneerLot)

    const lots = entities.sort((a, b) => {
        return a.entity.lotNumber && b.entity.lotNumber && a.entity.lotNumber > b.entity.lotNumber ? 1 : -1;
    });

    const {t} = useTranslation(["lot", "bid"]);

    const containerRef = createRef<HTMLDivElement>();

    const liveAuctionLotId = useSelector(selectAuctioneerLiveLotID);
    const lotStatus = useSelector(selectAuctioneerLotStatus);
    const liveAuction = useSelector(selectAuctioneerAuction)

    useEffect(() => {
        setPaginationQuery({docsPerPage: 10, page: 0});
        setContext(AuctioneerLotShort.CONTEXT)
        addFilters("entity.auctionRef",
            [
                new PropertyFilter("entity.auctionRef", new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, liveAuction?.entity.auctionRef))
            ])
        fetchEntities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [liveAuctionLotId, lotStatus])

    const refs = entities.reduce<Record<string, RefObject<HTMLDivElement>>>((acc, entity) => {
        const id = entity.id ? entity.id : "0"
        acc[id] = createRef();
        return acc;
    }, {});

    return <div className={"auctioneer-view-lots-table"}>
        <AuctioneerLotFilter/>
        <div className={"auctioneer-table"} ref={containerRef}>
            <div className={"auctioneer-table-row header-row"}>
                <div className={"lot-number"}>
                    {t("entity.singular")}
                </div>
                <div className={"lot-title"}>
                    {t("entity.labels.name")}
                </div>
                <div className={"lot-status"}>
                    {t("entity.labels.status")}
                </div>
                <div className={"lot-bid-count"}>
                    {t("bid:entity.plural")}
                </div>
            </div>
            {lots.map(
                lot => {
                    const liveLotClass = liveLot?.id === lot.id ? " live-lot " : "";
                    const currentLotClass = currentLot?.id === lot.id ? " current-lot " : "";
                    const url = liveLot?.id === lot.id ? "/auktionator/" : "/auktionator/" + lot.id;
                    return (lot.id &&
                        <div key={lot.id} className={"auctioneer-table-row" + currentLotClass + liveLotClass}
                             ref={refs[lot.id]}>
                            <div className={"lot-number"}>
                                <a href={url}>{lot.entity.lotNumber?.toString().padStart(4, "0")}</a>
                            </div>
                            <div className={"lot-title"}>
                                <LotTitleDisplay description={lot.entity.description.de}/>
                            </div>
                            <div className={"lot-status"}>
                                {t("status." + lot.entity.status)}
                            </div>
                            <div className={"lot-bid-count"}>
                                {lot.entity.bidCount}
                            </div>
                        </div>
                    )
                }
            )}
        </div>
        {/*<Pagination entityType={AuctioneerLotShort.TYPE}/>*/}
    </div>
};

export const AuctioneerLotTable = connect<{}, DispatchProps, {}>(
    null,
    {
        fetchEntities: reloadEntityListAction(AuctioneerLotShort.TYPE),
        setPaginationQuery: setPaginationQueryAction(AuctioneerLotShort.TYPE),
        setContext: setContextAction(AuctioneerLotShort.TYPE),
        addFilters: addFiltersAction(AuctioneerLotShort.TYPE)
    }
)(
    WithEntityList<WithSelectionsProps<string>, AuctioneerLotShort>(
        AuctioneerLotTableComponent as any,
        AuctioneerLotShort.TYPE,
        AuctioneerLotShort.CONTEXT
    )
);
