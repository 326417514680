import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {ItemCategory} from "../model/itemCategory";

export const API_SAVE_ITEM_CATEGORY = "save-item-category";



export const saveItemCategoryAction = (itemCategory: EntityWrapper<ItemCategory>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_ITEM_CATEGORY,
            callType: API_SAVE_ITEM_CATEGORY
        },
        payload: itemCategory
    } as ApiCallAction<EntityWrapper<ItemCategory>>;
};
