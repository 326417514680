import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {LoggedInRoutes} from "./routes/LoggedInRoutes";
import {Sidebar} from "./sidebar/components/Sidebar";

import {DialogPortal} from "@thekeytechnology/framework-react-ux";
import {NotLoggedInRoutes} from "./routes/NotLoggedInRoutes";

export const App = () => <>
    <BreadcrumbsItem to="/"><FontAwesomeIcon icon={faHome}/></BreadcrumbsItem>
    <div className={"h-100 d-flex"}>
        <Sidebar/>
        <main role="main" className="w-100">
            <div className={"content"}>
                <LoggedInRoutes/>
                <NotLoggedInRoutes/>
            </div>
        </main>
        <DialogPortal/>
    </div>
</>;
