import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {SelectionModel} from "@thekeytechnology/framework-react";
import {connect} from "react-redux";
import {ungroupLotAction} from "../../actions/group/ungroup-lots";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectUngroup} from "@fortawesome/free-solid-svg-icons";

interface OwnProps {
    lotId: string;
    selectionModel: SelectionModel<string>;
}

interface DispatchProps {
    ungroupLot: typeof ungroupLotAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const UngroupEntitiesButtonComponent = (props: Props) => {
    const {t, lotId, selectionModel, ungroupLot} = props;

    return <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip id={`tooltip-remove`}>
                {t("ungroup-lots-button.submit")}
            </Tooltip>
        }
    >
        <button className="btn link mr-2"
                onClick={() => {
                    ungroupLot(lotId);
                    selectionModel.handleClearAll();
                }}>
            <FontAwesomeIcon icon={faObjectUngroup}/>
        </button>
    </OverlayTrigger>
};

export const UngroupLotsButton = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        ungroupLot: ungroupLotAction
    }
)(withTranslation("auctionPlanning")(UngroupEntitiesButtonComponent));
