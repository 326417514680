import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctionAppSettings} from "../model/AuctionAppSettings";

export const API_SAVE_AUCTION_APP_SETTINGS = "save-auction-app-settings";



export const saveAuctionAppSettings = (settings: EntityWrapper<AuctionAppSettings>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_AUCTION_APP_SETTINGS,
            callType: API_SAVE_AUCTION_APP_SETTINGS
        },
        payload: settings
    } as ApiCallAction<EntityWrapper<AuctionAppSettings>>;
};