import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DELETE_DUPLICATE_ITEMS = "delete-duplicate-items";

export interface DeleteDuplicateItemsPayload {
    itemId: string
}

export const deleteDuplicateItemsAction = (itemId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DELETE_DUPLICATE_ITEMS,
            callType: API_DELETE_DUPLICATE_ITEMS
        },
        payload: {
            itemId
        }
    } as ApiCallAction<DeleteDuplicateItemsPayload>;
};
