import {
    ActionTableColumn,
    AsyncEntityTableBody,
    Table,
    TableColumn,
    TableHeader,
    TableRow
} from "@thekeytechnology/framework-react-ux";
import {ItemCategoryDisplay} from "../../../item-category/display/ItemCategoryDisplay";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {
    ApiCallState,
    EntityWrapper,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import {Item} from "../../../items/model/item";
import {LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    items: Array<EntityWrapper<Item>>
    selectedItemIds: Set<string>
    apiState: ApiCallState

    handleAdd: (item: EntityWrapper<Item>) => void;
    handleRemove: (item: EntityWrapper<Item>) => void;
}

type Props = OwnProps & WithTranslation & WithSwitchableLanguageProps;

const ItemForSelectionTableComponent = (props: Props) => {
    const {items, t, selectedItemIds, apiState, handleAdd, handleRemove, currentLanguage} = props;

    return <Table itemCount={items.length} className="manually-assign-items-table">
        <TableHeader>
            <TableColumn>{t("item:entity.labels.inventory-number")}</TableColumn>
            <TableColumn>{t("item:entity.labels.short-description")}</TableColumn>
            <TableColumn>{t("item:entity.labels.planned-auction")}</TableColumn>
            <TableColumn>{t("item:entity.labels.category")}</TableColumn>
            <TableColumn>{t("item:entity.labels.status")}</TableColumn>
            <TableColumn>{t("item:entity.labels.actions")}</TableColumn>
        </TableHeader>
        <AsyncEntityTableBody
            apiCallState={apiState}
            items={items}
            renderItem={item => {
                return <TableRow key={item.id}>
                    <TableColumn className="bold title-column">
                        {item.entity.inventoryNumber}
                    </TableColumn>
                    <TableColumn>
                        <LotTitleDisplay description={item.entity.description[currentLanguage]}/>
                    </TableColumn>
                    <TableColumn>
                        {item.entity.plannedAuction?.entity.auctionNumber}
                    </TableColumn>
                    <TableColumn>
                        <ItemCategoryDisplay
                            displayItem={item.entity.category}/>
                    </TableColumn>
                    <TableColumn>
                        {t(`item:status-options.${item.entity.status}`)}
                    </TableColumn>
                    <ActionTableColumn isDisabled={false}>
                        {selectedItemIds.has(item.id as string) ?
                            <button className="btn btn-danger" type="button" onClick={() => {
                                handleRemove(item)
                            }}><FontAwesomeIcon icon={faMinus}/></button>
                            : <button className="btn btn-success" type="button" onClick={() => {
                                handleAdd(item)
                            }}><FontAwesomeIcon icon={faPlus}/></button>}
                    </ActionTableColumn>
                </TableRow>
            }}/>
    </Table>
};

export const ItemForSelectionTable =
    WithSwitchableLanguage<any>(withTranslation("auctionPlanning")(ItemForSelectionTableComponent));
