import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSection} from "@fortawesome/free-solid-svg-icons";
import {API_DOWNLOAD_P86_CSV, downloadP86CsvAction} from "../../actions/download-csvs/download-p86-csv";
import {Dropdown} from "react-bootstrap";

export const DownloadP86CsvButton = () => {

    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_P86_CSV))

    // const {t} = useTranslation("auctionPlanning");

    return auctionId ? <Dropdown.Item disabled={apiCallState.inProgress} onClick={() => {
        dispatch(downloadP86CsvAction(auctionId));

    }}>
        <FontAwesomeIcon className="mr-2"
                         icon={faSection}/>
        §86 Export
    </Dropdown.Item> : null;
}
