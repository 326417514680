import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox} from "@fortawesome/free-solid-svg-icons/faBox";
import {useDispatch, useSelector} from "react-redux";
import {selectCombinedApiState, selectSingleEntity} from "@thekeytechnology/framework-react";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {API_GENERATE_INVOICES, generateInvoicesAction} from "../../actions/generate-invoices";
import {useTranslation} from "react-i18next";

export const GenerateInvoicesButton = () => {
    const dispatch = useDispatch();
    const generateInvoicesApiCallState = useSelector(selectCombinedApiState(API_GENERATE_INVOICES))
    const {t} = useTranslation("auctionPlanning");
    const selectedAuction = useSelector(selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION))

    return selectedAuction ? <button type="button" disabled={generateInvoicesApiCallState.inProgress} onClick={() => {
        dispatch(generateInvoicesAction(selectedAuction.id as string));

    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon className="mr-2"
                         icon={faBox}/>{t("auction-planning.actions.generate-invoices")}
    </button> : null;
};
