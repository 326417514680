import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID = "assign-buyers-based-on-manual-id";

export interface AssignBuyersBasedOnManuelIdPayload extends Action {
    auctionId: string
}

export const assignBuyersBasedOnManuelIdAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID,
            callType: API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<AssignBuyersBasedOnManuelIdPayload>;
};
