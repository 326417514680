import {
    API_DELETE_ENTITIES,
    ApiCallAction,
    ApiCallSucceededAction,
    apiGet,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadOnEntityApiOperation,
    reloadSingleEntityAction
} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import "@thekeytechnology/framework-react/"
import {API_GET_CURRENT_SETTLEMENT_NUMBER} from "../actions/get-current-settlement-number";
import {ENTITY_TYPE_SETTLEMENT} from "../model/settlement";
import {API_FINALIZE_CREDIT_NOTE} from "../actions/finalize-credit-note";
import {Observable} from "rxjs";
import {Action} from "redux";
import {filter, map} from "rxjs/operators";
import {SendReceiptPayload} from "../../orders/actions/send-invoice";
import {API_FETCH_CREDIT_NOTE} from "../actions/fetch-credit-note";
import {API_SEND_CREDIT_NOTE} from "../actions/send-credit-note";
import {API_REFRESH_CREDIT_NOTE} from "../actions/refresh-credit-note";
import {API_CANCEL_SETTLEMENT} from "../actions/cancel-order";
import {API_SAVE_SETTLEMENT} from "../actions/save-settlement";
import {ReceiptData} from "../../orders/model/receipt-data";
import {DownloadCsvData} from "../../core/model/DownloadCsvData";
import {
    API_DOWNLOAD_SETTLEMENTS_FOR_DATEV_CSV,
    DownloadSettlementsForDatevCsvPayload
} from "../actions/download-settlements-for-datev-csv";
import {API_CREATE_CUSTOM_RECEIPT} from "../../orders/actions/custom-receipt";
import {triggerCsvResponseDownload} from "../../core/utils/trigger-csv-response-download";
import {API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF} from "../actions/download-settlement-preview-pdf";
import {DownloadPdfData} from "../../core/model/DownloadPdfData";
import {triggerPdfResponseDownload} from "../../core/utils/trigger-pdf-response-download";
import {API_GENERATE_FOLLOW_UP_CREDIT_NOTE} from "../actions/generate-follow-up-credit-note";
import {API_DOWNLOAD_BACK_TO_SELLER_LIST} from "../actions/download-back-to-seller-list";

// const redirectOnSettlementSave$ = redirectAfterEntityApiOperation(
//     [API_CREATE_ENTITIES], ENTITY_TYPE_SETTLEMENT_FOR_SAVING, "/abrechnungen");
//
// export const settlementSavedSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
//     matchesAnyOfTheseApiSuccesses({
//         apiType: API_UPDATE_ENTITIES,
//         callType: ENTITY_TYPE_SETTLEMENT_FOR_SAVING
//     }),
//     map(() => postMessageAction(Message.TYPE_SUCCESS, "Abrechnung wurde gespeichert."))
// );

const reloadOnRedirectDelete$ = reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_SETTLEMENT);
const reloadOrderOnCertainActions$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_FINALIZE_CREDIT_NOTE},
        {apiType: API_GENERATE_FOLLOW_UP_CREDIT_NOTE},
        {apiType: API_CANCEL_SETTLEMENT},
        {apiType: API_SEND_CREDIT_NOTE},
        {apiType: API_REFRESH_CREDIT_NOTE},
        {apiType: API_CREATE_CUSTOM_RECEIPT},
        {apiType: API_SAVE_SETTLEMENT}
    ),
    map(() => {
        return reloadSingleEntityAction(ENTITY_TYPE_SETTLEMENT)()
    })
);


const getCurrentSettlementNumber$ = apiGet(
    {apiType: API_GET_CURRENT_SETTLEMENT_NUMBER},
    "/settlements/current-number"
);

const finalizeCreditNote$ = apiGet(
    {apiType: API_FINALIZE_CREDIT_NOTE},
    (action: ApiCallAction<string>) => `/settlements/${action.payload}/finalize-credit-note`
);

const generateFollowUpCreditNote$ = apiGet(
    {apiType: API_GENERATE_FOLLOW_UP_CREDIT_NOTE},
    (action: ApiCallAction<string>) => `/settlements/${action.payload}/generate-follow-up-credit-note`
);


const fetchReceipt$ = apiGet(
    {apiType: API_FETCH_CREDIT_NOTE},
    (action: ApiCallAction<string>) => `/settlements/download-receipt/${action.payload}`
);

const fetchReceiptSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_FETCH_CREDIT_NOTE),
    map((action: ApiCallSucceededAction<ReceiptData>) => {
        const link = document.createElement("a");
        const file = new Blob([new Uint8Array(action.payload.content)], {type: 'text/pdf'})
        link.href = URL.createObjectURL(file);
        link.download = action.payload.number + ".pdf";
        link.click();
        return {
            type: "invoice-download"
        }
    })
);

const sendReceiptEmail$ = apiGet(
    {apiType: API_SEND_CREDIT_NOTE},
    (action: ApiCallAction<SendReceiptPayload>) => `/settlements/${action.payload.orderId}/send-receipt/${action.payload.receiptId}/`
);

const refreshReceipt$ = apiGet(
    {apiType: API_REFRESH_CREDIT_NOTE},
    (action: ApiCallAction<SendReceiptPayload>) => `/settlements/${action.payload.orderId}/refresh-receipt/${action.payload.receiptId}/`
);

const cancelSettlement$ = apiPost(
    {apiType: API_CANCEL_SETTLEMENT},
    "/settlements/cancel"
);

const downloadSettlementsForDatevCsv$ = apiPost<DownloadSettlementsForDatevCsvPayload, any>(
    {apiType: API_DOWNLOAD_SETTLEMENTS_FOR_DATEV_CSV},
    "/settlements/download-settlements-for-datev",
);

const downloadSettlementsForDatevCsvSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_SETTLEMENTS_FOR_DATEV_CSV),
        map((action: ApiCallSucceededAction<DownloadCsvData>) => {
            triggerCsvResponseDownload(action.payload, "Datev Abrechnungen.csv")
            return {
                type: ""
            }
        })
    );


const saveSettlements$ = apiPost(
    {apiType: API_SAVE_SETTLEMENT},
    "/settlements/save-settlement"
)

const successMessageAfterSettlementCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_SETTLEMENT
    }),
    filter(action => action.payload.isNew),
    map(action => {
        console.log(action)
        return postMessageAction(Message.TYPE_SUCCESS, "Abrechnung erfolgreich erstellt")
    })
);

const successMessageAfterSettlementSave$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_SETTLEMENT
    }),
    filter(action => !action.payload.isNew),
    map(action => {
        console.log(action)
        return postMessageAction(Message.TYPE_SUCCESS, "Abrechnung erfolgreich gespeichert")
    })
);

const redirectAfterSettlementCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_SETTLEMENT
    }),
    filter(action => action.payload.isNew),
    map(action => window.location.href = "/abrechnungen/".concat(action.payload.id).concat("/edit"))
);

const downloadSettlementPreviewPdf$ = apiGet(
    {apiType: API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF},
    (action: ApiCallAction<string>) => `/settlements/${action.payload}/download-preview`
);

const downloadSettlementPreviewPdfSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF),
        map((action: ApiCallSucceededAction<DownloadPdfData>) => {
            triggerPdfResponseDownload(action.payload, "Vorschau.pdf")
            return {
                type: ""
            }
        })
    );

const downloadBackToSellerListPdf$ = apiPost(
    {apiType: API_DOWNLOAD_BACK_TO_SELLER_LIST},
    (action: ApiCallAction<string>) => `/settlements/${action.payload}/download-back-to-seller-list`
);

const downloadBackToSellerListSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_BACK_TO_SELLER_LIST),
        map((action: ApiCallSucceededAction<DownloadPdfData>) => {
            triggerPdfResponseDownload(action.payload, "Rücklosliste.pdf")
            return {
                type: ""
            }
        })
    );

export const settlementModuleEpics$ = combineEpics(
    reloadOnRedirectDelete$,
    getCurrentSettlementNumber$,
    finalizeCreditNote$,
    reloadOrderOnCertainActions$,
    fetchReceipt$,
    sendReceiptEmail$,
    refreshReceipt$,
    cancelSettlement$,
    redirectAfterSettlementCreate$,
    successMessageAfterSettlementSave$,
    successMessageAfterSettlementCreate$,
    saveSettlements$,
    fetchReceiptSuccess$,
    downloadSettlementsForDatevCsv$,
    downloadSettlementsForDatevCsvSuccess$,
    downloadSettlementPreviewPdf$,
    downloadSettlementPreviewPdfSuccess$,
    generateFollowUpCreditNote$,
    downloadBackToSellerListPdf$,
    downloadBackToSellerListSuccess$
);
