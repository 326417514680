import React from "react";
import {useFilePicker} from "use-file-picker";
import {useDispatch} from "react-redux";
import {uploadLimitBidsAction} from "../../actions/limit-bids/upload-limit-bids";

type Props = {
    auctionId: string
}


export const UploadLimitBidsButton = ({
                                          auctionId
}: Props) => {

    const [openFileSelector, { filesContent, loading }] = useFilePicker({
        accept: '.csv',
        multiple: false
    });

    const dispatch = useDispatch();

    const startUpload = () => {
        dispatch(uploadLimitBidsAction(auctionId, filesContent[0].content));
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (filesContent.length) {
        return <>
            <button className={"btn btn-primary mb-1"} onClick={startUpload}>Datei {filesContent[0].name} importieren</button>
            <button className={"btn btn-primary ml-1 mb-1"} onClick={() => openFileSelector()}>Andere Datei wählen</button>
        </>
    }

    return <>
            <button className={"btn btn-primary mb-1"} onClick={() => openFileSelector()}>Vorgebote importieren</button>
    </>;

}
