import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_DUNNING = "download-dunning";

export interface DownloadDunningPayload {
    orderId: string,
    receiptId: string
}

export const downloadDunningAction = (orderId: string, receiptId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_DUNNING,
            callType: API_DOWNLOAD_DUNNING
        },
        payload: {
            orderId,
            receiptId
        }
    } as ApiCallAction<DownloadDunningPayload>;
};
