import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_CATEGORY = "update-category";

export interface UpdateCategoryPayload {
    lotId: string;
    writeToItem: boolean;
    category: string
}

export const updateCategoryAction = (lotId: string, writeToItem: boolean, category: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_CATEGORY,
            callType: API_UPDATE_CATEGORY
        },
        payload: {
            lotId,
            writeToItem,
            category
        }
    } as ApiCallAction<UpdateCategoryPayload>;
};
