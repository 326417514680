import {combineReducers} from "redux";
import {nextItemNumberReducer} from "./nextItemNumberReducer"
import {nextAuctionReducer} from "./nextAuctionReducer";

export const itemModuleReducer = combineReducers({
    nextItemNumber: nextItemNumberReducer,
    nextAuction: nextAuctionReducer
});

export type ItemModuleState = ReturnType<typeof itemModuleReducer>;
