import {EntityWrapper} from "@thekeytechnology/framework-react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import React from "react";
import {
    AttachmentField,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "@thekeytechnology/framework-react-ux";
import {LotForSaving} from "../../model/LotForSaving";
import CategorySelectField from "../../../item-category/components/edit/CategorySelectField";
import {LotForEditing} from "../../model/LotForEditing";
import {SubmitLimitBidForm} from "./SubmitLimitBidForm";
import {EditFormHeading} from "@thekeytechnology/auktionshaus-frontend-library";
import {Lot} from "../../model/Lot";

type Props = WithSingleEntityFromPathProps<LotForEditing, LotForSaving> & WithTranslation;

const LotEditFormComponent = (props: Props) => {
    const {
        entity,
        upsertEntity,
        t
    } = props;

    if (!entity) {
        return (
            <>
                "Loading"
            </>
        );
    }

    return (
        <>
            <EditFormHeading entity={entity} label={t("entity.singular")}/>

            <Formik
                initialValues={{
                    description: entity.entity.description[props.i18n.language],
                    category: entity.entity.category,
                    status: entity.entity.status,
                    lotNumber: entity.entity.lotNumber,
                    condition: entity.entity.condition,
                    limitPrice: entity.entity.pricingInformation.limitPrice,
                    publicAttachments: entity.entity.publicAttachments,
                    privateAttachments: entity.entity.privateAttachments,
                }}

                onSubmit={(values, {setSubmitting}) => {
                    const description = entity.entity.description;
                    description[props.i18n.language] = values.description;

                    upsertEntity(
                        new EntityWrapper<LotForSaving>(
                            entity.id,
                            new LotForSaving(
                                description,
                                values.category ? values.category.id : undefined,
                                values.status,
                                values.lotNumber || 0,
                                values.condition,
                                values.limitPrice || 0,
                                values.publicAttachments.map(a => a.id as string),
                                values.privateAttachments.map(a => a.id as string)
                            )
                        )
                    );
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className={"lot-edit-form col-12 col-md-10 m-auto"}>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component="textarea"
                                            placeholder={t("entity.labels.description")}
                                            name="description"
                                            className="form-control default-input"
                                            label={t("entity.labels.description")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={CategorySelectField} placeholder={t("entity.labels.category")}
                                            name="category"
                                            className="form-control default-input"
                                            label={t("entity.labels.category")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="text" placeholder={t("entity.labels.status")} name="status"
                                            className="form-control default-input" label={t("entity.labels.status")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="number" placeholder={t("entity.labels.ticketNumber")}
                                            name="lotNumber"
                                            className="form-control default-input"
                                            label={t("entity.labels.lot-number")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="text" placeholder={t("entity.labels.condition")} name="condition"
                                            className="form-control default-input"
                                            label={t("entity.labels.condition")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={AttachmentField}
                                            placeholder={t("entity.labels.public-attachments")}
                                            name="publicAttachments"
                                            className="form-control default-input"
                                            label={t("entity.labels.public-attachments")}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={AttachmentField}
                                            placeholder={t("entity.labels.private-attachments")}
                                            name="privateAttachments"
                                            className="form-control default-input"
                                            label={t("entity.labels.private-attachments")}
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="number" placeholder={t("entity.labels.limit-price")}
                                            name="limitPrice"
                                            className="form-control default-input"
                                            label={t("entity.labels.limit-price")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            type="checkbox"
                                            name="addNew"
                                            className="form-control default-input" label={"Neu?"}/>
                        </div>
                        <div className="form-group d-flex mb-3 row">
                            <button type="submit" disabled={formikState.isSubmitting}
                                    className="btn btn-primary align-self-end w-100">
                                {t("core:edit.submit")}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

            <SubmitLimitBidForm/>
        </>
    );
};

export const LotEditForm = WithSingleEntityFromPath<{}, LotForEditing, LotForSaving>(
    withTranslation(["lot", "core"])(LotEditFormComponent),
    Lot.TYPE,
    "lotId", LotForEditing.new, LotForEditing.CONTEXT);
