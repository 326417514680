import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointUp} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {ManualAssignmentEditor} from "./ManualAssignmentEditor";

type Props = WithTranslation;

const ManualAssignmentButtonComponent = (props: Props) => {
    const {t} = props;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        <button type="button"
                className="btn btn-secondary mr-3"
                onClick={() => handleShow()}
        >
            <FontAwesomeIcon className="mr-2"
                             icon={faHandPointUp}/> {t("auction-planning.actions.assign-manually")}
        </button>

        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <ManualAssignmentEditor handleClose={handleClose}/> : null}
        </Modal>
    </>
};

export const ManualAssignmentButton = withTranslation("auctionPlanning")(ManualAssignmentButtonComponent);
