import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import {useTranslation} from "react-i18next";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGavel} from "@fortawesome/free-solid-svg-icons";
import {
    API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID,
    assignBuyersBasedOnManuelIdAction
} from "../../actions/assign-buyers/assign-buyers-based-on-manual-id";

export const AssignBuyersBasedOnManualIdButton = () => {

    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]
    const apiCallState = useSelector(selectCombinedApiState(API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID))

    const {t} = useTranslation("auctionPlanning");

    return auctionId ? <button type="button" disabled={apiCallState.inProgress} onClick={() => {
        dispatch(assignBuyersBasedOnManuelIdAction(auctionId));
    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon className="mr-2"
                         icon={faGavel}/>{t("Käufer zuordnen")}
    </button> : null;
}
