import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_BACK_TO_SELLER_LIST = "download-back-to-seller-list-pdf";

export const downloadBackToSellerListAction = (settlementId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_BACK_TO_SELLER_LIST,
            callType: API_DOWNLOAD_BACK_TO_SELLER_LIST
        },
        payload: settlementId
    } as ApiCallAction<string>;
};
