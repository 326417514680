import {EntityWrapper} from "@thekeytechnology/framework-react";

export class ItemCategory {
    public static TYPE = "item-category";

    public static new() {
        return new EntityWrapper(undefined, new ItemCategory(
            {},
            "",
            0,
            ""
        ));
    }

    constructor(
        public name: { [lang: string]: string },
        public color: string,
        public orderIndex: number,
        public catalogPart: string) {
    }

}
