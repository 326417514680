import React from "react";

import {useDispatch} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {deleteDuplicateCustomersAction} from "../../actions/delete-duplicate-customers";


interface OwnProps {
    customerId: string
    handleClose: () => void;
}

export const DeleteDuplicateCustomersModal = ({customerId, handleClose}: OwnProps) => {
    const dispatch = useDispatch();

    return (<>
            <Modal.Header closeButton>
                <Modal.Title>Doppelte Kunden löschen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Hierbei werden alle Kunden mit der gleichen Kundennummer wie dieser Kunde gelöscht.
                    Objekte, Lose, Gebote, Rechnungen und Abrechnung der dabei gelöschten Kunden
                    werden diesem Kunden zugewiesen.
                </p>
                <p>
                    Bitte darauf achten, dass dieser Kunde auch derjenige ist, mit dem sich der Nutzer am Ende auch
                    anmeldet.
                    Falls nicht, muss danach hier in der Maske noch bei "Benutzer" der richtige Wert eingestellt werden
                    (einfach nach der E-Mail Adresse suchen).
                </p>
                <p>
                    <strong>Achtung: Dieser Vorgang ist nicht umkehbar!</strong>
                </p>

                <Button type={"button"} onClick={() => {
                    dispatch(deleteDuplicateCustomersAction(customerId))
                    handleClose()
                }}>
                    Endgültig löschen
                </Button>
            </Modal.Body>
        </>
    );
}
