import {createSelector} from "reselect";
import {AppState} from "../../reducers";
import {ItemModuleState} from "../reducers";
import {ItemNumberState} from "../reducers/nextItemNumberReducer";
import {NextAuctionState} from "../reducers/nextAuctionReducer";

export const selectItemModuleState = (state: AppState) => state.item;

export const selectNextItemNumberState =  createSelector(
    selectItemModuleState,
    (moduleState: ItemModuleState) =>
        moduleState.nextItemNumber
);

export const selectorNextItemNumber = createSelector(
    selectNextItemNumberState,
    (moduleState: ItemNumberState) =>
        moduleState.nextItemNumber
);

export const selectNextAuctionState = createSelector(
    selectItemModuleState,
    (moduleState: ItemModuleState) =>
        moduleState.nextAuction
);

export const selectNextAuction = createSelector(
    selectNextAuctionState,
    (moduleState: NextAuctionState) =>
        moduleState.nextAuction
);
