import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeading, faPercentage, faSection} from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import {CommissionPosition} from "../../model/position/CommissionPosition";
import {useTranslation} from "react-i18next";

interface OwnProps {
    disabled?: boolean
    position: CommissionPosition;
    onChange: (newPosition: CommissionPosition) => void;
    sum?: number;
}

type Props = OwnProps;

export const CommissionPositionEditor = ({
                                               disabled,
                                               position,
                                               onChange,
                                               sum = 0
                                           }: Props) => {

    const {t} = useTranslation(["order"])

    const value = (sum * ((100 + position.amount) / 100) - sum);
    const tax = (value * (100 + position.tax) / 100 - value);

    return <div className="d-flex w-100">
        <div className="input-field-wrapper mr-2 w-100">
            <FontAwesomeIcon className="input-icon" icon={faHeading}/>
            <input className="form-control default-input"
                   placeholder={t("custom-position-editor.comment-placeholder")}
                   type="text"
                   disabled={disabled}
                   value={position.comment}
                   onChange={event => {
                       onChange(update(position, {comment: {$set: event.target.value}}))
                   }}/>
        </div>
        <div className="input-field-wrapper">
            <FontAwesomeIcon className="input-icon" icon={faPercentage}/>
            <input className="form-control default-input"
                   placeholder={t("commission-position-editor.placeholder")}
                   type="number"
                   disabled={disabled}
                   step={0.01}
                   value={position.amount}
                   onChange={event => {
                       const newAmount = Number.parseFloat(event.target.value);
                       onChange(update(position, {amount: {$set: newAmount}}))
                   }}/>
        </div>
        <div className="input-field-wrapper">
            <FontAwesomeIcon className="input-icon" icon={faSection}/>
            <input className="form-control default-input"
                   placeholder={t("position-editor.tax")}
                   type="number"
                   disabled={disabled}
                   min={0}
                   step={1}
                   value={position.tax}
                   onChange={event => {
                       const newAmount = Number.parseFloat(event.target.value);
                       onChange(update(position, {tax: {$set: newAmount}}))
                   }}/>
        </div>
        <div className="input-field-wrapper p-2" style={{ minWidth: '161px' }}>
            Wert: {value.toFixed(2)} €<br/>
            Ust: {tax.toFixed(2)} €
        </div>
    </div>
};