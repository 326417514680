import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_CANCEL_SETTLEMENT = "cancel-settlement"

export interface CancelSettlementActionPayload {
    containerId: string
}

export const cancelSettlementAction = (settlementId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CANCEL_SETTLEMENT,
            callType: API_CANCEL_SETTLEMENT
        },
        payload: {
            containerId: settlementId
        }
    } as ApiCallAction<CancelSettlementActionPayload>;
};
