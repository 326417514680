import {createSelector} from "reselect";
import {AuctionModuleState} from "../reducers";
import {AuctionNumberState} from "../reducers/currentAuctionReducer";
import {AppState} from "../../reducers";

export const selectAuctionModuleState = (state: AppState) => state.auction;

export const selectCurrentAuctionNumberState =  createSelector(
    selectAuctionModuleState,
    (moduleState: AuctionModuleState) =>
        moduleState.currentAuctionNumber
);

export const selectCurrentAuctionNumber = createSelector(
    selectCurrentAuctionNumberState,
    (moduleState: AuctionNumberState) =>
        moduleState.currentAuctionNumber
);
