import {DateTimeDisplay} from "@thekeytechnology/framework-react-ux";
import React from "react";
import "./receipt-list.scss";
import {useDispatch, useSelector} from "react-redux";
import {API_SEND_INVOICE_EMAIL, sendReceiptEmailAction} from "../../actions/send-invoice";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {API_REFRESH_INVOICE, refreshInvoiceAction} from "../../actions/refresh-invoice";
import {useTranslation} from "react-i18next";
import {Action} from "redux";
import {sendCreditNoteEmailAction} from "../../../settlements/actions/send-credit-note";
import {refreshCreditNoteAction} from "../../../settlements/actions/refresh-credit-note";
import {AttachedReceipt} from "@thekeytechnology/auktionshaus-frontend-library";
import {downloadReceiptAction} from "../../actions/download-receipt";
import {CustomReceiptButton} from "../custom-receipt/CustomReceiptButton";

export const ACTION_FACTORY_FETCH_INVOICE = (receiptId: string, receiptType: string) => downloadReceiptAction(receiptId, receiptType)
export const ACTION_FACTORY_SEND_INVOICE_EMAIL = (containerId: string, receiptId: string) => sendReceiptEmailAction(containerId, receiptId)
export const ACTION_FACTORY_REFRESH_INVOICE = (containerId: string, receiptId: string) => refreshInvoiceAction(containerId, receiptId)

export const ACTION_FACTORY_FETCH_CREDIT_NOTE = (receiptId: string, receiptType: string) => downloadReceiptAction(receiptId, receiptType)
export const ACTION_FACTORY_SEND_CREDIT_NOTE_EMAIL = (containerId: string, receiptId: string) => sendCreditNoteEmailAction(containerId, receiptId)
export const ACTION_FACTORY_REFRESH_CREDIT_NOTE = (containerId: string, receiptId: string) => refreshCreditNoteAction(containerId, receiptId)


interface OwnProps {
    containerId?: string,
    containerType: string,
    attachedReceipts: AttachedReceipt[],
    containerStatus?: string;
    fetchActionFactory?: (receiptId: string, receiptType: string) => Action
    sendEmailActionFactory?: (containerId: string, receiptId: string) => Action
    refreshReceiptActionFactory?: (containerId: string, receiptId: string) => Action
    refreshApiStateId?: string
    sendReceiptApiStateId?: string
}

export const ReceiptList = ({
                                containerId,
                                containerType,
                                attachedReceipts,
                                containerStatus,
                                fetchActionFactory = ACTION_FACTORY_FETCH_INVOICE,
                                sendEmailActionFactory = ACTION_FACTORY_SEND_INVOICE_EMAIL,
                                refreshReceiptActionFactory = ACTION_FACTORY_REFRESH_INVOICE,
                                refreshApiStateId = API_REFRESH_INVOICE,
                                sendReceiptApiStateId = API_SEND_INVOICE_EMAIL
                            }: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(refreshApiStateId))
    const {t} = useTranslation("order");

    const sendReceiptEmailApiCallState = useSelector(selectCombinedApiState(sendReceiptApiStateId))

    return containerId && containerStatus && attachedReceipts.length > 0 ? <>
        <h3>Belege</h3>
        <div className="receipt-list mb-5 alert alert-secondary">
            <div className="receipt-row header">
                <div className="upload-time">Erstellt</div>
                <div className="invoice-name">Name</div>
                <div className="invoice-name">Status</div>
            </div>
            {attachedReceipts.map(receipt => {
                return <div key={receipt.receiptId} className="receipt-row">
                    <div className="upload-time"><DateTimeDisplay
                        dateTime={receipt.dateTime}/></div>
                    <div className="invoice-name">
                        <button type="button"
                                className="btn-link"
                                onClick={() => dispatch(fetchActionFactory(receipt.receiptId, receipt.receiptType))}>
                            {receipt.receiptName} ({t("receipt-list.receipt-types." + receipt.receiptType)})
                        </button>
                    </div>
                    <div>
                        {t("receipt-list.receipt-status." + receipt.status)}
                    </div>
                    <div className="send-to-customer">
                        <button
                            disabled={sendReceiptEmailApiCallState.inProgress || sendReceiptEmailApiCallState.succeeded}
                            onClick={() => dispatch(sendEmailActionFactory(containerId, receipt.receiptId))}
                            type="button"
                            className="btn-link">
                            An Kunden senden
                        </button>
                    </div>
                    <div>
                        <a href={`https://app.lexoffice.de/permalink/invoices/edit/${receipt.receiptId}`}
                           target="_blank" rel="noopener noreferrer">Auf LexOffice öffnen</a>
                    </div>
                    <div>
                        <button
                            disabled={apiCallState.inProgress}
                            type="button"
                            onClick={() => dispatch(refreshReceiptActionFactory(containerId, receipt.receiptId))}
                            className="btn-link">Beleg aktualisieren
                        </button>
                    </div>
                    {containerId ?
                        <div>
                            <CustomReceiptButton containerId={containerId} initialPositions={receipt.positions} containerType={containerType}/>
                        </div>
                        : null
                    }
                    {/*<div>*/}
                    {/*    {containerStatus === ORDER_STATUS_CANCELLED && receipt.receiptType === RECEIPT_TYPE_INVOICE && receipt.status !== RECEIPT_STATUS_VOIDED ?*/}
                    {/*        <span*/}
                    {/*            className="text-danger">Dieser Beleg muss noch auf LexOffice storniert werden.</span> : null}*/}
                    {/*</div>*/}
                </div>
            })}
        </div>
    </> : null
}
