import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    Pagination,
    PaginationContainer,
    SimpleHeader
} from "@thekeytechnology/framework-react-ux";
import {ItemCategoryFilter} from "./ItemCategoryFilter";
import {ItemCategoryTable} from "./ItemCategoryTable";
import {ItemCategory} from "../model/itemCategory";

const ItemCategoryDefinitionOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/item-categories"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <ItemCategoryFilter/>
            </BackgroundContainer>
            <ContentContainer>
                <ItemCategoryTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ItemCategory.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const ItemCategoryOverview = withTranslation("itemCategory")(ItemCategoryDefinitionOverviewComponent);
