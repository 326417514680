import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_CURRENT_AUCTION_NUMBER = "get-current-auction-number";

export const getCurrentAuctionNumberAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_CURRENT_AUCTION_NUMBER,
            callType: API_GET_CURRENT_AUCTION_NUMBER
        },
        payload: null
    } as ApiCallAction<null>;
};
