import {EntityWrapper} from "@thekeytechnology/framework-react";
import Modal from "react-bootstrap/Modal";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";
import {
    LotDescriptionDisplay,
    LotThumbnailDisplay,
    LotTitleDisplay
} from "@thekeytechnology/auktionshaus-frontend-library";
import {Col, Row} from "react-bootstrap";
import {LotDetailsRow} from "./LotDetailsRow";

interface OwnProps {
    handleClose: () => void;
    lot: EntityWrapper<AuctionManagerLot>
}

type Props = OwnProps;

export const LotDetailsModal = ({
                                    handleClose,
                                    lot
                                }: Props) => {

    const {t} = useTranslation(["lot"])

    return <>

        <Modal.Header closeButton>
            <Modal.Title>
                {t("entity.singular")} {lot.entity.lotNumber} - <LotTitleDisplay
                description={lot.entity.description.de}/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className={"mb-3"}>
                <Col>
                    {lot.entity.publicAttachments.length ?
                        <LotThumbnailDisplay url={lot.entity.publicAttachments[0].entity.url}/>
                        : null}
                </Col>
                <Col>
                    <LotDetailsRow
                        title={t("entity.labels.inventory-number")}
                        value={lot.entity.items[0].inventoryNumber.toFixed(0)}/>
                    <LotDetailsRow
                        title={t("entity.labels.seller")}
                        value={lot.entity.seller.name.concat(" (").concat(lot.entity.seller.customerNumber.toFixed(0)).concat(")")}
                    />
                    {lot.entity.buyer ? <LotDetailsRow
                        title={t("entity.labels.buyer")}
                        value={lot.entity.buyer.name.concat(" (").concat(lot.entity.buyer.customerNumber.toString(10)).concat(")")}
                    /> : null}
                    {lot.entity.winningBid ? <LotDetailsRow
                        title={t("entity.labels.winning-bid")}
                        value={lot.entity.winningBid.entity.value.toFixed(0).concat(" €")}
                    /> : null}
                </Col>
            </Row>
            <Row>
                <Col>
                    <LotDescriptionDisplay description={lot.entity.description.de}/>
                </Col>
            </Row>
            <small>Los-ID: {lot.id}</small>
        </Modal.Body>


        <Modal.Footer>
        </Modal.Footer>
    </>;
}