import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FINALIZE_CREDIT_NOTE = "finalize-credit-note";

export const finalizeCreditNoteAction = (settlementId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FINALIZE_CREDIT_NOTE,
            callType: API_FINALIZE_CREDIT_NOTE
        },
        payload: settlementId
    } as ApiCallAction<string>;
};
