import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SUBMIT_LIMIT_BID_AUCTIONEER = "submit-limit-bid-auctioneer";

export interface SubmitLimitBidAuctioneerPayload extends Action {
    value: number;
    customerId: string;
    source: string;
    comment: string;
    lotId?: string;
    tel?: string;
}

export const submitLimitBidAuctioneerAction = (
        value: number,
        customerId: string,
        source: string,
        comment: string,
        lotId?: string,
        tel?: string,
    ) => {
        return {
            type: ApiCallAction,
            apiQualifier: {
                apiType: API_SUBMIT_LIMIT_BID_AUCTIONEER,
                callType: API_SUBMIT_LIMIT_BID_AUCTIONEER
            },
            payload: {
                value,
                customerId,
                source,
                comment,
                lotId,
                tel,
            }
        } as ApiCallAction<SubmitLimitBidAuctioneerPayload>;
    }
;
