import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_AUCTION_PROTOCOL = "download-auction-protocol";

export interface DownloadAuctionProtocolPayload extends Action {
    auctionId: string;
}

export const downloadAuctionProtocolAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_AUCTION_PROTOCOL,
            callType: API_DOWNLOAD_AUCTION_PROTOCOL
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<DownloadAuctionProtocolPayload>;
};
