import {
    API_DELETE_ENTITIES,
    ApiCallAction,
    ApiCallSucceededAction,
    apiGet,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadOnEntityApiOperation,
    reloadSingleEntityAction
} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import "@thekeytechnology/framework-react/"
import {ENTITY_TYPE_ORDER} from "../model/Order";
import {API_GET_CURRENT_ORDER_NUMBER} from "../actions/get-current-order-number";
import {API_FINALIZE_INVOICE} from "../actions/finalize-invoice";
import {Observable} from "rxjs";
import {Action} from "redux";
import {filter, map} from "rxjs/operators";
import {API_FETCH_INVOICE} from "../actions/fetch-invoice";
import {ReceiptData} from "../model/receipt-data";
import {API_SEND_INVOICE_EMAIL, SendReceiptPayload} from "../actions/send-invoice";
import {API_CANCEL_ORDER} from "../actions/cancel-order";
import {API_REFRESH_INVOICE} from "../actions/refresh-invoice";
import {API_REOPEN_ORDER} from "../actions/reopen-order";
import {API_REFRESH_ALL_INVOICES} from "../actions/refresh-all-invoices";
import {DownloadCsvData} from "../../core/model/DownloadCsvData";
import {
    API_DOWNLOAD_ORDERS_FOR_DATEV_CSV,
    DownloadOrdersForDatevCsvPayload
} from "../actions/download-orders-for-datev-csv";
import {API_SAVE_ORDER} from "../actions/save-order";
import {API_DOWNLOAD_RECEIPT} from "../actions/download-receipt";
import {API_GENERATE_FINANCING_CREDIT_NOTE} from "../actions/generate-financing-credit-note";
import {API_CREATE_CUSTOM_RECEIPT, CreateCustomReceiptPayload} from "../actions/custom-receipt";
import {triggerCsvResponseDownload} from "../../core/utils/trigger-csv-response-download";
import {API_SEND_INVOICE_REMINDER_EMAIL} from "../actions/send-reminder";
import {API_DOWNLOAD_DUNNING, DownloadDunningPayload} from "../actions/download-dunning";
import {DownloadPdfData} from "../../core/model/DownloadPdfData";
import {triggerPdfResponseDownload} from "../../core/utils/trigger-pdf-response-download";
import {API_COMMISSION_ORDER, CommissionOrderPayload} from "../actions/commission-order";
import {API_SHIP_ORDER, ShipOrderPayload} from "../actions/ship-order";
import {API_DOWNLOAD_ORDER_PREVIEW_PDF} from "../actions/download-order-preview-pdf";

const reloadOnRedirectDelete$ = reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_ORDER);
const reloadOrderOnCertainActions$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_FINALIZE_INVOICE},
        {apiType: API_SEND_INVOICE_EMAIL},
        {apiType: API_SEND_INVOICE_REMINDER_EMAIL},
        {apiType: API_CANCEL_ORDER},
        {apiType: API_REFRESH_INVOICE},
        {apiType: API_REOPEN_ORDER},
        {apiType: API_GENERATE_FINANCING_CREDIT_NOTE},
        {apiType: API_CREATE_CUSTOM_RECEIPT},
        {apiType: API_SAVE_ORDER}
    ),
    map(() => {
        return reloadSingleEntityAction(ENTITY_TYPE_ORDER)()
    })
);


const getCurrentOrderNumber$ = apiGet(
    {apiType: API_GET_CURRENT_ORDER_NUMBER},
    "/orders/current-number"
);

const generateInvoice$ = apiGet(
    {apiType: API_FINALIZE_INVOICE},
    (action: ApiCallAction<string>) => `/orders/${action.payload}/finalize-invoice`
);

const generateFinancingCreditNote$ = apiPost(
    {apiType: API_GENERATE_FINANCING_CREDIT_NOTE},
    (action: ApiCallAction<string>) => `/orders/${action.payload}/generate-financing-credit-note`
);

const refreshAllInvoices$ = apiPost(
    {apiType: API_REFRESH_ALL_INVOICES},
    (action: ApiCallAction<string>) => `/orders/refresh-all-invoices`
);

const reopenOrder$ = apiGet(
    {apiType: API_REOPEN_ORDER},
    (action: ApiCallAction<string>) => `/orders/${action.payload}/reopen`
);

const fetchInvoice$ = apiGet(
    {apiType: API_FETCH_INVOICE},
    (action: ApiCallAction<string>) => `/orders/fetch-invoice/${action.payload}`
);

const sendReceiptEmail$ = apiGet(
    {apiType: API_SEND_INVOICE_EMAIL},
    (action: ApiCallAction<SendReceiptPayload>) => `/orders/${action.payload.orderId}/send-receipt/${action.payload.receiptId}/`
);

const sendReminderEmail$ = apiGet(
    {apiType: API_SEND_INVOICE_REMINDER_EMAIL},
    (action: ApiCallAction<SendReceiptPayload>) => `/orders/${action.payload.orderId}/send-reminder/${action.payload.receiptId}/`
);

const sendReminderEmailSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SEND_INVOICE_REMINDER_EMAIL
    }),
    map(action => {
        return postMessageAction(Message.TYPE_SUCCESS, "Erinnerungsmail erfolgreich versendet")
    })
);

const downloadDunning$ = apiGet(
    {apiType: API_DOWNLOAD_DUNNING},
    (action: ApiCallAction<DownloadDunningPayload>) => `/orders/${action.payload.orderId}/download-dunning/${action.payload.receiptId}/`
);

const downloadDunningSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DOWNLOAD_DUNNING),
    map((action: ApiCallSucceededAction<DownloadPdfData[]>) => {
        action.payload.forEach((payload, index) => {
            triggerPdfResponseDownload(payload, "Mahnung Teil " + (index + 1) + ".pdf")
        })
        return {
            type: ""
        }
    })
);

const refreshReceipt$ = apiGet(
    {apiType: API_REFRESH_INVOICE},
    (action: ApiCallAction<SendReceiptPayload>) => `/orders/${action.payload.orderId}/refresh-receipt/${action.payload.receiptId}/`
);

const cancelOrder$ = apiPost(
    {apiType: API_CANCEL_ORDER},
    "/orders/cancel"
);


const downloadReceipt$ = apiPost(
    {apiType: API_DOWNLOAD_RECEIPT},
    "/orders/download-receipt"
)

const downloadReceiptSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_DOWNLOAD_RECEIPT),
    map((action: ApiCallSucceededAction<ReceiptData>) => {
        const link = document.createElement("a");
        const file = new Blob([new Uint8Array(action.payload.content)], {type: 'text/pdf'})
        link.href = URL.createObjectURL(file);
        link.download = action.payload.number + ".pdf";
        link.click();
        return {
            type: "invoice-download"
        }
    })
);


const fetchInvoiceSuccess = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_FETCH_INVOICE),
    map((action: ApiCallSucceededAction<ReceiptData>) => {
        const link = document.createElement("a");
        const file = new Blob([new Uint8Array(action.payload.content)], {type: 'text/pdf'})
        link.href = URL.createObjectURL(file);
        link.download = action.payload.number + ".pdf";
        link.click();
        return {
            type: "invoice-download"
        }
    })
);

const downloadOrdersForDatevCsv$ = apiPost<DownloadOrdersForDatevCsvPayload, any>(
    {apiType: API_DOWNLOAD_ORDERS_FOR_DATEV_CSV},
    "/orders/download-orders-for-datev",
);

const downloadOrdersForDatevCsvSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_ORDERS_FOR_DATEV_CSV),
        map((action: ApiCallSucceededAction<DownloadCsvData>) => {
            triggerCsvResponseDownload(action.payload, "Datev Bestellungen.csv")
            return {
                type: ""
            }
        })
    );

const createCustomReceipt$ = apiPost<CreateCustomReceiptPayload, any>(
    {apiType: API_CREATE_CUSTOM_RECEIPT},
    "/orders/custom-receipt",
);

const commissionOrder$ = apiPost<CommissionOrderPayload, any>(
    {apiType: API_COMMISSION_ORDER},
    "/orders/commission-order",
);

export const commissionOrderSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_COMMISSION_ORDER
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Bestellung Kommissioniert."))
);

const shipOrder$ = apiPost<ShipOrderPayload, any>(
    {apiType: API_SHIP_ORDER},
    "/orders/ship-order",
);

export const shipOrderSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SHIP_ORDER
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Versand bestätigt."))
);

const saveOrder$ = apiPost(
    {apiType: API_SAVE_ORDER},
    "/orders/save-order"
)

const successMessageAfterOrderCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ORDER
    }),
    filter(action => action.payload.isNew),
    map(action => {
        console.log(action)
        return postMessageAction(Message.TYPE_SUCCESS, "Bestellung erfolgreich erstellt")
    })
);

const successMessageAfterOrderSave$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ORDER
    }),
    filter(action => !action.payload.isNew),
    map(action => {
        console.log(action)
        return postMessageAction(Message.TYPE_SUCCESS, "Bestellung erfolgreich gespeichert")
    })
);

const redirectAfterOrderCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ORDER
    }),
    filter(action => action.payload.isNew),
    map(action => window.location.href = "/bestellungen/".concat(action.payload.id).concat("/edit"))
);

const downloadOrderPreviewPdf$ = apiGet(
    {apiType: API_DOWNLOAD_ORDER_PREVIEW_PDF},
    (action: ApiCallAction<string>) => `/orders/${action.payload}/download-preview`
);

const downloadOrderPreviewPdfSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_ORDER_PREVIEW_PDF),
        map((action: ApiCallSucceededAction<DownloadPdfData>) => {
            triggerPdfResponseDownload(action.payload, "Vorschau.pdf")
            return {
                type: ""
            }
        })
    );

export const orderModuleEpics$ = combineEpics(
    reloadOnRedirectDelete$,
    getCurrentOrderNumber$,
    generateInvoice$,
    reloadOrderOnCertainActions$,
    downloadReceipt$,
    downloadReceiptSuccess$,
    fetchInvoice$,
    fetchInvoiceSuccess,
    refreshReceipt$,
    sendReceiptEmail$,
    cancelOrder$,
    saveOrder$,
    successMessageAfterOrderSave$,
    redirectAfterOrderCreate$,
    successMessageAfterOrderCreate$,
    reopenOrder$,
    refreshAllInvoices$,
    downloadOrdersForDatevCsv$,
    downloadOrdersForDatevCsvSuccess$,
    generateFinancingCreditNote$,
    createCustomReceipt$,
    sendReminderEmail$,
    sendReminderEmailSuccess$,
    downloadDunning$,
    downloadDunningSuccess$,
    commissionOrder$,
    commissionOrderSuccessMessage$,
    shipOrder$,
    shipOrderSuccessMessage$,
    downloadOrderPreviewPdf$,
    downloadOrderPreviewPdfSuccess$
);
