import {faHeading} from "@fortawesome/free-solid-svg-icons";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import React from "react";
import {EmailConfiguration} from "../../../model/email-configuration.model";
import * as Yup from "yup";
import {
    ContentContainer,
    EditHeaderWithBackground,
    SaveAndBackButtons,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps,
    WysiwygField
} from "@thekeytechnology/framework-react-ux";
import {EditFormHeading} from "@thekeytechnology/auktionshaus-frontend-library";

type Props =
    WithSingleEntityFromPathProps<EmailConfiguration, EmailConfiguration>
    & WithTranslation;

const EditEmailComponent = (props: Props) => {
    const {entity, upsertEntity, t} = props;

    return (
        entity ? <>
            <BreadcrumbsItem to={"/emails"}>{t("Emails")}</BreadcrumbsItem>
            {entity ? <BreadcrumbsItem
                to={"/emails/" + entity.id + "/edit"}>
                {"Bearbeite Email"} "{"email-keys." + t(entity.entity.key)}"
            </BreadcrumbsItem> : null}
            <EditFormHeading entity={entity} label={t("Email")}/>

            {entity ?
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        subject: entity.entity.subjects.de,
                        preview: entity.entity.previewTexts.de,
                        body: entity.entity.bodies.de
                    }}
                    validationSchema={Yup.object().shape({
                        subject: Yup.string().required(t("Subject") + " " + t("translation:is a required field")),
                        body: Yup.string().required(t("Body") + " " + t("translation:is a required field")),
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        const emailForSaving = new EntityWrapper(entity.id,
                            new EmailConfiguration(
                                entity.entity.key,
                                {
                                    ...entity.entity.subjects,
                                    de: values.subject
                                },
                                {
                                    ...entity.entity.bodies,
                                    de: values.body
                                },
                                {
                                    ...entity.entity.previewTexts,
                                    de: values.preview
                                },
                                entity.entity.variables
                            )
                        );
                        upsertEntity(emailForSaving);
                        setSubmitting(false);
                    }}
                >
                    {(formikState) => (
                        <Form>
                            <EditHeaderWithBackground
                                heading={t("email-keys." + entity.entity.key)}
                                leadText={<>{formikState.values.subject}</>}>
                                <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                    backPath="/emails"/>
                            </EditHeaderWithBackground>
                            <ContentContainer>
                                {/*<div className="form-group row">*/}
                                {/*    <label htmlFor="editing-language"*/}
                                {/*           className="col-sm-2 col-form-label">{t("Choose translation")}</label>*/}
                                {/*    <div className="col-sm-10">*/}
                                {/*        <LanguageSwitcher availableLanguages={props.availableLanguages}*/}
                                {/*                          selectedLanguage={props.editingLanguage}*/}
                                {/*                          selectLanguage={language => props.setEditingLanguage(language)}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="form-group row">
                                    <ValidatedField icon={faHeading} label={"Betreff"} name="subject"
                                                    formikState={formikState}
                                                    type="text"
                                                    placeholder={"Betreff"} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField icon={faHeading} label={"Vorschau"} name="preview"
                                                    formikState={formikState}
                                                    type="text"
                                                    placeholder={"Vorschau"} required
                                    />
                                </div>
                                <div className="form-group row">
                                    <ValidatedField label={"Text"} name="body"
                                                    formikState={formikState}
                                                    component={WysiwygField}
                                                    required
                                    />
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="html"
                                           className="col-sm-2 col-form-label">Verfügbare Variablen</label>
                                    <div className="col-sm-10">
                                        {entity.entity.variables.length ? <ul>
                                            {entity.entity.variables.map(v => {
                                                return <li key={v}>%%{v}%%
                                                    - {t("email-variables." + v)}</li>;
                                            })}
                                        </ul> : null}
                                    </div>
                                </div>
                            </ContentContainer>
                        </Form>
                    )}
                </Formik> : null}
        </> : null
    );
};

export const EditEmail = WithSingleEntityFromPath<{}, EmailConfiguration, EmailConfiguration>(
    withTranslation("emails")(EditEmailComponent), EmailConfiguration.TYPE, "emailId"
);
