import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {ItemCategoryEditForm} from "../components/edit/ItemCategoryEditForm";
import {ItemCategoryOverview} from "../components/ItemCategoryOverview";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {USER_ROLE_ITEM_MANAGER} from "../../core/model/AuctionUserRoles";

export const ItemCategoryModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]}
                          path="/item-categories/" component={ItemCategoryOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]}
                          path="/item-categories/:itemCategoryId/edit" component={ItemCategoryEditForm}/>
        </>

    );
};
