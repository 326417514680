import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_NEXT_AUCTION = "get-next-auction";

export const getNextAuctionAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_GET_NEXT_AUCTION,
        callType: API_GET_NEXT_AUCTION
    },
    payload: null
} as ApiCallAction<null>;

