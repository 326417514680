import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Address} from "@thekeytechnology/auktionshaus-frontend-library";

export const API_EXTENDED_REGISTRATION = "extended-registration";

export interface ExtendedRegistrationPayload {
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    tel: string,
    address?: Address
}

export const extendedRegistrationAction = (payload: ExtendedRegistrationPayload) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_EXTENDED_REGISTRATION,
            callType: API_EXTENDED_REGISTRATION
        },
        payload
    } as ApiCallAction<ExtendedRegistrationPayload>;
};


