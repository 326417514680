import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {AuctionEditForm} from "../components/AuctionEditForm";
import {AuctionOverview} from "../components/AuctionsOverview";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";


export const AuctionRoutes = () => {
    return (
            <>
                <PrivateRoute exact
        requiredRoles={[UserWithRoles.ROLE_ADMIN]}
        path="/auktionen/" component={AuctionOverview}/>
        <PrivateRoute exact
        requiredRoles={[UserWithRoles.ROLE_ADMIN]}
        path="/auktionen/:auctionId/edit" component={AuctionEditForm}/>
        <PrivateRoute exact
        requiredRoles={[UserWithRoles.ROLE_ADMIN]}
        path="/auktionen/new/" component={AuctionEditForm}/>
        </>

    );
};
