import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import Modal from "react-bootstrap/Modal";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import Button from "react-bootstrap/Button";
import React from "react";
import CategorySelectField from "../../../../item-category/components/edit/CategorySelectField";
import {useTranslation} from "react-i18next";
import {CategoryJustIdNameAndColor} from "@thekeytechnology/auktionshaus-frontend-library";
import {
    API_DOWNLOAD_PDF_CATALOGUE,
    downloadPdfCatalogueAction
} from "../../../actions/download-pdfs/download-pdf-catalogue";

interface OwnProps {
    handleClose: () => void;
}

type Props = OwnProps;

export const DownloadPdfCatalogueForm = ({
                                             handleClose
                                         }: Props) => {
    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"];
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_PDF_CATALOGUE));
    const {t} = useTranslation(["lot"])

    return <Formik
        initialValues={{
            category: undefined as undefined | CategoryJustIdNameAndColor,
        }}
        onSubmit={(values, {setSubmitting}) => {
            if (values.category) {
                dispatch(downloadPdfCatalogueAction(auctionId, values.category.id));
                setSubmitting(false);
            }
            handleClose();
        }}
    >
        {formikState => (
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="category" className="col-sm-10 col-form-label">
                            Erste Kategorie des 2. Teils
                        </label>
                        <ValidatedField formikState={formikState}
                                        component={CategorySelectField}
                                        name="category"
                                        className="form-control default-input"/>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button type={"submit"} className={"btn btn-primary"} disabled={apiCallState.inProgress}>
                        Download
                    </Button>
                    <Button className="btn btn-secondary"
                            onClick={handleClose}>
                        {t("core:save-and-back-buttons.cancel")}
                    </Button>
                </Modal.Footer>
            </Form>
        )}
    </Formik>;
}