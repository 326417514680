import React from "react";
import {useTranslation} from "react-i18next";
import {
    ComplexFilter,
    EntityWrapper,
    FilterTerm,
    PropertyFilter,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import {ONLY_INVALID_FILTER_KEY, OnlyInvalidLotsFilter} from "./OnlyInvalidLotsFilter";
import {
    CategoryJustIdNameAndColor,
    ENTITY_TYPE_CATEGORY,
    LOT_SOURCE_ADDED_MANUALLY,
    LOT_SOURCE_FROM_TRANSFER,
    LOT_SOURCE_GROUPED,
    LOT_SOURCE_PREASSIGNED,
    LOT_SOURCE_UNGROUPED,
    LOT_STATUS_AT_AUCTION,
    LOT_STATUS_IN_PLANNING,
    LOT_STATUS_LIVE,
    LOT_STATUS_NOT_SOLD,
    LOT_STATUS_SOLD,
    LOT_STATUS_TRANSFERRED
} from "@thekeytechnology/auktionshaus-frontend-library";
import {PaginatedEndpointCollapsibleFilters} from "../../../paginated-endpoints/components/PaginatedEndpointCollapsibleFilters";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {InventoryNumberFilter} from "./InventoryNumberFilter";
import {LotNumberFilter} from "./LotNumberFilter";
import {CustomerSelectFilter} from "./CustomerSelectFilter";
import {PaginatedEndpointSimpleSelectFilter} from "../../../paginated-endpoints/components/PaginatedEndpointSimpleSelectFilter";
import {PaginatedEndPointTextFilter} from "../../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import {PaginatedEndPointAsyncEntitySelectFilter} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelectFilter";
import {CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelect";
import {useRouteMatch} from "react-router";
import {Bid} from "../../../bids/model/bid";
import {PaginatedEndPointCheckboxFilter} from "../../../paginated-endpoints/components/PaginatedEndPointCheckboxFilter";
import {createRangeFilterForNumberInput} from "../../../core/utils/create-range-filter-for-number-input";

interface OwnProps {
    isInPlanning: boolean;
    isConcluded: boolean;
    isLive: boolean;
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = OwnProps & WithSwitchableLanguageProps;

const AssignedLotsFilterComponent = ({
                                         currentLanguage,
                                         isInPlanning,
                                         isConcluded,
                                         isLive,
                                         endpointFilters
                                     }: Props) => {

    const {t} = useTranslation(["auction-planning", "lot", "bid", "core"])
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]

    return (
        <div className={"mt-4 assigned-lots-filters"}>
            <PaginatedEndpointCollapsibleFilters
                endpointFilters={endpointFilters}
                filterKeys={["entity.lotNumber", `entity.description.${currentLanguage}`, "inventoryNumber", "entity.sellerRef", "entity.buyerRef", "entity.categoryRef", "entity.status", "entity.source", ONLY_INVALID_FILTER_KEY]}
            >
                {!isInPlanning ? <LotNumberFilter endpointFilters={endpointFilters}/> : null}

                <InventoryNumberFilter endpointFilters={endpointFilters}/>

                <PaginatedEndPointTextFilter
                    property={`entity.description.${currentLanguage}`}
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("lot:entity.labels.short-description")
                    })}
                />

                {isConcluded || isLive ? <CustomerSelectFilter
                    endpointFilters={endpointFilters}
                    property={"entity.buyerRef"}
                    placeholder={t("core:filters.filter-by", {
                        label: t("bid:entity.labels.buyer")
                    })}
                /> : null}

                <CustomerSelectFilter
                    endpointFilters={endpointFilters}
                    property={"entity.sellerRef"}
                    placeholder={t("core:filters.filter-by", {
                        label: t("lot:entity.labels.seller")
                    })}
                />

                <PaginatedEndPointAsyncEntitySelectFilter<CategoryJustIdNameAndColor>
                    property={"entity.categoryRef"}
                    entityType={ENTITY_TYPE_CATEGORY}
                    shownEntityProperties={["entity.name.de", "entity.orderIndex"]}
                    listRenderer={(item: EntityWrapper<CategoryJustIdNameAndColor>) => item.entity.orderIndex.toString(10) + " - " + item.entity.name["de"]}
                    placeholder={t("core:filters.filter-by", {
                        label: t("lot:entity.labels.category")
                    })}
                    endpointFilters={endpointFilters}
                    customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                        if (property === "entity.orderIndex") {
                            const intVal = parseInt(inputValue)
                            if (intVal) {
                                return createRangeFilterForNumberInput(property, inputValue, 4)
                            } else {
                                return []
                            }
                        } else {
                            return [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))]
                        }
                    }}
                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("item-categories/get-categories")}
                />


                {!isInPlanning ?
                    <PaginatedEndpointSimpleSelectFilter
                        property={"entity.status"}
                        placeholder={t("core:filters.filter-by", {
                            label: t("lot:entity.labels.status")
                        })}
                        options={[LOT_STATUS_IN_PLANNING, LOT_STATUS_AT_AUCTION, LOT_STATUS_LIVE, LOT_STATUS_SOLD, LOT_STATUS_NOT_SOLD, LOT_STATUS_TRANSFERRED]
                            .map(status => ({
                                label: t("lot:status." + status),
                                value: status
                            }))}
                        endpointFilters={endpointFilters}
                    /> : null}
                {isInPlanning ?
                    <PaginatedEndpointSimpleSelectFilter
                        property="entity.source"
                        placeholder={t("core:filters.filter-by", {
                            label: t("lot:entity.labels.source")
                        })}
                        options={[LOT_SOURCE_ADDED_MANUALLY, LOT_SOURCE_PREASSIGNED, LOT_SOURCE_FROM_TRANSFER, LOT_SOURCE_GROUPED, LOT_SOURCE_UNGROUPED]
                            .map(status => ({
                                label: t("lot:source." + status),
                                value: status
                            }))}
                        endpointFilters={endpointFilters}
                    />
                    : null}
                {isConcluded ?
                    <PaginatedEndpointSimpleSelectFilter
                        property="bid-source"
                        placeholder={t("core:filters.filter-by", {
                            label: t("lot:entity.labels.bid-source")
                        })}
                        options={Bid.SOURCES_FOR_SELECT_FIELD
                            .map(source => ({
                                label: t("bid:source." + source),
                                value: source
                            }))}
                        endpointFilters={endpointFilters}
                        customValueFunction={(property: string, endpointFilters: PaginatedEndpointFiltersReturnProps) => {
                            const currentFilters = endpointFilters.getFilters().filter(f => f.key === property).flatMap(f => f.filters)
                            return currentFilters && currentFilters.length && currentFilters[0] ?
                                (currentFilters[0] as ComplexFilter).parameters['source'] : undefined;
                        }}
                        customOnSelectFunction={(property: string,
                                                 endpointFilters: PaginatedEndpointFiltersReturnProps,
                                                 selected: { value: string, label: string } | { value: string, label: string }[] | undefined) => {
                            if (selected) {
                                endpointFilters.addFilters(property, [
                                    new ComplexFilter(property, {
                                        "auctionId": auctionId,
                                        "source": (selected as { value: string, label: string }).value
                                    })
                                ])
                            } else {
                                endpointFilters.removeFilters([property])
                            }
                        }}
                    />
                    : null}

                {isInPlanning ? <OnlyInvalidLotsFilter/> : null}
                <PaginatedEndPointCheckboxFilter property={"entity.p86"} endpointFilters={endpointFilters}
                                                 label={"§86"}/>
            </PaginatedEndpointCollapsibleFilters>
        </div>
    );
};

export const AuctionManagerLotsFilters =
    WithSwitchableLanguage<any>(AssignedLotsFilterComponent);
