import {Action} from "redux";
import {
    API_DELETE_ENTITIES,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    Message,
    postMessageAction,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import {filter, map} from "rxjs/operators";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {ItemCategory} from "../model/itemCategory";
import {API_SAVE_ITEM_CATEGORY} from "../actions/save-item-category";

const reloadAfterItemCategoryDelete$ = reloadOnEntityApiOperation(API_DELETE_ENTITIES, ItemCategory.TYPE);

const successMessageAfterItemCategoryCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ITEM_CATEGORY
    }),
    filter(action => action.payload.isNew),
    map(action => {
        return postMessageAction(Message.TYPE_SUCCESS, "Objekt erfolgreich erstellt")
    })
);

const successMessageAfterItemCategorySave$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ITEM_CATEGORY
    }),
    filter(action => !action.payload.isNew),
    map(action => {
        return postMessageAction(Message.TYPE_SUCCESS, "Objekt erfolgreich gespeichert")
    })
);

const redirectAfterItemCategoryCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ITEM_CATEGORY
    }),
    filter(action => action.payload.isNew),
    map(action => window.location.href = "/item-categories/".concat(action.payload.id).concat("/edit"))
);

const saveItemCategory$ = apiPost(
    {apiType: API_SAVE_ITEM_CATEGORY},
    "/item-categories/save-category"
)

export const itemsCategoryModuleEpics$ = combineEpics(
    saveItemCategory$,
    reloadAfterItemCategoryDelete$,
    redirectAfterItemCategoryCreate$,
    successMessageAfterItemCategorySave$,
    successMessageAfterItemCategoryCreate$
);
