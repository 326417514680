import React from "react";
import {Form, Formik} from "formik";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {CustomerListSelectField} from "./CustomerListSelectField";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {SelectionModel} from "@thekeytechnology/framework-react";
import {bulkUpdateAllowedListsAction} from "../../actions/bulk-update-allowed-lists";

interface OwnProps {
    selectionModel: SelectionModel<string>;
}

type Props =  OwnProps;
export const BulkCustomerListChanger = (props : Props) => {

    const {
        selectionModel
    } = props;

    const {t} = useTranslation(["customer", "core"]);

    const dispatch = useDispatch();


    return (
        <div className={"bulk-customer-allowed-list-changer"}>
            <Formik
                initialValues={{
                    "allowedList": "",
                }}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    if (selectionModel.areAnySelected) {
                        dispatch(bulkUpdateAllowedListsAction(selectionModel.selections, values.allowedList))
                    }
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="d-flex flex-row">
                        <ValidatedField formikState={formikState}
                                        component={CustomerListSelectField}
                                        name="allowedList"
                        />
                        <button disabled={formikState.isSubmitting} className="btn btn-outline-success mr-3 ml-3"
                                type="submit">
                            {t("core:actions.process-selection")}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
