import React from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {FieldProps} from "formik";
import {
    CUSTOMER_ALLOWED_LIST_ALLOW_ONCE,
    CUSTOMER_ALLOWED_LIST_BLACK,
    CUSTOMER_ALLOWED_LIST_NO_LIST,
    CUSTOMER_ALLOWED_LIST_REQUESTED,
    CUSTOMER_ALLOWED_LIST_WHITE
} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    className?: string
}

type Props = OwnProps & FieldProps;

export const CustomerListSelectField = (props: Props) => {

    const {
        field,
        form,
        className
    } = props;

    const {t} = useTranslation(["customer", "core"]);

    const allowedListsOptions = [
        CUSTOMER_ALLOWED_LIST_BLACK,
        CUSTOMER_ALLOWED_LIST_NO_LIST,
        CUSTOMER_ALLOWED_LIST_ALLOW_ONCE,
        CUSTOMER_ALLOWED_LIST_REQUESTED,
        CUSTOMER_ALLOWED_LIST_WHITE,
    ].map(option => ({
            value: option,
            label: t("entity.labels.allowed-lists." + option)
        })
    );

    const onChange = (option: any) => {
        form.setFieldValue(field.name, option.value);
    };

    const getValue = () => {
        return allowedListsOptions.filter(option => option.value === field.value)
    }

    const combinedClassName = "react-select allowed-list-select-field " + (className ? className : "")

    return (
        <>
            <Select
                className={combinedClassName}
                classNamePrefix={"react-select"}
                placeholder={t("core:controls.select.select-with-type", {
                    type: t("entity.labels.allowed-list")
                })}
                options={allowedListsOptions}
                name={field.name}
                value={getValue()}
                onChange={onChange as any}
                onBlur={field.onBlur}
            />
        </>
    )

}
