import React, {useState} from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {BidInfo} from "./BidInfo";
import {CurrencyDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";

interface OwnProps {
    lot: EntityWrapper<AuctionManagerLot>
}

type Props = OwnProps;

export const WinningBidDisplay = ({
                                      lot
                                  }: Props) => {

    const [showInfo, setShowInfo] = useState(false);

    const handleInfoClose = () => setShowInfo(false);
    const handleInfoShow = () => setShowInfo(true);

    return <>
        <button
            className={"btn-link"}
            onClick={() => handleInfoShow()}
        >
            <CurrencyDisplay amount={lot.entity.winningBid!.entity.value}/>
        </button>
        <BidInfo bid={lot.entity.winningBid!} handleClose={handleInfoClose} show={showInfo}/>
    </>;
};
