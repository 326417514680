import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_CURRENT_SETTLEMENT_NUMBER = "get-current-settlement-number";

export const getCurrentSettlementNumber = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_CURRENT_SETTLEMENT_NUMBER,
            callType: API_GET_CURRENT_SETTLEMENT_NUMBER
        },
        payload: null
    } as ApiCallAction<null>;
};
