import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_ORDER_PREVIEW_PDF = "download-order-preview-pdf";

export const downloadOrderPreviewPdfAction = (orderId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_ORDER_PREVIEW_PDF,
            callType: API_DOWNLOAD_ORDER_PREVIEW_PDF
        },
        payload: orderId
    } as ApiCallAction<string>;
};
