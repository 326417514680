import {ApiCallAction} from "@thekeytechnology/framework-react";
import {UploadCsvFileWithAuctionIdDto} from "./upload-limit-bids";

export const API_UPLOAD_LIMIT_BIDS_TELEPHONE_LIST = "upload-limit-bids-telephone-list";


export const uploadLimitBidsTelephoneListAction = (auctionId: string, data: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPLOAD_LIMIT_BIDS_TELEPHONE_LIST,
            callType: API_UPLOAD_LIMIT_BIDS_TELEPHONE_LIST
        },
        payload: {
            auctionId,
            data
        }
    } as ApiCallAction<UploadCsvFileWithAuctionIdDto>;
};
