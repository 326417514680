import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";

export const POSITION_TYPE_UNSOLD_LOT = "unsold-lot"


export const LOT_TRANSFER_STATUS_TRANSFER = "to-transfer"
export const LOT_TRANSFER_STATUS_RETURN = "to-return"
export const LOT_TRANSFER_STATUS_TRANSFERRED = "transferred"

export interface UnsoldLotPosition extends OrderPosition {
    lotRef?: string
    amount: number
    todo: string
    lot?: EntityWrapper<AuctionManagerLot>
}
