import {Lot} from "./Lot";

export class LotForSaving {
    public static TYPE = Lot.TYPE;

    constructor(
        public description: { [lang: string]: string },
        public category: string | undefined,
        public status: string,
        public lotNumber: number,
        public condition: string,
        public limitPrice: number,
        public publicAttachments: string[],
        public privateAttachments: string[]
    ) {
    }
}
