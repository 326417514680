import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_SEND_SELLER_LIST_EMAIL = "send-seller-list-email";

export interface SendSellerListPayload extends Action {
    customerId: string,
    fileId: string,
    auctionId: string;
}

export const sendSellerListEmailAction = (customerId: string, fileId: string, auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SEND_SELLER_LIST_EMAIL,
            callType: API_SEND_SELLER_LIST_EMAIL
        },
        payload: {
            customerId,
            fileId,
            auctionId
        }
    } as ApiCallAction<SendSellerListPayload>;
};
