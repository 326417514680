import React from "react";
import {Login} from "../auth/components/Login";
import {InvitationScreen} from "../auth/components/invitation/InvitationScreen";
import {OnlyNonLoggedInRoute} from "@thekeytechnology/framework-react-ux";

export const NotLoggedInRoutes = () => {
    return (
        <>
            <OnlyNonLoggedInRoute exact
                                  path="/login" component={Login}/>
            <OnlyNonLoggedInRoute exact
                                  path="/einladung" component={InvitationScreen}/>

        </>
    );
};
