import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import {ItemInLot} from "./ItemInLot";
import {CategoryJustIdNameAndColor} from "../../item-category/model/CategoryJustIdNameAndColor";
import {CustomerJustIdAndName, PricingInformation} from "@thekeytechnology/auktionshaus-frontend-library";

export class Lot {
    public static TYPE = "lot";

    public static new() {
        return new EntityWrapper(
            undefined,
            new Lot(
                new CustomerJustIdAndName("", "", 0),
                [],
                new CategoryJustIdNameAndColor("", {}, ""),
                "",
                [],
                "",
                undefined,
                {},
                {discounts: [], discountedLimitPrice: undefined, limitPrice: undefined},
                [],
                [],
                []
            ));
    }

    constructor(
        public seller: CustomerJustIdAndName,
        public items: ItemInLot[],
        public category: CategoryJustIdNameAndColor,
        public status: string,
        public attributes: string[],
        public source: string,
        public lotNumber: number | undefined,
        public description: { [lang: string]: string },
        public pricingInformation: PricingInformation,
        public errors: string[],
        public publicAttachments: Array<EntityWrapper<TkFile>>,
        public privateAttachments: Array<EntityWrapper<TkFile>>,
    ) {
    }
}
