import React from "react";
import {useSelector} from "react-redux";
import {selectAuctioneerBids, selectAuctioneerLotHighestBid} from "../../../../selectors";
import {useTranslation} from "react-i18next";
import {AssignWinningBidButton} from "../../../action-bar/action-buttons/AssignWinningBidButton";
import {Bid} from "../../../../../bids/model/bid";
import moment from "moment";
import {DeleteBidButton} from "../../../action-bar/action-buttons/DeleteBidButton";

export const AuctioneerBidTable = () => {

    const {t} = useTranslation(["bid"]);

    const bids = useSelector(selectAuctioneerBids)
    const highestBid = useSelector(selectAuctioneerLotHighestBid)

    const winningBidId = bids.filter(bid => bid.entity.status === Bid.STATUS_WINNING)?.map(bid => bid.id)[0];
    const highlightedBidId = winningBidId ? winningBidId : highestBid ? highestBid.id : "";

    return <div className={"auctioneer-view-bids-table h-100"}>
        <div className={"auctioneer-table h-100"}>
            <div className={"auctioneer-table-row header-row"}>
                <div className={"bid-customer"}>
                    Bieter
                </div>
                <div className={"bid-time"}>
                    Abgegeben
                </div>
                <div className={"bid-value"}>
                    Gebotshöhe
                </div>
                <div className={"assign-winning-bid"}>
                    Bestätigen
                </div>
            </div>
            {bids.map(
                bid => {
                    const currentBidClass = highlightedBidId === bid.id ? " highest-bid " : "";
                    const bidCap = bid.entity.limitedAt ? "(max. " + bid.entity.limitedAt + " €)" : "";
                    return (
                        <div key={bid.id} className={"auctioneer-table-row" + currentBidClass}>
                            <div className={"bid-customer"}>
                                {[Bid.SOURCE_ONLINE, Bid.SOURCE_IMPORT].includes(bid.entity.source) ?
                                    bid.entity.customer.customerNumber && bid.entity.customer.customerNumber !== 1 ?
                                        bid.entity.customer.customerNumber :
                                        t("source." + Bid.SOURCE_AUCTIONEER).concat(" (").concat(bid.entity.manualCustomerId).concat(")")  :
                                    t("source." + Bid.SOURCE_AUCTIONEER)
                                }
                            </div>
                            <div className={"bid-time"}>
                                {moment(bid.entity.timestamp).format("HH:mm:ss")} Uhr
                            </div>
                            <div className={"bid-value"}>
                                {bid.entity.value} € <span className={"bid-cap"}>{bidCap}</span>
                            </div>
                            <div className={"assign-winning-bid"}>
                                <AssignWinningBidButton bid={bid} useIcon={true}/>
                                <DeleteBidButton bid={bid}/>
                            </div>

                        </div>
                    )
                }
            )}
        </div>
    </div>
};
