import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {AuctionManager} from "../components/AuctionManager";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {LimitBidScreen} from "../components/limit-bids-screen/LimitBidScreen";
import {SellerListsTable} from "../components/seller-lists/SellerListsTable";

export const AuctionPlanningRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/auktionen/:auctionId/planen" component={AuctionManager}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/auktionen/:auctionId/vorgebote" component={LimitBidScreen}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/auktionen/:auctionId/einlieferer" component={SellerListsTable}/>
        </>
    );
};
