import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FINALIZE_INVOICE = "finalize-invoice";

export const finalizeInvoiceAction = (orderId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FINALIZE_INVOICE,
            callType: API_FINALIZE_INVOICE
        },
        payload: orderId
    } as ApiCallAction<string>;
};
