import {ApiCallSucceededAction, EntityWrapper, isApiSuccess} from "@thekeytechnology/framework-react";
import {Action} from "redux";
import {NextAuctionDto} from "../model/next-auction-dto";
import {API_GET_NEXT_AUCTION} from "../actions/next-auction";
import {Auction} from "../../auction/model/auction";


export interface NextAuctionState {
    nextAuction?: EntityWrapper<Auction>;

}

export const initialState: NextAuctionState = {
    nextAuction: undefined,
};

export function nextAuctionReducer(state = initialState, action: Action): NextAuctionState {
    if (isApiSuccess(API_GET_NEXT_AUCTION)(action)) {
        const nextAuctionResponse = action as ApiCallSucceededAction<NextAuctionDto>;
        return {
            ...state,
            nextAuction: nextAuctionResponse.payload.nextAuction
        };
    }
    return state;
}
