import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "react-bootstrap";
import {
    API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS,
    downloadCatalogueDeliveryLabels
} from "../../actions/download-pdfs/download-catalogue-delivery-labels";

export const DownloadCatalogueDeliveryLabelsButton = () => {

    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS))

    return <Dropdown.Item disabled={apiCallState.inProgress} onClick={() => {
        dispatch(downloadCatalogueDeliveryLabels());

    }}>
        <FontAwesomeIcon className="mr-2"
                         icon={faAddressCard}/>
        Katalog Versandlabels
    </Dropdown.Item>;
}
