import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {ItemForSaving} from "../model/item-for-saving";

export const API_SAVE_ITEM = "save-item";



export const saveItemAction = (item: EntityWrapper<ItemForSaving>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_ITEM,
            callType: API_SAVE_ITEM
        },
        payload: item
    } as ApiCallAction<EntityWrapper<ItemForSaving>>;
};
