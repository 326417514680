import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Order, SHIPPING_STATUS_COMMISSIONED} from "../../model/Order";
import Modal from "react-bootstrap/Modal";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {useTranslation} from "react-i18next";
import {API_SHIP_ORDER, shipOrderAction} from "../../actions/ship-order";

interface OwnProps {
    order: EntityWrapper<Order>
    disabled?: boolean;
}

type Props = OwnProps

export const ShipOrderButton = ({
                                          order,
                                          disabled
                                      }: Props) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_SHIP_ORDER))

    const {t} = useTranslation(["order", "core"])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return order?.entity.shippingStatus === SHIPPING_STATUS_COMMISSIONED ?
        <>
            <button disabled={apiCallState.inProgress || disabled}
                    onClick={handleShow}
                    className="btn btn-info mr-2" type="button">
                Versand bestätigen
            </button>
            <Modal size="lg" show={show} onHide={handleClose} autoFocus={false} onShow={() => {
            }}>
                <Modal.Header>
                    Bestellung BR{order.entity.orderNumber} Versand bestätigen
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Hiermit wird die Bestellung auf "Versendet" gesetzt und die Trackingnummer hinterlegt. Der Kunder erhält eine E-Mail mit der Trackingnummer.
                    </p>
                    <Formik
                        initialValues={{
                            trackingNumber: "",
                        }}
                        enableReinitialize={true}
                        validationSchema={Yup.object().shape({
                            trackingNumber: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.tracking-number")})),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            if (values.trackingNumber && order.id) {
                                dispatch(shipOrderAction(
                                    order.id!,
                                    values.trackingNumber
                                ));
                            }
                            document.body.click()
                            setSubmitting(false);
                        }}
                    >
                        {formikState => (
                            <Form className="d-flex flex-column">
                                <ValidatedField formikState={formikState}
                                                type={"text"}
                                                name="trackingNumber"
                                                className="form-control default-input"
                                                label={"Trackingnummer"}
                                                required
                                />
                                <button disabled={formikState.isSubmitting} className="btn btn-success w-100 mt-3"
                                        type="submit">
                                    Ausführen
                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </> : null
}
