import React from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {DateTimeDisplay} from "@thekeytechnology/framework-react-ux";
import {AuctioneerBid} from "../../../bids/model/auctioneerBid";
import "./bid-info.scss";
import {CurrencyDisplay} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    bid: EntityWrapper<AuctioneerBid>
    handleClose: () => void;
    show: boolean
}

type Props = OwnProps;

export const BidInfo = ({
                            bid,
                            handleClose,
                            show
                        }: Props) => {

    const {t} = useTranslation(["bid", "customer"]);

    return <div className={"bid-info"}>
        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("entity.singular")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {t("entity.labels.value")}: <CurrencyDisplay amount={bid.entity.value}/> {bid.entity.limitedAt ?
                        <strong>({t("entity.limit-bid")}: <CurrencyDisplay
                            amount={bid.entity.limitedAt}/>)</strong> : ""}
                    </div>

                    <div>
                        {t("entity.labels.timestamp")}: <DateTimeDisplay dateTime={bid.entity.timestamp}/>
                    </div>

                    <div>
                        {t("customer:entity.singular")}: {bid.entity.customer.name}
                    </div>

                    <div>
                        {t("customer:entity.labels.customer-number")}: {bid.entity.customer.customerNumber}
                    </div>

                    <h3 className={"mt-3"}>{t("entity.labels.additional-info")}</h3>

                    <div>
                        {t("entity.labels.manual-customer-id")}: {bid.entity.manualCustomerId}
                    </div>

                    <div>
                        {t("entity.labels.tel")}: {bid.entity.tel}
                    </div>

                    <div>
                        {t("entity.labels.comment")}: {bid.entity.comment}
                    </div>

                    <div>
                        {t("entity.labels.source")}: {t("source." + bid.entity.source)}
                    </div>

                    <div>
                        {t("entity.labels.bid-type")}: {t("bid-type." + bid.entity.bidType)}
                    </div>
                </Modal.Body>
            </> : null}
        </Modal>
    </div>;
};
