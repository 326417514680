import {
    EntityWrapper,
    FilterTerm,
    PropertyFilter,
    selectCombinedApiState,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import {Item} from "../../model/item";
import {withTranslation, WithTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import {faEuroSign} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import {ItemForSaving} from "../../model/item-for-saving";
import {
    AsyncEntitySelectField,
    AttachmentField,
    ContentContainer,
    DateField,
    EditHeaderWithBackground,
    LoadingRow,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableRow,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "@thekeytechnology/framework-react-ux";
import {connect, useDispatch, useSelector} from "react-redux";
import {selectNextAuction, selectorNextItemNumber} from "../../selectors";
import * as Yup from "yup";
import CategorySelectField from "../../../item-category/components/edit/CategorySelectField";
import {StatusSelectField} from "./StatusSelectField";
import moment from "moment";
import {
    CheckboxField,
    CustomerJustIdAndName,
    EditFormHeading,
    LotTitleDisplay
} from "@thekeytechnology/auktionshaus-frontend-library";
import {CUSTOMER_CONTEXT_JUST_NAME_AND_ID, ENTITY_TYPE_CUSTOMER} from "../../../customers/model/customer";
import {API_GET_NEXT_AUCTION, getNextAuctionAction} from "../../actions/next-auction";
import {saveItemAction} from "../../actions/save-item";
import {NavLink} from "react-router-dom";
import {API_GET_NEXT_ITEM_NUMBER, getNextItemNumberAction} from "../../actions/next-item";
import {WysiwygWithSpellcheckField} from "../../../core/components/WysiwygWithSpellcheck";
import {AuctionSelectField} from "./AuctionSelectField";
import {CustomerWarnings} from "../../../warnings/components/CustomerWarnings";
import {LogDisplay} from "../../../log/components/LogDisplay";
import {useCustomEndpoint} from "../../../core/utils/use-custom-endpoint";
import {IntRequest} from "../../../core/model/custom-endpoints/IntRequest";
import {DeleteDuplicateItemsButton} from "../delete-duplicates/DeleteDuplicateItemsButton";
import {LotForItemEditor} from "../../../lots/model/LotForItemEditor";
import "./lots-with-item-table.scss";

type Props =
    WithSingleEntityFromPathProps<Item, ItemForSaving>
    & WithTranslation
    & WithSwitchableLanguageProps;

const ItemEditFormComponent = (props: Props) => {
    const {entity, t} = props;

    const nextItemNumber = useSelector(selectorNextItemNumber);
    const itemNumberCallState = useSelector(selectCombinedApiState(API_GET_NEXT_ITEM_NUMBER))
    const nextAuction = useSelector(selectNextAuction);
    const auctionIdCallState = useSelector(selectCombinedApiState(API_GET_NEXT_AUCTION))

    const nextFreeInventoryNumberEndpoint = useCustomEndpoint<number>("/items/get-next-free-inventory-number")


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNextItemNumberAction);
        dispatch(getNextAuctionAction);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // @ts-ignore
    return entity && itemNumberCallState.succeeded && nextItemNumber && auctionIdCallState.succeeded ? (
        <>
            <EditFormHeading entity={entity} label={t("entity.singular")}/>
            <Formik
                initialValues={{
                    inventoryNumber: entity.entity.inventoryNumber ? entity.entity.inventoryNumber
                        : nextItemNumber,
                    description: entity.entity.description["de"],
                    descriptionEn: entity.entity.description["en"],
                    condition: entity.entity.condition,
                    category: entity.entity.category,
                    limitPrice: entity.entity.limitPrice,
                    seller: entity.entity.seller,
                    tags: entity.entity.tags,
                    publicAttachments: entity.entity.publicAttachments,
                    privateAttachments: entity.entity.privateAttachments,
                    status: entity.entity.status,
                    checkinDate: entity?.entity.checkinDate ? entity?.entity.checkinDate : moment().format('YYYY-MM-DD'),
                    plannedAuction: entity?.entity.plannedAuction ? entity?.entity.plannedAuction : nextAuction,
                    transferCount: entity.entity.transferCount,
                    p86: entity.entity.p86,

                }}
                validationSchema={Yup.object().shape({
                    inventoryNumber: Yup.number().required(t("core:forms.required-field", {fieldName: t("entity.labels.inventory-number")})),
                    description: Yup.string()
                        .test("description", t("entity.labels.description-error"), function (val) {
                            if (!val || (val.length < 100 && val.indexOf("#") < 0)) {
                                return this.createError({message: t("entity.labels.description-error")})
                            }
                            const div = document.createElement("div");
                            div.innerHTML = val;
                            const text = div.textContent || div.innerText || "";
                            if (text.indexOf("#") > 58) {
                                return this.createError({message: "Bitte als Titel maximal 58 Zeichen angeben."})
                            }
                            return true;
                        }),
                    checkinDate: Yup.date().required(t("core:forms.required-field", {fieldName: t("entity.labels.checkin-date")})),
                    rate: Yup.number().min(1, t("entity.labels.rate-error")).max(4, t("entity.labels.rate-error")),
                    seller: Yup.object().required(t("core:forms.required-field", {fieldName: t("entity.labels.seller")})),
                    category: Yup.object().required(t("core:forms.required-field", {fieldName: t("entity.labels.category")})),
                    limitPrice: Yup.number().min(20, t("entity.labels.rate-error")).required(t("core:forms.required-field", {fieldName: t("entity.labels.limit-price")}))
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper(entity.id,
                        new ItemForSaving(
                            values.inventoryNumber,
                            {
                                ...(entity ? entity.entity.description : {}),
                                de: values.description,
                                en: values.descriptionEn
                            },
                            values.condition,
                            values.category ? values.category.id : undefined,
                            values.limitPrice,
                            values.seller!.id!,
                            values.tags,
                            values.publicAttachments.map(a => a.id as string),
                            values.privateAttachments.map(a => a.id as string),
                            true,
                            values.status,
                            moment(values.checkinDate).format("YYYY-MM-DD"),
                            values.plannedAuction ? values.plannedAuction.id : undefined,
                            values.transferCount,
                            values.p86
                        )
                    );
                    dispatch(saveItemAction(saving));
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                <LotTitleDisplay
                                    description={entity.entity.description["de"]}/> :
                                t("core:edit-header.heading-empty")}
                        >
                            {entity.id && entity?.entity.inventoryNumber ?
                                <DeleteDuplicateItemsButton itemId={entity.id!}/> : null}

                            <button type="submit" disabled={formikState.isSubmitting}
                                    className="btn btn-primary mr-3"
                            >
                                {entity.id ?
                                    t("core:save-and-back-buttons.edit") :
                                    t("core:save-and-back-buttons.create")}
                            </button>
                            {/*<button type="submit" disabled={formikState.isSubmitting}*/}
                            {/*        className="btn btn-primary mr-3">*/}
                            {/*    {entity.id ?*/}
                            {/*        t("core:save-and-back-buttons.edit") + " und Neu" :*/}
                            {/*        t("core:save-and-back-buttons.create") + " und Neu"*/}
                            {/*    }*/}
                            {/*</button>*/}
                            <NavLink to={"/objekte/"}>
                                <button type="button"
                                        className="btn btn-secondary">{t("core:save-and-back-buttons.cancel")}</button>
                            </NavLink>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <CustomerWarnings customerId={formikState.values.seller?.id}/>

                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type={"number"}
                                                placeholder={t("entity.labels.inventory-number")}
                                                name="inventoryNumber"
                                                className="form-control default-input"
                                                label={t("entity.labels.inventory-number")}
                                                required
                                                onKeyDown={(event: React.KeyboardEvent) => {
                                                    if (event.keyCode === 9) {
                                                        nextFreeInventoryNumberEndpoint.call(result => {
                                                            formikState.setFieldValue("inventoryNumber", result)
                                                        }, {value: formikState.values.inventoryNumber} as IntRequest)
                                                    }
                                                }}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={WysiwygWithSpellcheckField}
                                                name="description"
                                                className="form-control default-input"
                                                label={t("entity.labels.description")}
                                                required
                                />
                            </div>
                            <div className={"form-group row"}>
                                <ValidatedField formikState={formikState}
                                                type={"text"}
                                                name="descriptionEn"
                                                className="form-control default-input"
                                                label={t("entity.labels.description-en")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AsyncEntitySelectField}
                                                placeholder={t("entity.labels.seller")}
                                                name="seller"
                                                className="form-control default-input"
                                                label={t("entity.labels.seller")}
                                                required
                                                listRenderer={(value: EntityWrapper<CustomerJustIdAndName>) => value.entity?.customerNumber + " " + value.entity?.name}
                                                shownEntityType={ENTITY_TYPE_CUSTOMER}
                                                shownEntityTypeProperties={["entity.customerNumber", "entity.personalData.firstName", "entity.personalData.lastName"]}
                                                customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                                                    if ("entity.customerNumber" === property) {
                                                        const intVal = parseInt(inputValue);
                                                        if (intVal) {
                                                            return new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, intVal))
                                                        }
                                                    }
                                                    return new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))
                                                }}
                                                shownEntityTypeContext={CUSTOMER_CONTEXT_JUST_NAME_AND_ID}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={DateField}
                                                placeholder={t("entity.labels.checkin-date")}
                                                name="checkinDate"
                                                className="form-control default-input"
                                                label={t("entity.labels.checkin-date")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={StatusSelectField}
                                                placeholder={t("entity.labels.status")}
                                                name="status"
                                                className="form-control default-input"
                                                label={t("entity.labels.status")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AuctionSelectField}
                                                placeholder={t("entity.labels.planned-auction")}
                                                name="plannedAuction"
                                                className="form-control default-input"
                                                label={t("entity.labels.planned-auction")}
                                                required
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="number"
                                                placeholder={t("entity.labels.transfer-count")}
                                                name="transferCount"
                                                min={0}
                                                className="form-control default-input"
                                                label={t("entity.labels.transfer-count")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.rate")}
                                                name="condition"
                                                className="form-control default-input"
                                                label={t("entity.labels.rate")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={CategorySelectField}
                                                name="category"
                                                className="form-control default-input"
                                                label={t("entity.labels.category")}
                                                required
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                icon={faEuroSign}
                                                type="number"
                                                placeholder={t("entity.labels.limit-price")}
                                                name="limitPrice"
                                                min="20"
                                                step="1"
                                                className="form-control default-input"
                                                label={t("entity.labels.limit-price")}
                                                required
                                />
                            </div>

                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.tags")}
                                                name="tags"
                                                className="form-control default-input"
                                                label={t("entity.labels.tags")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AttachmentField}
                                                placeholder={t("entity.labels.public-attachments")}
                                                name="publicAttachments"
                                                className="form-control default-input"
                                                label={t("entity.labels.public-attachments")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AttachmentField}
                                                placeholder={t("entity.labels.private-attachments")}
                                                name="privateAttachments"
                                                className="form-control default-input"
                                                label={t("entity.labels.private-attachments")}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={CheckboxField}
                                                name="p86"
                                                className="form-control default-input"
                                                label={t("entity.labels.p86")}
                                />
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>

            {/*Lots*/}
            <Table itemCount={entity.entity.lots.length}
                   className={"lots-with-item-table"}>
                <TableHeader>
                    <TableColumn>{t("auction:entity.singular")}</TableColumn>
                    <TableColumn>{t("lot:entity.labels.lot-number-short")}</TableColumn>
                    <TableColumn>{t("lot:entity.labels.status")}</TableColumn>
                    <TableColumn>{t("lot:entity.labels.order-status")}</TableColumn>
                    <TableColumn>{t("lot:entity.labels.settlement-status")}</TableColumn>
                    <TableColumn>{t("lot:entity.labels.transfer-status")}</TableColumn>
                </TableHeader>
                <TableBody>
                    {entity.entity.lots.map((lot: EntityWrapper<LotForItemEditor>) => {
                        return <TableRow key={lot.id}>
                            <TableColumn>
                                {lot.entity.auction ? <NavLink
                                        to={"/auktionen/" + lot.entity.auction.id + "/planen"}>{lot.entity.auction.entity.auctionNumber}
                                    </NavLink>
                                    : null}
                            </TableColumn>
                            <TableColumn>
                                {lot.entity.lotNumber}
                            </TableColumn>
                            <TableColumn>
                                {t("lot:status." + lot.entity.status)}
                            </TableColumn>
                            <TableColumn>
                                {t("lot:order-status." + lot.entity.orderStatus)}
                            </TableColumn>
                            <TableColumn>
                                {t("lot:settlement-status." + lot.entity.settlementStatus)}
                            </TableColumn>
                            <TableColumn>
                                {t("lot:transfer-status." + lot.entity.transferStatus)}
                            </TableColumn>
                        </TableRow>;
                    })}
                </TableBody>
            </Table>

            {entity.id ? <LogDisplay entityId={entity.id}/> : null}
        </>
    ) : <LoadingRow/>;
}


export const ItemEditForm = connect<{}, {}, {}>(
    null,
    {})(WithSingleEntityFromPath<{}, Item, ItemForSaving>(
    WithSwitchableLanguage<any>(withTranslation(["item", "core", "lot", "auction"])(ItemEditFormComponent)),
    Item.TYPE,
    "itemId",
    Item.new,
    undefined,
    ItemForSaving.TYPE) as any);
