import React from "react";
import "./order-table.scss";
import {ApiCallState, EntityWrapper, Pagination, SortQuery} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {
    AboveTableContainer,
    ActionTableColumn,
    AsyncEntityTableBody,
    DateTimeDisplay,
    Table,
    TableColumn,
    TableHeader,
    TableRow,
    UnconnectedPaginationInfo
} from "@thekeytechnology/framework-react-ux";
import {CurrencyDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {TableOrder} from "../model/TableOrder";
import {PaginatedEndpointSortableTableHeaderColumn} from "../../paginated-endpoints/components/PaginatedEndpointSortableTableHeaderColumn";
import {CustomActionLink} from "../../core/components/CustomActionLink/CustomActionLink";
import {useDispatch} from "react-redux";
import {refreshAllInvoicesAction} from "../actions/refresh-all-invoices";
import {DownloadOrdersForDatevButton} from "./download-orders-for-datev/DownloadOrdersForDatevButton";

interface OwnProps {
    orders: Array<EntityWrapper<TableOrder>>;
    apiCallState: ApiCallState;
    pagination: Pagination;
    sortQuery: SortQuery;
    setSortQuery: (sortQuery: SortQuery) => void;
}

type Props = OwnProps;

export const OrderTable = ({
                               apiCallState,
                               orders,
                               pagination,
                               sortQuery,
                               setSortQuery
                           }: Props) => {

    const entities = orders
    const entityApiState = apiCallState
    const dispatch = useDispatch();

    const {t} = useTranslation(["order", "core", "customer"])

    return (
        <>
            <AboveTableContainer>
                <UnconnectedPaginationInfo pagination={pagination} className="mr-auto"/>
                <NavLink className="mr-3"
                         to={"/bestellungen/mahnungen"}>
                    <button type="button" className="btn btn-primary">
                        Mahnungen
                    </button>
                </NavLink>
                <NavLink className="mr-3"
                                   to={"/bestellungen/new/edit"}>
                <button type="button" className="btn btn-primary">
                    Erstellen
                </button>
            </NavLink>
                <button type="button" className="btn btn-primary" onClick={() => {
                    dispatch(refreshAllInvoicesAction)
                }}>
                    Rechnungen aktualisieren
                </button>
                <DownloadOrdersForDatevButton/>
            </AboveTableContainer>
            <Table className="order-table" itemCount={entities.length}>
                <TableHeader>
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.createdAt"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >{t("entity.labels.created-at")}</PaginatedEndpointSortableTableHeaderColumn>
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.orderNumber"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >{t("entity.labels.order-number")}</PaginatedEndpointSortableTableHeaderColumn>
                    <TableColumn>{t("entity.labels.customer")}</TableColumn>
                    <TableColumn>{t("customer:entity.labels.customer-number")}</TableColumn>
                    <TableColumn>{t("entity.labels.sum")}</TableColumn>
                    <TableColumn>{t("entity.labels.status")}</TableColumn>
                    <TableColumn>{t("entity.labels.invoice-status")}</TableColumn>
                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </TableHeader>

                <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={order => {

                    return (
                        <TableRow key={order.id}>
                            <TableColumn>
                                <DateTimeDisplay dateTime={order.entity.createdAt}/>
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {order.entity.orderNumber}
                            </TableColumn>
                            <TableColumn>
                                {order.entity.customer.name}
                            </TableColumn>
                            <TableColumn>
                                {order.entity.customer.customerNumber}
                            </TableColumn>
                            <TableColumn>
                                <CurrencyDisplay amount={order.entity.sum}/>
                            </TableColumn>
                            <TableColumn>
                                {t("status." + order.entity.status)}
                            </TableColumn>
                            <TableColumn>
                                {order.entity.invoiceStatus ? t("receipt-list.receipt-status." + order.entity.invoiceStatus) : ""}
                            </TableColumn>
                            <ActionTableColumn isDisabled={false}>
                                <>
                                    <CustomActionLink to={"/bestellungen/" + order.id + "/edit"}
                                                tooltip={t("core:actions.edit")}
                                                icon={faPencilAlt}/>
                                </>
                            </ActionTableColumn>
                        </TableRow>
                    );
                }}/>
            </Table>
        </>
    );
};
