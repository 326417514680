import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_ORDERS_FOR_DATEV_CSV = "download-orders-for-datev-csv";

export interface DownloadOrdersForDatevCsvPayload extends Action {
    month: number;
    year: number;
}

export const downloadOrdersForDatevCsvAction = (month: number, year: number) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_ORDERS_FOR_DATEV_CSV,
            callType: API_DOWNLOAD_ORDERS_FOR_DATEV_CSV
        },
        payload: {
            month,
            year
        }
    } as ApiCallAction<DownloadOrdersForDatevCsvPayload>;
};