import {EntityWrapper} from "@thekeytechnology/framework-react";
import {
    Address,
    AttachedReceipt,
    CustomerJustIdAndName,
    OrderHistory,
    OrderPosition
} from "@thekeytechnology/auktionshaus-frontend-library";
import moment from "moment";

export const ENTITY_TYPE_SETTLEMENT = "settlement";

export const SETTLEMENT_STATUS_OPEN = "open"
export const SETTLEMENT_STATUS_CANCELLED = "cancelled"
export const SETTLEMENT_STATUS_INVOICED = "invoiced"

export const ENTITY_NEW_SETTLEMENT_FACTORY = () => new EntityWrapper<Settlement>(
    undefined,
    {
        billingAddress: {postalCode: "", name: "", houseNumber: "", country: "de", city: "", addition: "", street: ""},
        status: SETTLEMENT_STATUS_OPEN,
        positions: [],
        attachedReceipts: [],
        settlementHistory: [],
        attributes: [],
        createdAt: moment().toISOString(),
        sum: 0,
    }
);

export interface Settlement {
    settlementNumber?: number,
    customer?: EntityWrapper<CustomerJustIdAndName>,
    positions: OrderPosition[],
    billingAddress: Address,
    checkNumber?: string,
    status: string,
    comment?: string,
    attachedReceipts: AttachedReceipt[],
    settlementHistory: OrderHistory[],
    attributes: string[],
    createdAt: string,
    sum: number,
    auctionRef?: string
}

/*
settlementNumber: Int,
                                customer: EntityWrapper[Customer],
                                checkNumber: Option[String] = None,
                                positions: List[OrderPosition] = List(),
                                billingAddress: Address = Address(),
                                status: String = Settlement.STATUS_OPEN,
                                comment: Option[String] = None,
                                attachedReceipts: List[AttachedReceipt] = List(),
                                orderHistory: List[OrderHistory] = List()
 */
