import {
    ApiCallState,
    ComplexFilter,
    EntityWrapper,
    fetchSingleEntityAction,
    Pagination,
    selectSingleEntity,
    SortQuery,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React, {useEffect} from "react";
import {
    AboveTableContainer,
    AsyncEntityTableBody,
    ContentContainer,
    EntityTableHeader,
    HeadingContainer,
    PaddedContainer,
    SelectionTableColumn,
    SimpleHeader,
    Table,
    TableColumn,
    TableRow,
    UnconnectedPaginationInfo
} from "@thekeytechnology/framework-react-ux";
import {useTranslation} from "react-i18next";
import "./seller-lists-table.scss";
import "./../../../../assets/scss/table.scss"
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {useDispatch, useSelector} from "react-redux";
import {usePaginatedEndpointFilters} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {usePaginatedEndpoint} from "../../../paginated-endpoints/hooks/use-paginated-endpoint";
import {selectReloadView} from "../../selector";
import {useRouteMatch} from "react-router";
import {CustomerForSellerListsView} from "../../../customers/model/customer-for-seller-lists-view";
import {PaginatedEndpointSortableTableHeaderColumn} from "../../../paginated-endpoints/components/PaginatedEndpointSortableTableHeaderColumn";
import {faDownload, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GenerateSellerListsButton} from "./GenerateSellerListsButton";
import {NavLink} from "react-router-dom";
import {PageTitle} from "../../../core/components/PageTitle";
import {Card} from "react-bootstrap";
import {ValidationErrorDisplay} from "../errordisplay/ValidationErrorDisplay";
import {sendSellerListEmailAction} from "../../actions/seller-lists/send-seller-list-email";
import {SendBulkSellerListsButton} from "./SendBulkSellerListsButton";

interface OwnProps {
    apiCallState: ApiCallState;
    isInPlanning: boolean;
    isConcluded: boolean;
    isLive: boolean;
    status: string;
    pagination: Pagination;
    sortQuery: SortQuery;
    setSortQuery: (sortQuery: SortQuery) => void;
}

type Props = WithSelectionsProps<string> & OwnProps;

const SellerListsTableComponent = ({
                                       selectionModel
                                   }: Props) => {

    const {t} = useTranslation(["customer", "auctionPlanning", "lot"])
    const dispatch = useDispatch();

    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]

    useEffect(() => {
        dispatch(fetchSingleEntityAction(ENTITY_TYPE_AUCTION)(auctionId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionId])
    const auction = useSelector(selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION))

    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "auction-manager-seller-lists-table"
    )

    const getBaseFilters = () => {
        return [new ComplexFilter("sellerList.auctionId", {"auctionId": auctionId})
        ]
    }

    const {
        resolved,
        reload,
        setFilters,
        apiCallState,
        setSortQuery,
        sortQuery
    } = usePaginatedEndpoint<CustomerForSellerListsView>(
        "/auction-planning/seller-lists/get-sellers",
        undefined,
        getBaseFilters(),
        endpointFilters.getFilters().flatMap(f => f.filters)
    )


    // This is a bit of a hack. When we use some action to update data, this value will be updated on apiSuccess,
    // which we then use here to trigger an entity reload
    const lastUpdate = useSelector(selectReloadView);
    useEffect(() => {
        if (lastUpdate) {
            reload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUpdate]);

    return auction ?
        <>
            <PageTitle title={"Auktion " + auction.entity.auctionNumber}/>
            <HeadingContainer>
                <SimpleHeader
                    heading={t("auctionPlanning:seller-lists.heading", {
                        auctionNumber: auction.entity.auctionNumber,
                    })}/>
            </HeadingContainer>
            <PaddedContainer className={"mb-4"}>
                <Card body>
                    <div className="d-flex">
                        <GenerateSellerListsButton/>
                        <SendBulkSellerListsButton selectionModel={selectionModel}/>
                    </div>
                </Card>
                <ValidationErrorDisplay/>
            </PaddedContainer>
            <ContentContainer>
                <AboveTableContainer>
                    <UnconnectedPaginationInfo pagination={resolved.pagination} className="mr-auto"/>
                </AboveTableContainer>

                <Table itemCount={resolved.entities.length}
                       className={"seller-lists-table"}>
                    <EntityTableHeader entities={resolved.entities} selectionModel={selectionModel}>
                        <PaginatedEndpointSortableTableHeaderColumn
                            property={"entity.customerNumber"}
                            sortQuery={sortQuery}
                            setSortQuery={setSortQuery}
                        >
                            {t("entity.labels.customer-number")}
                        </PaginatedEndpointSortableTableHeaderColumn>

                        <PaginatedEndpointSortableTableHeaderColumn
                            property={"entity.personalData.firstName"}
                            sortQuery={sortQuery}
                            setSortQuery={setSortQuery}
                        >
                            {t("entity.labels.personal-data.first-name")}
                        </PaginatedEndpointSortableTableHeaderColumn>

                        <PaginatedEndpointSortableTableHeaderColumn
                            property={"entity.personalData.lastName"}
                            sortQuery={sortQuery}
                            setSortQuery={setSortQuery}
                        >
                            {t("entity.labels.personal-data.last-name")}
                        </PaginatedEndpointSortableTableHeaderColumn>

                        <PaginatedEndpointSortableTableHeaderColumn
                            property={"entity.personalData.email"}
                            sortQuery={sortQuery}
                            setSortQuery={setSortQuery}
                        >
                            {t("entity.labels.personal-data.email")}
                        </PaginatedEndpointSortableTableHeaderColumn>

                        <TableColumn>{t("seller-list.lot-count")}</TableColumn>
                        <TableColumn>{t("seller-list.lot-sum")}</TableColumn>
                        <TableColumn>{t("lot:entity.labels.actions")}</TableColumn>
                    </EntityTableHeader>
                    <AsyncEntityTableBody<CustomerForSellerListsView>
                        apiCallState={apiCallState}
                        items={resolved.entities}
                        renderItem={(customer: EntityWrapper<CustomerForSellerListsView>) => {
                            return <TableRow key={customer.id}>
                                <SelectionTableColumn<string> item={customer.id as string}
                                                              selectionModel={selectionModel}/>
                                <TableColumn className="d-flex">
                                    <NavLink
                                        to={`/kunden/${customer.id}/edit`}>{customer.entity.customerNumber}</NavLink>
                                </TableColumn>
                                <TableColumn className="d-flex">
                                    {customer.entity.personalData.firstName}
                                </TableColumn>
                                <TableColumn className="d-flex">
                                    {customer.entity.personalData.lastName}
                                </TableColumn>
                                <TableColumn className="d-flex">
                                    {customer.entity.personalData.email}
                                </TableColumn>
                                <TableColumn className="d-flex">
                                    {customer.entity.lotCount}
                                </TableColumn>
                                <TableColumn className="d-flex">
                                    {customer.entity.sum}
                                </TableColumn>
                                <TableColumn className="d-flex">
                                    {customer.entity.existingFile ?
                                        <a href={customer.entity.existingFile.entity.url} download target={"_blank"}
                                           className={"mr-2"}
                                           rel={"noreferrer"}>
                                            <FontAwesomeIcon icon={faDownload}/>
                                        </a> : null}
                                    {customer.id && customer.entity.existingFile?.id && customer.entity.personalData.email ?
                                        <button
                                            type={"button"}
                                            role={"link"}
                                            className={"btn-link"}
                                            onClick={() => {
                                                dispatch(sendSellerListEmailAction(
                                                    customer.id!,
                                                    customer.entity.existingFile!.id!,
                                                    auctionId
                                                ))
                                            }}>
                                            <FontAwesomeIcon icon={faEnvelope}/>
                                        </button> : null}
                                </TableColumn>
                            </TableRow>;
                        }}/>
                </Table>
            </ContentContainer>


        </> : null;
};

export const SellerListsTable = WithSelections<OwnProps, string>(
    SellerListsTableComponent
);
