import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Item} from "../../model/item";
import {
    AsyncEntitySelectFilter,
    CollapsibleFilters,
    NumberFilter,
    TextFilter
} from "@thekeytechnology/framework-react-ux";
import {Customer, ENTITY_TYPE_CUSTOMER} from "../../../customers/model/customer";
import {
    EntityWrapper,
    FilterTerm,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {ItemCategory} from "../../../item-category/model/itemCategory";
import {PersonalData} from "@thekeytechnology/auktionshaus-frontend-library";

type Props = WithTranslation & WithSwitchableLanguageProps;

const ItemsFilterComponent = ({
                                  t,
                                  currentLanguage
                              }: Props) => {
    return (
        <div className={"tk-items-filter"}>
            <CollapsibleFilters
                entityType={Item.TYPE}
                filterKeys={[
                    "entity.inventoryNumber",
                    "entity.seller.id",
                    "entity.plannedAuction.id",
                    "entity.status",
                    `entity.description.${currentLanguage}`,
                    "entity.category.id",
                    "entity.minLimitPrice",
                    "entity.maxLimitPrice"
                ]}>
                <NumberFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.inventory-number")
                    })}
                    entityType={Item.TYPE}
                    property={"entity.inventoryNumber"}
                    step={1}
                />
                <AsyncEntitySelectFilter<Customer>
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.seller")
                    })}
                    shownEntityType={ENTITY_TYPE_CUSTOMER}
                    shownEntityTypeProperties={["entity.personalData.firstName", "entity.personalData.lastName"]}
                    listRenderer={(item: EntityWrapper<Customer>) => PersonalData.fullName(item.entity.personalData)}
                    filteredEntityType={Item.TYPE}
                    property="entity.sellerRef"
                />
                <TextFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("lot:entity.labels.short-description")
                    })}
                    entityType={Item.TYPE}
                    property={`entity.description.${currentLanguage}`}/>
                <AsyncEntitySelectFilter<Auction>
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.auction")
                    })}
                    shownEntityType={ENTITY_TYPE_AUCTION}
                    shownEntityTypeProperties={["entity.auctionNumber"]}
                    listRenderer={(item: EntityWrapper<Auction>) => item.entity.auctionNumber.toString()}
                    filteredEntityType={Item.TYPE}
                    filterType={FilterTerm.TYPE_STRING}
                    filterOperation={FilterTerm.OPERATION_EQ}
                    property="entity.plannedAuctionRef"
                />

                <AsyncEntitySelectFilter<ItemCategory>
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.category")
                    })}
                    shownEntityType={ItemCategory.TYPE}
                    shownEntityTypeProperties={[`entity.name.${currentLanguage}`]}
                    listRenderer={(item: EntityWrapper<ItemCategory>) => item.entity.name[currentLanguage]}
                    filteredEntityType={Item.TYPE}
                    filterType={FilterTerm.TYPE_INT}
                    filterOperation={FilterTerm.OPERATION_EQ}
                    property="entity.categoryRef"
                />
                <NumberFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.min-price")
                    })}
                    entityType={Item.TYPE}
                    filterOperation={FilterTerm.OPERATION_GTE}
                    filterKey={"entity.minLimitPrice"}
                    property={"entity.limitPrice"}
                    step={0.01}
                    min={0}
                />
                <NumberFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.max-price")
                    })}
                    entityType={Item.TYPE}
                    filterOperation={FilterTerm.OPERATION_LTE}
                    filterKey={"entity.maxLimitPrice"}
                    property={"entity.limitPrice"}
                    step={0.01}
                    min={0}
                />
            </CollapsibleFilters>
        </div>
    );
};

export const ItemFilters = WithSwitchableLanguage<any>(withTranslation("item")(ItemsFilterComponent));
