import {EntityWrapper, WithSwitchableLanguage, WithSwitchableLanguageProps} from "@thekeytechnology/framework-react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import React from "react";
import {
    ContentContainer,
    EditHeaderWithBackground,
    LoadingRow,
    SaveAndBackButtons,
    ValidatedField,
    WithSingleEntityFromPath,
    WithSingleEntityFromPathProps
} from "@thekeytechnology/framework-react-ux";
import "../item-category-table.scss"
import {CatalogPartSelectField} from "./CatalogPartSelectField";
import {ItemCategory} from "../../model/itemCategory";
import * as Yup from "yup";
import {ColorSelectField, EditFormHeading} from "@thekeytechnology/auktionshaus-frontend-library";
import {useDispatch} from "react-redux";
import {saveItemCategoryAction} from "../../actions/save-item-category";

type Props = WithSingleEntityFromPathProps<ItemCategory, ItemCategory> & WithTranslation & WithSwitchableLanguageProps;

const ItemsCategoryEditFormComponent = (props: Props) => {
    const {entity, t, currentLanguage} = props;
    const dispatch = useDispatch()

    return entity ? (
        <>
            <EditFormHeading entity={entity} label={t("entity.singular")}/>

            <Formik
                initialValues={{
                    name: entity.entity.name["de"],
                    nameEn: entity.entity.name["en"],
                    color: entity.entity.color,
                    orderIndex: entity.entity.orderIndex,
                    catalogPart: entity.entity.catalogPart
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.name")})),
                    orderIndex: Yup.number().required(t("core:forms.required-field", {fieldName: t("entity.labels.order-index")})),
                    catalogPart: Yup.string().required(t("core:forms.required-field", {fieldName: t("entity.labels.catalog-part")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    const saving = new EntityWrapper(entity.id,
                        new ItemCategory(
                            {
                                ...entity.entity.name,
                                "de": values.name,
                                "en": values.nameEn
                            },
                            values.color,
                            values.orderIndex,
                            values.catalogPart
                        )
                    );
                    dispatch(saveItemCategoryAction(saving))
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <EditHeaderWithBackground
                            heading={entity.id ?
                                entity.entity.name[currentLanguage] :
                                t("core:edit-header.heading-empty")}
                        >
                            <SaveAndBackButtons isSubmitting={formikState.isSubmitting} entity={entity}
                                                backPath="/item-categories"/>
                        </EditHeaderWithBackground>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="number" placeholder={t("entity.labels.order-index")}
                                                name="orderIndex"
                                                className="form-control default-input"
                                                label={t("entity.labels.order-index")}
                                                helpText={t("entity.labels.order-index-help-text")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text" placeholder={t("entity.labels.color")}
                                                name="color"
                                                className="form-control default-input"
                                                component={ColorSelectField}
                                                label={t("entity.labels.color")}
                                                requiered
                                />
                            </div>

                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.name-placeholder")}
                                                name="name"
                                                className="form-control default-input"
                                                label={t("entity.labels.name")}
                                                required/>
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("entity.labels.name-en")}
                                                name="nameEn"
                                                className="form-control default-input"
                                                label={t("entity.labels.name-en")}
                                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={CatalogPartSelectField}
                                                placeholder={t("entity.labels.catalogue-part")}
                                                name="catalogPart"
                                                className="form-control default-input"
                                                label={t("entity.labels.catalogue-part")}
                                                required/>
                            </div>
                        </ContentContainer>
                    </Form>
                )}
            </Formik>
        </>
    ) : <LoadingRow/>;
};

export const ItemCategoryEditForm = WithSingleEntityFromPath<{}, ItemCategory, ItemCategory>(
    WithSwitchableLanguage<any>(withTranslation("itemCategory")(ItemsCategoryEditFormComponent)),
    ItemCategory.TYPE,
    "itemCategoryId", ItemCategory.new);
