import React from "react";
import {ItemModuleRoutes} from "../items/routes/ItemModuleRoutes";
import {DashBoard} from "../dashboard/components/Dashboard";
import {AuctionRoutes} from "../auction/routes/AuctionRoutes";
import {ItemCategoryModuleRoutes} from "../item-category/routes/ItemCategoryModuleRoutes";
import {CustomersModuleRoutes} from "../customers/routes/CustomersModuleRoutes";
import {AuctionPlanningRoutes} from "../auction-planning/routes/AuctionPlanningRoutes";
import {OrderModuleRoutes} from "../orders/routes/OrderModuleRoutes";
import {AuctioneerViewModuleRoutes} from "../auctioneer-view/routes/AuctioneerViewModuleRoutes";
import {LotModuleRoutes} from "../lots/routes/LotModuleRoutes";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {SettlementModuleRoutes} from "../settlements/routes/settlement-module-routes";
import {EmailModuleRoutes} from "../emails/components/EmailModuleRoutes";
import {UsersModuleRoutes} from "../users/UsersModuleRoutes";
import {LogModuleRoutes} from "../log/routes/LogModuleRoutes";
import {AuctionAppSettingsModuleRoutes} from "../auction-app-settings/routes/AuctionAppSettingsModuleRoutes";

export const LoggedInRoutes = () => {
    return (
        <>
            <PrivateRoute exact path="/" component={DashBoard}/>
            <ItemModuleRoutes/>
            <ItemCategoryModuleRoutes/>
            <CustomersModuleRoutes/>
            <AuctionRoutes/>
            <AuctionPlanningRoutes/>
            <OrderModuleRoutes/>
            <AuctioneerViewModuleRoutes/>
            <LotModuleRoutes/>
            <SettlementModuleRoutes/>
            <EmailModuleRoutes/>
            <UsersModuleRoutes/>
            <LogModuleRoutes/>
            <AuctionAppSettingsModuleRoutes/>
        </>
    );
};
