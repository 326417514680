import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import {ItemInLot} from "./ItemInLot";
import {CategoryJustIdNameAndColor} from "../../item-category/model/CategoryJustIdNameAndColor";
import {AuctioneerBid} from "../../bids/model/auctioneerBid";
import {CustomerJustIdAndName, PricingInformation} from "@thekeytechnology/auktionshaus-frontend-library";

export class AuctionManagerLot {
    public static TYPE = "lot";
    public static CONTEXT = "auction-manager"

    constructor(
        public seller: CustomerJustIdAndName,
        public items: ItemInLot[],
        public category: CategoryJustIdNameAndColor,
        public status: string,
        public attributes: string[],
        public source: string,
        public lotNumber: number | undefined,
        public description: { [lang: string]: string },
        public pricingInformation: PricingInformation,
        public errors: string[],
        public publicAttachments: Array<EntityWrapper<TkFile>>,
        public privateAttachments: Array<EntityWrapper<TkFile>>,
        public bids: Array<EntityWrapper<AuctioneerBid>>,
        public winningBid?: EntityWrapper<AuctioneerBid>,
        public buyer?: CustomerJustIdAndName,
        public orderStatus?: String,
        public settlementStatus?: String,
        public transferStatus?: String
    ) {
    }
}
