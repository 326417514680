import {createSelector} from "reselect";
import {AppState} from "../../reducers";
import {SettlementModuleState} from "../reducers";
import {SettlementNumberState} from "../reducers/currentSettlementNumber";

export const selectSettlementModuleState = (state: AppState) => state.settlement;

export const selectSettlementNumberState = createSelector(
    selectSettlementModuleState,
    (moduleState: SettlementModuleState) =>
        moduleState.settlementNumber
);

export const selectNextSettlementNumber = createSelector(
    selectSettlementNumberState,
    (numberState: SettlementNumberState) =>
        numberState.currentSettlementNumber !== undefined ? (numberState.currentSettlementNumber + 1) : undefined
);
