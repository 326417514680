import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_LAST_CALL = "last-call";

export const lastCallAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_LAST_CALL,
            callType: API_LAST_CALL
        },
        payload: {}
    } as ApiCallAction<{}>;
};
