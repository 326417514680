import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_SETTLEMENTS_FOR_DATEV_CSV = "download-settlements-for-datev-csv";

export interface DownloadSettlementsForDatevCsvPayload extends Action {
    month: number;
    year: number;
}

export const downloadSettlementsForDatevCsvAction = (month: number, year: number) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_SETTLEMENTS_FOR_DATEV_CSV,
            callType: API_DOWNLOAD_SETTLEMENTS_FOR_DATEV_CSV
        },
        payload: {
            month,
            year
        }
    } as ApiCallAction<DownloadSettlementsForDatevCsvPayload>;
};
