import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {Action} from "redux";
import {API_GET_CURRENT_SETTLEMENT_NUMBER} from "../actions/get-current-settlement-number";

export interface SettlementNumberState {
    currentSettlementNumber?: number;
}

export const initialState: SettlementNumberState = {
    currentSettlementNumber: undefined,
};

export function currentSettlementNumber(state = initialState, action: Action): SettlementNumberState {
    if (isApiSuccess(API_GET_CURRENT_SETTLEMENT_NUMBER)(action)) {
        const settlementNumberResponse = action as ApiCallSucceededAction<any>;
        return {
            ...state,
            currentSettlementNumber: settlementNumberResponse.payload.currentNumber
        };
    }
    return state;
}
