import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_CUSTOMER_PRINT_SHEET = "download-customer-print-sheet";

export const downloadCustomerPrintSheet = (customerId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_CUSTOMER_PRINT_SHEET,
            callType: API_DOWNLOAD_CUSTOMER_PRINT_SHEET
        },
        payload: customerId
    } as ApiCallAction<string>;
};
