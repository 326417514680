import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_SET_CURRENT_LOT = "set-current-lot";

export interface SetCurrentLotPayload extends Action {
    lotId: string;
}

export const setCurrentLotAction = (
    lotId: string
) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SET_CURRENT_LOT,
            callType: API_SET_CURRENT_LOT
        },
        payload: {
            lotId
        }
    } as ApiCallAction<SetCurrentLotPayload>;
};
