import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Settlement, SETTLEMENT_STATUS_OPEN} from "../model/settlement";
import {
    API_GENERATE_FOLLOW_UP_CREDIT_NOTE,
    generateFollowUpCreditNoteAction
} from "../actions/generate-follow-up-credit-note";

interface OwnProps {
    settlement: EntityWrapper<Settlement>
    disabled?: boolean;
}

export const GenerateFollowUpCreditNoteButton = ({settlement, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_GENERATE_FOLLOW_UP_CREDIT_NOTE))

    return settlement.entity.status !== SETTLEMENT_STATUS_OPEN ?
        <button disabled={apiCallState.inProgress || disabled}
                onClick={() => dispatch(generateFollowUpCreditNoteAction(settlement.id!))}
                className="btn btn-info mr-5" type="button">
            Nächste Abrechnung erstellen {disabled ? "(bitte vorher speichern" : null}
        </button> : null;

}
