import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {LotEditForm} from "../components/admin/LotEditForm";
import {LotOverview} from "../components/admin/LotOverview";
import {SubmitBulkLimitBidsForm} from "../components/admin/SubmitBulkLimitBidsForm";
import {LotImporter} from "../components/admin/lot-importer/LotImporter";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";

export const LotModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/lose/" component={LotOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/lose/vorgebote" component={SubmitBulkLimitBidsForm}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/lose/import" component={LotImporter}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/lose/:lotId/edit" component={LotEditForm}/>
        </>
    );
};
