import React from "react";
import {connect} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {resumeAuctionAction} from "../../../actions/resume-live-auction-action";

interface DispatchProps {
    resumeAuction: typeof resumeAuctionAction
}

type Props = DispatchProps & WithTranslation;

const ResumeButtonComponent = ({
                                   t,
                                   resumeAuction
                               }: Props) => {

    return <button className={"btn btn-primary resume-button"} onClick={resumeAuction}>{t("actions.resume-auction")}</button>
};

export const ResumeButton = connect<{}, DispatchProps, {}>(
    null,
    {
        resumeAuction: resumeAuctionAction
    }
)
(withTranslation(["auctioneer", "core"])(ResumeButtonComponent));
