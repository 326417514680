import {createSelector} from "reselect";
import {AuctioneerModuleState} from "../reducers";
import {EntityWrapper, selectSingleEntity} from "@thekeytechnology/framework-react";
import {AuctioneerState} from "../reducers/auctioneer-live-auction";
import {Bid} from "../../bids/model/bid";
import {AuctioneerLot} from "../../lots/model/AuctioneerLot";
import {AuctioneerLiveAuction} from "../model/auctioneer-live-auction";
import {AuctioneerBid} from "../../bids/model/auctioneerBid";
import {LiveAuction, LOT_STATUS_AT_AUCTION} from "@thekeytechnology/auktionshaus-frontend-library";

export const selectAuctioneerModuleState = (state: any) => state.auctioneer;

export const selectAuctioneerState = createSelector(
    selectAuctioneerModuleState,
    (moduleState: AuctioneerModuleState) =>
        moduleState.liveAuction
);

export const selectAuctioneerAuction = createSelector(
    selectAuctioneerState,
    (liveAuctionState: AuctioneerState) =>
        liveAuctionState.liveAuction
);

export const selectAuctioneerSocketConnected = createSelector(
    selectAuctioneerState,
    (liveAuctionState: AuctioneerState) =>
        liveAuctionState.connected
);

export const selectAuctioneerAuctionStatus = createSelector(
    selectAuctioneerState,
    (liveAuctionState: AuctioneerState) => {
        return liveAuctionState.liveAuction ? liveAuctionState.liveAuction.entity.status : LiveAuction.STATUS_INACTIVE;
    }
);

export const selectAuctioneerOpenLotCount = createSelector(
    selectAuctioneerState,
    (liveAuctionState: AuctioneerState) => {
        return liveAuctionState.liveAuction ? liveAuctionState.liveAuction.entity.openLots : 0;
    }
);

export const selectAuctioneerLot = createSelector(
    selectSingleEntity<AuctioneerLot>(AuctioneerLot.TYPE),
    selectAuctioneerAuction,
    (lot: EntityWrapper<AuctioneerLot> | undefined, liveAuction: EntityWrapper<AuctioneerLiveAuction> | undefined) => {
        console.log(lot)
        console.log(liveAuction?.entity.currentLot)

        return lot ? lot : liveAuction?.entity.currentLot
    }
);

export const selectAuctioneerNextLots = createSelector(
    selectAuctioneerAuction,
    (auction: EntityWrapper<AuctioneerLiveAuction> | undefined) =>
        auction?.entity.nextLots
);

export const selectAuctioneerLiveLot = createSelector(
    selectAuctioneerAuction,
    (liveAuction: EntityWrapper<AuctioneerLiveAuction> | undefined) =>
        liveAuction?.entity.currentLot
);

export const selectAuctioneerLiveLotID = createSelector(
    selectAuctioneerAuction,
    (liveAuction: EntityWrapper<AuctioneerLiveAuction> | undefined) =>
        liveAuction?.entity.currentLot.id
);

export const selectAuctioneerLotIsCurrentLot = createSelector(
    selectAuctioneerLot,
    selectAuctioneerAuction,
    (lot: EntityWrapper<AuctioneerLot> | undefined, liveAuction: EntityWrapper<AuctioneerLiveAuction> | undefined) =>
        lot ? lot.id === liveAuction?.entity.currentLot.id : false
);

export const selectAuctioneerLotId = createSelector(
    selectAuctioneerLot,
    (lot: EntityWrapper<AuctioneerLot> | undefined) =>
        lot?.id ? lot?.id : undefined
);

export const selectAuctioneerLotAttributes = createSelector(
    selectAuctioneerLot,
    (lot: EntityWrapper<AuctioneerLot> | undefined) =>
        lot?.entity.attributes || []
);

export const selectAuctioneerBids = createSelector(
    selectAuctioneerLot,
    (lot: EntityWrapper<AuctioneerLot> | undefined) =>
        lot?.entity.bids ? lot?.entity.bids.sort(bidSortCompareFn) : []
);

export const selectAuctioneerLotStatus = createSelector(
    selectAuctioneerLot,
    (lot: EntityWrapper<AuctioneerLot> | undefined) =>
        lot?.entity.status ? lot.entity.status : LOT_STATUS_AT_AUCTION
);

export const selectAuctioneerLotMinimumBid = createSelector(
    selectAuctioneerLot,
    (lot: EntityWrapper<AuctioneerLot> | undefined) =>
        lot?.entity.minimumBid ? lot.entity.minimumBid : LiveAuction.DEFAULT_BID_STEP
);

export const selectAuctioneerLotHighestBid = createSelector(
    selectAuctioneerBids,
    (bids: Array<EntityWrapper<AuctioneerBid>>) =>
        bids.length ? bids[0] : undefined
);

export const selectAuctioneerLotBidCount = createSelector(
    selectAuctioneerBids,
    (bids: Array<EntityWrapper<AuctioneerBid>>) =>
        bids.length
);

export const bidSortCompareFn = (a: any, b: any) => {
    const value1 = a.entity.value;
    const value2 = b.entity.value;
    const limitedBid1 = !!a.entity.limitedAt;
    const limitedBid2 = !!b.entity.limitedAt;
    const source1 = a.entity.source;
    const source2 = b.entity.source;
    const timestamp1 = a.entity.timestamp;
    const timestamp2 = b.entity.timestamp;

    const sourceValues: { [key: string]: number } = {};
    sourceValues[Bid.SOURCE_AUCTIONEER] = 4;
    sourceValues[Bid.SOURCE_TEL] = 3;
    sourceValues[Bid.SOURCE_WRITTEN] = 3;
    sourceValues[Bid.SOURCE_ONLINE] = 2;
    sourceValues[Bid.SOURCE_INVALUABLE] = 1;
    sourceValues[Bid.SOURCE_LOTTISSIMO] = 1;
    sourceValues[Bid.SOURCE_BIDSPIRIT] = 1;

    if (value1 > value2) {
        return -1
    }
    if (value1 < value2) {
        return 1
    }
    if (limitedBid1 && !limitedBid2) {
        return -1
    }
    if (!limitedBid1 && limitedBid2) {
        return 1
    }
    if (sourceValues[source1] > sourceValues[source2]) {
        return -1
    }
    if (sourceValues[source1] < sourceValues[source2]) {
        return 1
    }
    if (timestamp1 < timestamp2) {
        return -1
    }
    if (timestamp1 > timestamp2) {
        return 1
    }
    return 0
};
