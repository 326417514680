import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Filters, TextFilter} from "@thekeytechnology/framework-react-ux";
import {ENTITY_TYPE_AUCTION} from "../model/auction";

const auctionsFilterComponent = (props: WithTranslation) => {
    const {
        t
    } = props;
    return (
        <div className={"tk-auctions-filter"}>
            <Filters
                entityType={ENTITY_TYPE_AUCTION}
                filterKeys={["entity.auctionNumber", "entity.startDateTime", "entity.previewDateTime", "entity.nextAuctionDateTime"]}>
                <TextFilter
                    placeholder={t("core:filters.search")}
                    entityType={ENTITY_TYPE_AUCTION}
                    property="entity.auctionNumber"/>
            </Filters>
        </div>
    );
};

export const AuctionsFilter = withTranslation(["auction", "core"])(auctionsFilterComponent);
