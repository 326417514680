import React from "react";
import {generateString,} from "@thekeytechnology/framework-react";
import * as Yup from "yup";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik} from "formik";
import {WriteToItemCheckbox} from "./WriteToItemCheckbox";
import {updateSellerAction} from "../../actions/inline-update/update-seller";
import {CustomerSelectField} from "../../../items/components/edit/CustomerSelectField";
import {CustomerJustIdAndName} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    lotId: string;
    editingEnabled: boolean;
    writeToItemEnabled: boolean;
    seller: CustomerJustIdAndName;
}

interface DispatchProps {
    updateSeller: typeof updateSellerAction;
}

type Props = OwnProps & WithTranslation & DispatchProps;

const InlineSellerEditorComponent = (props: Props) => {
    const {seller, writeToItemEnabled, lotId, editingEnabled, updateSeller, t} = props;

    const popover = <Popover id={generateString(10)}>
        <Popover.Title as="h3" className="d-flex align-items-center">
            {t("item:entity.labels.seller")}
            <button
                onClick={() => {
                    document.body.click()
                }}
                className="ml-auto btn btn-link p-0">
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </Popover.Title>
        <Popover.Content>
            <Formik
                initialValues={{
                    seller: seller,
                    writeToItem: writeToItemEnabled,
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    seller: Yup.object().required(t("core:forms.required-field", {fieldName: t("item:entity.labels.seller")}))
                })}
                onSubmit={(values, {setSubmitting}) => {
                    updateSeller(lotId,
                        writeToItemEnabled && values.writeToItem,
                        values.seller.id
                    );
                    document.body.click()
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="d-flex flex-column">
                        <ValidatedField formikState={formikState}
                                        component={CustomerSelectField}
                                        name="seller"
                                        className="form-control default-input"
                        />
                        {writeToItemEnabled ?
                            <WriteToItemCheckbox/>
                            : null}
                        <button disabled={formikState.isSubmitting} className="btn btn-success w-100 mt-3"
                                type="submit">
                            {t("inline-editor.save")}
                        </button>
                    </Form>
                )}
            </Formik>
        </Popover.Content>
    </Popover>;

    return <div className="d-flex align-items-center">
        {seller.name}

        {editingEnabled ? <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
            <button className="btn btn-link ml-1">
                <FontAwesomeIcon icon={faPencilAlt}/>
            </button>
        </OverlayTrigger> : null}
    </div>
};

export const InlineSellerEditor = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        updateSeller: updateSellerAction
    }
)(
    withTranslation(["auctionPlanning", "item", "core"])(InlineSellerEditorComponent)
);
