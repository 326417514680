import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Settlement} from "../model/settlement";
import {
    API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF,
    downloadSettlementPreviewPdfAction
} from "../actions/download-settlement-preview-pdf";

interface OwnProps {
    settlement: EntityWrapper<Settlement>
    disabled?: boolean;
}

export const DownloadSettlementPreviewButton = ({settlement, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF))

    return <button disabled={apiCallState.inProgress || disabled}
                   onClick={() => dispatch(downloadSettlementPreviewPdfAction(settlement.id!))}
                   className="btn btn-info mr-5" type="button">
        Vorschau (PDF)
    </button>;

}
