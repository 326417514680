import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {API_GET_CURRENT_ORDER_NUMBER} from "../actions/get-current-order-number";
import {Action} from "redux";

export interface OrderNumberState {
    currentOrderNumber?: number;
}

export const initialState: OrderNumberState = {
    currentOrderNumber: undefined,
};

export function currentOrderNumber(state = initialState, action: Action): OrderNumberState {
    if (isApiSuccess(API_GET_CURRENT_ORDER_NUMBER)(action)) {
        const orderNumberResponse = action as ApiCallSucceededAction<any>;
        return {
            ...state,
            currentOrderNumber: orderNumberResponse.payload.currentNumber
        };
    }
    return state;
}
