import {
    addFiltersAction,
    Filter,
    FilterTerm,
    OrFilter,
    PropertyFilter,
    reloadEntityListAction,
    removeFiltersAction,
    selectFilter
} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {AuctioneerLotShort} from "../../../../../lots/model/AuctioneerLotShort";
import {selectAuctioneerLiveLot} from "../../../../selectors";

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>,
    removeFilters: ReturnType<typeof removeFiltersAction>
    reloadEntities: ReturnType<typeof reloadEntityListAction>
}

type Props = StateProps & DispatchProps;

const TextFilterComponent = (props: Props) => {

    const lot = useSelector(selectAuctioneerLiveLot)

    const filter = (event: any) => {
        const text = event.target?.value ? event.target?.value : "";
        if (text.length) {
            props.removeFilters(["lot-only-past-current-lot"]);
            const filters = [new PropertyFilter("entity.description.de", new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, text))];
            const intVal = parseInt(text);
            if (intVal) {
                filters.push(new PropertyFilter("entity.lotNumber", new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, parseInt(text))));
            }
            props.addFilters("entity.description.de",
                [new OrFilter(
                    filters
                )]
            );
        } else {
            props.removeFilters(["entity.description.de"]);
            if (lot?.entity.lotNumber) {
                props.addFilters("lot-only-past-current-lot",
                    [
                        new PropertyFilter("entity.lotNumber", new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_GTE, lot.entity.lotNumber - 2))
                    ]
                );
            }
        }
        props.reloadEntities();
    };

    useEffect(() => {
        filter("")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lot])

    const currentFilter = props.currentFilters && props.currentFilters.length && props.currentFilters[0] ?
        ((props.currentFilters[0] as OrFilter).subfilters[0] as PropertyFilter).filterTerm.value : "";

    return (
        <div className="filter text-filter">
            <input type="text" value={currentFilter}
                   className="text-filter default-input" onChange={filter} placeholder={"Lose durchsuchen"}/>
            <FontAwesomeIcon icon={faSearch}/>
        </div>
    );
};

export const AuctioneerLotFilter = connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            currentFilters: selectFilter(AuctioneerLotShort.TYPE, "entity.description.de")(state)
        };
    },
    (dispatch) => {
        return {
            addFilters: (key: string, filters: Filter[]) => dispatch(addFiltersAction(AuctioneerLotShort.TYPE)(key, filters)),
            removeFilters: (keys: string[]) => dispatch(removeFiltersAction(AuctioneerLotShort.TYPE)(keys)),
            reloadEntities: () => dispatch(reloadEntityListAction(AuctioneerLotShort.TYPE)())
        };
    }
)(TextFilterComponent);
