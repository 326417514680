import {
    fetchSingleEntityAction,
    FilterTerm,
    PropertyFilter,
    selectSingleEntity
} from "@thekeytechnology/framework-react";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AuctionManagerLotsTable} from "./AuctionManagerLotsTable";
import {
    Auction,
    AUCTION_STATUS_ARCHIVED,
    AUCTION_STATUS_CONCLUDED,
    AUCTION_STATUS_FINALIZED,
    AUCTION_STATUS_IN_PLANNING,
    AUCTION_STATUS_LIVE,
    ENTITY_TYPE_AUCTION
} from "../../auction/model/auction";
import {useRouteMatch} from "react-router";
import {
    ContentContainer,
    HeadingContainer,
    PaddedContainer,
    SimpleHeader,
    UnconnectedPagination
} from "@thekeytechnology/framework-react-ux";
import {ManualAssignmentButton} from "./manual/ManualAssignmentButton";
import {AssignPreassignedButton} from "./preassigned/AssignPreassignedButton";
import {TransferButton} from "./transfer/TransferButton";
import {Card} from "react-bootstrap";
import {FinalizeButton} from "./finalization/FinalizeButton";
import {AuctionManagerLotsFilters} from "./filters/AuctionManagerLotsFilters";
import {ValidationErrorDisplay} from "./errordisplay/ValidationErrorDisplay";
import {GenerateInvoicesButton} from "./generate-invoices/GenerateInvoicesButton";
import {AuctionManagerLot} from "../../lots/model/AuctionManagerLot";
import {GenerateSettlementsButton} from "./generate-settlements/GenerateSettlementsButton";
import {NavLink} from "react-router-dom";
import {usePaginatedEndpointFilters} from "../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {usePaginatedEndpoint} from "../../paginated-endpoints/hooks/use-paginated-endpoint";
import {selectReloadView} from "../selector";
import {AssignBuyersBasedOnManualIdButton} from "./assign-buyers-based-on-manual-id/AssignBuyersBasedOnManualIdButton";
import {PageTitle} from "../../core/components/PageTitle";
import {AuctionManagerDownloads} from "./downloads/AuctionManagerDownloads";

export const AuctionManager = () => {

    const {t} = useTranslation(["auctionPlanning"])
    const dispatch = useDispatch();

    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]

    useEffect(() => {
        dispatch(fetchSingleEntityAction(ENTITY_TYPE_AUCTION)(auctionId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionId])
    const auction = useSelector(selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION))

    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "auction-manager-lot-table"
    )

    const getBaseFilters = () => {
        return [new PropertyFilter("entity.auctionRef",
            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, auctionId))
        ]
    }

    const {
        resolved,
        reload,
        setFilters,
        apiCallState,
        setPaginationQuery,
        setSortQuery,
        sortQuery
    } = usePaginatedEndpoint<AuctionManagerLot>(
        "/auction-planning/get-lots",
        undefined,
        getBaseFilters(),
        endpointFilters.getFilters().flatMap(f => f.filters)
    )



    // This is a bit of a hack. When we use some action to update data, this value will be updated on apiSuccess,
    // which we then use here to trigger an entity reload
    const lastUpdate = useSelector(selectReloadView);
    useEffect(() => {
        if (lastUpdate) {
            reload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUpdate]);


    if (!auction) {
        return null;
    }

    const isInPlanning = auction.entity.status === AUCTION_STATUS_IN_PLANNING;
    const isFinalized = auction.entity.status === AUCTION_STATUS_FINALIZED;
    const isConcluded = [AUCTION_STATUS_CONCLUDED, AUCTION_STATUS_ARCHIVED].includes(auction.entity.status ?? "")
    const isLive = auction.entity.status === AUCTION_STATUS_LIVE

    return (
        <>
            <PageTitle title={"Auktion " + auction.entity.auctionNumber} />
            <HeadingContainer>
                <SimpleHeader
                    heading={t("auction-planning.heading", {
                        auctionNumber: auction.entity.auctionNumber,
                        status: t(`auction:status.${auction.entity.status}`)
                    })}/>
            </HeadingContainer>
            <PaddedContainer>
                <Card body>
                    <div className="d-flex">
                        {isInPlanning ? <>
                            <AssignPreassignedButton/>
                            <ManualAssignmentButton/>
                            <TransferButton/>
                            <FinalizeButton/>
                        </> : null}
                        {isConcluded ? <>
                            <GenerateSettlementsButton/>
                            <AssignBuyersBasedOnManualIdButton/>
                        </> : null}
                        {isLive ? <>
                            <AssignBuyersBasedOnManualIdButton/>
                        </>: null}
                        {isLive || isConcluded ? <>
                            <GenerateInvoicesButton/>
                        </>: null}
                        {isFinalized || isInPlanning || isLive ? <>
                            <NavLink to={"/auktionen/" + auction.id + "/vorgebote/"}
                                     className={"btn btn-secondary ml-3 mr-3"}>Vorgebote</NavLink>
                        </> : null}
                        {isFinalized || isConcluded ? <>
                            <NavLink to={"/auktionen/" + auction.id + "/einlieferer/"}
                                     className={"btn btn-secondary ml-3 mr-3"}>Einlieferer</NavLink>
                        </> : null}
                        <AuctionManagerDownloads/>
                    </div>
                </Card>
                <ValidationErrorDisplay/>
            </PaddedContainer>
            <PaddedContainer>
                <AuctionManagerLotsFilters
                    isInPlanning={isInPlanning}
                    isConcluded={isConcluded}
                    isLive={isLive}
                    endpointFilters={endpointFilters}
                />
            </PaddedContainer>
            <ContentContainer>
                <AuctionManagerLotsTable isInPlanning={isInPlanning}
                                         isConcluded={isConcluded}
                                         isLive={isLive}
                                         lots={resolved.entities}
                                         apiCallState={apiCallState}
                                         status={auction.entity.status ? auction.entity.status : ""}
                                         pagination={resolved.pagination}
                                         setSortQuery={setSortQuery}
                                         sortQuery={sortQuery}
                />
                <UnconnectedPagination pagination={resolved.pagination} setPage={page => {
                    setPaginationQuery({
                        page,
                        docsPerPage: resolved.pagination.docsPerPage
                    })
                }}/>
            </ContentContainer>
        </>
    );
};
