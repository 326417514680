import {DownloadPdfData} from "../model/DownloadPdfData";

export const triggerPdfResponseDownload = (payload: DownloadPdfData, defaultFileName: string = "") => {
    const element = document.createElement("a");
    const file = new Blob([new Uint8Array(payload.data)], {type: 'text/pdf'});
    element.href = URL.createObjectURL(file);
    element.download = payload.filename ? payload.filename : defaultFileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
}
