import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {selectAuctioneerLot, selectAuctioneerLotStatus} from "../../../../selectors";
import {LotThumbnailDisplay} from "../../../../../lots/components/LotThumbnail";
import {
    LOT_STATUS_LAST_CALL,
    LotDescriptionDisplay,
    LotTitleDisplay
} from "@thekeytechnology/auktionshaus-frontend-library";


export const AuctioneerCurrentLot = () => {
    const {t} = useTranslation(["lot"]);

    const lot =useSelector(selectAuctioneerLot, (a,b) => {
        return a?.id === b?.id
    });
    const status = useSelector(selectAuctioneerLotStatus);

    if (!lot) {
        return <>
            "Los wurde nicht gefunden"
        </>
    }

    return <>
        <div className={"auctioneer-view-current-lot-content row"}>
            <LotThumbnailDisplay url={lot.entity.publicAttachments[0]?.entity.url} className={"col-sm-6"}/>
            <div className={"lot-content col-sm-6"}>
                <div className={"lot-info"}>
                <span className={"lot-number"}>
                    {t("entity.singular")} {lot.entity.lotNumber?.toString().padStart(4, "0")}
                </span>
                    <span className={"lot-status" + (status === LOT_STATUS_LAST_CALL ? " last-call" : "")}>
                    {t("status." + status)}
                </span>
                    {lot.entity.category ?
                        <div className={"lot-category"}>
                            {t("entity.labels.category")}: {lot.entity.category.name.de}
                        </div> : null}
                </div>
                <div className={"lot-title"}>
                    <h1>
                        <LotTitleDisplay description={lot.entity.description.de}/>
                    </h1>
                </div>
                <div className={"lot-text"}>
                    <LotDescriptionDisplay description={lot.entity.description.de}/>
                </div>
            </div>
        </div>
    </>;
};
