import React from "react";
import {Col, Row} from "react-bootstrap";

interface OwnProps {
    title: string,
    value: string
}

type Props = OwnProps

export const LotDetailsRow = ({
                                  title,
                                  value
                              }: Props) => {

    return <Row>
        <Col>{title}:</Col>
        <Col className={"text-right flex-grow-1"}>{value}</Col>
    </Row>

}