import React from "react";
import {connect} from "react-redux";
import {selectValidationErrors} from "../../selector";
import {WithTranslation, withTranslation} from "react-i18next";
import {addFiltersAction} from "@thekeytechnology/framework-react";
import {ONLY_INVALID_FILTER, ONLY_INVALID_FILTER_KEY} from "../filters/OnlyInvalidLotsFilter";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";

interface StateProps {
    errors: { [lotId: string]: string[] }
}

interface DispatchProps {
    addFilters: ReturnType<typeof addFiltersAction>
}

type Props = StateProps & DispatchProps & WithTranslation;

const ValidationErrorDisplayComponent = ({errors, addFilters, t}: Props) => {
    const errorCount = Object.keys(errors).length;
    return errorCount ? <div className="alert alert-warning mt-4 mb-4">
        {t("validation-error-display.text", {errorCount})}
        <button onClick={() => {
            addFilters(ONLY_INVALID_FILTER_KEY, [ONLY_INVALID_FILTER])
        }} className="btn btn-link">{t("validation-error-display.show-only-invalid")}</button>
    </div> : null;
};

export const ValidationErrorDisplay = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        errors: selectValidationErrors(state)
    }),
    {
        addFilters: addFiltersAction(AuctionManagerLot.TYPE)
    }
)(withTranslation("auctionPlanning")(ValidationErrorDisplayComponent));
