import {parseInt} from "lodash";
import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {dateDiffInDays} from "../../../core/utils/date-utils";
import {PaginatedEndPointTextFilter} from "../../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import React from "react";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps,
    placeholder: string,
    property: string
}

type Props = OwnProps;

export const DaysSinceFilter = ({
                                    endpointFilters,
                                    placeholder,
                                    property
                                }: Props) => {


    return <PaginatedEndPointTextFilter
        property={property}
        endpointFilters={endpointFilters}
        placeholder={placeholder}
        customFilterFunction={(
            property,
            endpointFilters,
            text
        ) => {
            if (text?.length) {
                const days = parseInt(text);
                if (days) {
                    const date = new Date();
                    date.setDate(date.getDate() - days);
                    endpointFilters.addFilters(property, [
                        new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_DATE_TIME, FilterTerm.OPERATION_LTE, date))
                    ])
                }
            } else {
                endpointFilters.removeFilters([property]);
            }
        }}
        customValueFunction={(endpointFilters: PaginatedEndpointFiltersReturnProps, property: string) => {
            const currentFilters = endpointFilters.getFilters().filter(f => f.key === property).flatMap(f => f.filters)

            const value = currentFilters && currentFilters.length && currentFilters[0] ?
                (currentFilters[0] as PropertyFilter).filterTerm.value :
                "";

            if (value) {
                const now = new Date()
                const then = new Date(value)
                return dateDiffInDays(then, now).toString(10)
            } else {
                return ""
            }
        }}
    />
}