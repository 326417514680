import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {closeLotAction} from "../../../actions/close-lot-action";
import {selectAuctioneerLotId} from "../../../selectors";

interface StateProps {
    lotId: string | undefined
}

interface DispatchProps {
    closeLot: typeof closeLotAction
}

type Props = StateProps & DispatchProps;

const CloseLotButtonComponent = ({
                                     closeLot,
                                     lotId
                                 }: Props) => {

    const {t} = useTranslation(["auctioneer", "core"]);

    return lotId ? <button className={"btn btn-primary close-lot-button"}
                           onClick={() => closeLot(lotId)}>{t("actions.close-lot")}</button> : null
};

export const CloseLotButton = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        lotId: selectAuctioneerLotId(state)
    }),
    {
        closeLot: closeLotAction
    }
)
(CloseLotButtonComponent);
