import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {CustomersOverview} from "../components/CustomersOverview";
import {CustomerEditForm} from "../components/edit/CustomerEditForm";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";

export const CustomersModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/kunden/"
                          component={CustomersOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/kunden/:customerId/edit" component={CustomerEditForm}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/kunden/new/" component={CustomerEditForm}/>
        </>
    );
};
