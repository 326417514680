import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_NEXT_ITEM_NUMBER = "get-next-item-number";

export const getNextItemNumberAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_GET_NEXT_ITEM_NUMBER,
        callType: API_GET_NEXT_ITEM_NUMBER
    },
    payload: null
} as ApiCallAction<null>;

