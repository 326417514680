import {withTranslation, WithTranslation} from "react-i18next";
import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";

const CatalogPartSelectFieldComponent = (props: FieldProps & WithTranslation) => {
    const {field, form, t} = props;
    const accountingTypeOptions = [
        {
            value: "autographs-toys",
            label: t("catalog-part-options.autographs-toys")
        },
        {
            value: "literature",
            label: t("catalog-part-options.literature")
        },
        {
            value: "medals",
            label: t("catalog-part-options.medals")
        },
        {
            value: "military-foreign",
            label: t("catalog-part-options.military-foreign")
        },
        {
            value: "military-kaiserreich",
            label: t("catalog-part-options.military-kaiserreich")
        },
        {
            value: "military-ww2",
            label: t("catalog-part-options.military-ww2")
        },
        {
            value: "waffenss-brd-ddr",
            label: t("catalog-part-options.waffenss-brd-ddr")
        },

    ];
    return (<Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        placeholder={t("catalog-part-select-field.placeholder")}
        options={accountingTypeOptions}
        name={field.name}
        value={accountingTypeOptions.find(o => o.value === field.value)}
        onChange={(item: any) => form.setFieldValue(field.name, item.value)}
        getOptionValue={opt => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};

export const CatalogPartSelectField = withTranslation("itemCategory")(CatalogPartSelectFieldComponent);
