import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {default as React} from "react";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import "./message-display.scss";
import {removeMessageAction, selectMessages} from "@thekeytechnology/framework-react";

export const CustomMessageDisplay = () => {

    const messages = useSelector(selectMessages)
    const dispatch = useDispatch();
    const {t} = useTranslation("core");

    if (!messages || messages.length === 0) {
        return null;
    }

    const messageDisplay = <div className="message-display w-100 d-flex flex-column">
        {messages.map(message => {
                setTimeout(() => {
                    dispatch(removeMessageAction(message.id))
                }, 3000);

                return <div key={message.id}
                            className={`message d-flex ${message.messageType} alert-${message.messageType}`}>
                    <div dangerouslySetInnerHTML={{__html: t(message.message)}}>
                    </div>

                    <button className="btn-link ml-auto" onClick={() => dispatch(removeMessageAction(message.id))}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </button>
                </div>
            }
        )}
    </div>;

    const element = document.querySelector("#messages");
    return element ? createPortal(
        messageDisplay,
        element
    ) : null;

}

