import {
    EntityWrapper,
    reloadEntityListAction,
    selectEntities,
    setContextAction,
    setPaginationQueryAction,
} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React, {useEffect} from "react";
import {useTranslation, WithTranslation} from "react-i18next";
import Select from "react-select";
import {connect} from "react-redux";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {CustomerForSelectField} from "../../model/customer-for-select-field";

interface StateProps {
    customers: EntityWrapper<CustomerForSelectField>[];
}

interface DispatchProps {
    fetchCustomers: ReturnType<typeof reloadEntityListAction>
    setPaginationQuery: ReturnType<typeof setPaginationQueryAction>
    setContext: ReturnType<typeof setContextAction>
}

type Props = StateProps & DispatchProps & WithTranslation & FieldProps;

const CustomerSelectFieldComponent = (props: Props) => {
    const {
        customers,
        field,
        fetchCustomers,
        setPaginationQuery,
        setContext
    } = props;
    const {t} = useTranslation(["customer", "core"]);

    useEffect(() => {
        setPaginationQuery({docsPerPage: -1, page: 0});
        setContext(CustomerForSelectField.CONTEXT);
        fetchCustomers();
    }, [setPaginationQuery, setContext, fetchCustomers]);

    const onChange = (customer: EntityWrapper<CustomerForSelectField>) => {
        props.form.setFieldValue(props.field.name, customer.id);
    };


    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            placeholder={t("core:controls.select.select-with-type", {
                type: t("entity.singular")
            })}
            components={{ClearIndicator}}
            isClearable={true}
            options={customers}
            name={field.name}
            value={customers ?
                customers.find(option => field.value && (field.value === option.id)) : undefined}
            onChange={onChange as any}
            getOptionValue={customer => customer.id!}
            getOptionLabel={(customer: EntityWrapper<CustomerForSelectField>) => customer.entity.name + " (" + customer.entity.customerNumber + ")"}
            onBlur={field.onBlur}
        />
    );
};

export const CustomerSelectField = connect<StateProps, DispatchProps, FieldProps>(
    (state: any) => {
        return {
            customers: selectEntities<CustomerForSelectField>(CustomerForSelectField.TYPE)(state)
        };
    },
    {
        fetchCustomers: reloadEntityListAction(CustomerForSelectField.TYPE),
        setPaginationQuery: setPaginationQueryAction(CustomerForSelectField.TYPE),
        setContext: setContextAction(CustomerForSelectField.TYPE)
    }
)(
    CustomerSelectFieldComponent
);
