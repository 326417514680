import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";

export interface CommissionPosition extends OrderPosition {
    comment: string;
    tax: number;
}

export const POSITION_TYPE_POSITIVE_COMMISSION = "commission-positive";

export interface PositiveCommissionPosition extends CommissionPosition {

}

export const POSITION_TYPE_NEGATIVE_COMMISSION = "commission-negative";

export interface NegativeCommissionPosition extends CommissionPosition {

}

export const POSITION_TYPE_LOTTISSIMO_COMMISSION = "commission-lottissimo";

export interface LottissimoCommissionPosition extends CommissionPosition {

}

export const POSITION_TYPE_INVALUABLE_COMMISSION = "commission-invaluable";

export interface InvaluableCommissionPosition extends CommissionPosition {

}

export const POSITION_TYPE_BIDSPIRIT_COMMISSION = "commission-bidspirit";

export interface BidSpiritCommissionPosition extends CommissionPosition {

}

export const POSITION_TYPE_FINANCING_COMMISSION = "commission-financing";

export interface FinancingCommissionPosition extends CommissionPosition {

}
