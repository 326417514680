import {Field} from "formik";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {CheckboxField} from "@thekeytechnology/auktionshaus-frontend-library";

type Props = WithTranslation;

const WriteToItemCheckboxComponent = ({t}: Props) => {
    return <div className="form-check pl-0 w-100 mt-2 mb-2">
        <Field
            component={CheckboxField}
            name="writeToItem"
            className="mr-2"
        />
        <label
            className="form-check-label">{t("inline-editor.write-to-item")}</label>
    </div>
};

export const WriteToItemCheckbox = withTranslation("auctionPlanning")(WriteToItemCheckboxComponent);
