import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DELETE_BID = "delete-bid";

export interface DeleteBidPayload extends Action {
    bidId: string;
}

export const deleteBidAction = (
    bidId: string,
) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DELETE_BID,
            callType: API_DELETE_BID
        },
        payload: {
            bidId
        }
    } as ApiCallAction<DeleteBidPayload>;
};
