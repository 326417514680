import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./auctioneer.scss";
import {AuctioneerContentTabs} from "./content/tabs/AuctioneerViewContentTabs";
import {AuctioneerHighestBid} from "./action-bar/highest-bid/AuctioneerHighestBid";
import {AuctioneerSubmitBidForm} from "./action-bar/submit-bid/AuctioneerSubmitBidForm";
import {AuctioneerLotStats} from "./action-bar/lot-stats/AuctioneerLotStats";
import {AuctioneerActionButtons} from "./action-bar/action-buttons/AuctioneerActionButtons";
import {connectToAuctioneerSocketAction} from "../actions/connect-to-auctioneer-socket-action";
import {selectAuctioneerAuction, selectAuctioneerSocketConnected} from "../selectors";
import {useSingleEntityFromPath} from "@thekeytechnology/framework-react-ux";
import {NavLink} from "react-router-dom";
import {LiveAuction} from "@thekeytechnology/auktionshaus-frontend-library";
import {AuctioneerLot} from "../../lots/model/AuctioneerLot";
import {AuctioneerCurrentLot} from "./content/tabs/current-lot/AuctioneerCurrentLot";

export const AuctioneerView = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(connectToAuctioneerSocketAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectToAuctioneerSocketAction]);

    const connected = useSelector(selectAuctioneerSocketConnected);
    const liveAuction = useSelector(selectAuctioneerAuction)

    useSingleEntityFromPath(AuctioneerLot.TYPE, "lotId", undefined, AuctioneerLot.CONTEXT)

    if (!connected && !liveAuction) {
        return <div className={"jumbotron no-auction"}>
            <p>Auktion wird geladen</p>
        </div>
    }

    if (!liveAuction || liveAuction.entity.status === LiveAuction.STATUS_INACTIVE) {
        return <div className={"jumbotron no-auction"}>
            <p>Keine Auktion auf Live gesetzt</p>
            <NavLink to={"/auktionen"}>
                Auktion auswählen
            </NavLink>
        </div>
    }

    return <div className={"auctioneer-view h-100"}>
        <div className="container h-100">
            <div className="row h-100">
                <div className={"auctioneer-view-content col-sm-8 h-100"}>
                    <AuctioneerCurrentLot/>
                    <AuctioneerContentTabs/>
                    {/*<AuctioneerNextLots/>*/}
                </div>
                <div className={"auctioneer-view-action-bar col-sm-4"}>
                    <AuctioneerHighestBid/>
                    <AuctioneerSubmitBidForm/>
                    <AuctioneerLotStats/>
                    <AuctioneerActionButtons/>
                </div>
            </div>
        </div>
    </div>
};
