import {useTranslation} from "react-i18next";
import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {SHIPPING_STATUS_COMMISSIONED, SHIPPING_STATUS_OPEN, SHIPPING_STATUS_SENT} from "../../model/Order";

type Props = FieldProps

export const ShippingStatusSelectField = ({field, form} : Props) => {
    const {t} = useTranslation(["order", "core"])
    const options =
        [SHIPPING_STATUS_OPEN, SHIPPING_STATUS_COMMISSIONED, SHIPPING_STATUS_SENT]
            .map(status => ({value: status, label: t(`shipping-status-options.${status}`)}));

    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            components={{ClearIndicator}}
            options={options}
            name={field.name}
            value={options.find(o => o.value === field.value)}
            onChange={(item: any) => form.setFieldValue(field.name, item.value)}
            getOptionValue={opt => opt.value}
            getOptionLabel={item => item.label}
            onBlur={field.onBlur}
        />);
};;
