import {useTranslation} from "react-i18next";
import {useCustomEndpoint} from "../../core/utils/use-custom-endpoint";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {Form, Formik} from "formik";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctionAppSettings} from "../model/AuctionAppSettings";
import {ContentContainer, EditHeaderWithBackground, ValidatedField} from "@thekeytechnology/framework-react-ux";
import {faPercentage} from "@fortawesome/free-solid-svg-icons";
import {saveAuctionAppSettings} from "../actions/save-auction-app-setting";


export const SettingsForm = () => {

    const {t} = useTranslation(["auctionAppSettings", "core"]);

    const [settings, setSettings] = useState<EntityWrapper<AuctionAppSettings>>(AuctionAppSettings.new());

    const settingsEndpoint = useCustomEndpoint<EntityWrapper<AuctionAppSettings>>(
        "/auction-app-settings/get-settings"
    )

    useEffect(() => {
        settingsEndpoint.call((result: EntityWrapper<AuctionAppSettings>) => {
            setSettings(result);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const dispatch = useDispatch();

    return <>
        <div className={"padded-container pl-2 pl-xs-3 pl-lg-3 pl-xl-5 pr-2 pt-4 pb-4 bg-white"}>
            <h1>{t("entity.plural")}</h1>
        </div>
        <Formik
            enableReinitialize={true}
            initialValues={{
                tax: settings?.entity.defaultTax
            }}
            onSubmit={(values, {setSubmitting}) => {
                const saving = new EntityWrapper(
                    "1",
                    new AuctionAppSettings(
                        values.tax
                    )
                );
                dispatch(saveAuctionAppSettings(saving));
                setSubmitting(false);
            }}
        >
            {formikState => (
                <Form>
                    <EditHeaderWithBackground
                        heading={""}
                    >
                        <button type="submit" disabled={formikState.isSubmitting}
                                className="btn btn-primary mr-3"
                        >
                            {t("core:save-and-back-buttons.edit")}
                        </button>
                    </EditHeaderWithBackground>
                    <ContentContainer>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            icon={faPercentage}
                                            type="number"
                                            placeholder={t("entity.labels.default-tax")}
                                            name="tax"
                                            className="form-control default-input"
                                            label={t("entity.labels.default-tax")}
                            />
                        </div>
                    </ContentContainer>
                </Form>
            )}
        </Formik>
    </>
}