import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import {
    ApiCallState,
    EmptyApiCallState,
    EntityListResolvedPayload,
    Filter,
    Message,
    PaginationQuery,
    PaginationResponse,
    postMessageAction,
    selectApiBase,
    selectAuthState,
    SortQuery,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import {useDispatch, useSelector} from "react-redux";

interface PaginatedEndpointReturnProps<EntityType> {
    resolved: EntityListResolvedPayload<EntityType>,
    reload: () => void,
    setFilters: (filters: Filter[]) => void,
    setPaginationQuery:(paginationQuery: PaginationQuery) => void,
    setSortQuery: (sortQuery: SortQuery) => void,
    apiCallState: ApiCallState,
    sortQuery: SortQuery
}

export function usePaginatedEndpoint<EntityType>(
    endpoint: string,
    context: string | undefined = undefined,
    baseFilters: Filter[] = [],
    initialFilters: Filter[] = []
): PaginatedEndpointReturnProps<EntityType> {
    const authedAxios = withAuthedAxios(
        useSelector(selectApiBase),
        useSelector(selectAuthState)
    );

    const dispatch = useDispatch();
    const [sortQuery, setSortQuery] = useState<SortQuery>(new SortQuery({}))
    const [filters, setFilters] = useState<Filter[]>(baseFilters.concat(initialFilters));
    const [paginationQuery, setPaginationQuery] = useState<PaginationQuery>({docsPerPage: 20, page: 0})

    const [apiCallState, setApiCallState] = useState<ApiCallState>(EmptyApiCallState.STATE)

    const [resolved, setResolved] = useState<EntityListResolvedPayload<EntityType>>({
        entityType: "",
        entities: [],
        pagination: {currentPage: 0, docsPerPage: 0, totalDocs: 0, totalPages: 0}
    })

    const reload = () => {
        setApiCallState({
            inProgress: true,
            failed: false,
            succeeded: false,
            progress: {
                current:0,
                max:100
            }
        })

        authedAxios.post(endpoint, {
            entityType: "",
            paginationRequest: {
                page: paginationQuery.page,
                docsPerPage: paginationQuery.docsPerPage
            },
            context,
            sortQuery,
            filters
        }).then((response: AxiosResponse) => {
            const paginationResponse = response.data as PaginationResponse<any>;

            setApiCallState({
                inProgress: false,
                failed: false,
                succeeded: true,
                progress: {
                    current:100,
                    max:100
                }
            })

            setResolved({
                pagination: {
                    currentPage: paginationResponse.page,
                    docsPerPage: paginationResponse.docsPerPage,
                    totalPages: paginationResponse.totalPages,
                    totalDocs: paginationResponse.totalDocs
                },
                entities: paginationResponse.entities,
                entityType: ""
            });
        }).catch(error => {
            setApiCallState({
                inProgress: false,
                failed: true,
                succeeded: false,
                progress: {
                    current:100,
                    max:100
                }
            });

            let errorKey;
            if (error.response) {
                errorKey = error.response.data.key;
                if (error.response.data.additionalInformation) {
                    errorKey = errorKey + "." + error.response.data.additionalInformation;
                }
            }
            if (errorKey) {
                dispatch(postMessageAction(Message.TYPE_DANGER, "errors." + errorKey));
            } else if (error.message) {
                dispatch(postMessageAction(Message.TYPE_DANGER, error.message));
            } else {
                dispatch(postMessageAction(Message.TYPE_DANGER, "errors.api-offline"));
            }
        });
    };

    useEffect(() => {
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortQuery, paginationQuery, filters])


    return {resolved, reload, setFilters: (newFilters: Filter[]) => {
        setFilters(baseFilters.concat(newFilters))
    }, setPaginationQuery, setSortQuery, apiCallState, sortQuery}
}
