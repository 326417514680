import React, {useState} from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import Modal from "react-bootstrap/Modal";
import {AuctioneerBid} from "../../../../bids/model/auctioneerBid";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {deleteBidAction} from "../../../actions/delete-bid-action";

interface OwnProps {
    bid: EntityWrapper<AuctioneerBid>;
    useIcon?: boolean;
}

type Props = OwnProps;

export const DeleteBidButton = ({
                                    bid,
                                    useIcon = false
                                }: Props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const handleDelete = () => {
        if (bid.id) {
            dispatch(deleteBidAction(bid.id));
            handleClose();
        }
    }

    return <>
        <button
            className={"btn-link delete-bid"}
            onClick={() => handleShow()}
        >
            <FontAwesomeIcon icon={faTrash}/>
        </button>

        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <div>
                    <Button onClick={() => handleDelete()}>Endgültig löschen</Button>
                    <Button onClick={() => handleClose()}>Abbrechen</Button>
                </div>
                : null}
        </Modal>
    </>;
};
