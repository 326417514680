import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {ItemEditForm} from "../components/edit/ItemEditForm";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {USER_ROLE_ITEM_MANAGER} from "../../core/model/AuctionUserRoles";
import {ItemOverviewNew} from "../components/ItemOverviewNew";

export const ItemModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]}
                          path="/objekte/" component={ItemOverviewNew}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]}
                          path="/objekte/:itemId/edit" component={ItemEditForm}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]}
                          path="/objekte/new/" component={ItemEditForm}/>
    </>

);
};
