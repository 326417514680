import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SEND_INVOICE_EMAIL = "send-invoice-email";

export interface SendReceiptPayload {
    orderId: string,
    receiptId: string
}

export const sendReceiptEmailAction = (orderId: string, receiptId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SEND_INVOICE_EMAIL,
            callType: API_SEND_INVOICE_EMAIL
        },
        payload: {
            orderId,
            receiptId
        }
    } as ApiCallAction<SendReceiptPayload>;
};
