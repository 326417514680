import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";

export class ShippingPosition implements OrderPosition {
    public static TYPE = "shipping";

    public positionType = ShippingPosition.TYPE;

    public constructor(
        public amount: number,
        public tax: number = 19
    ) {
    }
}
