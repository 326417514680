import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_BULK_UPDATE_ALLOWED_LISTS = "bulk-update-allowed-lists";

export interface bulkUpdateAllowedListsPayload {
    customerIds: string[]
    allowedList: string
}

export const bulkUpdateAllowedListsAction = (customerIds: string[], allowedList: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_BULK_UPDATE_ALLOWED_LISTS,
            callType: API_BULK_UPDATE_ALLOWED_LISTS
        },
        payload: {
            customerIds,
            allowedList
        }
    } as ApiCallAction<bulkUpdateAllowedListsPayload>;
};
