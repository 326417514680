import {selectCombinedApiState, selectSingleEntity} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox} from "@fortawesome/free-solid-svg-icons/faBox";
import {API_GENERATE_SETTLEMENTS, generateSettlementsAction} from "../../actions/generate-settlements";


export const GenerateSettlementsButton = () => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_GENERATE_SETTLEMENTS))
    const {t} = useTranslation("auctionPlanning");
    const selectedAuction = useSelector(selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION))

    return selectedAuction ? <button type="button" disabled={apiCallState.inProgress} onClick={() => {
        dispatch(generateSettlementsAction(selectedAuction.id as string));

    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon className="mr-2"
                         icon={faBox}/>{t("auction-planning.actions.generate-settlements")}
    </button> : null;
};
