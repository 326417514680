import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {lastCallAction} from "../../../actions/last-call-action";
import {selectAuctioneerLotAttributes} from "../../../selectors";
import {LOT_ATTRIBUTE_LAST_CALL} from "@thekeytechnology/auktionshaus-frontend-library";


export const LastCallButton = () => {

    const dispatch = useDispatch();
    const attributes = useSelector(selectAuctioneerLotAttributes)

    const {t} = useTranslation(["auctioneer"]);

    return <button className={"btn btn-primary last-call-button" + (attributes.includes(LOT_ATTRIBUTE_LAST_CALL) ? " last-call-active" : "")}
                   onClick={() => dispatch(lastCallAction())}>{t("actions.last-call")}</button>
};
