import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import InviteUsersModal from "./components/overview/invite/InviteUsersModal";
import {EditUser} from "./components/single/EditUser";
import {UsersInAccountOverview} from "./components/overview/UsersInAccountOverview";
import {ModalPortal, PrivateRoute} from "@thekeytechnology/framework-react-ux";

export const UsersModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact requiredRoles={[UserWithRoles.ROLE_ADMIN]} path="/benutzer"
                          component={UsersInAccountOverview}/>

            <PrivateRoute exact requiredRoles={[UserWithRoles.ROLE_ADMIN]} path="/benutzer/:userId/rollen"
                          component={EditUser}/>

            <PrivateRoute exact requiredRoles={[UserWithRoles.ROLE_ADMIN]} path="/benutzer/einladen"
                          component={(props: any) => (
                              <>
                                  <UsersInAccountOverview {...props}/>
                                  <ModalPortal show={true}>
                                      <InviteUsersModal {...props} />
                                  </ModalPortal>,
                              </>
                          )}
            />
        </>
    );
};
