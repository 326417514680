import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBaby,
    faBoxes,
    faChevronLeft,
    faChevronRight,
    faEnvelope,
    faFileInvoice,
    faGavel,
    faGears,
    faHome,
    faList,
    faShoppingCart,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import "./sidebar.scss";
import {CollapsibleNavLink} from "./CollapsibleNavLink";
import {CollapsibleHeading} from "./CollapsibleHeading";
import {CollapsibleLogoutLink} from "./CollapsibleLogoutLink";
import {OnlyShowToRoles} from "@thekeytechnology/framework-react-ux";
import {USER_ROLE_ITEM_MANAGER} from "../../core/model/AuctionUserRoles";
import {selectCurrentUser, UserWithRoles} from "@thekeytechnology/framework-react";
import {useSelector} from "react-redux";

export const Sidebar = () => {
    const {t} = useTranslation(["sidebar", "auth"]);

    const [state, setState] = useState({isCollapsed: true});

    const user = useSelector(selectCurrentUser);

    const isCollapsed = state.isCollapsed;

    if (!user) {
        return null;
    }

    return (
        <div className={"container sidebar " + (isCollapsed ? "collapsed" : "open")}>
            <ul className="menu">
                <CollapsibleNavLink path="/dashboard" label={t("sidebar.menu.dashboard")} icon={faHome}
                                    isCollapsed={isCollapsed}/>

                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>
                    <CollapsibleHeading isCollapsed={isCollapsed} label={"Kunden"}/>
                    <CollapsibleNavLink path="/kunden" label={t("sidebar.menu.customers")} icon={faBaby}
                                        isCollapsed={isCollapsed}/>
                    <CollapsibleNavLink path="/benutzer" label={t("sidebar.menu.users")} icon={faUsers}
                                        isCollapsed={isCollapsed}/>
                </OnlyShowToRoles>


                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN, USER_ROLE_ITEM_MANAGER]}>

                    <CollapsibleHeading isCollapsed={isCollapsed} label={"Objekte"}/>
                    <CollapsibleNavLink path="/objekte" label={t("sidebar.menu.items")} icon={faBoxes}
                                        isCollapsed={isCollapsed}/>
                    <CollapsibleNavLink path="/item-categories" label={t("sidebar.menu.item-category")}
                                        icon={faList}
                                        isCollapsed={isCollapsed}/>

                </OnlyShowToRoles>

                <OnlyShowToRoles requiredRoles={[UserWithRoles.ROLE_ADMIN]}>

                    <CollapsibleHeading isCollapsed={isCollapsed} label={"Auktionen"}/>
                    <CollapsibleNavLink path="/auktionen" label={t("sidebar.menu.auctions")} icon={faGavel}
                                        isCollapsed={isCollapsed}/>
                    <CollapsibleNavLink path="/bestellungen" label={t("sidebar.menu.orders")} icon={faShoppingCart}
                                        isCollapsed={isCollapsed}/>
                    <CollapsibleNavLink path="/abrechnungen" label={t("sidebar.menu.settlements")} icon={faFileInvoice}
                                        isCollapsed={isCollapsed}/>

                    <CollapsibleHeading isCollapsed={isCollapsed} label={"Einstellungen"}/>
                    <CollapsibleNavLink path="/einstellungen" label={t("sidebar.menu.app-settings")} icon={faGears}
                                        isCollapsed={isCollapsed}/>
                    <CollapsibleNavLink path="/emails" label={t("sidebar.menu.emails")} icon={faEnvelope}
                                        isCollapsed={isCollapsed}/>
                </OnlyShowToRoles>
                <CollapsibleHeading isCollapsed={isCollapsed} label={""}/>
                <CollapsibleLogoutLink isCollapsed={isCollapsed} label={t("auth:auth.logout.label")}/>

                <li className={isCollapsed ? "d-flex justify-content-center" : undefined}
                    onClick={() => setState({isCollapsed: !isCollapsed})}>
                    <button className="btn-link" type="button">

                    <span>
                        {isCollapsed ? <FontAwesomeIcon className="separator"
                                                        icon={faChevronRight}/> :
                            <FontAwesomeIcon className="separator"
                                             icon={faChevronLeft}/>}
                        {isCollapsed ? null : t("sidebar.menu.collapse")}</span>
                    </button>
                </li>

            </ul>
        </div>
    );
};