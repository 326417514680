import React from "react";
import {useSelector} from "react-redux";
import {selectAuctioneerAuction} from "../../../../selectors";

export const AuctioneerCurrentlyActiveCustomersList = () => {

    const state = useSelector(selectAuctioneerAuction);
    const customers = state?.entity.currentlyActiveCustomers ? state?.entity.currentlyActiveCustomers : [];


    return <>{customers.map(c => {
        return <div key={c.id}>
            {c.entity.customerNumber} | {c.entity.personalData.firstName} {c.entity.personalData.lastName}
        </div>
    })}</>
}
