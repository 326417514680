import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    Pagination,
    PaginationContainer,
    SimpleHeader
} from "@thekeytechnology/framework-react-ux";
import {CustomersFilters} from "./CustomersFilters";
import {ENTITY_TYPE_CUSTOMER} from "../model/customer";
import {CustomersTable} from "./CustomersTable";

const CustomersOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/kunden"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <CustomersFilters/>
            </BackgroundContainer>
            <ContentContainer>
                <CustomersTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={ENTITY_TYPE_CUSTOMER}/>
            </PaginationContainer>
        </>
    );
};

export const CustomersOverview = withTranslation("customer")(CustomersOverviewComponent);
