export class AuctionData {
    constructor(
        public catalogDelivery?: string | undefined,
        public deliveryMethod?: string | undefined,
        public repeatOrderer?: boolean | undefined,
        public invoiceCommission?: number | undefined,
        public porto?: number | undefined,
        public sellerCommission?: number | undefined,
        public priceReduction?: number | undefined,
        public auctionTransfersLimits?: number | undefined) {
    }
}
