import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_BACK_TO_SELLER = "back-to-seller";

export interface BackToSellerActionPayload {
    lotIds: string[]
}

export const backToSellerAction = (lotIds: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_BACK_TO_SELLER,
            callType: API_BACK_TO_SELLER
        },
        payload: {
            lotIds
        }
    } as ApiCallAction<BackToSellerActionPayload>;
};
