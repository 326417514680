import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {EmailConfiguration} from "../../model/email-configuration.model";
import {EmailTable} from "./table/EmailTable";
import {
    ContentContainer,
    HeadingContainer,
    Pagination,
    PaginationContainer,
    SimpleHeader
} from "@thekeytechnology/framework-react-ux";

const EmailOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/emails"}>{t("Emails")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("Emails")}/>
            </HeadingContainer>
            <ContentContainer>
                <EmailTable/>
            </ContentContainer>
            <PaginationContainer>
                <Pagination entityType={EmailConfiguration.TYPE}/>
            </PaginationContainer>
        </>
    );
};

export const EmailOverview = withTranslation("emails")(EmailOverviewComponent);
