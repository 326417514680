import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";

export const POSITION_TYPE_WINNING_BID = "winning-bid"

export interface WinningBidPosition extends OrderPosition {
    bidRef?: string,
    lotRef: string,
    amount: number,
    source?: string,
    status: string,
    lot?: EntityWrapper<AuctionManagerLot>
}
