import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {DialogResult, SelectionModel, showDialogAction} from "@thekeytechnology/framework-react";
import {connect} from "react-redux";
import {backToSellerAction} from "../../actions/back-to-seller";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReply} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface OwnProps {
    lotId: string;
    selectionModel: SelectionModel<string>;
}

interface DispatchProps {
    backToSeller: typeof backToSellerAction,
    showDialog: typeof showDialogAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const BackToSellerButtonComponent = ({t, lotId, selectionModel, backToSeller, showDialog}: Props) => {

    return <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip id={`tooltip-remove`}>
                {t("back-to-seller-button.submit")}
            </Tooltip>
        }
    >
        <button className="btn btn-link mr-2"
                onClick={() => {
                    showDialog({
                        title: t("back-to-seller-button.dialog.title"),
                        question: t("back-to-seller-button.dialog.question"),
                        explanation: t("back-to-seller-button.dialog.explanation"),
                        affirmativeText: t("back-to-seller-button.dialog.affirmative"),
                        negativeText: t("back-to-seller-button.dialog.negative"),
                        affirmativeButtonClass: "btn btn-danger"
                    }, (r: DialogResult) => {
                        if (r === DialogResult.ACCEPT) {
                            backToSeller([lotId]);
                            selectionModel.handleClearAll();
                        }
                    });

                }}>
            <FontAwesomeIcon icon={faReply}/>
        </button>
    </OverlayTrigger>
};

export const BackToSellerButton = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        backToSeller: backToSellerAction,
        showDialog: showDialogAction
    }
)(withTranslation("auctionPlanning")(BackToSellerButtonComponent));
