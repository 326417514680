import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import itemCategoryDE from "./item-category/i18n/itemCategory.de.json";
import itemsDE from "./items/i18n/item.de.json";
import auctionDE from "./auction/i18n/auction.de.json"
import authDE from "./auth/i18n/auth.de.json";
import auctioneerDE from "./auctioneer-view/i18n/auctioneer.de.json";
import sidebarDE from "./sidebar/i18n/sidebar.de.json";
import coreDE from "./core/i18n/core.de.json";
import filesDE from "./files/i18n/files.de.json";
import auctionPlanningDE from "./auction-planning/i18n/auction-planning.de.json";
import settlementDE from "./settlements/i18n/settlement.de.json"
import emailsDE from "./emails/i18n/emails.de.json"
import usersDE from "./users/i18n/users.de.json"
import logDE from "./log/i18n/log.de.json";
import auctionAppSettingsDE from "./auction-app-settings/i18n/auction-app-settings.de.json";

import {bidDE, customerDE, liveAuctionDE, lotDE, orderDE} from "@thekeytechnology/auktionshaus-frontend-library/";

// the translations
const resources = {
    de: {
        core: coreDE,
        auth: authDE,
        bid: bidDE,
        item: itemsDE,
        itemCategory: itemCategoryDE,
        sidebar: sidebarDE,
        auctioneer: auctioneerDE,
        auction: auctionDE,
        files: filesDE,
        customer: customerDE,
        lot: lotDE,
        auctionPlanning: auctionPlanningDE,
        order: orderDE,
        settlement: settlementDE,
        emails: emailsDE,
        users: usersDE,
        log: logDE,
        liveAuction: liveAuctionDE,
        auctionAppSettings: auctionAppSettingsDE
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "de",
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            checkWhitelist: true
        }
    });

export default i18n;
