import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_FINALIZE_AUCTION = "finalize-auction";

export interface FinalizeAuctionPayload {
    auctionId: string
}

export interface AuctionFinalizationResponse {
    newStatus: string;
    errors: { [lotId: string]: string[] }
}

export const finalizeAuctionAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_FINALIZE_AUCTION,
            callType: API_FINALIZE_AUCTION
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<FinalizeAuctionPayload>;
};
