import {withTranslation, WithTranslation} from "react-i18next";
import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";

const PreferredPaymentMethodSelectFieldComponent = (props: FieldProps & WithTranslation) => {
    const {field, form, t} = props;
    const paymentMethodsOptions = [
        {
            value: "money-order",
            label: t("payment-options.money-order")
        },
        {
            value: "credit-card",
            label: t("payment-options.credit-card")
        },
        {
            value: "paypal",
            label: t("payment-options.paypal")
        },
    ];
    return (<Select
        className="react-select category-select"
        classNamePrefix="react-select"
        components={{ClearIndicator}}
        options={paymentMethodsOptions}
        placeholder={t("payment-method-select-field.placeholder")}
        name={field.name}
        value={paymentMethodsOptions.find(o => o.value === field.value)}
        onChange={(item: any) => form.setFieldValue(field.name, item.value)}
        getOptionValue={opt => opt.value}
        getOptionLabel={item => item.label}
        onBlur={field.onBlur}
    />);
};

export const PaymentMethodSelectField = withTranslation("customer")(PreferredPaymentMethodSelectFieldComponent);
