import {SortQuery} from "@thekeytechnology/framework-react";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import {TableColumn} from "@thekeytechnology/framework-react-ux";

interface OwnProps {
    property: string
    sortQuery: SortQuery
    setSortQuery: (sortQuery: SortQuery) => void
    children: React.ReactNode
}

type Props = OwnProps;

const ASCENDING_SORT = 1;
const DESCENDING_SORT = -1;

export const PaginatedEndpointSortableTableHeaderColumn = ({property, sortQuery, setSortQuery, children}: Props) => {
    const ascendingSort = sortQuery && sortQuery.sorts[property] === ASCENDING_SORT;
    const descendingSort = sortQuery && sortQuery.sorts[property] === DESCENDING_SORT;

    const toggleStatus = () => {
        if (ascendingSort) {
            setSortQuery(new SortQuery({
                [property]: DESCENDING_SORT
            }))
        } else if (descendingSort) {
            setSortQuery(new SortQuery(
                update(sortQuery ? sortQuery.sorts : {}, {
                    $unset: [property]
                })
            ));
        } else {
            setSortQuery(new SortQuery({
                [property]: ASCENDING_SORT
            }))
        }
    };

    return <TableColumn>
        <button className="btn btn-link" onClick={() => toggleStatus()}>
            {children}
        </button>
        {ascendingSort ? <FontAwesomeIcon icon={faArrowUp}/> : null}
        {descendingSort ? <FontAwesomeIcon icon={faArrowDown}/> : null}
    </TableColumn>
};
