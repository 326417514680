import React from "react";
import {EntityWrapper, generateString,} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik} from "formik";
import {updateCustomerListAction} from "../../../actions/update-customer-list";
import {Customer} from "../../../model/customer";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {CustomerListSelectField} from "../CustomerListSelectField";
import {CUSTOMER_ALLOWED_LIST_NO_LIST} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    customer: EntityWrapper<Customer>;
    editingEnabled: boolean;
}

interface DispatchProps {
    updateCustomerList: typeof updateCustomerListAction;
}

type Props = OwnProps & DispatchProps;

const InlineCustomerListEditorComponent = (props: Props) => {
    const {customer, editingEnabled, updateCustomerList} = props;

    const {t} = useTranslation(["customer", "core"])

    const popover = <Popover id={generateString(10)}>
        <Popover.Title as="h3" className="d-flex align-items-center">
            {t("entity.labels.allowed-list")}
            <button
                onClick={() => {
                    document.body.click()
                }}
                className="ml-auto btn btn-link p-0">
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </Popover.Title>
        <Popover.Content>
            <Formik
                initialValues={{
                    "allowedList": customer?.entity.allowedList ? customer.entity.allowedList : CUSTOMER_ALLOWED_LIST_NO_LIST,
                }}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    updateCustomerList(
                        customer?.id ? customer.id : "",
                        values.allowedList
                    );
                    document.body.click()
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="d-flex flex-column">
                        <ValidatedField formikState={formikState}
                                        component={CustomerListSelectField}
                                        name="allowedList"
                                        className="form-control default-input"
                        />
                        <button disabled={formikState.isSubmitting} className="btn btn-success w-100 mt-3"
                                type="submit">
                            {t("core:edit.submit")}
                        </button>
                    </Form>
                )}
            </Formik>
        </Popover.Content>
    </Popover>;

    return <div className="d-flex align-items-center ml-1">
        {customer.entity.allowedList ? t("entity.labels.allowed-lists." + customer.entity.allowedList) : t("entity.labels.allowed-lists." + CUSTOMER_ALLOWED_LIST_NO_LIST)}

        {editingEnabled ? <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
            <button className="btn btn-link ml-1">
                <FontAwesomeIcon icon={faPencilAlt}/>
            </button>
        </OverlayTrigger> : null}
    </div>
};

export const InlineCustomerListEditor = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        updateCustomerList: updateCustomerListAction
    }
)(
    InlineCustomerListEditorComponent
);
