import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {PaginatedEndPointTextFilter} from "../../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import React from "react";
import {useTranslation} from "react-i18next";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = OwnProps

export const LotNumberFilter = ({
                                    endpointFilters
                                }: Props) => {
    const {t} = useTranslation(["auction-planning", "lot", "bid"])


    return <PaginatedEndPointTextFilter
        property={"entity.lotNumber"}
        endpointFilters={endpointFilters}
        placeholder={t("core:filters.filter-by", {
            label: t("lot:entity.labels.lot-number")
        })}
        customFilterFunction={(
            property,
            endpointFilters,
            text
        ) => {
            if (text?.length) {
                const intVal = parseInt(text);
                if (intVal) {
                    endpointFilters.addFilters(property, [
                        new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, parseInt(text)))
                    ])
                }
            } else {
                endpointFilters.removeFilters([property]);
            }
        }}
    />
}
