export class CustomerForSelectField {
    public static TYPE = "customer";
    public static CONTEXT = "select-field";

    public constructor(
        public name: string,
        public customerNumber: string,
        public tel?: string
    ) {
    }
}
