import React from "react";

export interface ItemCategoryColorBubbleProps {
    color: string;
    className?: string;
}

export class ItemCategoryColorBubble extends React.Component<ItemCategoryColorBubbleProps> {
    public render() {
        const {color, className} = this.props;

        return (
            <div className={"bulb mr-2 " + className}
                 style={{
                     height: 20, width: 20, borderRadius: 25,
                     backgroundColor: color
                 }}/>
        );
    }
}
