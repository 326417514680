import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_CUSTOMER_ADDRESS = "get-customer-address";

export const getCustomerAddressAction = (customerId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_CUSTOMER_ADDRESS,
            callType: API_GET_CUSTOMER_ADDRESS
        },
        payload: customerId
    } as ApiCallAction<string>;
};
