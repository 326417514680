import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {SettlementOverview} from "../components/SettlementOverview";
import {SettlementEditForm} from "../components/SettlementEditForm";

export const SettlementModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/abrechnungen" component={SettlementOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/abrechnungen/:settlementId/edit" component={SettlementEditForm}/>
        </>
    );
};
