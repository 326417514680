import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_WINNING_BIDS = "download-winning-bids";

export interface DownloadWinningBidsPayload extends Action {
    auctionId: string;
}

export const downloadWinningBidsAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_WINNING_BIDS,
            callType: API_DOWNLOAD_WINNING_BIDS
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<DownloadWinningBidsPayload>;
};
