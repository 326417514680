import {DialogResult, EntityWrapper, selectCombinedApiState, showDialogAction} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Settlement, SETTLEMENT_STATUS_CANCELLED} from "../model/settlement";
import {API_CANCEL_SETTLEMENT, cancelSettlementAction} from "../actions/cancel-order";

interface OwnProps {
    settlement: EntityWrapper<Settlement>
    disabled?: boolean;
}

export const CancelSettlement = ({settlement, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_CANCEL_SETTLEMENT))

    return settlement.entity.status !== SETTLEMENT_STATUS_CANCELLED ?
        <button disabled={apiCallState.inProgress || disabled}
                onClick={() => {
                    dispatch(showDialogAction({
                        title: "Abrechnung stornieren",
                        question: "Soll die Abrechnung storniert werden?",
                        explanation: "",
                        affirmativeText: "Abrechnung stornieren",
                        negativeText: "Abbrechen"
                    }, result => {
                        if (result === DialogResult.ACCEPT) {
                            dispatch(cancelSettlementAction(settlement.id!))
                        }
                    }))
                }}
                className="btn btn-info mr-2" type="button">
            Abrechnung stornieren
        </button> : null;
}
