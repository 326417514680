import React, {useState} from "react";
import {Dropdown, Modal} from "react-bootstrap";
import {PartialCancellationEditor} from "./PartialCancellationEditor";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {Order, ORDER_STATUS_INVOICED} from "../../../model/Order";

interface OwnProps {
    order: EntityWrapper<Order>
}

export const PartialCancellationButton = ({order}: OwnProps) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return order.entity.status === ORDER_STATUS_INVOICED ? <>
        <Dropdown.Item
                onClick={() => handleShow()}
        >Teilstornierung</Dropdown.Item>
        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <PartialCancellationEditor order={order} handleClose={handleClose}/> : null}
        </Modal>
    </> : null;
};

