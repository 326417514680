import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_CLOSE_LOT = "close-lot";

export interface CloseLotPayload extends Action {
    lotId: string;
}

export const closeLotAction = (lotId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CLOSE_LOT,
            callType: API_CLOSE_LOT
        },
        payload: {
            lotId
        }
    } as ApiCallAction<CloseLotPayload>;
};
