import React from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {EntityWrapper, selectSingleEntity} from "@thekeytechnology/framework-react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {BidSourceSelectField} from "../../../bids/components/BidSourceSelectField";
import {submitLimitBidAuctioneerAction} from "../../../auctioneer-view/actions/submit-limit-bid-auctioneer-action";
import {LotForEditing} from "../../model/LotForEditing";
import {BidField} from "@thekeytechnology/auktionshaus-frontend-library";

interface DispatchProps {
    submitLimitBid: typeof submitLimitBidAuctioneerAction
}

interface StateProps {
    lot?: EntityWrapper<LotForEditing>
}

type Props = StateProps & DispatchProps;

const SubmitLimitBidFormComponent = ({
                                         lot,
                                         submitLimitBid
                                     }: Props) => {

    const {t} = useTranslation(["auctioneer", " bid", " liveAuction"]);

    const minimumBid = lot?.entity.pricingInformation.limitPrice ? lot.entity.pricingInformation.limitPrice : 0;

    if (lot) {
        return (<>
            <div className={"submit-limit-bid-form col-12 col-md-10 m-auto"}>
                <h2 className={"my-4"}>Vorgebote</h2>
                <Formik
                    initialValues={{
                        bid: minimumBid,
                        customerId: "",
                        source: "",
                        tel: "",
                        comment: ""
                    }}
                    validationSchema={Yup.object().shape({
                        bid: Yup.number()
                            .required(
                                t("core:forms.required-field", {fieldName: t("liveAuction:liveAuction.labels.submitBid")})
                            )
                            .min(minimumBid)
                    })}
                    onSubmit={(values, {setSubmitting, resetForm}) => {

                        submitLimitBid(
                            values.bid,
                            values.customerId,
                            values.source,
                            values.tel,
                            values.comment,
                            lot ? lot.id : undefined
                        );

                        resetForm();

                        setSubmitting(false);
                    }}
                >
                    {formikState => (
                        <Form>
                            <div className="form-group">

                                <label htmlFor="bid" className="col-sm-10 col-form-label">
                                    {t("labels.bid-height")}
                                </label>
                                <ValidatedField formikState={formikState}
                                                component={BidField}
                                                minimumBid={minimumBid}
                                                name="bid"
                                                className="form-control default-input"/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="customerId" className="col-sm-10 col-form-label">
                                    {t("labels.customer-id")}
                                </label>
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder="Kundennummer"
                                                name="customerId"
                                                className="form-control default-input"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="tel" className="col-sm-10 col-form-label">
                                    {t("labels.tel")}
                                </label>
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                placeholder={t("labels.tel")}
                                                name="tel"
                                                className="form-control default-input"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="comment" className="col-sm-10 col-form-label">
                                    {t("labels.comment")}
                                </label>
                                <ValidatedField formikState={formikState}
                                                type="text"
                                                component="textarea"
                                                placeholder={t("labels.comment")}
                                                name="comment"
                                                className="form-control default-input"/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="source" className="col-sm-10 col-form-label">
                                    {t("labels.source")}
                                </label>
                                <ValidatedField formikState={formikState}
                                                component={BidSourceSelectField}
                                                name="source"
                                                className="form-control default-input"/>
                            </div>

                            <button type={"submit"} className={"btn btn-primary align-self-end w-100"}>
                                {t("liveAuction:liveAuction.actions.submit-bid")}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>);
    } else {
        return <></>;
    }

};

export const SubmitLimitBidForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        lot: selectSingleEntity<LotForEditing>(LotForEditing.TYPE)(state)
    }),
    {
        submitLimitBid: submitLimitBidAuctioneerAction
    }
)(SubmitLimitBidFormComponent);
