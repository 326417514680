import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import * as React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface OwnProps {
    path: string;
    label: string;
    icon: IconProp;
    isCollapsed: boolean;
}

export const CollapsibleNavLink = ({
                                       path,
                                       label,
                                       icon,
                                       isCollapsed
                                   }: OwnProps) => {

    const Link = <NavLink to={path} activeClassName="active">
        <li className={isCollapsed ? "d-flex justify-content-center" : undefined}>
            <FontAwesomeIcon className="separator" icon={icon}/>{isCollapsed ? null : label}
        </li>
    </NavLink>;

    return isCollapsed ? <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip id={label.replace(" ", "-").toLowerCase()}>
                {label}
            </Tooltip>
        }
    >
        {Link}
    </OverlayTrigger> : Link
};
