import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {Action} from "redux";
import {API_GET_CUSTOMER_ADDRESS} from "../actions/customer-address";
import {CustomerAddress} from "../model/customer-address";
import {Address} from "@thekeytechnology/auktionshaus-frontend-library";

export interface CustomerAddressState {
    billingAddress?: Address;
    shippingAddress?: Address;

}

export const initialState: CustomerAddressState = {
    billingAddress: undefined,
    shippingAddress: undefined,
};

export function customerAddressReducer(state = initialState, action: Action): CustomerAddressState {
    if (isApiSuccess(API_GET_CUSTOMER_ADDRESS)(action)) {
        const customerAddressResponse = action as ApiCallSucceededAction<CustomerAddress>;
        return {
            ...state,
            billingAddress: customerAddressResponse.payload.billingAddress,
            shippingAddress: customerAddressResponse.payload.shippingAddress
        };
    }
    return state;
}
