import {WithSwitchableLanguage, WithSwitchableLanguageProps} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {ItemCategoryColorBubble} from "./ItemCategoryColorBubble";

export interface ItemCategoryDisplayItem {
    name: { [lang: string]: string },
    color: string
}

interface OwnProps {
    displayItem?: ItemCategoryDisplayItem;
}

type Props = OwnProps & WithTranslation & WithSwitchableLanguageProps;

const ItemCategoryDisplayComponent = (props: Props) => {
    const {displayItem, t} = props;

    if (!displayItem) {
        return (<div className="item-category-display">{t("custom.no-category")}</div>);
    }
    return (
        <div className="item-category-display d-flex align-items-center">
            <ItemCategoryColorBubble
                className="mr-2"
                color={displayItem.color}/>
            {displayItem.name["de"]}
        </div>
    );
};

export const ItemCategoryDisplay =
    WithSwitchableLanguage<any>(withTranslation("itemCategory")(ItemCategoryDisplayComponent));
