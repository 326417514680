import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_REOPEN_ORDER = "reopen-order";

export const reopenOrderAction = (orderId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_REOPEN_ORDER,
            callType: API_REOPEN_ORDER
        },
        payload: orderId
    } as ApiCallAction<string>;
};
