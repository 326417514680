import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Settlement} from "../model/settlement";
import {
    API_DOWNLOAD_BACK_TO_SELLER_LIST,
    downloadBackToSellerListAction
} from "../actions/download-back-to-seller-list";

interface OwnProps {
    settlement: EntityWrapper<Settlement>
    disabled?: boolean;
}

export const DownloadBackToSellerListButton = ({settlement, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_BACK_TO_SELLER_LIST))

    return <button disabled={apiCallState.inProgress || disabled}
                   onClick={() => dispatch(downloadBackToSellerListAction(settlement.id!))}
                   className="btn btn-info mr-5" type="button">
        Rücklosliste
    </button>;

}
