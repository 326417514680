import {FetchFunctionFactory} from "@thekeytechnology/framework-react-ux";
import React from "react";
import {EntityWrapper, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";
import {PaginatedEndPointAsyncEntitySelect} from "./PaginatedEndPointAsyncEntitySelect";

interface OwnProps<T> {
    property: string
    entityType: string
    shownEntityProperties: string[]
    placeholder: string
    endpointFilters: PaginatedEndpointFiltersReturnProps
    listRenderer: (item: EntityWrapper<T>) => string
    fetchFunctionFactory?: FetchFunctionFactory
    customOnSelectFunction?: (selected: EntityWrapper<T> | EntityWrapper<T>[] | undefined) => void
    customValueFunction?: (endpointFilters: PaginatedEndpointFiltersReturnProps) => EntityWrapper<T> | EntityWrapper<T>[] | undefined
    customShownEntityTypePropertyFilterCallback?: (property: string, inputValue: string) => PropertyFilter[]

}

export function PaginatedEndPointAsyncEntitySelectFilter<T>(
    {
        property,
        entityType,
        shownEntityProperties,
        placeholder,
        endpointFilters,
        listRenderer,
        fetchFunctionFactory,
        customOnSelectFunction,
        customValueFunction,
        customShownEntityTypePropertyFilterCallback
    }: OwnProps<T>
) {


    const setSelected = () => {
        if (customValueFunction) {
           return customValueFunction(endpointFilters)
        } else {
            const currentFilters = endpointFilters.getFilters().filter(f => f.key === property).flatMap(f => f.filters)
            if (currentFilters && currentFilters.length && currentFilters[0]) {
                const entity = {
                    id: (currentFilters[0] as PropertyFilter).filterTerm.value
                } as EntityWrapper<T>
                return entity
            } else {
                return undefined
            }
        }
    }

    const onSelect = (selected: EntityWrapper<T> | EntityWrapper<T>[] | undefined) => {
        if (customOnSelectFunction) {
            customOnSelectFunction(selected)
        } else {
            if (selected) {
                if (Array.isArray(selected)) {
                    //Multiple
                    endpointFilters.addFilters(property, [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING_LIST, FilterTerm.OPERATION_IN, selected.map(s => s.id)))])
                } else {
                    //Single
                    endpointFilters.addFilters(property, [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, selected.id))])
                }
            } else {
                endpointFilters.removeFilters([property])
            }
        }
    }

    return <>
        <PaginatedEndPointAsyncEntitySelect<T>
            shownEntityType={entityType}
            shownEntityTypeProperties={shownEntityProperties}
            selected={setSelected()}
            select={onSelect}
            listRenderer={listRenderer}
            placeholder={placeholder}
            fetchFunctionFactory={fetchFunctionFactory}
            customShownEntityTypePropertyFilterCallback={customShownEntityTypePropertyFilterCallback}
        />
    </>
}
