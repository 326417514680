import React, {useEffect} from "react";


interface PageTitleRouteProps {
    title: string;
    children?: React.ReactNode
}

export const PageTitle = ({
                                   title,
                                   children
                               }: PageTitleRouteProps) => {

    let oldTitle = document.title

    useEffect(() => {
        if (title) {
            document.title = title;
        }

        return () => {
            if (document.title === title) {
                document.title = oldTitle
            }
        }
    });

    return <>{children ? children : null}</>;
}