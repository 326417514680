import React from "react";
import {AuctioneerView} from "../components/AuctioneerView";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";

export const AuctioneerViewModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          path="/auktionator/" component={AuctioneerView}/>
            <PrivateRoute exact
                          path="/auktionator/:lotId" component={AuctioneerView}/>
        </>
    );
};
