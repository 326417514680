import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_CUSTOMER_ALLOWED_LIST = "update-customer-allowed-list";

export interface UpdateCustomerListPayload {
    customerId: string
    allowedList: string
}

export const updateCustomerListAction = (customerId: string, allowedList: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_CUSTOMER_ALLOWED_LIST,
            callType: API_UPDATE_CUSTOMER_ALLOWED_LIST
        },
        payload: {
            customerId,
            allowedList
        }
    } as ApiCallAction<UpdateCustomerListPayload>;
};
