import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GENERATE_SELLER_LISTS = "generate-seller-lists";

export const generateSellerListsAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GENERATE_SELLER_LISTS,
            callType: API_GENERATE_SELLER_LISTS
        },
        payload: auctionId
    } as ApiCallAction<string>;
};
