import React from "react";
import {connect} from "react-redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {selectAuctioneerLot} from "../../../selectors";
import {useTranslation} from "react-i18next";
import {AuctioneerLot} from "../../../../lots/model/AuctioneerLot";

interface StateProps {
    lot?: EntityWrapper<AuctioneerLot>
}

type Props = StateProps;

const AuctioneerLotStatsComponent = ({
                                         lot
                                     }: Props) => {

    const {t} = useTranslation(["lot"]);

    return (lot ?
            <div className={"auctioneer-view-current-lot-statistics"}>
                <span className={"price-label"}>
                    {t("entity.labels.price")}:
                </span>
                <span className={"price"}>
                    {
                        (lot.entity.pricingInformation.discountedLimitPrice ?
                                lot.entity.pricingInformation.discountedLimitPrice :
                                lot.entity.pricingInformation.limitPrice ?
                                    lot.entity.pricingInformation.limitPrice :
                                    0
                        ).toFixed(0)
                    } €
                </span>
            </div> : null
    )

};

export const AuctioneerLotStats = connect<StateProps, {}, {}>(
    (state: any) => ({
        lot: selectAuctioneerLot(state)
    }),
    {}
)
(AuctioneerLotStatsComponent);
