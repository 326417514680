import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {API_GET_NEXT_ITEM_NUMBER} from "../actions/next-item";
import {NextItemNumberDto} from "../model/next-item-number-dto";
import {Action} from "redux";


export interface ItemNumberState {
    nextItemNumber?: number;

}

export const initialState: ItemNumberState = {
    nextItemNumber: undefined,
};

export function nextItemNumberReducer(state = initialState, action: Action): ItemNumberState {
    if (isApiSuccess(API_GET_NEXT_ITEM_NUMBER)(action)) {
        const itemNumberResponse = action as ApiCallSucceededAction<NextItemNumberDto>;
        return {
            ...state,
            nextItemNumber: itemNumberResponse.payload.nextItemNumber
        };
    }
    return state;
}
