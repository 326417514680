import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import Truncate from "react-truncate"
import {
    AboveTableContainer,
    ActionLink,
    ActionTableColumn,
    AsyncEntityTableBody,
    EntityCreateAndDeleteButtons,
    EntityTableHeader,
    EntityTableRow,
    PaginationInfo,
    Table,
    TableBody,
    TableColumn
} from "@thekeytechnology/framework-react-ux";
import {faEye, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {Lot} from "../../model/Lot";
import {LotDescriptionDisplay, LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {CustomActionLink} from "../../../core/components/CustomActionLink/CustomActionLink";

type Props = WithEntityListProps<Lot> & WithSelectionsProps<string> & WithTranslation;

const LotTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        selectionModel,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={Lot.TYPE} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={Lot.TYPE}
                    createPath="/lose/new/edit"
                    label={t("entity.singular")}
                    labelPlural={t("entity.plural")}
                />
            </AboveTableContainer>
            <Table className="lot-table" itemCount={entities.length}>
                <EntityTableHeader selectionModel={selectionModel} entities={entities}>
                    <TableColumn>{t("entity.labels.name")}</TableColumn>
                    <TableColumn>{t("entity.labels.description")}</TableColumn>
                    <TableColumn>{t("entity.labels.category")}</TableColumn>
                    <TableColumn> {t("entity.labels.status")}</TableColumn>
                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </EntityTableHeader>
                <TableBody>
                    <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={lot => {
                        return (
                            <EntityTableRow key={lot.id} entity={lot}
                                            selectionModel={selectionModel}>
                                <TableColumn className="bold name-column title-column">
                                    <LotTitleDisplay description={lot.entity.description.de}/>
                                </TableColumn>
                                <TableColumn className="description-column">
                                    <Truncate lines={1}><LotDescriptionDisplay description={lot.entity.description.de}/></Truncate>
                                </TableColumn>
                                <TableColumn className="category-column">
                                    {lot.entity.category ? lot.entity.category.name.de : ""}
                                </TableColumn>
                                <TableColumn className="category-column">
                                    {t("status." + lot.entity.status)} {lot.entity.attributes.length ? `(${lot.entity.attributes.map(a => t("attribute." + a)).join(",")})` : null}
                                </TableColumn>
                                <ActionTableColumn isDisabled={false}>
                                    <>
                                        <CustomActionLink to={"/lose/" + lot.id + "/edit"}
                                                    tooltip={t("core:actions.edit")}
                                                    icon={faPencilAlt}/>
                                        <ActionLink to={"/live-auktion/" + lot.id}
                                                    tooltip={t("core:actions.view")}
                                                    icon={faEye}/>
                                    </>
                                </ActionTableColumn>
                            </EntityTableRow>
                        );
                    }}/>
                </TableBody>
            </Table>
            <PaginationInfo entityType={Lot.TYPE} className="mr-auto"/>
        </>
    );
};

export const LotTable = WithSelections<{}, string>(WithEntityList<WithSelectionsProps<string>, Lot>(
    withTranslation(["lot", "core"])(LotTableComponent), Lot.TYPE) as any);
