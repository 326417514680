import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {Order} from "../model/Order";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ORDER_STATUS_INVOICED} from "@thekeytechnology/auktionshaus-frontend-library/dist/orders/model/Order";
import {
    API_GENERATE_FINANCING_CREDIT_NOTE,
    generateFinancingCreditNoteAction
} from "../actions/generate-financing-credit-note";

interface OwnProps {
    order: EntityWrapper<Order>
    disabled?: boolean;
}

export const GenerateFinancingCreditNoteButton = ({order, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_GENERATE_FINANCING_CREDIT_NOTE))
    return order.entity.status === ORDER_STATUS_INVOICED ?
        <button disabled={apiCallState.inProgress || disabled}
                onClick={() => dispatch(generateFinancingCreditNoteAction(order.id!))}
                className="btn btn-info mr-2" type="button">
            FK Gutschrift
        </button> : null;

}
