import {EntityWrapper, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {FieldInputProps, FieldProps, FormikProps} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {ENTITY_TYPE_CUSTOMER} from "../../../customers/model/customer";
import {
    CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY,
    PaginatedEndPointAsyncEntitySelect
} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelect";
import {CUSTOMER_ALLOWED_LIST_BLACK, CustomerJustIdAndName} from "@thekeytechnology/auktionshaus-frontend-library";
import {createRangeFilterForNumberInput} from "../../../core/utils/create-range-filter-for-number-input";

interface OwnProps {
    onSelect?: (
        selected: EntityWrapper<CustomerJustIdAndName> | EntityWrapper<CustomerJustIdAndName>[] | undefined,
        form: FormikProps<any>,
        field: FieldInputProps<any>
    ) => void,
    blockBlacklistedCustomers?: boolean
}

type Props = OwnProps & FieldProps;

export const CustomerSelectField = ({
                                        field,
                                        form,
                                        onSelect,
                                        blockBlacklistedCustomers
                                    }: Props) => {

    const {t} = useTranslation(["itemCategory", "core", "customer", "item"]);

    const onSelectValueSelected = (selected: EntityWrapper<CustomerJustIdAndName> | EntityWrapper<CustomerJustIdAndName>[] | undefined) => {
        if (selected) {
            form.setFieldValue(field.name, selected);
            if (onSelect) {
                onSelect(selected, form, field);
            }
        }
    }

    const additionalFilters = !!blockBlacklistedCustomers ? [
        new PropertyFilter(
            "entity.allowedList",
            new FilterTerm(
                FilterTerm.TYPE_STRING,
                "neq",
                CUSTOMER_ALLOWED_LIST_BLACK
            )
        )
    ] : []
    console.log(blockBlacklistedCustomers)

    console.log(additionalFilters)

    return (
        <>
            <PaginatedEndPointAsyncEntitySelect<CustomerJustIdAndName>
                shownEntityType={ENTITY_TYPE_CUSTOMER}
                shownEntityTypeProperties={["entity.personalData.firstName", "entity.personalData.lastName", "entity.customerNumber"]}
                selected={field.value}
                select={onSelectValueSelected}
                listRenderer={(item: EntityWrapper<CustomerJustIdAndName>) => `${item.entity.customerNumber} - ${item.entity.name}`}
                placeholder={t("core:controls.select.select-with-type", {
                    type: t("customer:entity.singular")
                })}
                onBlur={field.onBlur}
                fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("customers/get-customers-for-select")}
                customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                    if (property === "entity.customerNumber") {
                        if (inputValue && parseInt(inputValue)) {
                            return createRangeFilterForNumberInput(property, inputValue, 5)
                        } else {
                            return []
                        }
                    } else {
                        return [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))]
                    }
                }}
                additionalFilters={additionalFilters}
            />
        </>
    );
};
