import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_REMOVE_WINNING_BID = "remove-winning-bid";

export interface RemoveWinningBidPayload extends Action {
    lotId: string
}

export const removeWinningBidAction = (lotId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_REMOVE_WINNING_BID,
            callType: API_REMOVE_WINNING_BID
        },
        payload: {
            lotId
        }
    } as ApiCallAction<RemoveWinningBidPayload>;
};
