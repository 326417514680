import {
    API_INVITATION_DATA,
    ApiCallState,
    EntityWrapper,
    Invitation,
    invitationDataRetrievalAttempt,
    selectCombinedApiState,
    selectCurrentUser,
    selectInvitation,
    UserWithRoles
} from "@thekeytechnology/framework-react";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {AcceptInvitation} from "./AcceptInvitation";
import AcceptInvitationWithNewRegistration from "./AcceptInvitationWIthNewRegistration";
import {BaseAccountScreen} from "@thekeytechnology/framework-react-ux";

interface InvitationScreenState {
    token?: string;
}

interface StateProps {
    apiCallState: ApiCallState;
    currentUser?: EntityWrapper<UserWithRoles>;
    invitation?: EntityWrapper<Invitation>;
}

interface DispatchProps {
    fetchInvitation: (token: string) => void;
}

type Props = StateProps & DispatchProps & WithTranslation;

class InvitationScreenComponent extends React.Component<Props, InvitationScreenState> {

    public constructor(props: Props, state: InvitationScreenState) {
        super(props, state);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const token = params.get("token");
        this.state = {token: token ? token : undefined};
    }

    public componentDidMount(): void {
        const {token} = this.state;
        if (token) {
            this.props.fetchInvitation(token);
        }
    }

    public render() {
        const {apiCallState, invitation, currentUser} = this.props;
        const {token} = this.state;

        return (
            <BaseAccountScreen>
                <h1 className="text-white text-center mb-5">Konto beitreten</h1>

                {apiCallState.inProgress ?
                    <div className="d-flex justify-content-center">
                        <i className="fa fa-spinner fa-spin fa-3x fa-fw"/>
                    </div> : null
                }
                {apiCallState.succeeded && token && invitation ?
                    <>
                        <div className="d-flex justify-content-center paragraph text-center mb-3">
                            {invitation.entity.invitingUserName} hat dich zum Konto
                            "{invitation.entity.accountName}" eingeladen.
                        </div>
                        {currentUser ?
                            <AcceptInvitation token={token}/>
                            : <AcceptInvitationWithNewRegistration token={token}/>
                        }
                    </>
                    : null
                }
            </BaseAccountScreen>
        );
    }
}

export const InvitationScreen = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        currentUser: selectCurrentUser(state),
        invitation: selectInvitation(state),
        apiCallState: selectCombinedApiState(API_INVITATION_DATA)(state)
    }),
    {fetchInvitation: invitationDataRetrievalAttempt}
)(withTranslation()(InvitationScreenComponent));
