import React, {useEffect, useState} from "react";
import {Field, FieldProps} from "formik";

interface OwnProps {
    optional?: boolean;
    optionalText?: string;
    tel?: string
}

type Props = OwnProps & FieldProps;

export const UpdatingTelField = ({
                                     tel,
                                     field,
                                     form,
                                     optional,
                                     optionalText,
                                     ...rest
                                 }: Props) => {

    const [state, setState] = useState({
        hidden: optional !== undefined
    });

    useEffect(() => {
        form.setFieldValue(field.name, state.hidden ? undefined : tel)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tel, state.hidden]);

    const onChange = (text: string) => {
        form.setFieldValue(field.name, text);
    };

    return (<>
        {optional ? <div className="input-field-wrapper">
            <label className="w-100 d-flex align-items-center m-0">
                <input
                    type="checkbox"
                    className="form-check-inline mr-2"
                    checked={field.value}
                    onChange={change => {
                        const checked = change.target.checked;
                        setState({hidden: !checked});

                        if (!checked) {
                            form.setFieldValue(field.name, undefined);
                        }
                    }}
                />
                <div>{optionalText}</div>
            </label>
        </div> : null}

        {!state.hidden ? <Field
            type={"tel"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>)  => onChange(event.target.value)}
            value={field.value}
            {...rest}
        /> : null}
    </>)
}
