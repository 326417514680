import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_CLOSE_LOT_FOR_BIDDING = "close-lot-for-bidding";

export interface CloseLotForBiddingPayload extends Action {
    lotId: string;
}

export const closeLotForBiddingAction = (lotId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CLOSE_LOT_FOR_BIDDING,
            callType: API_CLOSE_LOT_FOR_BIDDING
        },
        payload: {
            lotId
        }
    } as ApiCallAction<CloseLotForBiddingPayload>;
};
