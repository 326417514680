import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import {DownloadPdfCatalogueForm} from "./DownloadPdfCatalogueForm";
import {Dropdown} from "react-bootstrap";

export const DownloadPdfCatalogueButton = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        <Dropdown.Item
            onClick={() => handleShow()}
        >
            <FontAwesomeIcon icon={faBook}/> Katalog
        </Dropdown.Item>

        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <DownloadPdfCatalogueForm handleClose={handleClose}/> : null}
        </Modal>
    </>;
}
