import {selectApiBase, selectAuthState, withAuthedAxios} from "@thekeytechnology/framework-react";
import {useSelector} from "react-redux";

interface CustomEndpointReturnProps<T> {
    call: (callback: (result: T) => void, data?: any) => void
}

export function useCustomEndpoint<T>(endpoint: string): CustomEndpointReturnProps<T>  {

    const authState = useSelector(selectAuthState)
    const apiBase = useSelector(selectApiBase)
    const authAxios = withAuthedAxios(apiBase, authState)

    const call = (callback: (result: T) => void, data?: any) => {
        authAxios.post<T>(endpoint, data)
            .then(result => callback(result.data))
    }

    return {
        call
    } as CustomEndpointReturnProps<T>
}
