import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
    ContentContainer,
    HeadingContainer,
    PaddedContainer,
    SimpleHeader,
    UnconnectedPagination
} from "@thekeytechnology/framework-react-ux";
import {usePaginatedEndpointFilters} from "../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {usePaginatedEndpoint} from "../../paginated-endpoints/hooks/use-paginated-endpoint";
import {Item} from "../model/item";
import {selectReloadView} from "../../auction-planning/selector";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {ItemFiltersNew} from "./filter/ItemFiltersNew";
import {ItemTableNew} from "./ItemTableNew";

export const ItemOverviewNew = () => {

    const {t} = useTranslation(["item"])

    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "item-overview-table"
    )

    const {
        resolved,
        reload,
        setFilters,
        apiCallState,
        setPaginationQuery,
        setSortQuery,
        sortQuery
    } = usePaginatedEndpoint<Item>(
        "/items/get-items",
        undefined,
        [],
        endpointFilters.getFilters().flatMap(f => f.filters)
    )


    // This is a bit of a hack. When we use some action to update data, this value will be updated on apiSuccess,
    // which we then use here to trigger an entity reload
    const lastUpdate = useSelector(selectReloadView);
    useEffect(() => {
        if (lastUpdate) {
            reload()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastUpdate]);

    return (
        <>
            <BreadcrumbsItem to={"/item"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <PaddedContainer>
                <ItemFiltersNew
                    endpointFilters={endpointFilters}
                />
            </PaddedContainer>
            <ContentContainer>
                <ItemTableNew
                    entities={resolved.entities}
                    apiCallState={apiCallState}
                    sortQuery={sortQuery}
                    setSortQuery={setSortQuery}
                    pagination={resolved.pagination}
                />
                <UnconnectedPagination pagination={resolved.pagination} setPage={page => {
                    setPaginationQuery({
                        page,
                        docsPerPage: resolved.pagination.docsPerPage
                    })
                }}/>
            </ContentContainer>
        </>
    );
};
