import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_P86_CSV = "download-p86-csv";

export interface DownloadP86CsvPayload extends Action {
    auctionId: string;
}

export const downloadP86CsvAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_P86_CSV,
            callType: API_DOWNLOAD_P86_CSV
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<DownloadP86CsvPayload>;
};
