import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {CustomerForSaving} from "../model/customer-for-saving";

export const API_SAVE_CUSTOMER = "save-customer";



export const saveCustomerAction = (customer: EntityWrapper<CustomerForSaving>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_CUSTOMER,
            callType: API_SAVE_CUSTOMER
        },
        payload: customer
    } as ApiCallAction<EntityWrapper<CustomerForSaving>>;
};
