import {combineReducers} from "redux";
import {validationReducer} from "./validation-reducer"
import {reloadViewReducer} from "./reload-view-reducer";

export const auctionPlanningModuleReducer = combineReducers({
    validation: validationReducer,
    reloadView: reloadViewReducer
});

export type AuctionPlanningModuleState = ReturnType<typeof auctionPlanningModuleReducer>;
