import {EntityWrapper} from "@thekeytechnology/framework-react";
import {User} from "../../customers/model/customer";

export const ENTITY_TYPE_LOG_ENTRY = "log-entry"

export interface LogEntry {
    createdAt: string
    user?: EntityWrapper<User>
    entityType?: string
    entityRef?: string
    operation?: string
    message?: string
    comment?: string
}

export interface LogEntryForSaving {
    createdAt: string
    userRef?: string
    entityType?: string
    entityRef?: string
    operation?: string
    message?: string
    comment?: string
}
