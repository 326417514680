import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {prevLotAction} from "../../../actions/prev-lot-action";

export const PrevLotButton = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation(['auctioneer']);

    return <button className={"btn btn-primary prev-lot-button"} onClick={() => dispatch(prevLotAction())}>{t("actions.prev-lot")}</button>
};
