import React, {useEffect} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AsyncEntitySelectField, ContentContainer, ValidatedField} from "@thekeytechnology/framework-react-ux";
import {RouteComponentProps} from "react-router";
import {
    addFiltersAction,
    EntityWrapper,
    fetchSingleEntityAction,
    FilterTerm,
    PropertyFilter,
    selectSingleEntity
} from "@thekeytechnology/framework-react";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {
    BidField,
    CUSTOMER_ALLOWED_LIST_BLACK,
    extractLotTitle,
    LOT_SOURCE_ADDED_MANUALLY,
    ShortLot
} from "@thekeytechnology/auktionshaus-frontend-library";
import {UpdatingTelField} from "./UpdatingTelField";
import {submitLimitBidAuctioneerAction} from "../../../auctioneer-view/actions/submit-limit-bid-auctioneer-action";
import {Customer, ENTITY_TYPE_CUSTOMER} from "../../../customers/model/customer";
import {downloadLimitBidsAction} from "../../actions/limit-bids/download-limit-bids";
import {UploadLimitBidsButton} from "./UploadLimitBidsButton";
import {UploadLimitBidsTelephoneListButton} from "./UploadLimitBidsTelephoneListButton";

type Props = RouteComponentProps<any>

interface FormState {
    lot: EntityWrapper<ShortLot> | undefined
    customer: EntityWrapper<Customer> | undefined
    bid: number,
    tel: string | undefined
}

export const LimitBidScreen = ({
                                   match: {params: {auctionId}}
                               }: Props) => {


    const {t} = useTranslation(['bid', 'customer', 'lot', 'liveAuction']);
    const dispatch = useDispatch();
    const auction = useSelector(selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION))

    useEffect(() => {
        if (auctionId) {
            dispatch(fetchSingleEntityAction(ENTITY_TYPE_AUCTION)(auctionId));
            dispatch(addFiltersAction(ShortLot.TYPE)("entity.auctionRef", [new PropertyFilter("entity.auctionRef", new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, auctionId))]))
        }
    }, [dispatch, auctionId]);

    return (
        <div className={"limit-bid-screen padded-container pl-2 pl-xs-3 pl-lg-3 pl-xl-5 pr-2 pt-4 pb-4 bg-white"}>
            <h1>Vorgebote hinzufügen: {auction?.entity.auctionNumber}</h1>
            <Formik<FormState>
                initialValues={{
                    lot: undefined,
                    customer: undefined,
                    bid: 0,
                    tel: undefined
                }}
                validationSchema={Yup.object().shape({
                    lot: Yup.object().required(t("core:forms.required-field", {fieldName: t("lot:entity.singular")})),
                    customer: Yup.string().required(t("core:forms.required-field", {fieldName: t("customer:entity.singular")})),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    if (values.customer?.id && values.lot?.id) {
                        dispatch(submitLimitBidAuctioneerAction(
                            values.bid,
                            values.customer?.id,
                            LOT_SOURCE_ADDED_MANUALLY,
                            "",
                            values.lot?.id,
                            values.tel
                        ))
                    }
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form>
                        <ContentContainer>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AsyncEntitySelectField}
                                                placeholder={t("customer:entity.singular")}
                                                name="customer"
                                                className="form-control default-input"
                                                label={t("customer:entity.singular")}
                                                required
                                                listRenderer={(value: EntityWrapper<Customer>) => value.entity.customerNumber + " " + value.entity.personalData.firstName + " " + value.entity.personalData.lastName}
                                                shownEntityType={ENTITY_TYPE_CUSTOMER}
                                                shownEntityTypeProperties={["entity.customerNumber", "entity.personalData.firstName", "entity.personalData.lastName"]}
                                                customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                                                    if ("entity.customerNumber" === property) {
                                                        const intVal = parseInt(inputValue);
                                                        if (intVal) {
                                                            return new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, intVal))
                                                        }
                                                    }
                                                    return new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))
                                                }}
                                                shownEntityTypeContext={ENTITY_TYPE_CUSTOMER}
                                                fetch
                                                additionalFilters={[new PropertyFilter(
                                                    "entity.allowedList",
                                                    new FilterTerm(
                                                        FilterTerm.TYPE_STRING,
                                                        "neq",
                                                        CUSTOMER_ALLOWED_LIST_BLACK
                                                    )
                                                )]}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={AsyncEntitySelectField}
                                                placeholder={t("lot:entity.singular")}
                                                name="lot"
                                                className="form-control default-input"
                                                label={t("lot:entity.singular")}
                                                required
                                                listRenderer={(value: EntityWrapper<ShortLot>) => value?.entity.lotNumber + " " + extractLotTitle(value.entity.description.de)}
                                                shownEntityType={ShortLot.TYPE}
                                                shownEntityTypeProperties={["entity.lotNumber"]}
                                                customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                                                    if ("entity.lotNumber" === property) {
                                                        const intVal = parseInt(inputValue);
                                                        if (intVal) {
                                                            return new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, intVal))
                                                        }
                                                    }
                                                    return new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))
                                                }}
                                                shownEntityTypeContext={ShortLot.CONTEXT}
                                                additionalFilters={[
                                                    new PropertyFilter(
                                                        "entity.auctionRef",
                                                        new FilterTerm(
                                                            FilterTerm.TYPE_STRING,
                                                            FilterTerm.OPERATION_EQ,
                                                            auctionId
                                                        )
                                                    )
                                                ]}
                                />
                            </div>
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={UpdatingTelField}
                                                placeholder={t("entity.labels.tel")}
                                                name="tel"
                                                className="form-control default-input"
                                                label={t("bid:entity.labels.tel")}
                                                tel={formikState.values.customer?.entity.personalData.tel || undefined}
                                                optional={true}
                                                optionalText={"Telefongebot"}
                                />
                            </div>
                            Limitpreis: {formikState.values.lot?.entity.limitPrice}
                            <div className="form-group row">
                                <ValidatedField formikState={formikState}
                                                component={BidField}
                                                disableSnapping={true}
                                                name="bid"
                                                minimumBid={0}
                                                className="form-control default-input"
                                                label={t("entity.labels.value")}
                                                required
                                />
                            </div>
                            <div className="form-group row">
                                <button className={"btn btn-primary"}
                                        disabled={formikState.isSubmitting}
                                        type={"submit"}
                                >
                                    {t("liveAuction:liveAuction.actions.submit-limit-bid")}
                                </button>
                            </div>
                        </ContentContainer>
                    </Form>
                )
                }
            </Formik>
            <div>
                <button className={"btn btn-primary mb-1"} onClick={() => {
                    dispatch(downloadLimitBidsAction(auctionId))
                }}>Download</button><br/>
                <UploadLimitBidsButton auctionId={auctionId}/>
                <br/>
                <UploadLimitBidsTelephoneListButton auctionId={auctionId} />
            </div>
        </div>
    )
}
