import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GROUP_LOTS = "group-lots";

export interface GroupLotsActionPayload {
    lotIds: string[]
}

export const groupLotsAction = (lotIds: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GROUP_LOTS,
            callType: API_GROUP_LOTS
        },
        payload: {
            lotIds
        }
    } as ApiCallAction<GroupLotsActionPayload>;
};
