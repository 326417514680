import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {fetchWarningsAction} from "../actions/fetch-warning";
import {selectWarnings} from "../selectors";

interface OwnProps {
    customerId?: string;
}

export const CustomerWarnings = ({customerId}: OwnProps) => {
    const dispatch = useDispatch();

    const warnings = useSelector(selectWarnings)

    useEffect(() => {
        if (customerId) {
            dispatch(fetchWarningsAction(customerId))
        }
    }, [dispatch, customerId])

    return warnings ? <div className="alert alert-danger mb-5">
        KUNDENWARNUNG: {warnings}
    </div> : null;
}
