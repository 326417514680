import React from "react";
import {generateString} from "@thekeytechnology/framework-react";
import * as Yup from "yup";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik} from "formik";
import {updateCommentAction} from "../actions/update-comment";

interface OwnProps {
    logEntryId: string;
    currentComment?: string
}


export const InlineCommentEditor = ({logEntryId, currentComment}: OwnProps) => {
    const {t} = useTranslation("log");
    const dispatch = useDispatch();

    const popover = <Popover id={generateString(10)}>
        <Popover.Title as="h3" className="d-flex align-items-center">
            {t("entity.labels.comment")}
            <button
                onClick={() => {
                    document.body.click()
                }}
                className="ml-auto btn btn-link p-0">
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </Popover.Title>
        <Popover.Content>
            <Formik
                initialValues={{
                    comment: currentComment ? currentComment : "",
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    comment: Yup.string().required()
                })}
                onSubmit={(values, {setSubmitting}) => {
                    dispatch(updateCommentAction(
                        logEntryId,
                        values.comment
                    ))
                    document.body.click()
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="d-flex flex-column">
                        <ValidatedField formikState={formikState}
                                        type="text"
                                        name="comment"
                                        className="form-control default-input"
                                        required/>
                        <button disabled={formikState.isSubmitting}
                                className="btn btn-success w-100 mt-3"
                                type="submit">
                            {t("inline-editor.save")}
                        </button>
                    </Form>
                )}
            </Formik>
        </Popover.Content>
    </Popover>;

    return <div className="d-flex align-items-center">
        {currentComment}

        <OverlayTrigger rootClose={true} trigger="click" placement="left" overlay={popover}>
            <button className="btn btn-link ml-1">
                <FontAwesomeIcon icon={faPencilAlt}/>
            </button>
        </OverlayTrigger>
    </div>
};
