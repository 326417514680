import React from "react";

interface OwnProps {
    url: string | undefined
    className?: string
}

type Props = OwnProps;

export const LotThumbnailDisplay = ({
                                        url,
                                        className
                                    }: Props) => {

    return <div className={"thumbnail lot-thumbnail " + (className ? className : "")}>
        <img alt={"Placeholder"} className={"tk-image"}
             src={url}/>
    </div>;
};
