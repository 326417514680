import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {useDispatch, useSelector} from "react-redux";
import {API_DOWNLOAD_CUSTOMER_PRINT_SHEET, downloadCustomerPrintSheet} from "../../actions/download-print-sheet";

interface OwnProps {
    customerId: string
}

export const DownloadCustomerPrintSheetButton = ({customerId}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_CUSTOMER_PRINT_SHEET))

    return <button disabled={apiCallState.inProgress}
                   onClick={() => dispatch(downloadCustomerPrintSheet(customerId))}
                   className="btn btn-info mr-5" type="button">
        Stammdaten PDF
    </button>;

}
