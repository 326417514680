import {DropdownButton} from "react-bootstrap";
import React from "react";
import {DownloadPdfCatalogueButton} from "./pdf-catalogue/DownloadPdfCatalogueButton";
import {DownloadAuctionProtocolButton} from "./DownloadAuctionProtocolButton";
import {DownloadP86CsvButton} from "./DownloadP86CsvButton";
import {DownloadWinningBidsButton} from "./DownloadWinningBidsButton";
import {DownloadLimitBidsButton} from "./DownloadLimitBidsButton";
import {DownloadCatalogueDeliveryLabelsButton} from "./DownloadCatalogueDeliveryLabelsButton";

export const AuctionManagerDownloads = () => {


    return <>
        <DropdownButton title={"Downloads..."} className={"mr-2"}>
            <DownloadWinningBidsButton/>
            <DownloadPdfCatalogueButton/>
            <DownloadAuctionProtocolButton/>
            <DownloadP86CsvButton/>
            <DownloadLimitBidsButton/>
            <DownloadCatalogueDeliveryLabelsButton/>
        </DropdownButton>

    </>
}