import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {
    API_DOWNLOAD_ITEMS_FOR_TRANSLATION,
    downloadItemsForTranslationAction
} from "../actions/download-items-for-translation";

interface OwnProps {
    auctionId?: string
}

type Props = OwnProps

export const DownloadItemsForTranslationButton = ({
                                              auctionId
                                          }: Props) => {

    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_ITEMS_FOR_TRANSLATION))

    const {t} = useTranslation("item");

    return <button type="button" disabled={apiCallState.inProgress} onClick={() => {
        dispatch(downloadItemsForTranslationAction(auctionId));

    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon className="mr-2"
                         icon={faDownload}/>{t("actions.download-items-for-translation")}
    </button>;
}
