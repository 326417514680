import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {Order, ORDER_STATUS_OPEN} from "../model/Order";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {API_FINALIZE_INVOICE, finalizeInvoiceAction} from "../actions/finalize-invoice";

interface OwnProps {
    order: EntityWrapper<Order>
    disabled?: boolean;
    isDirty: boolean
}

export const GenerateInvoiceButton = ({
                                          order,
                                          disabled,
                                          isDirty
                                      }: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_FINALIZE_INVOICE))

    return order.entity.status === ORDER_STATUS_OPEN ?
        <button disabled={apiCallState.inProgress || disabled}
                onClick={() => dispatch(finalizeInvoiceAction(order.id!))}
                className="btn btn-info mr-5" type="button"
        >
            Rechnung erstellen {isDirty ? "(bitte vorher speichern)" : null}
        </button>
        : null;

}
