import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {Action} from "redux";
import {API_FETCH_WARNING} from "../actions/fetch-warning";

export interface WarningModuleState {
    warning?: string;
}

export const initialState: WarningModuleState = {
    warning: undefined,
};

export function warningModuleReducer(state = initialState, action: Action): WarningModuleState {
    if (isApiSuccess(API_FETCH_WARNING)(action)) {
        const fetchWarningAction = action as ApiCallSucceededAction<{ warning?: string }>;
        return {
            ...state,
            warning: fetchWarningAction.payload.warning
        };
    }
    return state;
}
