import {SelectionModel} from "@thekeytechnology/framework-react";
import {FieldProps} from "formik";
import React from "react";
import {AttachmentSelectWithDownload} from "./AttachmentSelectWithDownload";

interface AttachmentFieldProps {
    selectionModel: SelectionModel<string>
    isSingleSelect: boolean;
}

type Props = FieldProps & AttachmentFieldProps;

export const AttachmentFieldWithDownload = ({selectionModel, isSingleSelect, form, field}: Props) => {

    return <AttachmentSelectWithDownload selectionModel={selectionModel}
                             isSingleSelect={isSingleSelect}
                             changeValue={(newValue => form.setFieldValue(field.name, newValue))}
                             value={field.value}/>
}
