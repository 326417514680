import {CreditCard} from "./credit-card";

export class PaymentData {

    constructor(
        public iban?: string | undefined,
        public bic?: string | undefined,
        public paymentMethod?: string | undefined,
        public vatId?: string | undefined,
        public daysUntilReminderBill?: number | undefined,
        public creditCardNumber?: string,
        public creditCard?: CreditCard
    ) {

    }
}
