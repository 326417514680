import {EntityWrapper} from "@thekeytechnology/framework-react";
import {
    Address,
    AttachedReceipt,
    CustomerJustIdAndName,
    OrderHistory,
    OrderPosition
} from "@thekeytechnology/auktionshaus-frontend-library";
import moment from "moment";

export const ENTITY_TYPE_ORDER = "order";
export const ORDER_CONTEXT_TABLE = "table";
export const ORDER_CONTEXT_AGGREGATED = "aggregated";

export const ORDER_STATUS_OPEN = "open"
export const ORDER_STATUS_CANCELLED = "cancelled"
export const ORDER_STATUS_INVOICED = "invoiced"

export const RECEIPT_STATUS_OPEN = "open"
export const RECEIPT_STATUS_PAID = "paid"
export const RECEIPT_STATUS_PAIDOFF = "paidoff"
export const RECEIPT_STATUS_VOIDED = "voided"

export const DUNNING_STATUS_OPEN = "open"
export const DUNNING_STATUS_REMINDER_MAIL_SENT = "reminder-mail-sent"
export const DUNNING_STATUS_DUNNING_SENT = "dunning-sent"

export const SHIPPING_STATUS_OPEN = "open"
export const SHIPPING_STATUS_COMMISSIONED = "commissioned"
export const SHIPPING_STATUS_SENT = "sent"

export const SHIPPING_METHOD_DHL = "dhl"
export const SHIPPING_METHOD_DPD = "dpd"
export const SHIPPING_METHOD_PICKUP = "pickup"
export const SHIPPING_METHOD_REGISTERED_MAIL = "registered-mail"
export const SHIPPING_METHOD_OTHER = "other"
export const SHIPPING_METHOD_ALLKURIER = "allkurier"

export const LOT_BILLING_STATUS_OPEN = "open"
export const LOT_BILLING_STATUS_RECEIPT_CREATED = "receipt-created"
export const LOT_BILLING_STATUS_PAID = "paid"
export const LOT_BILLING_STATUS_NO_PAYMENT = "no-payment"
export const LOT_BILLING_STATUS_ADDED_TO_BILLING = "added-to-billing"

export const ENTITY_NEW_ORDER_FACTORY = () => new EntityWrapper<Order>(
    undefined,
    {
        billingAddress: {postalCode: "", name: "", houseNumber: "", country: "de", city: "", addition: "", street: ""},
        status: ORDER_STATUS_OPEN,
        positions: [],
        attachedReceipts: [],
        orderHistory: [],
        createdAt: moment().toISOString(),
        sum: 0
    }
);

export interface Order {
    orderNumber?: number,
    customer?: EntityWrapper<CustomerJustIdAndName>,
    positions: OrderPosition[],
    billingAddress: Address,
    shippingAddress?: Address,
    trackingNumber?: string,
    pickingNumber?: string,
    status: string,
    comment?: string,
    attachedReceipts: AttachedReceipt[],
    orderHistory: OrderHistory[],
    createdAt: string,
    sum: number,
    auctionRef?: string
    shippingStatus?: string,
    shippingMethod?: string
}
