import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_MANUAL_ASSIGN_WINNING_BID = "manual-assign-winning-bid";

export interface ManualAssignWinningBidPayload extends Action {
    value: number;
    customerId: string;
    lotId: string;
    source: string;
}

export const manualAssignWinningBidAction = (
    value: number,
    customerId: string,
    lotId: string,
    source: string
) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_MANUAL_ASSIGN_WINNING_BID,
            callType: API_MANUAL_ASSIGN_WINNING_BID
        },
        payload: {
            value,
            customerId,
            lotId,
            source
        }
    } as ApiCallAction<ManualAssignWinningBidPayload>;
};
