import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_PARTIALLY_CANCEL_ORDER = "partially-cancel-order"

export interface CancelOrderActionPayload {
    orderId: string
    cancelledBidIds: string[]
    newLotStatus: string
}

export const partiallyCancelOrderAction = (orderId: string, cancelledBidIds: string[], newLotStatus: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_PARTIALLY_CANCEL_ORDER,
            callType: API_PARTIALLY_CANCEL_ORDER
        },
        payload: {
            orderId,
            cancelledBidIds,
            newLotStatus
        }
    } as ApiCallAction<CancelOrderActionPayload>;
};
