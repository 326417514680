import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import "./../../../assets/scss/table.scss"
import {faPencilAlt, faPlay} from "@fortawesome/free-solid-svg-icons";
import {
    AboveTableContainer,
    ActionLink,
    ActionTableColumn,
    AsyncEntityTableBody,
    DateTimeDisplay,
    EntityCreateAndDeleteButtons,
    EntityTableHeader,
    EntityTableRow,
    PaginationInfo,
    Table,
    TableColumn
} from "@thekeytechnology/framework-react-ux";
import {withTranslation, WithTranslation} from "react-i18next";
import {Auction, AUCTION_STATUS_FINALIZED, AUCTION_STATUS_LIVE, ENTITY_TYPE_AUCTION} from "../model/auction";
import {faBookOpen} from "@fortawesome/free-solid-svg-icons/faBookOpen";
import "./auctions-table.scss";
import {StartAuctionButton} from "../../auctioneer-view/components/StartAuctionButton";

type Props = WithEntityListProps<Auction> & WithSelectionsProps<string> & WithTranslation;

const AuctionTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        selectionModel,
        t
    } = props;

    const sortedAuctions = entities.sort((a,b) => {
        return a.entity.auctionNumber < b.entity.auctionNumber ? 1 : -1
    })

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_AUCTION} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={ENTITY_TYPE_AUCTION}
                    createPath="/auktionen/new/edit"
                    label={t("entity.singular")}
                    labelPlural={t("entity.plural")}
                />
            </AboveTableContainer>
            <Table className="auctions-table" itemCount={entities.length}>
                <EntityTableHeader selectionModel={selectionModel} entities={entities}>
                    <TableColumn>{t("entity.labels.auction-number")}</TableColumn>
                    <TableColumn> {t("entity.labels.start-date-time")}</TableColumn>
                    <TableColumn>{t("entity.labels.preview-date-time")}</TableColumn>
                    <TableColumn> {t("entity.labels.next-auction-date-time")}</TableColumn>
                    <TableColumn> {t("entity.labels.status")}</TableColumn>
                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </EntityTableHeader>

                <AsyncEntityTableBody apiCallState={entityApiState} items={sortedAuctions} renderItem={auction => {
                    return (
                        <EntityTableRow key={auction.id} selectionModel={selectionModel} entity={auction}>
                            <TableColumn className="bold globalNumber-column title-column">
                                {auction.entity.auctionNumber}
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay
                                    dateTime={auction.entity.startDateTime}/>
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay
                                    dateTime={auction.entity.previewDateTime}/>
                            </TableColumn>
                            <TableColumn>
                                <DateTimeDisplay
                                    dateTime={auction.entity.nextAuctionDateTime}/>
                            </TableColumn>
                            <TableColumn>
                                {t(`status.${auction.entity.status}`)}
                            </TableColumn>
                            <ActionTableColumn isDisabled={false}>
                                <>
                                    <ActionLink to={"/auktionen/" + auction.id + "/edit"}
                                                tooltip={t("core:actions.edit")}
                                                icon={faPencilAlt}/>
                                    <ActionLink to={"/auktionen/" + auction.id + "/planen"}
                                                tooltip={t("auction-planning.actions.plan")}
                                                icon={faBookOpen}/>
                                    {auction.entity.status === AUCTION_STATUS_FINALIZED && auction.id ?
                                        <StartAuctionButton auctionId={auction.id}/>
                                        : null
                                    }
                                    {auction.entity.status === AUCTION_STATUS_LIVE && auction.id ?
                                        <ActionLink to={"/auktionator/"}
                                                    tooltip={t("Zur Live Auktion")}
                                                    icon={faPlay}/>
                                        : null
                                    }
                                </>
                            </ActionTableColumn>
                        </EntityTableRow>
                    );
                }}/>
            </Table>
        </>
    );
};

export const AuctionsTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, Auction>(
        withTranslation(["auction", "core", "liveAuction"])(AuctionTableComponent), ENTITY_TYPE_AUCTION
    ) as any
);
