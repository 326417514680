import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_PDF_CATALOGUE = "download-pdf-catalogue";

export interface DownloadPdfCataloguePayload extends Action {
    auctionId: string;
    categoryId: string
}

export const downloadPdfCatalogueAction = (auctionId: string, categoryId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_PDF_CATALOGUE,
            callType: API_DOWNLOAD_PDF_CATALOGUE
        },
        payload: {
            auctionId,
            categoryId
        }
    } as ApiCallAction<DownloadPdfCataloguePayload>;
};
