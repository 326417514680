import {ComplexFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {PaginatedEndPointTextFilter} from "../../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import React from "react";
import {useTranslation} from "react-i18next";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = OwnProps

export const InventoryNumberFilter = ({
                                    endpointFilters
                                }: Props) => {
    const {t} = useTranslation(["auction-planning", "lot", "bid"])


    return <PaginatedEndPointTextFilter
        property={"inventoryNumber"}
        endpointFilters={endpointFilters}
        placeholder={t("core:filters.filter-by", {
            label: t("lot:entity.labels.inventory-number")
        })}
        customFilterFunction={(
            property,
            endpointFilters,
            text
        ) => {
            if (text?.length) {
                const intVal = parseInt(text);
                if (intVal) {
                    endpointFilters.addFilters(property, [
                        new ComplexFilter(property, {
                            "inventoryNumber": intVal.toString()
                        })
                    ])
                }
            } else {
                endpointFilters.removeFilters([property]);
            }
        }}
        customValueFunction={(endpointFilters: PaginatedEndpointFiltersReturnProps) => {
            const currentFilters = endpointFilters.getFilters().filter(f => f.key === "inventoryNumber").flatMap(f => f.filters)

            return currentFilters && currentFilters.length && currentFilters[0] ?
                (currentFilters[0] as ComplexFilter).parameters['inventoryNumber'] : ""
        }}
    />
}
