import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF = "download-settlement-preview-pdf";

export const downloadSettlementPreviewPdfAction = (settlementId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF,
            callType: API_DOWNLOAD_SETTLEMENT_PREVIEW_PDF
        },
        payload: settlementId
    } as ApiCallAction<string>;
};
