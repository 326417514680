import {
    API_CREATE_ENTITIES,
    API_DELETE_ENTITIES,
    ApiCallAction,
    ApiCallSucceededAction,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadEntityListAction,
} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import "@thekeytechnology/framework-react/"
import {API_ASSIGN_PREASSIGNED, AssignedItemsPayload} from "../actions/assign/assign-preassigned-items";
import {Observable} from "rxjs";
import {Action} from "redux";
import {map} from "rxjs/operators";
import {API_MANUALLY_ASSIGN_ITEMS, ManuallyAssignItemsPayload} from "../actions/assign/manually-assign-items";
import {API_TRANSFER_LOTS, TransferLotsPayload} from "../actions/assign/transfer";
import {API_GROUP_LOTS, GroupLotsActionPayload} from "../actions/group/group-lots";
import {API_UNGROUP_LOT, UngroupLotActionPayload} from "../actions/group/ungroup-lots";
import {API_UPDATE_DESCRIPTION, UpdateDescriptionPayload} from "../actions/inline-update/update-description";
import {API_UPDATE_LIMIT_PRICE, UpdateLimitPricePayload} from "../actions/inline-update/update-limit-price";
import {API_UPDATE_SELLER, UpdateSellerPayload} from "../actions/inline-update/update-seller";
import {API_UPDATE_CATEGORY, UpdateCategoryPayload} from "../actions/inline-update/update-category";
import {API_FINALIZE_AUCTION, FinalizeAuctionPayload} from "../actions/finalize-auction";
import {API_BACK_TO_SELLER, BackToSellerActionPayload} from "../actions/back-to-seller";
import {push} from "connected-react-router";
import {API_GENERATE_INVOICES} from "../actions/generate-invoices";
import {API_UPDATE_BUYER, UpdateBuyerPayload} from "../actions/inline-update/update-buyer";
import {AuctionManagerLot} from "../../lots/model/AuctionManagerLot";
import {API_GENERATE_SETTLEMENTS} from "../actions/generate-settlements";
import {API_DOWNLOAD_LIMIT_BIDS, DownloadLimitBidsPayload} from "../actions/limit-bids/download-limit-bids";
import {DownloadCsvData} from "../../core/model/DownloadCsvData";
import {API_UPLOAD_LIMIT_BIDS, UploadCsvFileWithAuctionIdDto} from "../actions/limit-bids/upload-limit-bids";
import {
    API_MANUAL_ASSIGN_WINNING_BID,
    ManualAssignWinningBidPayload
} from "../actions/winning-bids/manual-assign-winning-bid-action";
import {API_DOWNLOAD_WINNING_BIDS, DownloadWinningBidsPayload} from "../actions/winning-bids/download-winning-bids";
import {API_REMOVE_WINNING_BID, RemoveWinningBidPayload} from "../actions/winning-bids/remove-winning-bid-action";
import {
    API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID,
    AssignBuyersBasedOnManuelIdPayload
} from "../actions/assign-buyers/assign-buyers-based-on-manual-id";
import {DownloadPdfData} from "../../core/model/DownloadPdfData";
import {API_DOWNLOAD_P86_CSV} from "../actions/download-csvs/download-p86-csv";
import {API_GENERATE_SELLER_LISTS} from "../actions/seller-lists/generate-seller-lists";
import {API_SEND_SELLER_LIST_EMAIL, SendSellerListPayload} from "../actions/seller-lists/send-seller-list-email";
import {
    API_SEND_BULK_SELLER_LIST_EMAIL,
    SendBulkSellerListPayload
} from "../actions/seller-lists/send-bulk-seller-list-email";
import {triggerCsvResponseDownload} from "../../core/utils/trigger-csv-response-download";
import {API_DOWNLOAD_PDF_CATALOGUE, DownloadPdfCataloguePayload} from "../actions/download-pdfs/download-pdf-catalogue";
import {triggerPdfResponseDownload} from "../../core/utils/trigger-pdf-response-download";
import {
    API_DOWNLOAD_AUCTION_PROTOCOL,
    DownloadAuctionProtocolPayload
} from "../actions/download-pdfs/download-auction-protocol";
import {API_UPLOAD_LIMIT_BIDS_TELEPHONE_LIST} from "../actions/limit-bids/upload-limit-bids-telephone-list";
import {API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS} from "../actions/download-pdfs/download-catalogue-delivery-labels";

const assignPreassignedItems$ = apiPost<AssignedItemsPayload, any>(
    {apiType: API_ASSIGN_PREASSIGNED},
    "/auction-planning/assign-preassigned-items",
    (action: ApiCallAction<AssignedItemsPayload>) => {
        return {
            auctionId: action.payload.auctionId
        };
    }
);

const transferItems$ = apiPost<TransferLotsPayload, any>(
    {apiType: API_TRANSFER_LOTS},
    "/auction-planning/transfer-lots",
    (action: ApiCallAction<TransferLotsPayload>) => {
        return {
            auctionId: action.payload.auctionId
        };
    }
);

const manuallyAssignItems$ = apiPost<ManuallyAssignItemsPayload, any>(
    {apiType: API_MANUALLY_ASSIGN_ITEMS},
    "/auction-planning/manually-assign-items",
    (action: ApiCallAction<ManuallyAssignItemsPayload>) => {
        return {
            auctionId: action.payload.auctionId,
            itemIds: action.payload.itemIds
        };
    }
);

const groupLots$ = apiPost<GroupLotsActionPayload, any>(
    {apiType: API_GROUP_LOTS},
    "/auction-planning/group-lots",
    (action: ApiCallAction<GroupLotsActionPayload>) => {
        return {
            lotIds: action.payload.lotIds
        };
    }
);

const ungroupLots$ = apiPost<GroupLotsActionPayload, any>(
    {apiType: API_UNGROUP_LOT},
    "/auction-planning/ungroup-lot",
    (action: ApiCallAction<UngroupLotActionPayload>) => {
        return {
            lotId: action.payload.lotId
        };
    }
);

const updateDescription$ = apiPost<UpdateDescriptionPayload, any>(
    {apiType: API_UPDATE_DESCRIPTION},
    "/auction-planning/update-description",
    (action: ApiCallAction<UpdateDescriptionPayload>) => {
        return {
            lotId: action.payload.lotId,
            writeToItem: action.payload.writeToItem,
            description: action.payload.description
        };
    }
);

const updateLimitPrice$ = apiPost<UpdateLimitPricePayload, any>(
    {apiType: API_UPDATE_LIMIT_PRICE},
    "/auction-planning/update-limit-price",
    (action: ApiCallAction<UpdateLimitPricePayload>) => {
        return {
            lotId: action.payload.lotId,
            writeToItem: action.payload.writeToItem,
            limitPrice: action.payload.limitPrice
        };
    }
);

const updateCategory$ = apiPost<UpdateCategoryPayload, any>(
    {apiType: API_UPDATE_CATEGORY},
    "/auction-planning/update-category",
    (action: ApiCallAction<UpdateCategoryPayload>) => {
        return {
            lotId: action.payload.lotId,
            writeToItem: action.payload.writeToItem,
            category: action.payload.category
        };
    }
);

const updateSeller$ = apiPost<UpdateSellerPayload, any>(
    {apiType: API_UPDATE_SELLER},
    "/auction-planning/update-seller",
    (action: ApiCallAction<UpdateSellerPayload>) => {
        return {
            lotId: action.payload.lotId,
            writeToItem: action.payload.writeToItem,
            seller: action.payload.seller
        };
    }
);

const updateBuyer$ = apiPost<UpdateBuyerPayload, any>(
    {apiType: API_UPDATE_BUYER},
    "/auction-planning/update-buyer",
    (action: ApiCallAction<UpdateBuyerPayload>) => {
        return {
            bidId: action.payload.bidId,
            lotId: action.payload.lotId,
            buyer: action.payload.buyer
        };
    }
);

const finalizeAuction$ = apiPost<FinalizeAuctionPayload, any>(
    {apiType: API_FINALIZE_AUCTION},
    "/auction-planning/finalize-auction",
);

const backToSeller$ = apiPost(
    {apiType: API_BACK_TO_SELLER},
    "/auction-planning/back-to-seller",
    (action: ApiCallAction<BackToSellerActionPayload>) => {
        return {
            lotIds: action.payload.lotIds,
        };
    }
);

const generateInvoices$ = apiPost(
    {apiType: API_GENERATE_INVOICES},
    "/auction-planning/generate-invoices",
);

const generateSettlements$ = apiPost(
    {apiType: API_GENERATE_SETTLEMENTS},
    "/auction-planning/generate-settlements",
);

const reloadOnLotOperation$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_MANUALLY_ASSIGN_ITEMS},
        {apiType: API_ASSIGN_PREASSIGNED},
        {apiType: API_TRANSFER_LOTS},
        {apiType: API_GROUP_LOTS},
        {apiType: API_UNGROUP_LOT},
        {apiType: API_CREATE_ENTITIES, callType: AuctionManagerLot.TYPE},
        {apiType: API_DELETE_ENTITIES, callType: AuctionManagerLot.TYPE},
        {apiType: API_UPDATE_DESCRIPTION},
        {apiType: API_UPDATE_LIMIT_PRICE},
        {apiType: API_UPDATE_SELLER},
        {apiType: API_UPDATE_BUYER},
        {apiType: API_UPDATE_CATEGORY},
        {apiType: API_BACK_TO_SELLER},
        {apiType: API_GENERATE_INVOICES},
        {apiType: API_GENERATE_SETTLEMENTS},
        {apiType: API_REMOVE_WINNING_BID}
    ),
    map(() => reloadEntityListAction(AuctionManagerLot.TYPE)())
);

const redirectAfterLotFinalization$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_FINALIZE_AUCTION),
        map(() => push(`/auktionen`))
    );

const downloadLimitBids$ = apiPost<DownloadLimitBidsPayload, any>(
    {apiType: API_DOWNLOAD_LIMIT_BIDS},
    "/limit-bid/download-limit-bids",
);

const downloadLimitBidsSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_LIMIT_BIDS),
        map((action: ApiCallSucceededAction<DownloadCsvData>) => {
            triggerCsvResponseDownload(action.payload, "Vorgebote.csv")
            return {
                type: ""
            }
        })
    );

const downloadWinningBids$ = apiPost<DownloadWinningBidsPayload, any>(
    {apiType: API_DOWNLOAD_WINNING_BIDS},
    "/auction-planning/download-winning-bids",
);

const downloadWinningBidsSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_WINNING_BIDS),
        map((action: ApiCallSucceededAction<DownloadCsvData>) => {
            triggerCsvResponseDownload(action.payload, "Zuschläge.csv");
            return {
                type: ""
            }
        })
    );

const downloadPdfCatalogue$ = apiPost<DownloadPdfCataloguePayload, any>(
    {apiType: API_DOWNLOAD_PDF_CATALOGUE},
    "/auction-planning/download-pdf-catalogue",
);

const downloadPdfCatalogueSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_PDF_CATALOGUE),
        map((action: ApiCallSucceededAction<DownloadPdfData[]>) => {
            action.payload.forEach((payload, index) => {
                triggerPdfResponseDownload(payload, "Katalog_Teil_" + (index + 1) + ".pdf")
            })
            return {
                type: ""
            }
        })
    );

const downloadAuctionProtocol$ = apiPost<DownloadAuctionProtocolPayload, any>(
    {apiType: API_DOWNLOAD_AUCTION_PROTOCOL},
    "/auction-planning/download-auction-protocol",
);

const downloadAuctionProtocolSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_AUCTION_PROTOCOL),
        map((action: ApiCallSucceededAction<DownloadPdfData>) => {

            triggerPdfResponseDownload(action.payload, "Auktionsprotokoll.pdf")

            return {
                type: ""
            }
        })
    );


const manualAssignWinningBid$ = apiPost<ManualAssignWinningBidPayload, any>(
    {apiType: API_MANUAL_ASSIGN_WINNING_BID},
    "/auction-planning/manual-assign-winning-bid",
);

export const manualAssignWinningBidSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_MANUAL_ASSIGN_WINNING_BID
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Zuschlag angepasst"))
);

const removeWinningBid$ = apiPost<RemoveWinningBidPayload, any>(
    {apiType: API_REMOVE_WINNING_BID},
    "/auction-planning/remove-winning-bid",
);

export const removeAssignWinningBidSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_REMOVE_WINNING_BID
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Zuschlag entfernt"))
);

const uploadLimitBids$ = apiPost<UploadCsvFileWithAuctionIdDto, any>(
    {apiType: API_UPLOAD_LIMIT_BIDS},
    "/limit-bid/upload-limit-bids",
);

export const uploadLimitBidsSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_UPLOAD_LIMIT_BIDS
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Vorgebote wurden erfolgreich importiert."))
);

const uploadLimitBidsTelephoneList$ = apiPost<UploadCsvFileWithAuctionIdDto, any>(
    {apiType: API_UPLOAD_LIMIT_BIDS_TELEPHONE_LIST},
    "/limit-bid/upload-limit-bids-telephone-list",
);

export const uploadLimitBidsTelephoneListSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_UPLOAD_LIMIT_BIDS_TELEPHONE_LIST
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Telefonnummern wurden erfolgreich importiert."))
);

const assignBuyersBasedOnManualId$ = apiPost<AssignBuyersBasedOnManuelIdPayload, any>(
    {apiType: API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID},
    "/auction-planning/assign-buyers-based-on-manual-id",
);

export const assignBuyersBasedOnManualIdSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_ASSIGN_BUYERS_BASED_ON_MANUAL_ID
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Käufer aktualisiert. Bitte Seite neu laden"))
);

const downloadP86Csv$ = apiPost<DownloadWinningBidsPayload, any>(
    {apiType: API_DOWNLOAD_P86_CSV},
    "/auction-planning/download-p86-csv",
);

const downloadP86CsvSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_P86_CSV),
        map((action: ApiCallSucceededAction<DownloadCsvData>) => {
            triggerCsvResponseDownload(action.payload, "Lose p86.csv")
            return {
                type: ""
            }
        })
    );

const generateSellerLists$ = apiPost(
    {apiType: API_GENERATE_SELLER_LISTS},
    (action: ApiCallAction<string>) => `/auction-planning/${action.payload}/generate-seller-lists`
);

const sendSellerListEmail$ = apiPost<SendSellerListPayload, any>(
    {apiType: API_SEND_SELLER_LIST_EMAIL},
    "/auction-planning/seller-lists/send-email",
);

export const sendSellerListEmailSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SEND_SELLER_LIST_EMAIL
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "E-Mail wurde versendet."))
);

const sendBulkSellerListEmail$ = apiPost<SendBulkSellerListPayload, any>(
    {apiType: API_SEND_BULK_SELLER_LIST_EMAIL},
    "/auction-planning/seller-lists/send-bulk-email",
);

export const sendBulkSellerListEmailSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SEND_BULK_SELLER_LIST_EMAIL
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "E-Mails wurden versendet."))
);

const downloadCatalogueDeliveryLabels$ = apiPost(
    {apiType: API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS},
    "/auction-planning/download-catalogue-delivery-labels",
);

const downloadCatalogueDeliveryLabelsSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS),
        map((action: ApiCallSucceededAction<DownloadPdfData>) => {
            triggerPdfResponseDownload(action.payload, "Katalog Versandlabels.pdf")
            return {
                type: ""
            }
        })
    );

export const auctionPlanningModuleEpics$ = combineEpics(
    reloadOnLotOperation$,
    groupLots$,
    ungroupLots$,
    assignPreassignedItems$,
    transferItems$,
    manuallyAssignItems$,
    updateDescription$,
    updateLimitPrice$,
    updateSeller$,
    updateBuyer$,
    updateCategory$,
    finalizeAuction$,
    backToSeller$,
    generateInvoices$,
    generateSettlements$,
    redirectAfterLotFinalization$,
    downloadLimitBids$,
    downloadLimitBidsSuccess$,
    uploadLimitBids$,
    uploadLimitBidsSuccessMessage$,
    uploadLimitBidsTelephoneList$,
    uploadLimitBidsTelephoneListSuccessMessage$,
    manualAssignWinningBid$,
    manualAssignWinningBidSuccessMessage$,
    removeWinningBid$,
    downloadWinningBids$,
    downloadWinningBidsSuccess$,
    assignBuyersBasedOnManualId$,
    assignBuyersBasedOnManualIdSuccessMessage$,
    removeAssignWinningBidSuccessMessage$,
    downloadPdfCatalogue$,
    downloadPdfCatalogueSuccess$,
    downloadP86Csv$,
    downloadP86CsvSuccess$,
    generateSellerLists$,
    sendSellerListEmail$,
    sendSellerListEmailSuccessMessage$,
    sendBulkSellerListEmail$,
    sendBulkSellerListEmailSuccessMessage$,
    downloadAuctionProtocol$,
    downloadAuctionProtocolSuccess$,
    downloadCatalogueDeliveryLabels$,
    downloadCatalogueDeliveryLabelsSuccess$
);
