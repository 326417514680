export class Bid {
    public static TYPE = "bid"

    public static STATUS_WINNING = "winning"

    public static SOURCE_LOTTISSIMO = "lottissimo"
    public static SOURCE_INVALUABLE = "invaluable"
    public static SOURCE_BIDSPIRIT = "bidspirit"
    public static SOURCE_ONLINE = "online"
    public static SOURCE_AUCTIONEER = "auctioneer"
    public static SOURCE_TEL = "tel"
    public static SOURCE_WRITTEN = "written"
    public static SOURCE_IMPORT = "import"
    public static SOURCE_ROOM = "room"
    public static SOURCES_FOR_SELECT_FIELD = [
        Bid.SOURCE_LOTTISSIMO,
        Bid.SOURCE_INVALUABLE,
        Bid.SOURCE_BIDSPIRIT,
        Bid.SOURCE_TEL,
        Bid.SOURCE_WRITTEN,
        Bid.SOURCE_AUCTIONEER,
        Bid.SOURCE_ONLINE,
        Bid.SOURCE_IMPORT,
        Bid.SOURCE_ROOM,
    ]

    constructor(
        public lot: string,
        public timestamp: string,
        public value: number,
        public isCurrentUser: boolean,
        public confirmed: boolean
    ) {
    }
}
