import React from "react";
import {connect, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ResumeButton} from "./ResumeButton";
import {PauseButton} from "./PauseButton";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctioneerBid} from "../../../../bids/model/auctioneerBid";
import {SetCurrentLotButton} from "./SetCurrentLotButton";
import {
    selectAuctioneerAuctionStatus,
    selectAuctioneerLiveLot,
    selectAuctioneerLotHighestBid,
    selectAuctioneerLotIsCurrentLot,
    selectAuctioneerLotStatus,
    selectAuctioneerOpenLotCount
} from "../../../selectors";
import {LastCallButton} from "./LastCallButton";
import {ConcludeButton} from "./ConcludeButton";
import {LiveAuction} from "@thekeytechnology/auktionshaus-frontend-library";
import {PrevLotButton} from "./PrevLotButton";

interface StateProps {
    status: string
    lotStatus: string | undefined
    isCurrentLot: boolean
    highestBid: EntityWrapper<AuctioneerBid> | undefined
    lotCount: number
}

type Props = StateProps;

const AuctioneerActionButtonsComponent = ({
                                              status,
                                              lotStatus,
                                              isCurrentLot,
                                              lotCount
                                          }: Props) => {

    const buttons: React.ReactNode[] = [];

    const {t} = useTranslation(["auctioneer", "core"]);

    const currentLiveLot = useSelector(selectAuctioneerLiveLot);

    if (!status || !lotStatus) {
        return null;
    }

    if (isCurrentLot) {
        buttons.push(<LastCallButton key={"last-call-button"}/>);
    } else {
        buttons.push(<SetCurrentLotButton key={"set-current-lot-button"}/>);
        buttons.push(<a
            key={"go-to-live-lot-button"}
            className={"btn btn-primary go-to-live-lot-button"}
            href={"/auktionator/"}>
            {t("actions.go-to-live-lot")}
        </a>)
    }

    if (status === LiveAuction.STATUS_INACTIVE || status === LiveAuction.STATUS_PAUSED) {
        buttons.push(<ResumeButton key={"resume-button"}/>);
    }

    if (status === LiveAuction.STATUS_ACTIVE) {
        buttons.push(<PauseButton key={"pause-button"}/>);
    }

    if (currentLiveLot?.entity.lotNumber && currentLiveLot?.entity.lotNumber > 1) {
        buttons.push(<PrevLotButton key={"prev-lot-button"}/>);
    }

    // if (lotCount === 0 || (currentLiveLot?.entity.lotNumber && currentLiveLot?.entity.lotNumber === 2300 && [LOT_STATUS_SOLD, LOT_STATUS_NOT_SOLD].indexOf(currentLiveLot?.entity.status) > -1)) {
        buttons.push(<ConcludeButton key={"conclude-button"}/>);
    // }

    // buttons.push(<PrevLotButton key={"prev-lot-button"}/>);
    // buttons.push(<NextLotButton key={"next-lot-button"}/>);

    return <div className={"auctioneer-view-action-buttons"}>
        {isCurrentLot}
        {buttons}
    </div>;
};

export const AuctioneerActionButtons = connect<StateProps, {}, {}>(
    (state: any) => ({
        status: selectAuctioneerAuctionStatus(state),
        lotStatus: selectAuctioneerLotStatus(state),
        isCurrentLot: selectAuctioneerLotIsCurrentLot(state),
        highestBid: selectAuctioneerLotHighestBid(state),
        lotCount: selectAuctioneerOpenLotCount(state)
    }),
    {}
)
(AuctioneerActionButtonsComponent);
