import {createSelector} from "reselect";
import {AppState} from "../../reducers";
import {CustomerModuleState} from "../reducers";
import {HighestCustomerNumberState} from "../reducers/currentCustomerReducer";
import {CustomerAddressState} from "../reducers/currentCustomerAddressReducer";

export const selectCustomerModuleState = (state: AppState) => state.customer;

export const selectCurrentHighestCustomerNumberState =  createSelector(
    selectCustomerModuleState,
    (moduleState: CustomerModuleState) =>
        moduleState.currentHighestCustomerNumber
);

export const selectCurrentHighestCustomerNumber = createSelector(
    selectCurrentHighestCustomerNumberState,
    (moduleState: HighestCustomerNumberState) =>
        moduleState.currentHighestCustomerNumber
);

export const selectCustomerAddressState =  createSelector(
    selectCustomerModuleState,
    (moduleState: CustomerModuleState) =>
        moduleState.customerAddress
);

export const selectBillingAddress = createSelector(
    selectCustomerAddressState,
    (moduleState: CustomerAddressState) =>
        moduleState.billingAddress
);

export const selectShippingAddress = createSelector(
    selectCustomerAddressState,
    (moduleState: CustomerAddressState) =>
        moduleState.shippingAddress
);
