import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {downloadDunningAction} from "../../actions/download-dunning";

interface OwnProps {
    orderId: string,
    receiptId: string
}

type Props = OwnProps

export const DownloadDunningButton = ({
                                           orderId,
                                           receiptId
                                       }: Props) => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(downloadDunningAction(orderId, receiptId))
    }

    return <>
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-remove`}>
                    Mahnbrief herunterladen
                </Tooltip>
            }
        >
            <button
                className={"btn btn-link mr-2"}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={faDownload}/>
            </button>
        </OverlayTrigger>


    </>;
}
