import React from "react";
import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";
import {uuid} from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils";

interface OwnProps {
    property: string
    endpointFilters: PaginatedEndpointFiltersReturnProps
    customFilterFunction?: (
        property: string,
        endpointFilters: PaginatedEndpointFiltersReturnProps,
        value: boolean,
        filterKey: string
    ) => void
    customValueFunction?: (
        endpointFilters: PaginatedEndpointFiltersReturnProps,
        filterKey: string
    ) => boolean

    filterOperation?: string;
    filterKey?: string;
    label?: string;
}


export const PaginatedEndPointCheckboxFilter = ({
                                                    property,
                                                    endpointFilters,
                                                    customFilterFunction,
                                                    customValueFunction,
                                                    filterKey,
                                                    filterOperation,
                                                    label
                                                }: OwnProps) => {

    const filter = (event: any) => {
        const value = !!event?.target?.checked;
        const filterKeyToUse = filterKey ? filterKey : property;

        if (customFilterFunction) {
            customFilterFunction(property, endpointFilters, value, filterKeyToUse)
        } else {
            if (value) {
                const filterOperationToUse = filterOperation ? filterOperation : FilterTerm.OPERATION_EQ;
                endpointFilters.addFilters(filterKeyToUse, [new PropertyFilter(property,
                    new FilterTerm(FilterTerm.TYPE_BOOLEAN, filterOperationToUse, value as Boolean)
                )]);
            } else {
                endpointFilters.removeFilters([filterKeyToUse]);
            }
        }
    };

    const setValue = () => {
        const filterKeyToUse = filterKey ? filterKey : property;
        if (customValueFunction) {
            return customValueFunction(endpointFilters, filterKeyToUse)
        } else {
            const currentFilters = endpointFilters.getFilters().filter(f => f.key === filterKeyToUse).flatMap(f => f.filters)

            return !!(currentFilters && currentFilters.length && currentFilters[0])

        }
    }

    const id = uuid("checkbox-");

    return (
        <div className="form-check d-flex align-items-center pl-3 pr-3">

            <label className="form-check-label" htmlFor={id}>
                <input
                    className="mr-2"
                    type="checkbox"
                    checked={setValue()}
                    onChange={filter}
                    name={id}
                    id={id}
                />
                {label}
            </label>

        </div>
    );
};

