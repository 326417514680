import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GENERATE_INVOICES = "generate-invoices";

export interface GenerateInvoicesPayload {
    auctionId: string
}

export const generateInvoicesAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GENERATE_INVOICES,
            callType: API_GENERATE_INVOICES
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<GenerateInvoicesPayload>;
};
