import {
    ApiCallState,
    EntityWrapper,
    Pagination,
    SortQuery,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import "./item-table.scss";
import {
    AboveTableContainer,
    ActionTableColumn,
    AsyncEntityTableBody,
    EntityCreateAndDeleteButtons,
    EntityTableHeader,
    EntityTableRow,
    Table,
    TableColumn,
    UnconnectedPaginationInfo
} from "@thekeytechnology/framework-react-ux";
import {useTranslation} from "react-i18next";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {PaginatedEndpointSortableTableHeaderColumn} from "../../paginated-endpoints/components/PaginatedEndpointSortableTableHeaderColumn";
import {Item} from "../model/item";
import {CurrencyDisplay, LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {ItemCategoryDisplay} from "../../item-category/display/ItemCategoryDisplay";
import {CustomActionLink} from "../../core/components/CustomActionLink/CustomActionLink";
import {DownloadItemsForTranslationButton} from "./DownloadItemsForTranslationButton";

interface OwnProps {
    entities: Array<EntityWrapper<Item>>;
    apiCallState: ApiCallState;
    pagination: Pagination;
    sortQuery: SortQuery;
    setSortQuery: (sortQuery: SortQuery) => void;
}

type Props = WithSelectionsProps<string> & OwnProps;

const ItemTableNewComponent = ({
                                   entities,
                                   apiCallState,
                                   selectionModel,
                                   pagination,
                                   sortQuery,
                                   setSortQuery
                               }: Props) => {

    const {t} = useTranslation(["item"])

    return (
        <>

            <AboveTableContainer>
                <UnconnectedPaginationInfo pagination={pagination} className="mr-auto"/>
                <DownloadItemsForTranslationButton/>
                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={Item.TYPE}
                    createPath="/objekte/new/edit"
                    label={t("entity.singular")}
                    labelPlural={t("entity.plural")}
                />
            </AboveTableContainer>

            <Table itemCount={entities.length}
                   className={"item-table"}>
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    {/*<SortableTableHeaderColumn entityType={Item.TYPE}*/}
                    {/*                           property={"entity.items.0.entity.inventoryNumber"}>*/}
                    {/*    {t("entity.labels.inventory-number-short")}*/}
                    {/*</SortableTableHeaderColumn>*/}
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.inventoryNumber"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.inventory-number-short")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    {/*<SortableTableHeaderColumn entityType={Item.TYPE}*/}
                    {/*                           property={"entity.seller.entity.personalData.firstName"}>*/}
                    {/*    {t("entity.labels.seller")}*/}
                    {/*</SortableTableHeaderColumn>*/}
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.sellerRef"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.seller")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    {/*<SortableTableHeaderColumn entityType={Item.TYPE}*/}
                    {/*                           property={`entity.description.de`}>*/}
                    {/*    {t("entity.labels.short-description")}*/}
                    {/*</SortableTableHeaderColumn>*/}
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={`entity.description.de`}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.short-description")}
                    </PaginatedEndpointSortableTableHeaderColumn>


                    {/*<SortableTableHeaderColumn entityType={Item.TYPE}*/}
                    {/*                           property={`entity.plannedAuction.entity.auctionNumber`}>*/}
                    {/*    {t("entity.labels.planned-auction")}*/}
                    {/*</SortableTableHeaderColumn>*/}
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={`entity.plannedAuctionRef`}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.planned-auction")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    {/*<SortableTableHeaderColumn entityType={Item.TYPE}*/}
                    {/*                           property={`entity.limitPrice`}>*/}
                    {/*    {t("entity.labels.limit-price")}*/}
                    {/*</SortableTableHeaderColumn>*/}
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={`entity.limitPrice`}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.limit-price")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    {/*<SortableTableHeaderColumn entityType={Item.TYPE}*/}
                    {/*                           property={`entity.category.entity.name`}>*/}
                    {/*    {t("entity.labels.category")}*/}
                    {/*</SortableTableHeaderColumn>*/}
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={`entity.categoryRef`}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.category")}
                    </PaginatedEndpointSortableTableHeaderColumn>


                    {/*<SortableTableHeaderColumn entityType={Item.TYPE}*/}
                    {/*                           property={`entity.status`}>*/}
                    {/*    {t("entity.labels.status")}*/}
                    {/*</SortableTableHeaderColumn>*/}
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={`entity.status`}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("entity.labels.status")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody apiCallState={apiCallState} items={entities} renderItem={item => {
                    return (
                        <EntityTableRow key={item.id} selectionModel={selectionModel} entity={item}>
                            <TableColumn className="bold title-column">
                                {item.entity.inventoryNumber}
                            </TableColumn>
                            <TableColumn>
                                {item.entity.seller?.entity.name}
                            </TableColumn>
                            <TableColumn>
                                <LotTitleDisplay description={item.entity.description["de"]}/>
                            </TableColumn>
                            <TableColumn>
                                {item.entity.plannedAuction?.entity.auctionNumber}
                            </TableColumn>
                            <TableColumn>
                                <CurrencyDisplay amount={item.entity.limitPrice}/>
                            </TableColumn>
                            <TableColumn>
                                <ItemCategoryDisplay
                                    displayItem={item.entity.category}/>
                            </TableColumn>
                            <TableColumn>
                                {t(`status-options.${item.entity.status}`)}
                            </TableColumn>
                            <ActionTableColumn isDisabled={false}>
                                <>
                                    <CustomActionLink to={"/objekte/" + item.id + "/edit"}
                                                      tooltip={t("core:actions.edit")}
                                                      icon={faPencilAlt}/>
                                </>
                            </ActionTableColumn>
                        </EntityTableRow>
                    );
                }}/>
            </Table>
        </>
    );
};

export const ItemTableNew = WithSelections<OwnProps, string>(
    ItemTableNewComponent
);
