import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {AuctioneerBidTable} from "./bid-table/AuctioneerBidTable";
import {connect, useSelector} from "react-redux";
import {selectAuctioneerAuction, selectAuctioneerBids, selectAuctioneerLotBidCount} from "../../../selectors";
import {AuctioneerLimitBidTable} from "./bid-table/AuctioneerLimitBidTable";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctioneerBid} from "../../../../bids/model/auctioneerBid";
import {AuctioneerCurrentlyActiveCustomersList} from "./currently-active-customers/currently-active-customers";
import {AuctioneerLotTable} from "./lot-table/AuctioneerLotTable";

interface StateProps {
    bidCount: number
    bids: Array<EntityWrapper<AuctioneerBid>>
}

type Props = StateProps;

const AuctioneerContentTabsComponent = ({
                                            bidCount,
                                            bids
                                        }: Props) => {

    const {t} = useTranslation(["auctioneer", "lot", "bid"]);

    const limitBidCount = bids.filter(bid => bid.entity.limitedAt).length

    const state = useSelector(selectAuctioneerAuction);
    const customers = state?.entity.currentlyActiveCustomers ? state?.entity.currentlyActiveCustomers : [];


    return (
        <div className={"auctioneer-view-content-tabs"}>
            <Tabs defaultActiveKey="bids" id="uncontrolled-tab-example">
                <Tab eventKey="bids" title={t("bid:entity.plural") + " (" + bidCount + ")"}>
                    <AuctioneerBidTable/>
                </Tab>
                <Tab eventKey="lots" title={t("lot:entity.plural")}>
                    <AuctioneerLotTable/>
                </Tab>
                {limitBidCount ? <Tab eventKey="limitBids" title={t("bid:entity.labels.limit-bids") + " (" + limitBidCount + ")"}>
                    <AuctioneerLimitBidTable/>
                </Tab> : null}
                {customers.length > 0 ? <Tab eventKey={"currently-active-customers"} title={"Aktive Kunden (" + customers.length + ")"}>
                    <AuctioneerCurrentlyActiveCustomersList />
                </Tab> : null}
            </Tabs>
        </div>
    )
};

export const AuctioneerContentTabs = connect<StateProps, {}, {}>(
    (state: any) => ({
        bidCount: selectAuctioneerLotBidCount(state),
        bids: selectAuctioneerBids(state)
    }),
    {}
)
(AuctioneerContentTabsComponent);
