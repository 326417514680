import {ApiCallAction} from "@thekeytechnology/framework-react";
import {OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";

export const API_CREATE_CUSTOM_RECEIPT = "create-custom-receipt";

export interface CreateCustomReceiptPayload {
    containerId: string,
    receiptType: string,
    positions: OrderPosition[],
    containerType: string
}

export const createCustomReceiptAction = (containerId: string, receiptType: string, positions: OrderPosition[], processType: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CREATE_CUSTOM_RECEIPT,
            callType: API_CREATE_CUSTOM_RECEIPT
        },
        payload: {
            containerId,
            receiptType,
            positions,
            containerType: processType
        }
    } as ApiCallAction<CreateCustomReceiptPayload>;
};
