import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {SettingsForm} from "../components/SettingsForm";

export const AuctionAppSettingsModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/einstellungen/" component={SettingsForm}/>
    </>

);
};
