import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_ARCHIVE_AUCTION = "archive-auction";

export interface ArchiveAuctionPayload extends Action {
    auctionId: string;
}

export const archiveAuctionAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ARCHIVE_AUCTION,
            callType: API_ARCHIVE_AUCTION
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<ArchiveAuctionPayload>;
};
