import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_RESUME_AUCTION = "resume-live-auction";

export const resumeAuctionAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_RESUME_AUCTION,
            callType: API_RESUME_AUCTION
        },
        payload: {}
    } as ApiCallAction<{}>;
};
