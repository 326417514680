import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER = "get-current-highest-customer-number";

export const getCurrentCustomerNumberAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER,
            callType: API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER
        },
        payload: null
    } as ApiCallAction<null>;
};
