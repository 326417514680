import {Address, AttachedReceipt, OrderHistory, OrderPosition} from "@thekeytechnology/auktionshaus-frontend-library";

export const ENTITY_TYPE_ORDER_FOR_SAVING = "order"

export interface OrderForSaving {
    orderNumber: number,
    customerRef: string,
    positions: OrderPosition[],
    billingAddress: Address,
    shippingAddress?: Address,
    trackingNumber?: string,
    pickingNumber?: string,
    status: string,
    comment?: string,
    attachedReceipts: AttachedReceipt[]
    orderHistory: OrderHistory[],
    createdAt: string,
    overwriteUserData: boolean,
    auctionRef?: string,
    shippingStatus?: string,
    shippingMethod?: string
}
