import React from "react";

import "./email-customer-modal.scss";
import {useDispatch} from "react-redux";
import {Modal} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {sendEmailToCustomerAction} from "../../actions/send-email";
import {WysiwygField} from "@thekeytechnology/framework-react-ux";


interface OwnProps {
    customerId: string
    handleClose: () => void;
}

export const EmailCustomerModal = ({customerId, handleClose}: OwnProps) => {
    const dispatch = useDispatch();

    return (<>
            <Modal.Header closeButton>
                <Modal.Title>EMail senden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        subject: "",
                        body: ""
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        dispatch(sendEmailToCustomerAction(
                            customerId,
                            values.subject,
                            values.body
                        ))
                        handleClose();
                        setSubmitting(false);
                    }}>
                    {({isSubmitting}) => (
                        <Form>
                            <div className="form-group">
                                <label htmlFor="subject">Nachricht</label>
                                <Field className="default-input mb-3"
                                       placeholder="Betreff" name="subject" type="text"/>
                                <Field name="body"
                                       component={WysiwygField}/>
                            </div>

                            <button type="submit" className="btn btn-primary ml-auto"
                                    disabled={isSubmitting}>
                                Senden
                            </button>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </>
    );
}
