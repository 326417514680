import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArchive} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {API_ARCHIVE_AUCTION, archiveAuctionAction} from "../actions/archive-auction";

interface OwnProps {
    auctionId: string
}

type Props = OwnProps;

export const ArchiveAuctionButton = ({
                                         auctionId
                                     }: Props) => {

    const apiCallState = useSelector(selectCombinedApiState(API_ARCHIVE_AUCTION))
    const dispatch = useDispatch();

    return auctionId ? <button type="button" disabled={apiCallState.inProgress} onClick={() => {
        dispatch(archiveAuctionAction(auctionId));

    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon className="mr-2"
                         icon={faArchive}/>{"Archivieren + Freigeschaltete Nutzer zurücksetzen"}
    </button> : null;

}