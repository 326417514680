import {DialogResult, EntityWrapper, selectCombinedApiState, showDialogAction} from "@thekeytechnology/framework-react";
import {Order, ORDER_STATUS_CANCELLED} from "../../model/Order";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {API_CANCEL_ORDER, cancelOrderAction} from "../../actions/cancel-order";
import {Dropdown} from "react-bootstrap";

interface OwnProps {
    order: EntityWrapper<Order>
    disabled?: boolean;
}

export const CancelOrderFees = ({order, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_CANCEL_ORDER))

    return order.entity.status !== ORDER_STATUS_CANCELLED ?
        <Dropdown.Item disabled={apiCallState.inProgress || disabled}
                onClick={() => {
                    dispatch(showDialogAction({
                        title: "Bestellung stornieren (mit Nachfolgerechnung)",
                        question: "Soll die Bestellung storniert werden? Es wird eine Nachfolgerechnung über die Gebühren gestellt. Zu eventuell bereits abgerechneten Losen wird eine Rückrechnung erstellt.",
                        explanation: "",
                        affirmativeText: "Bestellung stornieren",
                        negativeText: "Abbrechen"
                    }, result => {
                        if (result === DialogResult.ACCEPT) {
                            dispatch(cancelOrderAction(order.id!, "fees"))
                        }
                    }))
                }}
                >
            Bestellung stornieren (Gebühren abrechnen)
        </Dropdown.Item> : null;
}
