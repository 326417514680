import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UNGROUP_LOT = "ungroup-lot";

export interface UngroupLotActionPayload {
    lotId: string
}

export const ungroupLotAction = (lotId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UNGROUP_LOT,
            callType: API_UNGROUP_LOT
        },
        payload: {
            lotId
        }
    } as ApiCallAction<UngroupLotActionPayload>;
};
