import {
    AuthState,
    reloadEntityListAction,
    selectApiBase,
    selectAuthState,
    TkFile,
    withAuthedAxios
} from "@thekeytechnology/framework-react";
import React from "react";
import {connect} from "react-redux";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import {FieldProps} from "formik";
import {FormProps} from "react-bootstrap";

registerPlugin(FilePondPluginImagePreview);

interface StateProps {
    apiBase: string;
    authState: AuthState;
}

interface DispatchProps {
    fetchList: ReturnType<typeof reloadEntityListAction>
}

type Props = StateProps & DispatchProps & FieldProps & FormProps;

const LotImportFileUploadFieldComponent = (props: Props) => {
    const {
        apiBase,
        authState,
        field,
        form
    } = props;

    const {t} = useTranslation(["files"]);

    // return <h1>test</h1>;

    const onChange = (fileKey: string) => {
        form.setFieldValue(field.name, fileKey);
    };

    return (
        <FilePond
            instantUpload={true}
            allowRevert={false}

            labelIdle={t("filepond.idle")}
            labelFileProcessing={t("filepond.file-processing")}
            labelTapToCancel={t("filepond.tap-to-cancel")}
            labelFileProcessingComplete={t("filepond.file-processing-complete")}
            server={{
                process: (fieldName: any, file: any, metadata: any, load: any, error: any, progress: any) => {
                    const formData = new FormData();
                    formData.append("file", file, file.name);

                    const CancelToken = Axios.CancelToken;
                    const source = CancelToken.source();

                    withAuthedAxios(apiBase, authState).post("/lot-importer/upload", formData, {
                        cancelToken: source.token,
                        onUploadProgress: e => {
                            // updating progress indicator
                            progress(e.lengthComputable, e.loaded, e.total);
                        }
                    }).then(response => {
                        // passing the file id to FilePond
                        load(file);
                        onChange(response.data.fileKey);
                    }).catch(thrown => {
                        error(thrown);
                    });

                    // noinspection JSUnusedGlobalSymbols
                    return {
                        abort: () => {
                            source.cancel("Operation canceled by the user.");
                        }
                    };
                }
            }}
        />
    );
};

export const LotImportFileUploadField = connect<StateProps, DispatchProps>(
    (state: any) => {
        return {
            apiBase: selectApiBase(state),
            authState: selectAuthState(state)
        };
    },
    {
        fetchList: reloadEntityListAction(TkFile.TYPE)
    }
)(LotImportFileUploadFieldComponent);
