import {withTranslation, WithTranslation} from "react-i18next";
import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {Item} from "../../model/item";

const StatusSelectFieldComponent = (props: FieldProps & WithTranslation) => {
    const {field, form, t} = props;
    const accountingTypeOptions =
        [Item.STATUS_CREATED, Item.STATUS_NOT_CHECKED, Item.STATUS_CHECKED, Item.STATUS_READY_FOR_SALE, Item.STATUS_SOLD, Item.STATUS_BACK_TO_SELLER]
            .map(status => ({value: status, label: t(`status-options.${status}`)}));

    return (
        <Select
            className="react-select category-select"
            classNamePrefix="react-select"
            components={{ClearIndicator}}
            options={accountingTypeOptions}
            name={field.name}
            value={accountingTypeOptions.find(o => o.value === field.value)}
            onChange={(item: any) => form.setFieldValue(field.name, item.value)}
            getOptionValue={opt => opt.value}
            getOptionLabel={item => item.label}
            onBlur={field.onBlur}
        />);
};

export const StatusSelectField = withTranslation("item")(StatusSelectFieldComponent);
