import {
    API_INVITE_USERS,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction
} from "@thekeytechnology/framework-react";
import {push} from "connected-react-router";
import {Action} from "redux";
import {combineEpics} from "redux-observable";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {API_SAVE_USER} from "../actions/save-user";

const redirectAfterInvitation$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_INVITE_USERS),
        map(() => push("/benutzer"))
    );

const saveUser$ = apiPost(
    {apiType: API_SAVE_USER},
    "/users/save-user"
)

export const userSavedSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_USER
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Nutzer wurde gespeichert."))
);



export const additionalUserEpics$ = combineEpics(
    redirectAfterInvitation$,
    saveUser$,
    userSavedSuccessMessage$
);
