import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_CANCEL_ORDER = "cancel-order"

export type CancellationMode = "no-fees" | "fees"

export interface CancelOrderActionPayload {
    containerId: string
    cancellationMode?: CancellationMode
}

export const cancelOrderAction = (orderId: string, cancellationMode: CancellationMode) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_CANCEL_ORDER,
            callType: API_CANCEL_ORDER
        },
        payload: {
            containerId: orderId,
            cancellationMode
        }
    } as ApiCallAction<CancelOrderActionPayload>;
};
