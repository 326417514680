import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {OrderForSaving} from "../model/OrderForSaving";

export const API_SAVE_ORDER = "save-order";



export const saveOrderAction = (item: EntityWrapper<OrderForSaving>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_ORDER,
            callType: API_SAVE_ORDER
        },
        payload: item
    } as ApiCallAction<EntityWrapper<OrderForSaving>>;
};
