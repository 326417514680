import {combineEpics} from "redux-observable";
import {connectToAuctioneerSocket$} from "./connect-to-auctioneer-socket";
import {
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadSingleEntityAction
} from "@thekeytechnology/framework-react";
import {API_ASSIGN_WINNING_BID} from "../actions/assign-winning-bid-action";
import {API_PAUSE_AUCTION, PauseLiveAuctionPayload} from "../actions/pause-live-auction-action";
import {API_RESUME_AUCTION} from "../actions/resume-live-auction-action";
import {API_CLOSE_LOT} from "../actions/close-lot-action";
import {API_SUBMIT_BID_AUCTIONEER} from "../actions/submit-bid-auctioneer-action";
import {API_SUBMIT_LIMIT_BID_AUCTIONEER} from "../actions/submit-limit-bid-auctioneer-action";
import {Action} from "redux";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {AuctioneerLot} from "../../lots/model/AuctioneerLot";
import {API_SET_CURRENT_LOT} from "../actions/set-current-lot-action";
import {API_LAST_CALL} from "../actions/last-call-action";
import {API_START_LIVE_AUCTION} from "../actions/start-live-auction-action";
import {API_CONCLUDE_LIVE_AUCTION} from "../actions/conclude-live-auction-action";
import {push} from "connected-react-router";
import {API_DELETE_BID} from "../actions/delete-bid-action";
import {API_PREV_LOT} from "../actions/prev-lot-action";
import {API_CLOSE_LOT_FOR_BIDDING} from "../actions/close-lot-for-bidding-action";

export const pauseAuction$ = apiPost<PauseLiveAuctionPayload, any>(
    {apiType: API_PAUSE_AUCTION},
    "/live-auction/pause-auction"
);

export const resumeAuction$ = apiPost(
    {apiType: API_RESUME_AUCTION},
    "/live-auction/resume-auction"
);

export const concludeAuction$ = apiPost(
    {apiType: API_CONCLUDE_LIVE_AUCTION},
    "/live-auction/conclude-auction"
);

export const startAuction$ = apiPost(
    {apiType: API_START_LIVE_AUCTION},
    "/live-auction/start-auction"
);

export const closeLot$ = apiPost(
    {apiType: API_CLOSE_LOT},
    "/live-auction/close-lot"
);

export const closeLotForBidding$ = apiPost(
    {apiType: API_CLOSE_LOT_FOR_BIDDING},
    "/live-auction/close-lot-for-bidding"
);


export const setCurrentLot$ = apiPost(
    {apiType: API_SET_CURRENT_LOT},
    "/live-auction/set-current-lot"
);

export const lastCall$ = apiPost(
    {apiType: API_LAST_CALL},
    "/live-auction/last-call"
);

export const assignWinningBid$ = apiPost(
    {apiType: API_ASSIGN_WINNING_BID},
    "/live-auction/assign-winning-bid"
);

export const deleteBid$ = apiPost(
    {apiType: API_DELETE_BID},
    "/live-auction/delete-bid"
);

export const submitBidAuctioneer$ = apiPost(
    {apiType: API_SUBMIT_BID_AUCTIONEER},
    "/live-auction/submit-bid-auctioneer"
);

export const submitLimitBidAuctioneer$ = apiPost(
    {apiType: API_SUBMIT_LIMIT_BID_AUCTIONEER},
    "/live-auction/submit-limit-bid-auctioneer"
);



export const submitLimitBidSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SUBMIT_LIMIT_BID_AUCTIONEER
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Vorgebot wurde erfolgreich abgegeben."))
);

export const submitLimitBidSuccessReload$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SUBMIT_LIMIT_BID_AUCTIONEER
    }),
    map(() => window.location.reload())
);

export const prevLot$ = apiPost(
    {apiType: API_PREV_LOT},
    "/live-auction/prev-lot"
);

export const auctioneerReloadLotOnBidSubmit$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SUBMIT_LIMIT_BID_AUCTIONEER
    }, {
        apiType: API_SUBMIT_BID_AUCTIONEER
    }),
    map(() => reloadSingleEntityAction(AuctioneerLot.TYPE)())
);

export const redirectAfterAuctionStart$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_START_LIVE_AUCTION),
        map(() => push(`/auktionator`))
    );

export const redirectAfterAuctionConcluded$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_CONCLUDE_LIVE_AUCTION),
        map(() => push(`/auktionen`))
    );

export const auctioneerModuleEpics$ = combineEpics(
    connectToAuctioneerSocket$,
    auctioneerReloadLotOnBidSubmit$,
    assignWinningBid$,
    deleteBid$,
    pauseAuction$,
    resumeAuction$,
    startAuction$,
    closeLot$,
    lastCall$,
    setCurrentLot$,
    submitBidAuctioneer$,
    submitLimitBidAuctioneer$,
    concludeAuction$,
    redirectAfterAuctionStart$,
    redirectAfterAuctionConcluded$,
    prevLot$,
    submitLimitBidSuccessMessage$,
    closeLotForBidding$,
    // submitLimitBidSuccessReload$
);
