import {
    addFiltersAction,
    Filter,
    FilterTerm,
    PropertyFilter,
    removeFiltersAction,
    selectFilter
} from "@thekeytechnology/framework-react";
import React, {Component} from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Item} from "../../model/item";
import {PaginatedEndpointSimpleSelectFilter} from "../../../paginated-endpoints/components/PaginatedEndpointSimpleSelectFilter";
import {PaginatedEndpointFiltersReturnProps} from "../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    removeFilters: ReturnType<typeof removeFiltersAction>
    addFilters: ReturnType<typeof addFiltersAction>
}

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = StateProps & DispatchProps & WithTranslation & OwnProps;

class StatusFilter extends Component<Props> {

    public constructor(props: Props) {
        super(props);
        this.filter = this.filter.bind(this);
    }

    public render() {
        const {t} = this.props;

        return (
            // <Select
            //     components={{ClearIndicator}}
            //     placeholder={t("status-filter.placeholder")}
            //     className="filter react-select"
            //     classNamePrefix="react-select"
            //     isClearable={true}
            //     onChange={this.filter}
            //     options={this.getOptions()}
            //     value={this.determineStatusSelectionFromFilter()}
            // />
            <PaginatedEndpointSimpleSelectFilter
                endpointFilters={this.props.endpointFilters}
                placeholder={t("status-filter.placeholder")}
                options={this.getOptions()}
                property={"entity.status"}
            />
        );
    }

    public determineStatusSelectionFromFilter() {
        const {currentFilters} = this.props;
        if (!currentFilters || currentFilters.length === 0) {
            return null;
        }

        const options = this.getOptions();

        const firstFilter = currentFilters[0] as PropertyFilter;
        if (firstFilter.property === "entity.status") {
            switch (firstFilter.filterTerm.value) {
                case "checked":
                    return options[0];
                case "not-checked":
                    return options[1];
                case    "created":
                    return options[2];
                case   "ready-for-sale":
                    return options[3];
                case    "sold":
                    return options[4];
                case    "back-to-the-seller":
                    return options[5];
            }
        }
        return null;
    }

    public filter(selection: any) {
        const {addFilters, removeFilters} = this.props;

        if (!selection) {
            removeFilters(["entity.status"]);
        } else {
            switch (selection.value) {
                case "checked":
                    addFilters("entity.status", [
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "checked")
                        ),
                    ]);
                    break;
                case "not-checked":
                    addFilters("entity.status", [
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "not-checked")
                        ),
                    ]);
                    break;
                case "created":
                    addFilters("entity.status", [
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "created")
                        ),
                    ]);
                    break;
                case "ready-for-sale":
                    addFilters("entity.status", [
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "ready-for-sale")
                        ),
                    ]);
                    break;
                case "sold":
                    addFilters("entity.status", [
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "sold")
                        ),
                    ]);
                    break;
                case "back-to-the-seller":
                    addFilters("entity.status", [
                        new PropertyFilter(
                            "entity.status",
                            new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_EQ, "back-to-the-seller")
                        ),
                    ]);
                    break;

            }
        }
    }

    private getOptions() {
        const {t} = this.props;

        return [
            {
                value: "created",
                label: t("status-options.created")
            },
            {
                value: "checked",
                label: t("status-options.checked")
            },
            {
                value: "not-checked",
                label: t("status-options.not-checked")
            },
            {
                value: "ready-for-sale",
                label: t("status-options.ready-for-sale")
            },
            {
                value: "sold",
                label: t("status-options.sold")
            },
            {
                value: "back-to-the-seller",
                label: t("status-options.back-to-seller")
            }
        ];
    }
}

export default withTranslation("item")(connect<StateProps, DispatchProps, {}>(
    (state: any) => {
        return {
            currentFilters: selectFilter(Item.TYPE, "entity.status")(state)
        };
    },
    {
        removeFilters: removeFiltersAction(Item.TYPE),
        addFilters: addFiltersAction(Item.TYPE)
    }
)(StatusFilter));
