import {
    API_DELETE_ENTITIES,
    ApiCallAction,
    ApiCallSucceededAction,
    apiGet,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadEntityListAction,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import "@thekeytechnology/framework-react/"
import {ENTITY_TYPE_CUSTOMER} from "../model/customer";
import {API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER} from "../actions/current-customer";
import {API_SEND_EMAIL_TO_CUSTOMER} from "../actions/send-email";
import {Observable} from "rxjs";
import {Action} from "redux";
import {map} from "rxjs/operators";
import {API_UPDATE_CUSTOMER_ALLOWED_LIST} from "../actions/update-customer-list";
import {API_BULK_UPDATE_ALLOWED_LISTS} from "../actions/bulk-update-allowed-lists";
import {API_SAVE_CUSTOMER} from "../actions/save-customer";
import {API_GET_CUSTOMER_ADDRESS} from "../actions/customer-address";
import {API_DELETE_DUPLICATE_CUSTOMERS, DeleteDuplicateCustomersPayload} from "../actions/delete-duplicate-customers";
import {API_RESET_AUCTION_SPECIFIC_NUMBERS} from "../actions/reset-auction-specific-numbers";
import {API_DOWNLOAD_CUSTOMER_PRINT_SHEET} from "../actions/download-print-sheet";
import {DownloadPdfData} from "../../core/model/DownloadPdfData";
import {triggerPdfResponseDownload} from "../../core/utils/trigger-pdf-response-download";
import {API_DOWNLOAD_CUSTOMERS_CSV} from "../actions/download-customers-csv";
import {DownloadCsvData} from "../../core/model/DownloadCsvData";
import {triggerCsvResponseDownload} from "../../core/utils/trigger-csv-response-download";

export const customerSavedSuccessMessage$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_CUSTOMER
    }),
    map(() => postMessageAction(Message.TYPE_SUCCESS, "Kunde wurde gespeichert."))
);

const reloadOnCustomerDelete$ = reloadOnEntityApiOperation(API_DELETE_ENTITIES, ENTITY_TYPE_CUSTOMER);

const getCurrentHighestCustomerNumber$ = apiGet(
    {apiType: API_GET_CURRENT_HIGHEST_CUSTOMER_NUMBER},
    "/customers/current-highest-customer-number"
);

const resetAuctionSpecificNumbers$ = apiPost(
    {apiType: API_RESET_AUCTION_SPECIFIC_NUMBERS},
    "/customers/reset-auction-specific-numbers"
);

const resetAuctionSpecificNumbersSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_RESET_AUCTION_SPECIFIC_NUMBERS}),
    map(() => {
        return postMessageAction(Message.TYPE_SUCCESS, "Inva und Room Nummern wurden zurück gesetzt")
    })
);


const getCustomerAddress$ = apiGet(
    {apiType: API_GET_CUSTOMER_ADDRESS},
    (action: ApiCallAction<string>) => `/customers/${action.payload}/get-address`
);

const sendEmailToCustomer$ = apiPost(
    {apiType: API_SEND_EMAIL_TO_CUSTOMER},
    "/customers/send-email"
)

const postMessageOnSuccessfulEmailSend$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_SEND_EMAIL_TO_CUSTOMER}),
    map(() => {
        return postMessageAction(Message.TYPE_SUCCESS, "message.email-sent")
    })
);

const updateCustomerAllowedList$ = apiPost(
    {apiType: API_UPDATE_CUSTOMER_ALLOWED_LIST},
    "/customers/update-allowed-list"
);

const bulkUpdateCustomerAllowedList$ = apiPost(
    {apiType: API_BULK_UPDATE_ALLOWED_LISTS},
    "/customers/bulk-update-allowed-list"
);

const reloadOnInlineUpdate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses(
        {apiType: API_UPDATE_CUSTOMER_ALLOWED_LIST},
        {apiType: API_BULK_UPDATE_ALLOWED_LISTS},
    ),
    map(() => reloadEntityListAction(ENTITY_TYPE_CUSTOMER)())
);

const saveCustomer$ = apiPost(
    {apiType: API_SAVE_CUSTOMER},
    "/customers/save-customer"
)

const deleteDuplicateCustomers$ = apiPost(
    {apiType: API_DELETE_DUPLICATE_CUSTOMERS},
    (action: ApiCallAction<DeleteDuplicateCustomersPayload>) => `/customers/${action.payload.customerId}/delete-duplicates`
);

const deleteDuplicateCustomersSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_DELETE_DUPLICATE_CUSTOMERS}),
    map(() => {
        return postMessageAction(Message.TYPE_SUCCESS, "Doppelte Einträge gelöscht")
    })
);

const downloadCustomerPrintSheet$ = apiPost(
    {apiType: API_DOWNLOAD_CUSTOMER_PRINT_SHEET},
    (action: ApiCallAction<string>) => `/customers/${action.payload}/download-print-sheet`
);

const downloadCustomerPrintSheetSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_CUSTOMER_PRINT_SHEET),
        map((action: ApiCallSucceededAction<DownloadPdfData>) => {
            triggerPdfResponseDownload(action.payload, "Kundenstammdatenblatt.pdf")

            return {
                type: ""
            }
        })
    );

const downloadCustomersCsv$ = apiPost(
    {apiType: API_DOWNLOAD_CUSTOMERS_CSV},
    "/customers/download-customers-csv",
);

const downloadCustomersCsvSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_CUSTOMERS_CSV),
        map((action: ApiCallSucceededAction<DownloadCsvData>) => {
            triggerCsvResponseDownload(action.payload, "Kunden.csv")
            return {
                type: ""
            }
        })
    );

export const customerModuleEpics$ = combineEpics(
    customerSavedSuccessMessage$,
    reloadOnCustomerDelete$,
    getCurrentHighestCustomerNumber$,
    sendEmailToCustomer$,
    postMessageOnSuccessfulEmailSend$,
    updateCustomerAllowedList$,
    bulkUpdateCustomerAllowedList$,
    reloadOnInlineUpdate$,
    saveCustomer$,
    getCustomerAddress$,
    deleteDuplicateCustomers$,
    deleteDuplicateCustomersSuccess$,
    resetAuctionSpecificNumbers$,
    resetAuctionSpecificNumbersSuccess$,
    downloadCustomerPrintSheet$,
    downloadCustomerPrintSheetSuccess$,
    downloadCustomersCsv$,
    downloadCustomersCsvSuccess$
);
