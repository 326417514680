import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import React from "react";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {Button} from "react-bootstrap";
import {LotImportFileUploadField} from "./LotImporterFileUploadField";
import {connect} from "react-redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {startLotImportAction} from "../../../actions/start-lot-import-action";
import {Auction} from "../../../../auction/model/auction";
import {AuctionSelectField} from "../../../../auction/components/AuctionSelectField";

interface DispatchProps {
    startLotImport: typeof startLotImportAction;
}

interface OwnProps {
    selectedAuction: EntityWrapper<Auction> | undefined;
}

type Props = DispatchProps & OwnProps

const LotImporterComponent = (props: Props) => {
    const {
        startLotImport,
        selectedAuction
    } = props;

    const {t} = useTranslation(["core", "lot", "auction"]);

    return (
        <>
            <Formik
                initialValues={{
                    lotFile: "",
                    imageZip: "",
                    auction: selectedAuction
                }}
                onSubmit={(values, {setSubmitting}) => {
                    if (values.auction?.id) {
                        startLotImport(values.lotFile, values.imageZip, values.auction.id);
                        setSubmitting(false);
                    }
                }}
            >

                {formikState => (
                    <Form className={"lot-edit-form col-12 col-md-10 m-auto"}>
                        <h1>Katalog Importieren</h1>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={LotImportFileUploadField}
                                            placeholder={t("lot:entity.labels.lot-import-catalogue-file")}
                                            name="lotFile"
                                            className="form-control default-input"
                                            label={t("lot:entity.labels.lot-import-catalogue-file")}
                                            required
                            />
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={LotImportFileUploadField}
                                            placeholder={t("lot:entity.labels.lot-import-image-zip")}
                                            name="imageZip"
                                            className="form-control default-input"
                                            label={t("lot:entity.labels.lot-import-image-zip")}
                                            required/>
                        </div>
                        <div className="form-group row">
                            <ValidatedField formikState={formikState}
                                            component={AuctionSelectField}
                                            placeholder={t("auction:entity.singular")}
                                            name="auction"
                                            className="form-control default-input"
                                            label={t("auction:entity.singular")}
                                            required/>
                        </div>
                        <Button type={"submit"} className={"btn btn-primary"}>
                            {t("edit.submit")}
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export const LotImporter = connect<{}, DispatchProps>(
    null,
    {
        startLotImport: startLotImportAction
    }
)(LotImporterComponent);
