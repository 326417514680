import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {submitBidAuctioneerAction} from "../../../actions/submit-bid-auctioneer-action";
import {selectAuctioneerLotHighestBid} from "../../../selectors";

export const RoomButton = () => {

    const dispatch = useDispatch();
    const highestBid = useSelector(selectAuctioneerLotHighestBid)
    const {t} = useTranslation(['auctioneer']);
    const val = highestBid?.entity.value ? highestBid?.entity.value : 0

    return <button className={"btn btn-primary prev-lot-button"} onClick={() => dispatch(submitBidAuctioneerAction(
        val,
        "",
        "",
        "",
        ""
    ))}>{t("actions.room")}</button>
};
