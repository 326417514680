import React from "react";
import "./settlement-table.scss";
import {ApiCallState, EntityWrapper, Pagination, SortQuery} from "@thekeytechnology/framework-react";
import {useTranslation} from "react-i18next";
import {
    AboveTableContainer,
    ActionTableColumn,
    AsyncEntityTableBody,
    DateTimeDisplay,
    Table,
    TableColumn,
    TableHeader,
    TableRow,
    UnconnectedPaginationInfo
} from "@thekeytechnology/framework-react-ux";
import {CurrencyDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {PaginatedEndpointSortableTableHeaderColumn} from "../../paginated-endpoints/components/PaginatedEndpointSortableTableHeaderColumn";
import {CustomActionLink} from "../../core/components/CustomActionLink/CustomActionLink";
import {TableSettlement} from "../model/TableSettlement";
import {DownloadSettlementsForDatevButton} from "./DownloadSettlementsForDatevButton";

interface OwnProps {
    settlements: Array<EntityWrapper<TableSettlement>>;
    apiCallState: ApiCallState;
    pagination: Pagination;
    sortQuery: SortQuery;
    setSortQuery: (sortQuery: SortQuery) => void;
}

type Props = OwnProps;

export const SettlementTableNew = ({
                                       apiCallState,
                                       settlements,
                                       pagination,
                                       sortQuery,
                                       setSortQuery
                                   }: Props) => {

    const entities = settlements
    const entityApiState = apiCallState
    // const dispatch = useDispatch();

    const {t} = useTranslation(["settlement", "core", "customer", "order"])

    return (
        <>
            <AboveTableContainer>
                <UnconnectedPaginationInfo pagination={pagination} className="mr-auto"/>
                <NavLink className="mr-3"
                         to={"/abrechnungen/new/edit"}>
                    <button type="button" className="btn btn-primary">
                        {t("core:entity-create-delete-button.create")}
                    </button>
                </NavLink>
                {/*<button type="button" className="btn btn-primary" onClick={() => {*/}
                {/*    dispatch(refreshAllInvoicesAction)*/}
                {/*}}>*/}
                {/*    Rechnungen aktualisieren*/}
                {/*</button>*/}
                <DownloadSettlementsForDatevButton/>
            </AboveTableContainer>
            <Table className="settlement-table" itemCount={entities.length}>
                <TableHeader>
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.createdAt"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >{t("entity.labels.created-at")}</PaginatedEndpointSortableTableHeaderColumn>
                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.settlementNumber"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >{t("entity.labels.settlement-number")}</PaginatedEndpointSortableTableHeaderColumn>
                    <TableColumn>{t("entity.labels.customer")}</TableColumn>
                    <TableColumn>{t("customer:entity.labels.customer-number")}</TableColumn>
                    <TableColumn>{t("entity.labels.sum")}</TableColumn>
                    <TableColumn>{t("entity.labels.status")}</TableColumn>
                    <TableColumn>{t("order:entity.labels.invoice-status")}</TableColumn>
                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </TableHeader>

                <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={settlement => {

                    return (
                        <TableRow key={settlement.id}>
                            <TableColumn>
                                <DateTimeDisplay dateTime={settlement.entity.createdAt}/>
                            </TableColumn>
                            <TableColumn className="bold title-column">
                                {settlement.entity.settlementNumber}
                            </TableColumn>
                            <TableColumn>
                                {settlement.entity.customer.name}
                            </TableColumn>
                            <TableColumn>
                                {settlement.entity.customer.customerNumber}
                            </TableColumn>
                            <TableColumn>
                                <CurrencyDisplay amount={settlement.entity.sum}/>
                            </TableColumn>
                            <TableColumn>
                                {t("status." + settlement.entity.status)}
                            </TableColumn>
                            <TableColumn>
                                {settlement.entity.invoiceStatus ? t("order:receipt-list.receipt-status." + settlement.entity.invoiceStatus) : ""}
                            </TableColumn>
                            <ActionTableColumn isDisabled={false}>
                                <>
                                    <CustomActionLink to={"/abrechnungen/" + settlement.id + "/edit"}
                                                      tooltip={t("core:actions.edit")}
                                                      icon={faPencilAlt}/>
                                </>
                            </ActionTableColumn>
                        </TableRow>
                    );
                }}/>
            </Table>
        </>
    );
};
