import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import "./../../../assets/scss/table.scss"
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {
    AboveTableContainer,
    ActionTableColumn,
    AsyncEntityTableBody,
    EntityCreateAndDeleteButtons,
    EntityTableHeader,
    EntityTableRow,
    PaginationInfo,
    SortableTableHeaderColumn,
    Table,
    TableColumn
} from "@thekeytechnology/framework-react-ux";
import {withTranslation, WithTranslation} from "react-i18next";
import {Customer, ENTITY_TYPE_CUSTOMER} from "../model/customer";
import "./customers-table.scss"
import {InlineCustomerListEditor} from "./edit/inline-editing/InlineCustomerListEditor";
import {BulkCustomerListChanger} from "./edit/BulkCustomerListChanger";
import {CustomActionLink} from "../../core/components/CustomActionLink/CustomActionLink";
import {ResetAuctionSpecificNumbersButton} from "./edit/ResetAuctionSpecificNumbersButton";
import {DownloadCustomersCsvButton} from "./downloads/DownloadCustomersCsvButton";

type Props = WithEntityListProps<Customer> & WithSelectionsProps<string> & WithTranslation;

const CustomersTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        selectionModel,
        t
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ENTITY_TYPE_CUSTOMER} className="mr-auto"/>
                <BulkCustomerListChanger selectionModel={selectionModel}/>
                <DownloadCustomersCsvButton/>
                <ResetAuctionSpecificNumbersButton/>
                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={ENTITY_TYPE_CUSTOMER}
                    createPath="/kunden/new/edit"
                    label={t("entity.singular")}
                    labelPlural={t("entity.plural")}
                />
            </AboveTableContainer>
            <Table className="customers-table" itemCount={entities.length}>
                <EntityTableHeader selectionModel={selectionModel} entities={entities}>
                    <SortableTableHeaderColumn entityType={ENTITY_TYPE_CUSTOMER}
                                               property={"entity.customerNumber"}>
                        {t("entity.labels.customer-number")}
                    </SortableTableHeaderColumn>
                    <SortableTableHeaderColumn entityType={ENTITY_TYPE_CUSTOMER}
                                               property={"entity.personalData.lastName"}>
                        {t("entity.labels.personal-data.last-name")}
                    </SortableTableHeaderColumn>
                    <SortableTableHeaderColumn entityType={ENTITY_TYPE_CUSTOMER}
                                               property={"entity.personalData.firstName"}>
                        {t("entity.labels.personal-data.first-name")}
                    </SortableTableHeaderColumn>
                    <SortableTableHeaderColumn entityType={ENTITY_TYPE_CUSTOMER}
                                               property={"entity.list"}>
                        {t("entity.labels.allowed-list")}
                    </SortableTableHeaderColumn>
                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </EntityTableHeader>

                <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={customer => {
                    return (
                        <EntityTableRow key={customer.id} selectionModel={selectionModel} entity={customer}>
                            <TableColumn className="bold title-column">
                                {customer.entity.customerNumber}
                            </TableColumn>
                            <TableColumn>
                                {customer.entity.personalData.lastName}
                            </TableColumn>
                            <TableColumn>
                                {customer.entity.personalData.firstName}
                            </TableColumn>
                            <TableColumn>
                                <InlineCustomerListEditor customer={customer} editingEnabled={true}/>
                            </TableColumn>
                            <ActionTableColumn isDisabled={false}>
                                <>
                                    <CustomActionLink to={"/kunden/" + customer.id + "/edit"}
                                                tooltip={t("core:actions.edit")}
                                                icon={faPencilAlt}/>
                                </>
                            </ActionTableColumn>
                        </EntityTableRow>
                    );
                }}/>
            </Table>
        </>
    );
};

export const CustomersTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, Customer>(
        withTranslation("customer")(CustomersTableComponent), ENTITY_TYPE_CUSTOMER) as any);
