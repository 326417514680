import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS = "download-catalogue-delivery-labels";


export const downloadCatalogueDeliveryLabels = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS,
            callType: API_DOWNLOAD_CATALOGUE_DELIVERY_LABELS
        },
        payload: null
    } as ApiCallAction<null>;
};
