import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEuroSign} from "@fortawesome/free-solid-svg-icons";
import update from "immutability-helper";
import {useTranslation} from "react-i18next";
import {LotTitleDisplay} from "@thekeytechnology/auktionshaus-frontend-library";
import {
    LOT_TRANSFER_STATUS_RETURN,
    LOT_TRANSFER_STATUS_TRANSFER,
    LOT_TRANSFER_STATUS_TRANSFERRED,
    UnsoldLotPosition
} from "../../model/position/UnsoldLotPosition";
import Select from "react-select";

interface OwnProps {
    disabled?: boolean
    position: UnsoldLotPosition;
    onChange: (newPosition: UnsoldLotPosition) => void;
}


export const UnsoldLotPositionEditor = ({
                                            disabled,
                                            position,
                                            onChange,
                                        }: OwnProps) => {

    const {t} = useTranslation(["order", "lot"])


    const TODO_OPTIONS = [LOT_TRANSFER_STATUS_TRANSFER, LOT_TRANSFER_STATUS_RETURN];
    if (position.todo === LOT_TRANSFER_STATUS_TRANSFERRED) {
        TODO_OPTIONS.push(LOT_TRANSFER_STATUS_TRANSFERRED)
    }
    const todoOptions = TODO_OPTIONS.map(todo => ({
        label: t(`lot:transfer-status.${todo}`),
        value: todo
    }));

    const [state, setState] = useState({
        selectedType: position.todo
    });


    return <div className="d-flex w-100 flex-wrap">
        <div className="w-100 mb-2">
            Los {position.lot?.entity.lotNumber} - <LotTitleDisplay
            description={position.lot?.entity.description.de}/> ({position.lot?.entity.items[0].inventoryNumber})
        </div>
        <div className="d-flex w-100">
            <div className="input-field-wrapper">
                <FontAwesomeIcon className="input-icon" icon={faEuroSign}/>
                <input className="form-control default-input"
                       placeholder={t("unsold-lot-position-editor.placeholder")}
                       type="number"
                       min={0}
                       disabled={disabled}
                       step={0.01}
                       value={position.amount}
                       onChange={event => {
                           const newAmount = Number.parseFloat(event.target.value);
                           onChange(update(position, {amount: {$set: newAmount}}))
                       }}/>
            </div>
            <div className="input-field-wrapper" style={{minWidth: '161px'}}>
                <Select
                    isDisabled={disabled}
                    className="react-select mr-2"
                    classNamePrefix="react-select"
                    isClearable={false}
                    options={todoOptions}
                    onChange={(newValue: any) => {
                        setState({
                            ...state,
                            selectedType: newValue.value
                        });
                        onChange(update(position, {todo: {$set: newValue.value}}));
                    }}
                    value={todoOptions.find((selected: any) => selected.value === state.selectedType)}
                />
            </div>
        </div>
    </div>
};

