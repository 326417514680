import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_LIMIT_BIDS = "download-limit-bids";

export interface DownloadLimitBidsPayload extends Action {
    auctionId: string;
}

export const downloadLimitBidsAction = (auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_LIMIT_BIDS,
            callType: API_DOWNLOAD_LIMIT_BIDS
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<DownloadLimitBidsPayload>;
};
