import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Placement} from "react-bootstrap/Overlay";
import {CustomTooltipLink} from "./CustomTooltipLink";

interface IconLinkWithTooltipProps {
    to: string;
    tooltip: string;
    icon: IconDefinition;
    placement?: Placement;
}

export const CustomActionLink = (props: IconLinkWithTooltipProps) => {
    return <CustomTooltipLink placement={props.placement} to={props.to} tooltip={props.tooltip}>
        <FontAwesomeIcon icon={props.icon}/>
    </CustomTooltipLink>;
};
