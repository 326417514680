import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {UserWithRoles, WithEntityList, WithSelections} from "@thekeytechnology/framework-react";
import {WithEntityListProps} from "@thekeytechnology/framework-react/dist/entity/hoc/WithEntityList";
import {WithSelectionsProps} from "@thekeytechnology/framework-react/dist/utils/hoc/selections/WithSelections";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import "./users-table.scss";
import {
    AboveTableContainer,
    ActionLink,
    ActionTableColumn,
    AsyncEntityTableBody,
    EntityTableHeader,
    EntityTableRow,
    Table,
    TableColumn,
    UnconnectedPagination
} from "@thekeytechnology/framework-react-ux";
import {usePaginatedEndpoint} from "../../../../paginated-endpoints/hooks/use-paginated-endpoint";
import {usePaginatedEndpointFilters} from "../../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {UserFilters} from "../filters/UserFilters";
import {UserForEditing} from "../../../model/UserForEditing";

type Props = WithSelectionsProps<string> & WithEntityListProps<UserWithRoles> & WithTranslation;

const UsersTableComponent = (props: Props) => {
    const {
        selectionModel,
        t
    } = props;

    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "users-table"
    )

    const {
        resolved,
        setFilters,
        apiCallState,
        setPaginationQuery,
    } = usePaginatedEndpoint<UserForEditing>(
        "/users/get-list",
        undefined,
        [],
        endpointFilters.getFilters().flatMap(f => f.filters)
    )



    return (
        <>
            <h3>{t("users-table.heading")}</h3>
            <UserFilters endpointFilters={endpointFilters}/>
            <AboveTableContainer>
                <NavLink className="mr-3"
                         to={"/benutzer/einladen"}>
                    <button type="button" className="btn btn-success">
                        {t("users-table.invite-button")}
                    </button>
                </NavLink>
                {/*<RemoveUsersButton selectedEntities={selectionModel.selections}/>*/}
            </AboveTableContainer>
            <Table itemCount={resolved.entities.length} className="users-table">
                <EntityTableHeader entities={resolved.entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.user.labels.name")}</TableColumn>
                    <TableColumn>{t("entity.user.labels.email")}</TableColumn>
                    <TableColumn>{t("entity.user.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody
                    apiCallState={apiCallState}
                    items={resolved.entities}
                    renderItem={user =>
                        <EntityTableRow
                            key={user.id}
                            entity={user}
                            selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                {user.entity.name}
                            </TableColumn>
                            <TableColumn>
                                {user.entity.email}
                            </TableColumn>
                            <ActionTableColumn>
                                <ActionLink
                                    to={"/benutzer/" + user.id + "/rollen"}
                                    tooltip={t("users-table.edit-user")}
                                    icon={faPencilAlt}/>
                            </ActionTableColumn>
                        </EntityTableRow>
                    }/>
            </Table>
            <UnconnectedPagination pagination={resolved.pagination} setPage={page => {
                setPaginationQuery({
                    page,
                    docsPerPage: resolved.pagination.docsPerPage
                })
            }}/>
        </>
    );
};

export const UsersTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, UserWithRoles>(
        withTranslation("users")(UsersTableComponent),
        UserWithRoles.TYPE
    ) as any
);
