import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {WithTranslation, withTranslation} from "react-i18next";
import {InvitationsTable} from "./pending-invites/InvitationsTable";
import {UsersTable} from "./table/UsersTable";
import {ContentContainer, HeadingContainer} from "@thekeytechnology/framework-react-ux";
import React from "react";

const UsersInAccountOverviewComponent = (props: WithTranslation) => {
    const {t} = props;
    return (
        <>
            <BreadcrumbsItem to={"/benutzer"}>{t("users-overview.heading")}</BreadcrumbsItem>
            <HeadingContainer>
                {/*<SimpleHeader heading={t("users-overview.heading")}/>*/}
            </HeadingContainer>
            <ContentContainer>
                <InvitationsTable/>
            </ContentContainer>
            <ContentContainer>
                <UsersTable/>
            </ContentContainer>
        </>
    );
};

export const UsersInAccountOverview = withTranslation("users")(UsersInAccountOverviewComponent);
