import {EntityWrapper, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {FieldInputProps, FieldProps, FormikProps} from "formik";
import React from "react";
import {useTranslation} from "react-i18next";
import {
    CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY,
    PaginatedEndPointAsyncEntitySelect
} from "../../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelect";
import {createRangeFilterForNumberInput} from "../../../core/utils/create-range-filter-for-number-input";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";

interface OwnProps {
    onSelect?: (
        selected: EntityWrapper<Auction> | EntityWrapper<Auction>[] | undefined,
        form: FormikProps<any>,
        field: FieldInputProps<any>
    ) => void
}

type Props = OwnProps & FieldProps;

export const AuctionSelectField = ({
                                        field,
                                        form,
                                        onSelect
                                    }: Props) => {

    const {t} = useTranslation(["itemCategory", "core", "customer", "item"]);

    const onSelectValueSelected = (selected: EntityWrapper<Auction> | EntityWrapper<Auction>[] | undefined) => {
        if (selected) {
            form.setFieldValue(field.name, selected);
            if (onSelect) {
                onSelect(selected, form, field);
            }
        }
    }

    return (
        <>
            <PaginatedEndPointAsyncEntitySelect<Auction>
                shownEntityType={ENTITY_TYPE_AUCTION}
                shownEntityTypeProperties={["entity.auctionNumber"]}
                selected={field.value}
                select={onSelectValueSelected}
                listRenderer={(item: EntityWrapper<Auction>) => `${item.entity.auctionNumber}`}
                placeholder={t("core:controls.select.select-with-type", {
                    type: t("customer:entity.singular")
                })}
                onBlur={field.onBlur}
                fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("auctions/get-auctions")}
                customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                    if (property === "entity.auctionNumber") {
                        if (inputValue && parseInt(inputValue)) {
                            return createRangeFilterForNumberInput(property, inputValue, 3)
                        } else {
                            return []
                        }
                    } else {
                        return [new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, inputValue))]
                    }
                }}
            />
        </>
    );
};
