import {API_LOGIN, ApiCallAction, apiPost, LogoutAction, matchesApiSuccess} from "@thekeytechnology/framework-react";
import {API_EXTENDED_REGISTRATION, ExtendedRegistrationPayload} from "../action/extended-registration";
import {combineEpics} from "redux-observable";
import {Observable, of} from "rxjs";
import {Action} from "redux";
import {filter, switchMap} from "rxjs/operators";
import {push} from "connected-react-router";

const registration$ = apiPost(
    {apiType: API_EXTENDED_REGISTRATION},
    `/api/app/v1/auth/registration`,
    (action: ApiCallAction<ExtendedRegistrationPayload>) => action.payload
);

const redirectAfterLogin$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_LOGIN),
    switchMap(() => of(push("/dashboard/")))
);

const redirectAfterLogout$ = (action$: Observable<Action>) => action$.pipe(
    filter(action => action.type === LogoutAction),
    switchMap(() => of(push("/")))
)

export const extendedAuthModuleEpics$ = combineEpics(
    redirectAfterLogin$,
    registration$,
    redirectAfterLogout$
)
