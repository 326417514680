import {
    API_DELETE_ENTITIES,
    ApiCallAction,
    ApiCallSucceededAction,
    apiGet,
    apiPost,
    matchesAnyOfTheseApiSuccesses,
    matchesApiSuccess,
    Message,
    postMessageAction,
    reloadOnEntityApiOperation
} from "@thekeytechnology/framework-react";
import {Item} from "../model/item";
import {combineEpics} from "redux-observable";
import {API_GET_NEXT_ITEM_NUMBER} from "../actions/next-item";
import {API_GET_NEXT_AUCTION} from "../actions/next-auction";
import {filter, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {Action} from "redux";
import {API_SAVE_ITEM} from "../actions/save-item";
import {DownloadCsvData} from "../../core/model/DownloadCsvData";
import {
    API_DOWNLOAD_ITEMS_FOR_TRANSLATION,
    DownloadItemsForTranslationPayload
} from "../actions/download-items-for-translation";
import {triggerCsvResponseDownload} from "../../core/utils/trigger-csv-response-download";
import {API_DELETE_DUPLICATE_ITEMS, DeleteDuplicateItemsPayload} from "../actions/delete-duplicate-items";

const successMessageAfterItemCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ITEM
    }),
    filter(action => action.payload.isNew),
    map(action => {
        return postMessageAction(Message.TYPE_SUCCESS, "Objekt erfolgreich erstellt")
    })
);

const successMessageAfterItemSave$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ITEM
    }),
    filter(action => !action.payload.isNew),
    map(action => {
        return postMessageAction(Message.TYPE_SUCCESS, "Objekt erfolgreich gespeichert")
    })
);

const redirectAfterItemCreate$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({
        apiType: API_SAVE_ITEM
    }),
    filter(action => action.payload.isNew),
    map(action => window.location.href = "/objekte/".concat(action.payload.id).concat("/edit"))
);


const reloadAfterItemDelete$ = reloadOnEntityApiOperation(API_DELETE_ENTITIES, Item.TYPE);

const getCurrentItemNumber$ = apiGet(
    {apiType: API_GET_NEXT_ITEM_NUMBER},
    "/items/next-number"
);

const getNextAuction$ = apiGet(
    {apiType: API_GET_NEXT_AUCTION},
    "/items/next-auction"
);

const saveItem$ = apiPost(
    {apiType: API_SAVE_ITEM},
    "/items/save-item"
)

const downloadItemsForTranslation$ = apiPost<DownloadItemsForTranslationPayload, any>(
    {apiType: API_DOWNLOAD_ITEMS_FOR_TRANSLATION},
    "/items/download-items-for-translation",
);

const downloadItemsForTranslationSuccess$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesApiSuccess(API_DOWNLOAD_ITEMS_FOR_TRANSLATION),
        map((action: ApiCallSucceededAction<DownloadCsvData>) => {
            triggerCsvResponseDownload(action.payload, "Objekte.csv")
            return {
                type: ""
            }
        })
    );

const deleteDuplicateItems$ = apiPost(
    {apiType: API_DELETE_DUPLICATE_ITEMS},
    (action: ApiCallAction<DeleteDuplicateItemsPayload>) => `/items/${action.payload.itemId}/delete-duplicates`
);

const deleteDuplicateItemsSuccess$ = (action$: Observable<Action>) => action$.pipe(
    matchesAnyOfTheseApiSuccesses({apiType: API_DELETE_DUPLICATE_ITEMS}),
    map(() => {
        return postMessageAction(Message.TYPE_SUCCESS, "Doppelte Objekte gelöscht")
    })
);

export const itemsModuleModuleEpics$ = combineEpics(
    successMessageAfterItemCreate$,
    successMessageAfterItemSave$,
    redirectAfterItemCreate$,
    reloadAfterItemDelete$,
    getCurrentItemNumber$,
    getNextAuction$,
    saveItem$,
    downloadItemsForTranslation$,
    downloadItemsForTranslationSuccess$,
    deleteDuplicateItems$,
    deleteDuplicateItemsSuccess$
);

