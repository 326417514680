import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import {DownloadOrdersForDatevForm} from "./DownloadOrdersForDatevForm";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {
    API_DOWNLOAD_ORDERS_FOR_DATEV_CSV,
    downloadOrdersForDatevCsvAction
} from "../../actions/download-orders-for-datev-csv";
import {useDispatch, useSelector} from "react-redux";

export const DownloadOrdersForDatevButton = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_ORDERS_FOR_DATEV_CSV));
    const dispatch = useDispatch();

    return <>
        <button
            className={"btn btn-secondary ml-3"}
            onClick={() => handleShow()}
        >
            <FontAwesomeIcon icon={faFileCsv}/> Datev CSV
        </button>



        <Modal size="xl" show={show} onHide={handleClose}>
            {show ? <DownloadOrdersForDatevForm
                handleClose={handleClose}
                disabled={apiCallState.inProgress}
                submitCallback={(month: number, year: number) => {
                    dispatch(downloadOrdersForDatevCsvAction(month, year));
                }}
            /> : null}
        </Modal>
    </>;
}
