import React, {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import {LotDetailsModal} from "./LotDetailsModal";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";

interface OwnProps {
    lot: EntityWrapper<AuctionManagerLot>
}

type Props = OwnProps

export const LotDetailsButton = ({
                                     lot
                                 }: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {t} = useTranslation(["auctionPlanning"])

    return <>
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-remove`}>
                    {t("details-button")}
                </Tooltip>
            }
        >
            <button
                className={"btn btn-link mr-2"}
                onClick={() => handleShow()}
            >
                <FontAwesomeIcon icon={faEye}/>
            </button>
        </OverlayTrigger>


        <Modal size={"lg"} show={show} onHide={handleClose}>
            {show ? <LotDetailsModal handleClose={handleClose} lot={lot}/> : null}
        </Modal>
    </>;
}
