import {DialogResult, EntityWrapper, selectCombinedApiState, showDialogAction} from "@thekeytechnology/framework-react";
import {Order, ORDER_STATUS_OPEN} from "../model/Order";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {API_REOPEN_ORDER, reopenOrderAction} from "../actions/reopen-order";
import {Dropdown} from "react-bootstrap";

interface OwnProps {
    order: EntityWrapper<Order>
    disabled?: boolean;
}

export const ReopenOrderButton = ({order, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_REOPEN_ORDER))

    return order.entity.status !== ORDER_STATUS_OPEN ?
        <Dropdown.Item disabled={apiCallState.inProgress || disabled}
                        onClick={() => dispatch(showDialogAction({
                            title: "Bestellung wieder öffnen",
                            question: "Soll die Bestellung wieder geöffet werden? Falls bereits eine Rechnung im Lexoffice vorliegt, wird eine gleichwertige Gutschrift erzeugt. Zuschläge werden nicht vom Kunden entfernt.",
                            explanation: "",
                            affirmativeText: "Bestellung öffnen",
                            negativeText: "Abbrechen"
                        }, result => {
                            if (result === DialogResult.ACCEPT) {
                                dispatch(reopenOrderAction(order.id!))
                            }
                        }))}
                        >
            Rechnung bearbeiten
        </Dropdown.Item>
        : null;

}
