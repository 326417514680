import {Invitation, WithEntityList, WithSelections} from "@thekeytechnology/framework-react";
import {WithEntityListProps} from "@thekeytechnology/framework-react/dist/entity/hoc/WithEntityList";
import {WithSelectionsProps} from "@thekeytechnology/framework-react/dist/utils/hoc/selections/WithSelections";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import "./invitations-table.scss";
import {
    AboveTableContainer,
    AsyncEntityTableBody,
    DateTimeDisplay,
    EntityDeletionButton,
    EntityTableHeader,
    EntityTableRow,
    Table,
    TableBody,
    TableColumn
} from "@thekeytechnology/framework-react-ux";

type Props = WithEntityListProps<Invitation> & WithSelectionsProps<string> & WithTranslation;

const InvitationsTableComponent = (props: Props) => {
    const {
        entities,
        selectionModel,
        entityApiState,
        t
    } = props;

    if (entities && entities.length === 0) {
        return <></>;
    }

    return (
        <>
            <h3>{t("invitations-table.heading")}</h3>
            <AboveTableContainer>
                <EntityDeletionButton entityType={Invitation.TYPE}
                                      selectedEntities={selectionModel.selections}
                                      label={t("entity.invitation.singular")}
                                      labelPlural={t("entity.invitation.plural")}
                />
            </AboveTableContainer>
            <Table itemCount={entities.length} className="invitations-table">
                <EntityTableHeader entities={entities} selectionModel={selectionModel}>
                    <TableColumn>{t("entity.invitation.labels.email")}</TableColumn>
                    <TableColumn>{t("entity.invitation.labels.inviter")}</TableColumn>
                    <TableColumn>{t("entity.invitation.labels.valid-until")}</TableColumn>
                </EntityTableHeader>
                <TableBody>
                    <AsyncEntityTableBody apiCallState={entityApiState}
                                          items={entities}
                                          renderItem={invitation =>
                                              <EntityTableRow entity={invitation} selectionModel={selectionModel}>
                                                  <TableColumn className="bold title-column">
                                                      {invitation.entity.email}
                                                  </TableColumn>
                                                  <TableColumn>
                                                      {invitation.entity.invitingUserName}
                                                  </TableColumn>
                                                  <TableColumn>
                                                      <DateTimeDisplay dateTime={invitation.entity.validUntil}/>
                                                  </TableColumn>
                                              </EntityTableRow>
                                          }/>
                </TableBody>
            </Table>
        </>
    );
};

export const InvitationsTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, Invitation>(
        withTranslation("users")(InvitationsTableComponent) as any,
        Invitation.TYPE
    ) as any
);
