import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_UPDATE_COMMENT = "update-comment";

export interface UpdateCommentPayload {
    logEntryId: string;
    comment: string
}

export const updateCommentAction = (logEntryId: string, comment: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_UPDATE_COMMENT,
            callType: API_UPDATE_COMMENT
        },
        payload: {
            logEntryId,
            comment
        }
    } as ApiCallAction<UpdateCommentPayload>;
};
