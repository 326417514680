import React, {useState} from "react"
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctionManagerLot} from "../../../lots/model/AuctionManagerLot";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGavel} from "@fortawesome/free-solid-svg-icons";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {CustomerSelectField} from "../../../items/components/edit/CustomerSelectField";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {manualAssignWinningBidAction} from "../../actions/winning-bids/manual-assign-winning-bid-action";
import {removeWinningBidAction} from "../../actions/winning-bids/remove-winning-bid-action";
import Modal from "react-bootstrap/Modal";
import {BidFieldCustom} from "../../../auctioneer-view/components/action-bar/submit-bid/BidFieldCustom";
import {BidSourceSelectField} from "../../../bids/components/BidSourceSelectField";
import {Bid} from "../../../bids/model/bid";

interface OwnProps {
    lot: EntityWrapper<AuctionManagerLot>
}

type Props = OwnProps

export const ManualAssignWinningBidForm = ({
                                               lot
                                           }: Props) => {

    const {t} = useTranslation(["lot", "bid", "core", "auctioneer"]);
    const dispatch = useDispatch();

    const startingBid = lot.entity.winningBid?.entity.value ?
        lot.entity.winningBid.entity.value :
        lot.entity.pricingInformation.limitPrice ? lot.entity.pricingInformation.limitPrice : 20

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        <button className="btn btn-link ml-1" onClick={handleShow}>
            <FontAwesomeIcon icon={faGavel}/>
        </button>
        <Modal size="xl" show={show} onHide={handleClose} autoFocus={false} onShow={() => {
        }}>
            <Modal.Header>
                {t("auctioneer:actions.assign-winning-bid")}
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        buyer: lot.entity.winningBid?.entity.customer ? lot.entity.winningBid?.entity.customer : null,
                        value: startingBid,
                        source: Bid.SOURCE_AUCTIONEER
                    }}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        buyer: Yup.object().required(t("core:forms.required-field", {fieldName: t("item:entity.labels.seller")})),
                        value: Yup.number().required(t("core:forms.required-field", {fieldName: t("bid:entity.singular")}))
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        if (values.value && values.buyer && lot.id) {
                            dispatch(manualAssignWinningBidAction(
                                values.value,
                                values.buyer.id,
                                lot.id,
                                values.source
                            ));
                        }
                        document.body.click()
                        setSubmitting(false);
                    }}
                >
                    {formikState => (
                        <Form className="d-flex flex-column">
                            <ValidatedField formikState={formikState}
                                            component={CustomerSelectField}
                                            name="buyer"
                                            className="form-control default-input"
                                            onlyWhitelistedCustomers={true}
                            />
                            <ValidatedField formikState={formikState}
                                            component={BidFieldCustom}
                                            name="value"
                                            className="form-control default-input"
                                            autoAdjustOnNewBidOrLot={false}
                            />
                            <ValidatedField formikState={formikState}
                                            component={BidSourceSelectField}
                                            name="source"
                                            className="form-control default-input"
                                            autoAdjustOnNewBidOrLot={false}
                            />
                            <div>{lot.entity.winningBid ? <>Zuschlag: {startingBid} ---</> : null} Ausruf: {lot.entity.pricingInformation.limitPrice}</div>
                            <button disabled={formikState.isSubmitting} className="btn btn-success w-100 mt-3"
                                    type="submit">
                                {t("auctioneer:actions.assign-winning-bid")}
                            </button>
                        </Form>
                    )}
                </Formik>
                {lot.entity.winningBid ? <button
                    onClick={() => {
                        if (lot.id) {
                            dispatch(removeWinningBidAction(
                                lot.id
                            ));
                            document.body.click();
                        }
                    }}
                    className="btn btn-warning w-100 mt-3"
                    type="submit">
                    Zuschlag entfernen
                </button> : null}
            </Modal.Body>
        </Modal>
    </>
}
