import {ApiCallAction} from "@thekeytechnology/framework-react";
import {Action} from "redux";

export const API_DOWNLOAD_ITEMS_FOR_TRANSLATION = "download-items-for-translation";

export interface DownloadItemsForTranslationPayload extends Action {
    auctionId?: string;
}

export const downloadItemsForTranslationAction = (auctionId?: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_ITEMS_FOR_TRANSLATION,
            callType: API_DOWNLOAD_ITEMS_FOR_TRANSLATION
        },
        payload: {
            auctionId
        }
    } as ApiCallAction<DownloadItemsForTranslationPayload>;
};
