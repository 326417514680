import React from "react";
import {BreadcrumbsItem} from "react-breadcrumbs-dynamic";
import {useTranslation} from "react-i18next";
import {
    BackgroundContainer,
    ContentContainer,
    HeadingContainer,
    PaginationContainer,
    SimpleHeader,
    UnconnectedPagination
} from "@thekeytechnology/framework-react-ux";
import {usePaginatedEndpointFilters} from "../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {usePaginatedEndpoint} from "../../paginated-endpoints/hooks/use-paginated-endpoint";
import {TableOrder} from "../../orders/model/TableOrder";
import {PageTitle} from "../../core/components/PageTitle";
import {SettlementFiltersNew} from "./SettlementFiltersNew";
import {SettlementTableNew} from "./SettlementTableNew";

export const SettlementOverview = () => {

    const {t} = useTranslation(["settlement"])

    const endpointFilters = usePaginatedEndpointFilters(
        filters => setFilters(filters),
        "settlement-table"
    )

    const {
        resolved,
        setFilters,
        apiCallState,
        setPaginationQuery,
        setSortQuery,
        sortQuery
    } = usePaginatedEndpoint<TableOrder>(
        "/settlements/get-table-settlements",
        undefined,
        [],
        endpointFilters.getFilters().flatMap(f => f.filters)
    )


    return (
        <>
            <PageTitle title={"Alle Abrechnungen"} />
            <BreadcrumbsItem to={"/abrechnungen"}>{t("entity.plural")}</BreadcrumbsItem>
            <HeadingContainer>
                <SimpleHeader heading={t("entity.plural")}/>
            </HeadingContainer>
            <BackgroundContainer>
                <SettlementFiltersNew endpointFilters={endpointFilters}/>
            </BackgroundContainer>
            <ContentContainer>
                <SettlementTableNew settlements={resolved.entities}
                            apiCallState={apiCallState}
                            pagination={resolved.pagination}
                            setSortQuery={setSortQuery}
                            sortQuery={sortQuery}
                />
            </ContentContainer>
            <PaginationContainer>
                <UnconnectedPagination pagination={resolved.pagination} setPage={page => {
                    setPaginationQuery({
                        page,
                        docsPerPage: resolved.pagination.docsPerPage
                    })
                }}/>
            </PaginationContainer>
        </>
    );
};

