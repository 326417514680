import {
    addFiltersAction,
    Filter,
    FilterTerm,
    OrFilter,
    PropertyFilter,
    removeFiltersAction,
    selectFilter
} from "@thekeytechnology/framework-react";
import React from "react";
import {connect} from "react-redux";
import Select from "react-select";
import {Action, Dispatch} from "redux";
import {OptionType} from "@thekeytechnology/framework-react-ux/dist/core/components/form/SimpleOptionSelectField";
import {ClearIndicator} from "@thekeytechnology/framework-react-ux";
import {CUSTOMER_ALLOWED_LIST_NO_LIST} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    options: Array<OptionType<string>>;
    entityType: string;
    property: string;
    filterKey: string;
    placeholder: string;
    filterType?: string;
}

interface StateProps {
    currentFilters?: Filter[];
}

interface DispatchProps {
    removeFilters: () => void;
    addFilters: (filters: Filter[]) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const CustomersListSelectFilterComponent = (props: Props) => {
    const {placeholder, options, currentFilters} = props;

    const determineStatus = () => {
        if (!currentFilters || currentFilters.length === 0) {
            return null;
        }

        const firstFilter = currentFilters[0] as Filter;
        if (firstFilter instanceof PropertyFilter) {
            return options.find(option => option.value === firstFilter.filterTerm.value);
        } else {
            return options.find(option => option.value === CUSTOMER_ALLOWED_LIST_NO_LIST);
        }
    };

    const filter = (selection: OptionType<string>) => {
        const {addFilters, removeFilters, property, filterType} = props;

        const filterTypeToUse = filterType ? filterType : FilterTerm.TYPE_STRING;

        if (selection === null) {
            removeFilters();
        } else {
            if (selection.value === "") {
                addFilters([
                    new OrFilter([
                        new PropertyFilter(
                            property, new FilterTerm(FilterTerm.TYPE_BOOLEAN, "exists", false)
                        ),
                        new PropertyFilter(
                            property, new FilterTerm(filterTypeToUse, FilterTerm.OPERATION_EQ, CUSTOMER_ALLOWED_LIST_NO_LIST)
                        )
                    ])
                ]);
            } else {
                addFilters([
                    new PropertyFilter(
                        property, new FilterTerm(filterTypeToUse, FilterTerm.OPERATION_EQ, selection.value)
                    )
                ]);
            }
        }
    };

    return (
        <Select
            components={{ClearIndicator}}
            placeholder={placeholder}
            className="filter react-select"
            classNamePrefix="react-select"
            isClearable={true}
            onChange={(option: any) => filter(option as OptionType<string>)}
            options={options as any}
            value={determineStatus()}
        />
    );
}

export const CustomersListSelectFilter: () => React.NamedExoticComponent<OwnProps> = () => connect<StateProps, DispatchProps, OwnProps>(
    (state: any, props: OwnProps) => {
        return {
            currentFilters: selectFilter(props.entityType, props.filterKey)(state)
        };
    },
    (dispatch: Dispatch<Action>, props: OwnProps) =>
        ({
            addFilters: (filters: Filter[]) => dispatch(addFiltersAction(props.entityType)(props.filterKey, filters)),
            removeFilters: () => dispatch(removeFiltersAction(props.entityType)([props.filterKey]))
        })
)
(CustomersListSelectFilterComponent);
