import {ApiCallSucceededAction, isApiSuccess} from "@thekeytechnology/framework-react";
import {API_FINALIZE_AUCTION, AuctionFinalizationResponse} from "../actions/finalize-auction";
import {Action} from "redux";

export interface ValidationState {
    newStatus: string;
    errors: { [lotId: string]: string[] };
}

export const initialState: ValidationState = {
    newStatus: "not-validated",
    errors: {},
};

export function validationReducer(state = initialState, action: Action): ValidationState {
    if (isApiSuccess(API_FINALIZE_AUCTION)(action)) {
        const finalizationResponse = action as ApiCallSucceededAction<AuctionFinalizationResponse>;
        return {
            ...state,
            newStatus: finalizationResponse.payload.newStatus,
            errors: finalizationResponse.payload.errors
        };
    }
    return state;
}
