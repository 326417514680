import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {WithEntityList} from "@thekeytechnology/framework-react";
import {WithEntityListProps} from "@thekeytechnology/framework-react/dist/entity/hoc/WithEntityList";
import React from "react";
import "./email-table.scss";
import {withTranslation, WithTranslation} from "react-i18next";
import {EmailConfiguration} from "../../../model/email-configuration.model";
import {AsyncEntityTableBody, Table, TableColumn, TableHeader, TableRow} from "@thekeytechnology/framework-react-ux";
import {CustomActionLink} from "../../../../core/components/CustomActionLink/CustomActionLink";

type Props = WithEntityListProps<EmailConfiguration> & WithTranslation;

const EmailTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        t
    } = props;

    return (
        <Table className="email-table" itemCount={entities.length}>
            <TableHeader>
                <div className="table-column">Name</div>
                <div className="table-column">Aktionen</div>
            </TableHeader>
            <div className="table-body">
                <AsyncEntityTableBody
                    apiCallState={entityApiState}
                    items={entities}
                    renderItem={email =>
                        <TableRow key={email.id}>
                            <TableColumn className="bold">
                                {t("email-keys." + email.entity.key)}
                            </TableColumn>
                            <TableColumn>
                                <CustomActionLink
                                    to={"/emails/" + email.id + "/edit"}
                                    tooltip={"Email bearbeiten"}
                                    icon={faPencilAlt}/>
                            </TableColumn>
                        </TableRow>
                    }/>
            </div>
        </Table>
    );
};

export const EmailTable = WithEntityList<{}, EmailConfiguration>(
    withTranslation("emails")(EmailTableComponent),
    EmailConfiguration.TYPE);
