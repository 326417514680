import {API_CREATE_ENTITIES, API_DELETE_ENTITIES, isApiSuccess} from "@thekeytechnology/framework-react";
import {Action} from "redux";
import {API_MANUALLY_ASSIGN_ITEMS} from "../actions/assign/manually-assign-items";
import {API_ASSIGN_PREASSIGNED} from "../actions/assign/assign-preassigned-items";
import {API_TRANSFER_LOTS} from "../actions/assign/transfer";
import {API_GROUP_LOTS} from "../actions/group/group-lots";
import {API_UNGROUP_LOT} from "../actions/group/ungroup-lots";
import {AuctionManagerLot} from "../../lots/model/AuctionManagerLot";
import {API_UPDATE_DESCRIPTION} from "../actions/inline-update/update-description";
import {API_UPDATE_LIMIT_PRICE} from "../actions/inline-update/update-limit-price";
import {API_UPDATE_SELLER} from "../actions/inline-update/update-seller";
import {API_UPDATE_BUYER} from "../actions/inline-update/update-buyer";
import {API_UPDATE_CATEGORY} from "../actions/inline-update/update-category";
import {API_BACK_TO_SELLER} from "../actions/back-to-seller";
import {API_GENERATE_INVOICES} from "../actions/generate-invoices";
import {API_GENERATE_SETTLEMENTS} from "../actions/generate-settlements";
import {API_MANUAL_ASSIGN_WINNING_BID} from "../actions/winning-bids/manual-assign-winning-bid-action";
import {API_REMOVE_WINNING_BID} from "../actions/winning-bids/remove-winning-bid-action";
import {API_GENERATE_SELLER_LISTS} from "../actions/seller-lists/generate-seller-lists";

export interface ReloadViewState {
    lastUpdate: string;
}

export const initialState: ReloadViewState = {
    lastUpdate: "",
};

export function reloadViewReducer(state = initialState, action: Action): ReloadViewState {

    const isCorrectApiSuccess = [
        {apiType: API_MANUALLY_ASSIGN_ITEMS},
        {apiType: API_ASSIGN_PREASSIGNED},
        {apiType: API_TRANSFER_LOTS},
        {apiType: API_GROUP_LOTS},
        {apiType: API_UNGROUP_LOT},
        {apiType: API_CREATE_ENTITIES, callType: AuctionManagerLot.TYPE},
        {apiType: API_DELETE_ENTITIES, callType: AuctionManagerLot.TYPE},
        {apiType: API_UPDATE_DESCRIPTION},
        {apiType: API_UPDATE_LIMIT_PRICE},
        {apiType: API_UPDATE_SELLER},
        {apiType: API_UPDATE_BUYER},
        {apiType: API_UPDATE_CATEGORY},
        {apiType: API_BACK_TO_SELLER},
        {apiType: API_GENERATE_INVOICES},
        {apiType: API_GENERATE_SETTLEMENTS},
        {apiType: API_MANUAL_ASSIGN_WINNING_BID},
        {apiType: API_REMOVE_WINNING_BID},
        {apiType: API_GENERATE_SELLER_LISTS}
    ].map(x => {
        return isApiSuccess(x.apiType, x.callType)(action)
    }).includes(true)

    if (isCorrectApiSuccess) {
        return {
            ...state,
            lastUpdate: Date.now().toString()
        };
    }
    return state;
}
