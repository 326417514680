import React from "react";
import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {PaginatedEndpointFiltersReturnProps} from "../hooks/use-paginated-endpoint-filters";

interface OwnProps {
    property: string
    placeholder: string
    endpointFilters: PaginatedEndpointFiltersReturnProps
    customFilterFunction?: (
        property: string,
        endpointFilters: PaginatedEndpointFiltersReturnProps,
        text: string
    ) => void
    customValueFunction?: (endpointFilters: PaginatedEndpointFiltersReturnProps, property: string) => string
}

export const PaginatedEndPointTextFilter = ({
                                                property,
                                                placeholder,
                                                endpointFilters,
                                                customFilterFunction,
                                                customValueFunction
                                            }: OwnProps) => {

    const filter = (event: any) => {
        const text: string = event?.target?.value;
        if (customFilterFunction) {
            customFilterFunction(property, endpointFilters, text)
        } else {
            if (text?.length) {
                endpointFilters.addFilters(property, [
                    new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_STRING, FilterTerm.OPERATION_REGEX, text))
                ])
            } else {
                endpointFilters.removeFilters([property]);
            }
        }
    };

    const setValue = () => {
        if (customValueFunction) {
            return customValueFunction(endpointFilters, property)
        } else {
            const currentFilters = endpointFilters.getFilters().filter(f => f.key === property).flatMap(f => f.filters)

            return currentFilters && currentFilters.length && currentFilters[0] ?
                (currentFilters[0] as PropertyFilter).filterTerm.value : "";

        }
    }

    return (
        <div className={"tk-catalogue-item-filter"}>
            <div className="filter text-filter">
                <input type="text" value={setValue()}
                       className="text-filter default-input" onChange={filter} placeholder={placeholder}/>
                <FontAwesomeIcon icon={faSearch}/>
            </div>
        </div>
    );
};

