import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_GENERATE_FOLLOW_UP_CREDIT_NOTE = "generate-follow-up-credit-note";

export const generateFollowUpCreditNoteAction = (settlementId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_GENERATE_FOLLOW_UP_CREDIT_NOTE,
            callType: API_GENERATE_FOLLOW_UP_CREDIT_NOTE
        },
        payload: settlementId
    } as ApiCallAction<string>;
};
