import React from "react";
import {useTranslation} from "react-i18next";
import {connect, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import * as Yup from "yup";
import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import {API_SUBMIT_BID_AUCTIONEER, submitBidAuctioneerAction} from "../../../actions/submit-bid-auctioneer-action";
import {submitLimitBidAuctioneerAction} from "../../../actions/submit-limit-bid-auctioneer-action";
import {Bid} from "../../../../bids/model/bid";
import {selectAuctioneerLot, selectAuctioneerLotMinimumBid} from "../../../selectors";
import {AuctioneerLot} from "../../../../lots/model/AuctioneerLot";
import {RoomButton} from "../action-buttons/RoomButton";
import {BidFieldCustom} from "./BidFieldCustom";

interface DispatchProps {
    submitBid: typeof submitBidAuctioneerAction,
    submitLimitBid: typeof submitLimitBidAuctioneerAction
}

interface StateProps {
    minimumBid: number
    lot?: EntityWrapper<AuctioneerLot>
}

type Props = StateProps & DispatchProps;

const AuctioneerSubmitBidFormComponent = ({
                                              minimumBid,
                                              lot,
                                              submitBid,
                                              submitLimitBid
                                          }: Props) => {

    const {t} = useTranslation(["auctioneer", " bid", " liveAuction"]);

    const apiCallState = useSelector(selectCombinedApiState(API_SUBMIT_BID_AUCTIONEER));
    // const highestBid = useSelector(selectAuctioneerLotHighestBid);

    if (lot) {
        return (
            <div className={"auctioneer-view-bid-submit-form"}>
                <Formik
                    initialValues={{
                        bid: minimumBid ? minimumBid : 0,
                        customerId: "",
                        source: "",
                        tel: "",
                        comment: "",
                        isLimitBid: false
                    }}
                    validationSchema={Yup.object().shape({
                        bid: Yup.number()
                            .required(
                                t("core:forms.required-field", {fieldName: t("liveAuction:liveAuction.labels.submitBid")})
                            )
                    })}
                    onSubmit={(values, {setSubmitting, resetForm}) => {

                        if (values.isLimitBid) {
                            submitLimitBid(
                                values.bid,
                                values.customerId,
                                values.source,
                                values.tel,
                                values.comment,
                                lot ? lot.id : undefined
                            );
                        } else {
                            submitBid(
                                values.bid,
                                values.customerId,
                                values.source ? values.source : Bid.SOURCE_AUCTIONEER,
                                values.tel,
                                values.comment,
                                lot ? lot.id : undefined
                            );
                        }

                        resetForm();

                        setSubmitting(false);
                    }}
                >
                    {formikState => (
                        <Form>
                            {/*<div className="form-group is-limit-bid">*/}
                            {/*    <label htmlFor="isLimitBid">*/}
                            {/*        {t("bid:entity.labels.limit-bid")}*/}
                            {/*    </label>*/}
                            {/*    <ValidatedField formikState={formikState}*/}
                            {/*                    type="checkbox"*/}
                            {/*                    name="isLimitBid"*/}
                            {/*                    className="form-control default-input"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            <div className="form-group">
                                <ValidatedField formikState={formikState}
                                                component={BidFieldCustom}
                                                currentNeededBid={minimumBid}
                                                disableSnapping={true}
                                                name="bid"
                                                className="form-control default-input"/>
                            </div>
                            {/*<div className="form-group">*/}
                            {/*    <ValidatedField formikState={formikState}*/}
                            {/*                    type="text"*/}
                            {/*                    placeholder="Kundennummer"*/}
                            {/*                    name="customerId"*/}
                            {/*                    className="form-control default-input"/>*/}
                            {/*</div>*/}
                            {/*<div className="form-group">*/}
                            {/*    <ValidatedField formikState={formikState}*/}
                            {/*                    type="text"*/}
                            {/*                    component="textarea"*/}
                            {/*                    placeholder={t("labels.comment")}*/}
                            {/*                    name="comment"*/}
                            {/*                    className="form-control default-input"/>*/}
                            {/*</div>*/}

                            {/*<div className="form-group">*/}
                            {/*    <ValidatedField formikState={formikState}*/}
                            {/*                    component={BidSourceSelectField}*/}
                            {/*                    name="source"*/}
                            {/*                    className="form-control default-input"/>*/}
                            {/*</div>*/}


                            <button type={"submit"} className={"btn btn-primary"}
                                    disabled={apiCallState.inProgress || formikState.isSubmitting}>
                                {t("liveAuction:liveAuction.actions.submit-bid")}
                            </button>
                        </Form>
                    )}
                </Formik>
                <RoomButton/>
            </div>

        );
    } else {
        return <></>;
    }

};

export const AuctioneerSubmitBidForm = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        minimumBid: selectAuctioneerLotMinimumBid(state),
        lot: selectAuctioneerLot(state)
    }),
    {
        submitBid: submitBidAuctioneerAction,
        submitLimitBid: submitLimitBidAuctioneerAction
    }
)(AuctioneerSubmitBidFormComponent);
