import React from "react";
import {selectAuctioneerLotHighestBid} from "../../../selectors";
import {useTranslation} from "react-i18next";
import {AssignWinningBidButton} from "../action-buttons/AssignWinningBidButton";
import {CloseLotButton} from "../action-buttons/CloseLotButton";
import {Bid} from "../../../../bids/model/bid";
import {useSelector} from "react-redux";

export const AuctioneerHighestBid = () => {

    const bid = useSelector(selectAuctioneerLotHighestBid)

    const {t} = useTranslation(["auctioneer", "bid"]);
    const isOnlineBid = bid?.entity.source === Bid.SOURCE_ONLINE;
    return (
        <div className={"auctioneer-view-current-highest-bid".concat(isOnlineBid ? " source-online" : "")}>
            <div className={"highest-bid-value"}>
                {(bid?.entity.value ? bid.entity.value : 0).toFixed(0) + "€"}
            </div>
            <div className={"highest-bid-label"}>
                {t("labels.highest-bid")}
            </div>
            {bid ?
                <div className={"highest-bid-info"}>

                    {bid.entity.source === Bid.SOURCE_AUCTIONEER ? "Room" : bid.entity.customer.customerNumber ? <span className={"highest-bid-customer-id"}>
                        {t("Bieter")}: {bid.entity.customer.customerNumber}
                    </span> : bid.entity.customer ? <span className={"highest-bid-customer-id"}>
                        {t("Bieter")}: {bid.entity.customer.name}
                    </span> : ""}

                    {bid.entity.tel ? <span className={"highest-bid-tel"}>
                        Tel: {bid.entity.tel}
                    </span> : ""}

                    {bid.entity.limitedAt ? <span className={"highest-bid-limited-at"}>
                        {t("bid:entity.labels.limit-bid")}: {bid.entity.limitedAt.toFixed(0)} €
                    </span> : ""}

                    {bid.entity.source ?
                        <span className={"highest-bid-source"}>
                            {t("bid:entity.labels.source")}: {t("bid:source." + bid.entity.source)}
                        </span> : ""
                    }
                </div> : ""
            }
            {bid ? <>
                    <AssignWinningBidButton bid={bid}/>
                </>
                : <CloseLotButton/>}
        </div>
    )

};
