import React from "react";
import {Placement} from "react-bootstrap/Overlay";
import {Link} from "react-router-dom";
import {OverlayTooltip} from "@thekeytechnology/framework-react-ux";

interface TooltipLinkProps {
    to: string;
    tooltip: string;
    placement?: Placement;
    children: React.ReactNode
}

export const CustomTooltipLink = (props: TooltipLinkProps) => {
    return <OverlayTooltip placement={props.placement} tooltip={props.tooltip}>
        <Link to={props.to} target="_blank">
            {props.children}
            <div className="lg-and-above-hide">{props.tooltip}</div>
        </Link>
    </OverlayTooltip>;
};
