import {FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";

export const createRangeFilterForNumberInput = (property: string, inputValue: string, padding: number) => {

    const intVal = parseInt(inputValue);
    const strVal = intVal.toFixed(0);

    const filters: PropertyFilter[] = [];
    filters.push(new PropertyFilter(property, new FilterTerm(
        FilterTerm.TYPE_INT,
        FilterTerm.OPERATION_EQ,
        parseInt(inputValue)
    )))

    if (strVal.length < padding) {
        const min = parseInt(strVal.padEnd(padding, "0"));
        const diff = parseInt("1".padEnd(padding - (strVal.length -1), "0")) - 1;
        const max = min + diff;
        filters.push(new PropertyFilter(property, new FilterTerm(
            FilterTerm.TYPE_INT_LIST,
            FilterTerm.OPERATION_RANGE,
            [
                min,
                max
            ]
        )))
    }


    return filters;
}