import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_MANUALLY_ASSIGN_ITEMS = "manually-assign-items";

export interface ManuallyAssignItemsPayload {
    auctionId: string;
    itemIds: string[];
}

export const manuallyAssignItemsAction = (auctionId: string, itemIds: string[]) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_MANUALLY_ASSIGN_ITEMS,
            callType: API_MANUALLY_ASSIGN_ITEMS
        },
        payload: {
            auctionId,
            itemIds
        }
    } as ApiCallAction<ManuallyAssignItemsPayload>;
};
