import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import {selectCombinedApiState} from "@thekeytechnology/framework-react";
import {API_GENERATE_SELLER_LISTS, generateSellerListsAction} from "../../actions/seller-lists/generate-seller-lists";

export const GenerateSellerListsButton = () => {

    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]
    const apiCallState = useSelector(selectCombinedApiState(API_GENERATE_SELLER_LISTS))

    // const {t} = useTranslation("auctionPlanning");

    return auctionId ? <button type="button" disabled={apiCallState.inProgress} onClick={() => {
        dispatch(generateSellerListsAction(auctionId));

    }} className="btn btn-secondary mr-3">
        Listen neu generieren
    </button> : null;
}
