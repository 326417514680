import React from "react";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {updateCategoryAction} from "../../actions/inline-update/update-category";
import {ItemCategoryDisplay} from "../../../item-category/display/ItemCategoryDisplay";
import CategorySelectField from "../../../item-category/components/edit/CategorySelectField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {generateString} from "@thekeytechnology/framework-react";
import {Form, Formik} from "formik";
import {WriteToItemCheckbox} from "./WriteToItemCheckbox";
import {CategoryJustIdNameAndColor} from "../../../item-category/model/CategoryJustIdNameAndColor";
import * as Yup from "yup";

interface OwnProps {
    lotId: string;
    editingEnabled: boolean;
    writeToItemEnabled: boolean;
    currentCategory?: CategoryJustIdNameAndColor
}

interface DispatchProps {
    updateCategory: typeof updateCategoryAction;
}

type Props = OwnProps & WithTranslation & DispatchProps;

const InlineCategoryEditorComponent = (props: Props) => {
    const {currentCategory, writeToItemEnabled, lotId, editingEnabled, updateCategory, t} = props;

    const popover = <Popover id={generateString(10)}>
        <Popover.Title as="h3" className="d-flex align-items-center">
            {t("item:entity.labels.category")}
            <button
                onClick={() => {
                    document.body.click()
                }}
                className="ml-auto btn btn-link p-0">
                <FontAwesomeIcon icon={faTimes}/>
            </button>
        </Popover.Title>
        <Popover.Content>
            <Formik
                initialValues={{
                    category: currentCategory,
                    writeToItem: writeToItemEnabled,
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    category: Yup.object().nullable(true).required(t("core:forms.required-field", {fieldName: t("item:entity.labels.category")}))
                })}
                onSubmit={(values, {setSubmitting}) => {
                    updateCategory(lotId,
                        writeToItemEnabled && values.writeToItem,
                        values.category!.id
                    );
                    document.body.click()
                    setSubmitting(false);
                }}
            >
                {formikState => (
                    <Form className="d-flex flex-column">
                        <ValidatedField formikState={formikState}
                                        component={CategorySelectField}
                                        name="category"
                                        className="form-control default-input"
                        />
                        {writeToItemEnabled ?
                            <WriteToItemCheckbox/>
                            : null}
                        <button disabled={formikState.isSubmitting}
                                className="btn btn-success w-100 mt-3"
                                type="submit">
                            {t("inline-editor.save")}
                        </button>
                    </Form>
                )}
            </Formik>
        </Popover.Content>
    </Popover>;

    return <div className="d-flex align-items-center">
        <ItemCategoryDisplay displayItem={currentCategory}/>

        {editingEnabled ?
            <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={popover}>
                <button className="btn btn-link ml-1">
                    <FontAwesomeIcon icon={faPencilAlt}/>
                </button>
            </OverlayTrigger> : null}
    </div>
};

export const InlineCategoryEditor = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        updateCategory: updateCategoryAction
    }
)(
    withTranslation(["auctionPlanning", "item"])(InlineCategoryEditorComponent)
);
