import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {EmailOverview} from "./overview/EmailOverview";
import {EditEmail} from "./overview/single/EditEmail";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";

export const EmailModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/emails"
                          component={EmailOverview}/>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/emails/:emailId/edit"
                          component={EditEmail}/>
        </>
    );
};
