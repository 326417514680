import React from "react"
import {useDispatch, useSelector} from "react-redux";
import {useRouteMatch} from "react-router";
import {selectCombinedApiState, SelectionModel} from "@thekeytechnology/framework-react";
import {API_GENERATE_SELLER_LISTS} from "../../actions/seller-lists/generate-seller-lists";
import {sendBulkSellerListEmailAction} from "../../actions/seller-lists/send-bulk-seller-list-email";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface OwnProps {
    selectionModel: SelectionModel<string>;
}

type Props = OwnProps;
export const SendBulkSellerListsButton = ({
                                              selectionModel
                                          }: Props) => {

    const dispatch = useDispatch();
    const {params} = useRouteMatch<any>();
    const auctionId = params["auctionId"]
    const apiCallState = useSelector(selectCombinedApiState(API_GENERATE_SELLER_LISTS))

    return auctionId ? <button type="button" disabled={apiCallState.inProgress} onClick={() => {
        dispatch(sendBulkSellerListEmailAction(selectionModel.selections, auctionId));

    }} className="btn btn-secondary mr-3">
        <FontAwesomeIcon icon={faMailBulk} className={"mr-2"}/>
        E-Mails versenden
    </button> : null;
}
