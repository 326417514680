import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {EntitySelectFilter, Filters, TextFilter} from "@thekeytechnology/framework-react-ux";
import {Lot} from "../../model/Lot";
import {ItemCategory} from "../../../item-category/model/itemCategory";

const LotFiltersComponent = (props: WithTranslation) => {
    const {
        t
    } = props;
    return (
        <div className={"tk-items-filter"}>
            <Filters
                entityType={Lot.TYPE}
                filterKeys={["entity.inventoryNumber", "entity.categoryRef", "entity.status", "entity.description"]}>
                <TextFilter
                    placeholder={t("core:filters.search")}
                    entityType={Lot.TYPE}
                    property="entity.description"/>
                <EntitySelectFilter
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.category")
                    })}
                    fetchedEntityType={ItemCategory.TYPE}
                    listRenderer={(item: EntityWrapper<ItemCategory>) => item.entity.name[props.i18n.language]}
                    filteredEntityType={Lot.TYPE}
                    property="entity.categoryRef"
                />
            </Filters>
        </div>
    );
};

export const LotFilters = withTranslation("lot")(LotFiltersComponent);
