import * as React from "react";

interface OwnProps {
    label: string;
    isCollapsed: boolean;
}

export const CollapsibleHeading = ({
                                    label,
                                    isCollapsed
                                }: OwnProps) => {

    return isCollapsed ? <h4>&nbsp;</h4> : <h4>{label}</h4>
};
