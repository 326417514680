import {
    ActionTableColumn,
    AsyncEntitySelect,
    Table,
    TableBody,
    TableColumn,
    TableHeader,
    TableRow
} from "@thekeytechnology/framework-react-ux";
import React from "react";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {WinningBidPosition} from "../../../model/position/WinningBidPosition";
import {BidInOrder, ENTITY_CONTEXT_BID_IN_ORDER, ENTITY_TYPE_BID_IN_ORDER} from "../../../model/BidInOrder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {CurrencyDisplay, extractLotTitle, renderCurrency} from "@thekeytechnology/auktionshaus-frontend-library";

interface OwnProps {
    items: Array<WinningBidPosition>
    selectedBids: Set<string>

    handleAdd: (item: WinningBidPosition) => void;
    handleRemove: (item: WinningBidPosition) => void;
}

export const WinningBidsForSelectionTable = ({items, handleAdd, handleRemove, selectedBids}: OwnProps) => {

    return <Table itemCount={items.length} className="winning-bid-selection-table">
        <TableHeader>
            <TableColumn>Los</TableColumn>
            <TableColumn>Kosten</TableColumn>
            <TableColumn>Aktion</TableColumn>
        </TableHeader>
        <TableBody>
            {items.map(item => {
                return <TableRow key={item.bidRef}>
                    <TableColumn>
                        <AsyncEntitySelect<BidInOrder>
                            shownEntityType={ENTITY_TYPE_BID_IN_ORDER}
                            shownEntityTypeContext={ENTITY_CONTEXT_BID_IN_ORDER}
                            shownEntityTypeProperties={[
                                "entity.customer.entity.firstName",
                                "entity.customer.entity.lastName",
                                "entity.lot.entity.description.de"
                            ]}
                            disabled={true}
                            listRenderer={(value: EntityWrapper<BidInOrder>) => {
                                return value.entity ? `${value?.entity?.customerName} - ${renderCurrency(value?.entity?.value)} - ${extractLotTitle(value?.entity?.lotDescription.de)}` : "";
                            }}
                            select={newBid => {
                            }}
                            selected={item.bidRef ? {
                                id: item.bidRef
                            } as EntityWrapper<BidInOrder> : undefined}
                            isMulti={false}
                            isClearable={false}
                            placeholder={""}
                        />
                    </TableColumn>
                    <TableColumn>
                        <CurrencyDisplay amount={item.amount}/>
                    </TableColumn>
                    <ActionTableColumn isDisabled={false}>
                        {selectedBids.has(item.bidRef as string) ?
                            <button className="btn btn-danger" type="button" onClick={() => {
                                handleRemove(item)
                            }}><FontAwesomeIcon icon={faMinus}/></button>
                            : <button className="btn btn-success" type="button" onClick={() => {
                                handleAdd(item)
                            }}><FontAwesomeIcon icon={faPlus}/></button>}
                    </ActionTableColumn>
                </TableRow>

            })}
        </TableBody>
    </Table>
};
