import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_RESET_AUCTION_SPECIFIC_NUMBERS = "reset-auction-specific-numbers";

export const resetAuctionSpecificNumbersAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_RESET_AUCTION_SPECIFIC_NUMBERS,
            callType: API_RESET_AUCTION_SPECIFIC_NUMBERS
        },
        payload: null
    } as ApiCallAction<null>;
};
