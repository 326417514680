import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {SelectionModel} from "@thekeytechnology/framework-react";
import {groupLotsAction} from "../../actions/group/group-lots";
import {connect} from "react-redux";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectGroup} from "@fortawesome/free-solid-svg-icons";

interface OwnProps {
    selectionModel: SelectionModel<string>
}

interface DispatchProps {
    groupLots: typeof groupLotsAction
}

type Props = OwnProps & DispatchProps & WithTranslation;

const GroupEntitiesButtonComponent = (props: Props) => {
    const {selectionModel, t, groupLots} = props;

    return <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip id={`tooltip-remove`}>
                {t("group-lots-button.submit")}
            </Tooltip>
        }
    >
        <button className="btn btn-secondary mr-2"
                onClick={() => {
                    groupLots(selectionModel.selections);
                    selectionModel.handleClearAll();
                }}
                disabled={selectionModel.selectedCount < 2}>
            <FontAwesomeIcon icon={faObjectGroup}/>
        </button>
    </OverlayTrigger>
};

export const GroupLotsButton = connect<{}, DispatchProps, OwnProps>(
    null,
    {
        groupLots: groupLotsAction
    }
)(withTranslation("auctionPlanning")(GroupEntitiesButtonComponent));
