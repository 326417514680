import React from "react";
import {connect, useDispatch} from "react-redux";
import {WithTranslation, withTranslation} from "react-i18next";
import {setCurrentLotAction} from "../../../actions/set-current-lot-action";
import {selectAuctioneerLotId} from "../../../selectors";
import {API_FETCH_SINGLE_ENTITY, apiCallSucceededAction} from "@thekeytechnology/framework-react";
import {AuctioneerLot} from "../../../../lots/model/AuctioneerLot";
import {push} from "connected-react-router";

interface StateProps {
    lotId: string | undefined
}

interface DispatchProps {
    setCurrentLot: typeof setCurrentLotAction
}

type Props = StateProps & DispatchProps & WithTranslation;

const SetCurrentLotButtonComponent = ({
                                          t,
                                          setCurrentLot,
                                          lotId
                                      }: Props) => {

    const dispatch = useDispatch();

    return <button className={"btn btn-primary set-current-lot-button"}
                   onClick={() => {
                       setCurrentLot(lotId as string);
                       dispatch(push("/auktionator/"));
                       dispatch(apiCallSucceededAction({
                           apiType: API_FETCH_SINGLE_ENTITY,
                           callType: AuctioneerLot.TYPE
                       }, {
                           entityType: AuctioneerLot.TYPE,
                           entity: undefined
                       }))
                   }}
    >
        {t("actions.set-current-lot")}
    </button>
};

export const SetCurrentLotButton = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        lotId: selectAuctioneerLotId(state),
    }),
    {
        setCurrentLot: setCurrentLotAction,
    }
)
(withTranslation(["auctioneer", "core"])(SetCurrentLotButtonComponent));
