import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_REFRESH_INVOICE = "refresh-invoice";

export interface SendReceiptPayload {
    orderId: string,
    receiptId: string
}

export const refreshInvoiceAction = (orderId: string, receiptId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_REFRESH_INVOICE,
            callType: API_REFRESH_INVOICE
        },
        payload: {
            orderId,
            receiptId
        }
    } as ApiCallAction<SendReceiptPayload>;
};
