import React from "react";
import {connect} from "react-redux";
import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AuctioneerBid} from "../../../../../bids/model/auctioneerBid";
import {selectAuctioneerBids, selectAuctioneerLotHighestBid} from "../../../../selectors";
import {useTranslation} from "react-i18next";
import {Bid} from "../../../../../bids/model/bid";
import moment from "moment";

interface StateProps {
    bids: Array<EntityWrapper<AuctioneerBid>>,
    highestBid?: EntityWrapper<AuctioneerBid>
}

type Props = StateProps;

const AuctioneerLimitBidTableComponent = ({
                                              bids,
                                              highestBid
                                          }: Props) => {

    const {t} = useTranslation(["bid"]);

    const winningBidId = bids.filter(bid => bid.entity.status === Bid.STATUS_WINNING)?.map(bid => bid.id)[0];
    const highlightedBidId = winningBidId ? winningBidId : highestBid ? highestBid.id : "";

    return <div className={"auctioneer-view-bids-table h-100"}>
        <div className={"auctioneer-table h-100"}>
            <div className={"auctioneer-table-row header-row"}>
                <div className={"bid-customer"}>
                    Bieter
                </div>
                <div className={"bid-time"}>
                    Abgegeben
                </div>
                <div className={"bid-value"}>
                    Gebotshöhe
                </div>
                <div className={"tel"}>
                    Telefonnummer
                </div>
            </div>
            {bids.filter(bid => bid.entity.limitedAt).map(
                bid => {
                    const currentBidClass = highlightedBidId === bid.id ? " highest-bid " : "";
                    const bidCap = bid.entity.limitedAt ? "(max. " + bid.entity.limitedAt + " €)" : "";
                    return (
                        <div key={bid.id} className={"auctioneer-table-row" + currentBidClass}>
                            <div className={"bid-customer"}>
                                {bid.entity.customer.customerNumber !== 0 ?
                                    bid.entity.customer.customerNumber :
                                    bid.entity.customer.name !== "" ?
                                        bid.entity.customer.name :
                                        bid.entity.manualCustomerId ?
                                            bid.entity.manualCustomerId :
                                            bid.entity.source ?
                                                t("source." + bid.entity.source)
                                                : ""
                                }
                            </div>
                            <div className={"bid-time"}>
                                {moment(bid.entity.timestamp).format("HH:mm:ss")} Uhr
                            </div>
                            <div className={"bid-value"}>
                                {bid.entity.value} € <span className={"bid-cap"}>{bidCap}</span>
                            </div>
                            <div className={"tel"}>
                                {bid.entity.tel}
                            </div>
                        </div>
                    )
                }
            )}
        </div>
    </div>
};

export const AuctioneerLimitBidTable = connect<StateProps, {}, {}>(
    (state: any) => ({
        bids: selectAuctioneerBids(state),
        highestBid: selectAuctioneerLotHighestBid(state)
    }),
    {}
)
(AuctioneerLimitBidTableComponent);
