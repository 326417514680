import {DownloadCsvData} from "../model/DownloadCsvData";

export const triggerCsvResponseDownload = (payload: DownloadCsvData, defaultFileName: string = "") => {
    const element = document.createElement("a");
    const file = new Blob([payload.data], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = payload.filename ? payload.filename : defaultFileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
}
