import React from "react";
import {useTranslation} from "react-i18next";
import {ComplexFilter} from "@thekeytechnology/framework-react";
import {PaginatedEndpointCollapsibleFilters} from "../../../../paginated-endpoints/components/PaginatedEndpointCollapsibleFilters";
import {PaginatedEndPointTextFilter} from "../../../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import {PaginatedEndPointCheckboxFilter} from "../../../../paginated-endpoints/components/PaginatedEndPointCheckboxFilter";
import {PaginatedEndpointFiltersReturnProps} from "../../../../paginated-endpoints/hooks/use-paginated-endpoint-filters";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = OwnProps;

export const UserFilters = ({
                                         endpointFilters
                                     }: Props) => {

    const {t} = useTranslation(["users"])

    return (
        <div className={"mt-4 mb-3 assigned-lots-filters"}>
            <PaginatedEndpointCollapsibleFilters
                endpointFilters={endpointFilters}
                filterKeys={["entity.name", `entity.email`, "only-admin"]}
            >

                <PaginatedEndPointTextFilter
                    property={`entity.name`}
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.user.labels.name")
                    })}
                />

                <PaginatedEndPointTextFilter
                    property={`entity.email`}
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.user.labels.email")
                    })}
                />

                <PaginatedEndPointCheckboxFilter
                    property={"only-admin"}
                    endpointFilters={endpointFilters}
                    label={"Admin"}
                    customFilterFunction={(
                        property: string,
                        endpointFilters: PaginatedEndpointFiltersReturnProps,
                        value: boolean
                    ) => {
                        if (value) {
                           endpointFilters.addFilters(property, [new ComplexFilter(property, {})]);
                        } else {
                            endpointFilters.removeFilters([property])
                        }
                    }}
                />
            </PaginatedEndpointCollapsibleFilters>
        </div>
    );
};
