import {EntityWrapper, TkFile} from "@thekeytechnology/framework-react";
import {Auction} from "../../auction/model/auction";
import {CategoryJustIdNameAndColor} from "../../item-category/model/CategoryJustIdNameAndColor";
import {CustomerJustIdAndName} from "@thekeytechnology/auktionshaus-frontend-library";
import {LotForItemEditor} from "../../lots/model/LotForItemEditor";

export class Item {
    public static TYPE = "item";

    public static STATUS_CREATED = "created";
    public static STATUS_NOT_CHECKED = "not-checked";
    public static STATUS_CHECKED = "checked";
    public static STATUS_READY_FOR_SALE = "ready-for-sale";
    public static STATUS_BACK_TO_SELLER = "back-to-seller";
    public static STATUS_SOLD = "sold";

    public static new = () => new EntityWrapper<Item>(
        undefined,
        new Item(
            0,
            {},
            "I",
            undefined,
            0,
            undefined,
            "",
            [],
            [],
            false,
            Item.STATUS_CREATED,
            "",
            undefined,
            0,
            false,
            []
        )
    );

    constructor(
        public inventoryNumber: number,
        public description: { [lang: string]: string },
        public condition: string,
        public category: CategoryJustIdNameAndColor | undefined,
        public limitPrice: number,
        public seller: EntityWrapper<CustomerJustIdAndName> | undefined,
        public tags: string,
        public publicAttachments: Array<EntityWrapper<TkFile>>,
        public privateAttachments: Array<EntityWrapper<TkFile>>,
        public suitability: boolean,
        public status: string,
        public checkinDate: string,
        public plannedAuction: EntityWrapper<Auction> | undefined,
        public transferCount: number,
        public p86: boolean,
        public lots: EntityWrapper<LotForItemEditor>[]
    ) {
    }
}
