import {
    WithEntityList,
    WithEntityListProps,
    WithSelections,
    WithSelectionsProps,
    WithSwitchableLanguage,
    WithSwitchableLanguageProps
} from "@thekeytechnology/framework-react";
import React from "react";
import "./item-category-table.scss";
import {
    AboveTableContainer,
    ActionTableColumn,
    AsyncEntityTableBody,
    EntityCreateAndDeleteButtons,
    EntityTableHeader,
    EntityTableRow,
    PaginationInfo,
    Table,
    TableColumn
} from "@thekeytechnology/framework-react-ux";
import {faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import {withTranslation, WithTranslation} from "react-i18next";
import {ItemCategory} from "../model/itemCategory";
import {ItemCategoryColorBubble} from "../display/ItemCategoryColorBubble";
import {CustomActionLink} from "../../core/components/CustomActionLink/CustomActionLink";

type Props =
    WithEntityListProps<ItemCategory>
    & WithSelectionsProps<string>
    & WithTranslation
    & WithSwitchableLanguageProps;

const ItemCategoryTableComponent = (props: Props) => {
    const {
        entities,
        entityApiState,
        selectionModel,
        t,
        currentLanguage
    } = props;

    return (
        <>
            <AboveTableContainer>
                <PaginationInfo entityType={ItemCategory.TYPE} className="mr-auto"/>

                <EntityCreateAndDeleteButtons
                    selectedEntities={selectionModel.selections}
                    entityType={ItemCategory.TYPE}
                    createPath="/item-categories/new/edit"
                    label={t("entity.singular")}
                    labelPlural={t("entity.plural")}
                />
            </AboveTableContainer>
            <Table className="item-category-table" itemCount={entities.length}>
                <EntityTableHeader selectionModel={selectionModel} entities={entities}>
                    <TableColumn>{t("entity.labels.name")}</TableColumn>
                    <TableColumn>{t("entity.labels.catalogue-part")}</TableColumn>
                    <TableColumn>{t("entity.labels.order-index")}</TableColumn>
                    <TableColumn> {t("core:actions.actions")}</TableColumn>
                </EntityTableHeader>

                <AsyncEntityTableBody apiCallState={entityApiState} items={entities} renderItem={itemCategory => {
                    return (
                        <EntityTableRow key={itemCategory.id} entity={itemCategory} selectionModel={selectionModel}>
                            <TableColumn className="bold title-column">
                                <ItemCategoryColorBubble color={itemCategory.entity.color}/>
                                {itemCategory.entity.name[currentLanguage]}
                            </TableColumn>
                            <TableColumn>
                                {t(`catalog-part-options.${itemCategory.entity.catalogPart}`)}
                            </TableColumn>
                            <TableColumn>
                                {itemCategory.entity.orderIndex}
                            </TableColumn>
                            <ActionTableColumn isDisabled={false}>
                                <>
                                    <CustomActionLink to={"/item-categories/" + itemCategory.id + "/edit"}
                                                tooltip={t("core:actions.edit")}
                                                icon={faPencilAlt}/>
                                </>
                            </ActionTableColumn>
                        </EntityTableRow>
                    );
                }}/>
            </Table>
        </>
    );
};

export const ItemCategoryTable = WithSelections<{}, string>(
    WithEntityList<WithSelectionsProps<string>, ItemCategory>(
        WithSwitchableLanguage<any>(withTranslation(["itemCategory", "core"])(ItemCategoryTableComponent)),
        ItemCategory.TYPE) as any
);
