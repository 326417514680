import React from "react";
import {useTranslation} from "react-i18next";
import {EntityWrapper, FilterTerm, PropertyFilter} from "@thekeytechnology/framework-react";
import {CustomerSelectFilter} from "../../auction-planning/components/filters/CustomerSelectFilter";
import {PaginatedEndPointTextFilter} from "../../paginated-endpoints/components/PaginatedEndPointTextFilter";
import {PaginatedEndpointFiltersReturnProps} from "../../paginated-endpoints/hooks/use-paginated-endpoint-filters";
import {PaginatedEndpointSimpleSelectFilter} from "../../paginated-endpoints/components/PaginatedEndpointSimpleSelectFilter";
import {PaginatedEndpointCollapsibleFilters} from "../../paginated-endpoints/components/PaginatedEndpointCollapsibleFilters";
import {PaginatedEndPointAsyncEntitySelectFilter} from "../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelectFilter";
import {Auction, ENTITY_TYPE_AUCTION} from "../../auction/model/auction";
import {CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY} from "../../paginated-endpoints/components/PaginatedEndPointAsyncEntitySelect";
import {createRangeFilterForNumberInput} from "../../core/utils/create-range-filter-for-number-input";
import {RECEIPT_STATUS_OPEN, RECEIPT_STATUS_PAIDOFF, RECEIPT_STATUS_VOIDED} from "../../orders/model/Order";
import {SETTLEMENT_STATUS_CANCELLED, SETTLEMENT_STATUS_INVOICED, SETTLEMENT_STATUS_OPEN} from "../model/settlement";

interface OwnProps {
    endpointFilters: PaginatedEndpointFiltersReturnProps
}

type Props = OwnProps

export const SettlementFiltersNew = ({
                                 endpointFilters
                             }: Props) => {

    const {t} = useTranslation(["settlement", "order", "core", "item"]);

    return (
        <div className={"tk-itemCategory-filter"}>
            <PaginatedEndpointCollapsibleFilters
                endpointFilters={endpointFilters}
                filterKeys={[
                    "entity.settlementNumber",
                    "entity.customerRef",
                    "entity.attachedReceipts.status",
                    "entity.status",
                    "entity.attachedReceipts.receiptName",
                    "entity.auctionRef"
                ]}
            >
                <PaginatedEndPointTextFilter
                    property={"entity.settlementNumber"}
                    endpointFilters={endpointFilters}
                    placeholder={t("core:filters.filter-by", {
                        label: t("entity.labels.settlement-number")
                    })}
                    customFilterFunction={(
                        property,
                        endpointFilters,
                        text
                    ) => {
                        if (text?.length) {
                            const intVal = parseInt(text);
                            if (intVal) {
                                endpointFilters.addFilters(property, [
                                    new PropertyFilter(property, new FilterTerm(FilterTerm.TYPE_INT, FilterTerm.OPERATION_EQ, parseInt(text)))
                                ])
                            }
                        } else {
                            endpointFilters.removeFilters([property]);
                        }
                    }}
                />

                <PaginatedEndPointTextFilter
                    property={"entity.attachedReceipts.receiptName"}
                    placeholder={t("entity.labels.invoice-number")}
                    endpointFilters={endpointFilters}
                />

                <CustomerSelectFilter
                    endpointFilters={endpointFilters}
                    property={"entity.customerRef"}
                    placeholder={t("entity.labels.customer")}/>

                <PaginatedEndpointSimpleSelectFilter
                    property={"entity.attachedReceipts.status"}
                    placeholder={t("entity.labels.invoice-status")}
                    endpointFilters={endpointFilters}
                    options={[RECEIPT_STATUS_OPEN, RECEIPT_STATUS_PAIDOFF, RECEIPT_STATUS_VOIDED]
                        .map(status => ({
                            label: t("receipt-list.receipt-status." + status),
                            value: status
                        }))}
                />

                <PaginatedEndpointSimpleSelectFilter
                    property={"entity.status"}
                    placeholder={t("entity.labels.status")}
                    endpointFilters={endpointFilters}
                    options={[SETTLEMENT_STATUS_OPEN, SETTLEMENT_STATUS_INVOICED, SETTLEMENT_STATUS_CANCELLED]
                        .map(status => ({
                            label: t("status." + status),
                            value: status
                        }))}
                />
                <PaginatedEndPointAsyncEntitySelectFilter<Auction>
                    property={"entity.auctionRef"}
                    entityType={ENTITY_TYPE_AUCTION}
                    shownEntityProperties={["entity.auctionNumber"]}
                    listRenderer={(item: EntityWrapper<Auction>) => item.entity.auctionNumber.toString()}
                    placeholder={t("core:filters.filter-by", {
                        label: t("item:entity.labels.auction")
                    })}
                    endpointFilters={endpointFilters}
                    fetchFunctionFactory={CUSTOM_ENDPOINT_FETCH_FUNCTION_FACTORY("/auctions/get-auctions")}
                    customShownEntityTypePropertyFilterCallback={(property: string, inputValue: string) => {
                        if (inputValue) {
                            return createRangeFilterForNumberInput(property, inputValue, 3)
                        } else {
                            return []
                        }
                    }}
                />

                <PaginatedEndPointTextFilter
                    property={"entity.positions.lotRef"}
                    placeholder={t("Los-ID")}
                    endpointFilters={endpointFilters}
                />
            </PaginatedEndpointCollapsibleFilters>
        </div>
    );
};

