import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_SUBMIT_BID_AUCTIONEER = "submit-bid-auctioneer";

export interface SubmitBidAuctioneerPayload extends Action {
    value: number;
    customerId: string;
    source: string;
    tel: string;
    comment: string;
    lotId?: string
}

export const submitBidAuctioneerAction = (
    value: number,
    customerId: string,
    source: string,
    tel: string,
    comment: string,
    lotId?: string
) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SUBMIT_BID_AUCTIONEER,
            callType: API_SUBMIT_BID_AUCTIONEER
        },
        payload: {
            value,
            customerId,
            source,
            tel,
            comment,
            lotId
        }
    } as ApiCallAction<SubmitBidAuctioneerPayload>;
};
