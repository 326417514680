import {Action} from "redux";
import {
    API_CREATE_ENTITIES,
    API_UPDATE_ENTITIES,
    ApiCallAction,
    apiPost,
    fetchSingleEntityAction,
    matchesAnyOfTheseApiSuccesses
} from "@thekeytechnology/framework-react";
import {push} from "connected-react-router";
import {map} from "rxjs/operators";
import {combineEpics, ofType} from "redux-observable";
import {Observable} from "rxjs";
import {Lot} from "../model/Lot";
import {API_START_LOT_IMPORT, StartLotImportActionPayload} from "../actions/start-lot-import-action";
import {API_SUBMIT_BULK_LIMIT_BIDS, SubmitBulkLimitBidsActionPayload} from "../actions/submit-bulk-limit-bids-action";

const reloadOnCatalogueItemSave$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_CREATE_ENTITIES, callType: Lot.TYPE
        }, {
            apiType: API_UPDATE_ENTITIES, callType: Lot.TYPE
        }),
        map((action: any) => ({
            type: "lotUpdated",
            payload: action.payload.ids[0]
        }))
    );

const updatePathOnCatalogueItemSave$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType("lotUpdated"),
        map((action: any) => push(`/lose/${action.payload}/edit`))
    );

const fetchUpdatedEntityOnCatalogueItemSave$ = (action$: Observable<Action>) =>
    action$.pipe(
        ofType("lotUpdated"),
        map((action: any) => fetchSingleEntityAction(Lot.TYPE)(action.payload))
    );

export const startLotImport$ = apiPost(
    {apiType: API_START_LOT_IMPORT},
    "/lot-importer/start",
    (action: ApiCallAction<StartLotImportActionPayload>) => {
        return {
            lotFileKey: action.payload.lotFileKey,
            imageZipKey: action.payload.imageZipKey,
            auctionId: action.payload.auctionId
        };
    }
);

export const lotImportFinished$ = (action$: Observable<Action>) =>
    action$.pipe(
        matchesAnyOfTheseApiSuccesses({
            apiType: API_START_LOT_IMPORT
        }),
        map(() => alert("Import abgeschlossen."))
    );

export const submitBulkLimitBids$ = apiPost<SubmitBulkLimitBidsActionPayload, any>(
    {apiType: API_SUBMIT_BULK_LIMIT_BIDS},
    "/limit-bid/submit-bulk-limit-bids",
    (action: ApiCallAction<SubmitBulkLimitBidsActionPayload>) => {
        return {
            customerId: action.payload.customerId,
            value: action.payload.value,
            lotIds: action.payload.lotIds
        };
    }
);

export const catalogueModuleEpics$ = combineEpics(
    reloadOnCatalogueItemSave$,
    updatePathOnCatalogueItemSave$,
    fetchUpdatedEntityOnCatalogueItemSave$,
    lotImportFinished$,
    submitBulkLimitBids$,
    startLotImport$
);
