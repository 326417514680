import {combineEpics} from "redux-observable";
import {
    apiModuleEpics$,
    authModuleEpics$,
    dialogModuleEpics$,
    entityModuleEpics$,
    filesModuleEpics$,
    i18nModuleEpics$,
    integrationsModuleEpics$,
    settingsModuleEpics$,
    userModuleEpics$
} from "@thekeytechnology/framework-react";
import {catalogueModuleEpics$} from "./lots/epics/lot-epics";
import {itemsModuleModuleEpics$} from "./items/epics";
import {itemsCategoryModuleEpics$} from "./item-category/epics";
import {auctionModuleEpics$} from "./auction/epics/auctions";
import {customerModuleEpics$} from "./customers/epics";
import {auctionPlanningModuleEpics$} from "./auction-planning/epics/auction-planning";
import {orderModuleEpics$} from "./orders/epics/orders";
import {auctioneerModuleEpics$} from "./auctioneer-view/epics";
import {extendedAuthModuleEpics$} from "./auth/epics";
import {settlementModuleEpics$} from "./settlements/epics/settlements";
import {emailModuleEpic$} from "./emails/epics/email";
import {additionalUserEpics$} from "./users/epics/additionalUserEpics";
import {warningModuleEpics$} from "./warnings/epics";
import {logModuleEpics$} from "./log/epics";
import {auctionAppSettingsModuleEpics$} from "./auction-app-settings/epics";

export const appEpics = combineEpics(
    entityModuleEpics$,
    dialogModuleEpics$,
    filesModuleEpics$,
    apiModuleEpics$,
    i18nModuleEpics$,
    settingsModuleEpics$,
    integrationsModuleEpics$,
    authModuleEpics$,
    extendedAuthModuleEpics$,
    catalogueModuleEpics$,
    itemsModuleModuleEpics$,
    itemsCategoryModuleEpics$,
    customerModuleEpics$,
    auctionModuleEpics$,
    auctionPlanningModuleEpics$,
    orderModuleEpics$,
    auctioneerModuleEpics$,
    settlementModuleEpics$,
    emailModuleEpic$,
    additionalUserEpics$,
    userModuleEpics$,
    warningModuleEpics$,
    logModuleEpics$,
    auctionAppSettingsModuleEpics$
);
