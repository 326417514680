import {EntityWrapper, selectCombinedApiState} from "@thekeytechnology/framework-react";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {API_DOWNLOAD_ORDER_PREVIEW_PDF, downloadOrderPreviewPdfAction} from "../actions/download-order-preview-pdf";
import {Order} from "../model/Order";

interface OwnProps {
    order: EntityWrapper<Order>
    disabled?: boolean;
}

export const DownloadOrderPreviewButton = ({order, disabled}: OwnProps) => {
    const dispatch = useDispatch();
    const apiCallState = useSelector(selectCombinedApiState(API_DOWNLOAD_ORDER_PREVIEW_PDF))

    return <button disabled={apiCallState.inProgress || disabled}
                   onClick={() => dispatch(downloadOrderPreviewPdfAction(order.id!))}
                   className="btn btn-info mr-5" type="button">
        Vorschau (PDF)
    </button>;

}
