import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {Form, Formik} from "formik";
import {ValidatedField} from "@thekeytechnology/framework-react-ux";
import {pauseAuctionAction} from "../../../actions/pause-live-auction-action";

interface OwnProps {
    handleClose: () => void;
}

interface DispatchProps {
    pauseAuction: typeof pauseAuctionAction;
}

type Props = OwnProps & DispatchProps;

const PauseAuctionFormComponent = ({
                                       pauseAuction,
                                       handleClose
                                   }: Props) => {

    const {t} = useTranslation(["auctioneer", "core", "bid"]);

    return <>
        <Formik
            initialValues={{
                message: "",
            }}
            onSubmit={(values, {setSubmitting}) => {
                pauseAuction(values.message);
                setSubmitting(false);
            }}
        >
            {formikState => (
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Live-Auktion pausieren</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <div className="form-group">
                            {/*<label htmlFor="customerId" className="col-sm-10 col-form-label">*/}
                            {/*    {t("labels.message")}*/}
                            {/*</label>*/}
                            <ValidatedField formikState={formikState}
                                            component="textarea"
                                            placeholder={t("labels.message")}
                                            name="message"
                                            className="form-control default-input"/>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        {/*<Button className="btn btn-secondary"*/}
                        {/*        onClick={handleClose}>*/}
                        {/*    {t("core:save-and-back-buttons.cancel")}*/}
                        {/*</Button>*/}
                        <Button type={"submit"} className={"btn btn-primary"}>
                            {t("actions.pause-auction")}
                        </Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </>;
};

export const PauseAuctionForm = connect<null, DispatchProps, {}>(
    null,
    {
        pauseAuction: pauseAuctionAction
    }
)
(PauseAuctionFormComponent);
