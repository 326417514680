import {apiPost, matchesApiSuccess, reloadEntityListAction} from "@thekeytechnology/framework-react";
import {combineEpics} from "redux-observable";
import {API_UPDATE_COMMENT, UpdateCommentPayload} from "../actions/update-comment";
import {ENTITY_TYPE_LOG_ENTRY} from "../model/log-entry";
import {Observable} from "rxjs";
import {Action} from "redux";
import {map} from "rxjs/operators";

const updateComment$ = apiPost<UpdateCommentPayload, any>(
    {apiType: API_UPDATE_COMMENT},
    "/log-entries/update-comment"
);

const reloadOnCommendUpdate$ = (action$: Observable<Action>) => action$.pipe(
    matchesApiSuccess(API_UPDATE_COMMENT),
    map(() => reloadEntityListAction(ENTITY_TYPE_LOG_ENTRY)())
);

export const logModuleEpics$ = combineEpics(
    updateComment$,
    reloadOnCommendUpdate$
);
