import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withTranslation, WithTranslation} from "react-i18next";
import {connect} from "react-redux";
import {DialogResult, EntityWrapper, selectSingleEntity, showDialogAction} from "@thekeytechnology/framework-react";
import {Auction, ENTITY_TYPE_AUCTION} from "../../../auction/model/auction";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {finalizeAuctionAction} from "../../actions/finalize-auction";

interface StateProps {
    selectedAuction?: EntityWrapper<Auction>;
}

interface DispatchProps {
    finalize: typeof finalizeAuctionAction;
    showDialog: typeof showDialogAction;
}

type Props = WithTranslation & StateProps & DispatchProps;

const FinalizeButtonComponent = ({
                                     t,
                                     finalize,
                                     selectedAuction,
                                     showDialog
                                 }: Props) => {

    return selectedAuction ? <button type="button" onClick={() => {
        showDialog({
            title: t("finalize-button.dialog.title"),
            question: t("finalize-button.dialog.question"),
            explanation: t("finalize-button.dialog.explanation"),
            affirmativeText: t("finalize-button.dialog.affirmative"),
            negativeText: t("finalize-button.dialog.negative"),
            affirmativeButtonClass: "btn btn-success"
        }, (r: DialogResult) => {
            if (r === DialogResult.ACCEPT) {
                finalize(selectedAuction.id as string);
            }
        });
    }} className="btn btn-secondary ml-auto">
        <FontAwesomeIcon className="mr-2"
                         icon={faCheck}/>{t("finalize-button.button")}
    </button> : null;
};

export const FinalizeButton = connect<StateProps, DispatchProps, {}>(
    (state: any) => ({
        selectedAuction: selectSingleEntity<Auction>(ENTITY_TYPE_AUCTION)(state),
    }),
    {
        finalize: finalizeAuctionAction,
        showDialog: showDialogAction
    }
)(withTranslation("auctionPlanning")(FinalizeButtonComponent));
