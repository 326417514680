import React from "react";
import {LoginForm} from "./LoginForm";
import {withTranslation, WithTranslation} from "react-i18next";

type Props = WithTranslation;

const LoginComponent = (props: Props) => {
    const {t} = props;
    return (
        <>
            <div className={"h-100 row login justify-content-center align-content-center"}>
                <div className={"row col-12 col-md-10 col-lg-8"}>
                    <div className={"col-6 p-3"}>
                        <h1>{t("auth.login.heading")}</h1>
                        <p>
                            {t("auth.login.text")}
                        </p>
                        <LoginForm/>
                    </div>
                </div>
            </div>
        </>
    );
};

export const Login = withTranslation("auth")(LoginComponent);
