import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_ASSIGN_WINNING_BID = "assign-winning-bid";

export interface AssignWinningBidPayload extends Action {
    bidId: string;
    customerId: string;
    source: string;
    tel: string;
    comment: string;
}

export const assignWinningBidAction = (
    bidId: string,
    customerId: string,
    source: string,
    tel: string,
    comment: string
) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_ASSIGN_WINNING_BID,
            callType: API_ASSIGN_WINNING_BID
        },
        payload: {
            bidId,
            customerId,
            source,
            tel,
            comment
        }
    } as ApiCallAction<AssignWinningBidPayload>;
};
