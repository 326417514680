import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_DOWNLOAD_CUSTOMERS_CSV = "download-customers-csv";

export const downloadCustomersCsvAction = () => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_DOWNLOAD_CUSTOMERS_CSV,
            callType: API_DOWNLOAD_CUSTOMERS_CSV
        },
        payload: null
    } as ApiCallAction<null>;
};
