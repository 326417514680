import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_CONCLUDE_LIVE_AUCTION = "conclude-live-auction";

export const concludeAuctionAction = {
    type: ApiCallAction,
    apiQualifier: {
        apiType: API_CONCLUDE_LIVE_AUCTION,
        callType: API_CONCLUDE_LIVE_AUCTION
    },
    payload: {}
} as ApiCallAction<{}>;

