import {
    ApiCallState,
    EntityWrapper,
    Pagination,
    SortQuery,
    WithSelections,
    WithSelectionsProps
} from "@thekeytechnology/framework-react";
import React from "react";
import "./../../../assets/scss/table.scss"
import {
    AboveTableContainer,
    AsyncEntityTableBody,
    EntityDeletionButton,
    EntityTableHeader,
    SelectionTableColumn,
    Table,
    TableColumn,
    TableRow,
    UnconnectedPaginationInfo
} from "@thekeytechnology/framework-react-ux";
import {useTranslation} from "react-i18next";
import "./assigned-lots-table.scss";
import {ItemInLot} from "../../lots/model/ItemInLot";
import {GroupLotsButton} from "./group/GroupLotsButton";
import {UngroupLotsButton} from "./group/UngroupLotsButton";
import {InlineDescriptionEditor} from "./inline-editing/InlineDescriptionEditor";
import {InlinePriceEditor} from "./inline-editing/InlinePriceEditor";
import {InlineCategoryEditor} from "./inline-editing/InlineCategoryEditor";
import {InlineSellerEditor} from "./inline-editing/InlineSellerEditor";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {BackToSellerButton} from "./backtoseller/BackToSellerButton";
import {AuctionManagerLot} from "../../lots/model/AuctionManagerLot";
import {PaginatedEndpointSortableTableHeaderColumn} from "../../paginated-endpoints/components/PaginatedEndpointSortableTableHeaderColumn";
import {WinningBidDisplay} from "./winning-bids/WinningBidDisplay";
import {ManualAssignWinningBidForm} from "./winning-bids/ManualAssignWinningBidForm";
import {LOT_STATUS_NOT_SOLD} from "@thekeytechnology/auktionshaus-frontend-library";
import {LotDetailsButton} from "./lot-details/LotDetailsButton";

interface OwnProps {
    lots: Array<EntityWrapper<AuctionManagerLot>>;
    apiCallState: ApiCallState;
    isInPlanning: boolean;
    isConcluded: boolean;
    isLive: boolean;
    status: string;
    pagination: Pagination;
    sortQuery: SortQuery;
    setSortQuery: (sortQuery: SortQuery) => void;
}

type Props = WithSelectionsProps<string> & OwnProps;

const AuctionManagerLotsTableComponent = ({
                                              apiCallState,
                                              selectionModel,
                                              lots,
                                              isInPlanning,
                                              isConcluded,
                                              isLive,
                                              status,
                                              pagination,
                                              sortQuery,
                                              setSortQuery
                                          }: Props) => {

    const {t} = useTranslation(["auctionPlanning", "lot"])
    const currentLanguage = "de"

    return (
        <>
            {!isInPlanning && !isConcluded ?
                <div className="alert alert-warning">{t("assigned-lots-table.edit-warning")}</div> : null}

            <AboveTableContainer>
                <UnconnectedPaginationInfo pagination={pagination} className="mr-auto"/>

                {isInPlanning ? <>
                    <GroupLotsButton selectionModel={selectionModel}/>
                    <EntityDeletionButton
                        selectedEntities={selectionModel.selections}
                        entityType={AuctionManagerLot.TYPE}
                        label={t("lot:entity.singular")}
                        labelPlural={t("lot:entity.plural")}/>
                </> : null}
            </AboveTableContainer>

            <Table itemCount={lots.length}
                   className={"assigned-lots-table status-" + status}>
                <EntityTableHeader entities={lots} selectionModel={selectionModel}>
                    {!isInPlanning ? <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.lotNumber"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.lot-number-short")}
                    </PaginatedEndpointSortableTableHeaderColumn> : null}

                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.itemInventoryNumber"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.inventory-number-short")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    <PaginatedEndpointSortableTableHeaderColumn
                        property={`entity.description.${currentLanguage}`}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.short-description")}
                    </PaginatedEndpointSortableTableHeaderColumn>


                    {!(isConcluded || isLive) ? <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.pricingInformation.discountedLimitPrice"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.price")}
                    </PaginatedEndpointSortableTableHeaderColumn> : <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.winningBid.value"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.winning-bid")}
                    </PaginatedEndpointSortableTableHeaderColumn>}

                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.seller.entity.personalData.firstName"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.seller")}
                    </PaginatedEndpointSortableTableHeaderColumn>


                    {(isConcluded || isLive) ? <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.buyer.entity.personalData.firstName"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.buyer")}
                    </PaginatedEndpointSortableTableHeaderColumn> : null}

                    <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.category.entity.name.de"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.category")}
                    </PaginatedEndpointSortableTableHeaderColumn>

                    {!isInPlanning ? <PaginatedEndpointSortableTableHeaderColumn
                        property={"entity.status"}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    >
                        {t("lot:entity.labels.status")}
                    </PaginatedEndpointSortableTableHeaderColumn> : null}

                    {isInPlanning ? <TableColumn>{t("lot:entity.labels.source")}</TableColumn> : null}
                    <TableColumn>{t("lot:entity.labels.actions")}</TableColumn>
                </EntityTableHeader>
                <AsyncEntityTableBody<AuctionManagerLot>
                    apiCallState={apiCallState}
                    items={lots}
                    renderItem={(lot: EntityWrapper<AuctionManagerLot>) => {
                        const isGrouped = lot.entity.items.length > 1;
                        return <TableRow key={lot.id}
                                         className={lot.entity.errors.length > 1 ? "alert-warning" : undefined}>
                            <SelectionTableColumn<string> item={lot.id as string}
                                                          selectionModel={selectionModel}/>
                            {!isInPlanning ? <TableColumn className="d-flex flex-column">
                                {lot.entity.lotNumber}
                            </TableColumn> : null}
                            <TableColumn className="d-flex flex-column">
                                {lot.entity.items.map((item: ItemInLot) => {
                                    return <div key={item.id}>
                                        <NavLink to={`/objekte/${item.id}/edit`}>{item.inventoryNumber}</NavLink>
                                    </div>;
                                })}
                            </TableColumn>
                            <TableColumn>
                                <InlineDescriptionEditor
                                    editingEnabled={true}
                                    writeToItemEnabled={lot.entity.items.length === 1}
                                    lotId={lot.id as string}
                                    currentDescription={lot.entity.description}/>
                            </TableColumn>
                            <TableColumn>
                                {!(isConcluded || isLive) ? <InlinePriceEditor
                                        editingEnabled={true}
                                        writeToItemEnabled={lot.entity.items.length === 1}
                                        lotId={lot.id as string}
                                        pricingInformation={lot.entity.pricingInformation}/>
                                    : <>
                                        {lot.entity.winningBid ? <WinningBidDisplay lot={lot}/> : null}
                                        <ManualAssignWinningBidForm lot={lot}/>
                                    </>
                                }
                            </TableColumn>
                            <TableColumn>
                                <InlineSellerEditor
                                    editingEnabled={true}
                                    writeToItemEnabled={lot.entity.items.length === 1}
                                    lotId={lot.id as string}
                                    seller={lot.entity.seller}
                                />
                            </TableColumn>

                            {isConcluded || isLive ? <>
                                <TableColumn>
                                    {lot.entity.buyer ? (lot.entity.buyer.name + " (" + lot.entity.buyer.customerNumber) + ")" : null}
                                </TableColumn>
                            </> : null}

                            <TableColumn>
                                <InlineCategoryEditor
                                    editingEnabled={true}
                                    writeToItemEnabled={lot.entity.items.length === 1}
                                    lotId={lot.id as string}
                                    currentCategory={lot.entity.category}
                                />
                            </TableColumn>
                            {!isInPlanning ? <TableColumn className={"d-block"}>
                                {t("lot:status." + lot.entity.status)}
                                {lot.entity.orderStatus ? <small
                                    className={"d-block"}>{t("lot:order-status." + lot.entity.orderStatus)}</small> : ""}
                                {lot.entity.settlementStatus ? <small
                                    className={"d-block"}>{t("lot:settlement-status." + lot.entity.settlementStatus)}</small> : ""}
                                {lot.entity.transferStatus && lot.entity.status === LOT_STATUS_NOT_SOLD ? <small
                                    className={"d-block"}>{t("lot:transfer-status." + lot.entity.transferStatus)}</small> : ""}
                            </TableColumn> : null}
                            {isInPlanning ? <TableColumn>
                                {lot.entity.source ? t("lot:source." + lot.entity.source) : ""}
                                {lot.entity.errors.length > 0 ? <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-remove`}>
                                            {lot.entity.errors.map(error =>
                                                <div key={error}>
                                                    {t(`lot:error.${error}`)}
                                                </div>
                                            )}
                                        </Tooltip>
                                    }
                                >
                                    <span className="text-danger ml-2">
                                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                                    </span>
                                </OverlayTrigger> : null}
                            </TableColumn> : null}
                            <TableColumn>
                                <LotDetailsButton lot={lot} />
                                {isInPlanning && isGrouped ?
                                    <UngroupLotsButton selectionModel={selectionModel}
                                                       lotId={lot.id as string}/> : null}
                                {isInPlanning && !isGrouped ?
                                    <BackToSellerButton lotId={lot.id as string}
                                                        selectionModel={selectionModel}/> : null}

                            </TableColumn>
                        </TableRow>;
                    }}/>
            </Table>
        </>
    );
};

export const AuctionManagerLotsTable = WithSelections<OwnProps, string>(
    AuctionManagerLotsTableComponent
);
