import {EntityWrapper} from "@thekeytechnology/framework-react";
import {AUCTIONEER_UPDATED_ACTION, AuctioneerUpdatedAction} from "../actions/live-auction-updated-auctioneer";
import {AuctioneerLiveAuction} from "../model/auctioneer-live-auction";
import {Action} from "redux";
import {CONNECT_TO_AUCTIONEER_SOCKET} from "../actions/connect-to-auctioneer-socket-action";

export interface AuctioneerState {
    liveAuction?: EntityWrapper<AuctioneerLiveAuction>,
    connected: boolean
}

const initialState: AuctioneerState = {
    liveAuction: undefined,
    connected: false
};

export function AuctioneerLiveAuctionReducer(state = initialState, action: Action): AuctioneerState {
    if (action.type === AUCTIONEER_UPDATED_ACTION) {

        const updatedAction = action as AuctioneerUpdatedAction;

        return {
            ...state,
            liveAuction: updatedAction.liveAuction,
            connected: true
        };
    }

    if (action.type === CONNECT_TO_AUCTIONEER_SOCKET) {
        return {
            ...state,
            connected: false
        }
    }

    return state;
}
