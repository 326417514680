import {ApiCallAction, EntityWrapper} from "@thekeytechnology/framework-react";
import {UserForSaving} from "../model/UserForSaving";

export const API_SAVE_USER = "save-user";



export const saveUserAction = (user: EntityWrapper<UserForSaving>) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_SAVE_USER,
            callType: API_SAVE_USER
        },
        payload: user
    } as ApiCallAction<EntityWrapper<UserForSaving>>;
};
