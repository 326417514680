import {Action} from "redux";
import {ApiCallAction} from "@thekeytechnology/framework-react";

export const API_START_LOT_IMPORT = "star-lot-import";

export interface StartLotImportActionPayload extends Action {
    lotFileKey: string;
    imageZipKey: string;
    auctionId: string;
}

export const startLotImportAction = (lotFileKey: string, imageZipKey: string, auctionId: string) => {
    return {
        type: ApiCallAction,
        apiQualifier: {
            apiType: API_START_LOT_IMPORT,
            callType: API_START_LOT_IMPORT
        },
        payload: {
            lotFileKey,
            imageZipKey,
            auctionId
        }
    } as ApiCallAction<StartLotImportActionPayload>;
};
