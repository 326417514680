import {UserWithRoles} from "@thekeytechnology/framework-react";
import React from "react";
import {PrivateRoute} from "@thekeytechnology/framework-react-ux";
import {LogOverview} from "../components/LogDisplayOverview";

export const LogModuleRoutes = () => {
    return (
        <>
            <PrivateRoute exact
                          requiredRoles={[UserWithRoles.ROLE_ADMIN]}
                          path="/logs" component={LogOverview}/>
        </>

    );
};
