import {EntityWrapper} from "@thekeytechnology/framework-react";

export class AuctionAppSettings {
    public static TYPE = "auction-app-settings";

    public static new = () => new EntityWrapper<AuctionAppSettings>(
        undefined,
        new AuctionAppSettings(
            19
        )
    )

    constructor(
        public defaultTax?: number,
    ) {
    }
}